import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';

import routes from '@/resources/routes';

import useUsersTable from '@/hooks/useUsersTable';
import useUserInfo from '@/hooks/useUserInfo';

import ModalInvite from '@/modals/ModalInvite/ModalInvite';

const CommandPage = () => {
  const { t } = useTranslation(['common', 'command']);

  const history = useHistory();
  const { isLeader } = useUserInfo();

  const { config, data, isLoading } = useUsersTable(false);

  const openInviteModal = () => {
    ModalInvite.show(data);
  };

  const onGoBack = () => {
    history.push(routes.commands.link);
  };

  return (
    <>
      <PageTitle onGoBack={!isLeader && onGoBack}>{t('common:sidebar.employees')}</PageTitle>

      <PageHeaderContent label={t('common:roles.employee')} onClick={openInviteModal} />

      <Table isAutoWidth config={config} isLoading={isLoading} />
    </>
  );
};

export default CommandPage;
