import { styled, FormControlLabel, css } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledFormControlLabel = styled(FormControlLabel, transientProps)<{
  $isChecked: boolean;
}>`
  margin-top: 12px;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      margin-bottom: 12px;
    `}
`;
