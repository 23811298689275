import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ListItemButton, Typography } from '@mui/material';

import {
  StyledSidebarItemSimpleDeep,
  StyledSidebarItemSimpleDeepPopper,
} from '@/components/SidebarItemSimple/SidebarItemSimpleStyled';
import SidebarItemIcon from '@/components/SidebarItemIcon';

import { usePermissionsGranted } from '@/hooks/usePermissionGranted';
import useActiveRoute from '@/hooks/useActiveRoute';

import { SidebarItemWithoutItemsProps } from '@/types/sidebar';
import { selectSidebarState } from '@/redux/user/userGetters';

const SidebarItemSimple = ({
  name,
  path,
  icon,
  style,
  isDeep,
  onClick,
  isHidden,
  isDeepPopper,
  ...restProps
}: SidebarItemWithoutItemsProps) => {
  const items = useMemo(() => [{ path }], [path]);
  const linkProps = useMemo(() => {
    if (!path) return {};
    return { component: Link, to: path };
  }, [path]);

  const isSidebarOpen = useSelector(selectSidebarState);

  const isActiveRoute = useActiveRoute(items);
  const isAvailable = usePermissionsGranted(restProps.permissions);

  if (!isAvailable || isHidden) return null;

  if (isDeep) {
    if (isDeepPopper) {
      return (
        <StyledSidebarItemSimpleDeepPopper
          $isActive={isActiveRoute}
          onClick={onClick}
          variant='subtitle2'
          {...linkProps}
        >
          {name}
        </StyledSidebarItemSimpleDeepPopper>
      );
    }

    return (
      <StyledSidebarItemSimpleDeep $isActive={isActiveRoute}>
        <Typography variant='subtitle2' {...linkProps}>
          {name}
        </Typography>
      </StyledSidebarItemSimpleDeep>
    );
  }

  const className = [restProps.className, isActiveRoute && restProps.adviseKey]
    .filter(Boolean)
    .join(' ');

  return (
    <ListItemButton
      onClick={onClick}
      style={style}
      {...linkProps}
      className={className}
      selected={isActiveRoute}
    >
      {icon && <SidebarItemIcon $isActive={isActiveRoute}>{icon}</SidebarItemIcon>}
      {isSidebarOpen && <Typography variant='subtitle3'>{name}</Typography>}
    </ListItemButton>
  );
};

export default SidebarItemSimple;
