import { useTranslation } from 'react-i18next';
import { ChangeEvent, useCallback } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  Portal,
  Stack,
  TableFooter as LibraryTableFooter,
} from '@mui/material';

import { TableFooterProps } from '@/components/TableFooter/TableFooterTypes';
import { StyledSelect, StyledWrapper } from '@/components/TableFooter/TableFooterStyled';
// import { confirm } from '@/components/Confirm';
import Button from '@/components/Button';

import { limitPageOptions, ROUTER_CONTENT_ID } from '@/resources/constants';

const TableFooter = ({
  page,
  pages,
  isDisabled,
  limit,
  total,
  onDelete,
  onChangePage,
  onChangeLimit,
}: TableFooterProps) => {
  const { t } = useTranslation('common');

  const selector = document.getElementById(ROUTER_CONTENT_ID);

  const handleDelete = useCallback(async () => {
    // if (await confirm(t('questions.delete'))) {
    if (onDelete) onDelete();
    // }
  }, [onDelete]);

  const handleChangePage = useCallback(
    (_event: ChangeEvent<unknown>, page: number) => {
      onChangePage(String(page));
    },
    [onChangePage],
  );

  const renderButton = useCallback((props: PaginationRenderItemParams) => {
    return <PaginationItem {...props} />;
  }, []);

  const handleChangeLimit = useCallback((value: string) => onChangeLimit(value), [onChangeLimit]);

  if (isNaN(pages)) pages = 0;
  if (isNaN(total)) total = 0;

  // NOTE
  // Завязка на pages <= 1 некорректна!
  // Нам не нужно отображать футер, если данных меньше чем минимальное кол-во лимита
  const isTotalLessDefault = total <= Number(limitPageOptions[0].value);
  if (isTotalLessDefault && !onDelete) return null;

  return (
    <Portal container={selector}>
      <StyledWrapper>
        <LibraryTableFooter component='div' sx={{ flexDirection: 'row-reverse', width: '100%' }}>
          {onDelete && (
            <Button color='error' variant='outlined' onClick={handleDelete} disabled={isDisabled}>
              {t('pagination.deleteSelected')}
            </Button>
          )}

          <Stack
            width={'100%'}
            direction='row-reverse'
            spacing={6}
            justifyContent='space-between'
            alignItems='center'
          >
            <StyledSelect
              size='small'
              value={String(limit)}
              onSelect={handleChangeLimit}
              options={limitPageOptions}
            />

            {pages > 1 && (
              <Pagination
                page={page}
                count={pages}
                shape='rounded'
                variant='outlined'
                renderItem={renderButton}
                onChange={handleChangePage}
              />
            )}
          </Stack>
        </LibraryTableFooter>
      </StyledWrapper>
    </Portal>
  );
};

export default TableFooter;
