import { ApiOkResponse, ApiPaginationParams, ApiResponseWithPagination, Void } from '@/types/api';

export interface ApiCap {
  id: string;
  limit: number;
  deposits: number;

  caps: {
    isProblematic: boolean;

    limit: number;
    deposits: number;
    percentageForAlerts: number;
    id: string;
    offerId: string;
    comment: string;
    createdAt: string;
    updatedAt: string;

    user: { firstName?: string; id?: string; lastName?: string };
    company: { name?: string; id?: string };
    command: { name?: string; id?: string };
  }[];

  offerId: string;
  offerName: string;
}

export enum ApiCapStatus {
  NORMAL = 'normal',
  PROBLEMATIC = 'problematic',
}

// === GET CAPS ↴

export type ApiCapsGetQuery = Void<ApiPaginationParams & { status: ApiCapStatus }>;
export type ApiCapsGetResponse = ApiResponseWithPagination<ApiCap>;

// === CREATE CAP ↴

export type ApiCapsPostBody = {
  offerId: string;
  comment?: string;
  percentageForAlerts: number;
  caps: { limit: number; entity?: string; entityType: 'company' | 'command' | 'master' }[];
};
export type ApiCapsPostResponse = ApiResponseWithPagination<ApiCap>;

// === UPDATE CAP ↴

export type ApiCapsByIdPatchBody = ApiCapsPostBody;
export type ApiCapsByIdPatchResponse = ApiResponseWithPagination<ApiCap>;

// === DELETE CAP ↴

export type ApiCapsByIdDeleteBody = Pick<ApiCapsPostBody, 'offerId'> & { caps: string[] };
export type ApiCapsByIdDeleteResponse = ApiOkResponse;
