import { Components, Theme } from '@mui/material';

import { ZINDEX } from '@/resources/constants';

const ungroupedComponents: Components<Omit<Theme, 'components'>> = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,

        '&.Mui-expanded': {
          margin: theme.spacing(4, 0, 0),
        },

        '&::before': {
          display: 'none',
        },

        border: 'none',
        borderRadius: '6px',
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: { borderRadius: '4px !important' },
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        // vvdev-theme
        height: 72,
        borderRadius: 0,
        padding: theme.spacing(0, 3),
        justifyContent: 'center',

        border: 0,
        borderBottom: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.other.outlineBorder,

        // custom
        boxSizing: 'content-box',
      }),
    },
  },

  // @ts-ignore
  MuiPickersCalendarHeader: {
    styleOverrides: {
      label: {
        textTransform: 'capitalize',
      },
    },
  },

  MuiDayPicker: {
    styleOverrides: {
      header: {
        textTransform: 'capitalize',
      },
    },
  },

  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '4px 0',
        borderRadius: theme.shape.borderRadiusSecond,

        '& + &': {
          marginTop: '4px',
        },

        '&:hover': {
          background: theme.palette.background.default,
        },

        '&.Mui-selected': {
          '& .MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: '8px',
        minWidth: 'auto',
      },
    },
  },

  MuiPickersPopper: {
    styleOverrides: {
      root: {
        zIndex: ZINDEX.OVERLAY,
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      root: {
        zIndex: ZINDEX.OVERLAY,
      },
    },
  },

  MuiButtonBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-disabled': {
          '& svg > path': { stroke: theme.palette.action.disabled },
        },
      }),
    },
  },
};

export default ungroupedComponents;
