export enum FormLoginValues {
  EMAIL = 'email',
  COMPANY = 'company',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'passwordConfirm',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export interface FormLoginData {
  [FormLoginValues.EMAIL]: string;
  [FormLoginValues.COMPANY]: string;
  [FormLoginValues.PASSWORD]: string;
  [FormLoginValues.FIRST_NAME]: string;
  [FormLoginValues.LAST_NAME]: string;
}

export interface FormLoginProps {
  isForgotPassword?: boolean;
  setForgotPasswordState: (value: boolean) => void;
}
