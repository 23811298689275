import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';

import {
  FormRecoveryPasswordProps,
  FormRecoveryValues,
} from '@/pages/LoginPage/FormRecoveryPassword/FormRecoveryPasswordTypes';

import Typography from '@/components/Typography';
import FormElement from '@/components/FormElement';
import Button from '@/components/Button/Button';
import { StyledLoginForm } from '@/components/AuthPageForm';

import IconArrow from '@/icons/IconArrow';

import FormRuler from '@/utils/FormRuler';

import useBoolState from '@/hooks/useBoolState';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { useRecoveryPasswordMutation } from '@/services/auth/auth';

const FormRecoveryPassword = ({
  setForgotPasswordState,
  setRecoveryHash,
  recoveryHash,
}: FormRecoveryPasswordProps) => {
  const { t } = useTranslation(['login', 'common']);

  const methods = useForm();
  const newPassword = methods.watch(FormRecoveryValues.newPassword);

  const { state, setTrue } = useBoolState(false);
  const [recoveryPassword, { isLoading }] = useRecoveryPasswordMutation();

  const handleClick = useCallback(() => {
    setRecoveryHash('');
    setForgotPasswordState(false);
  }, [setForgotPasswordState, setRecoveryHash]);

  const [handleSubmit] = useAwaitCallback(async (data) => {
    const response = await recoveryPassword({
      password: data[FormRecoveryValues.newPassword],
      recoveryHash,
    });

    if ('data' in response) {
      setTrue();
    }
  }, []);

  const config = useMemo(
    () =>
      state ? (
        <Grid item>
          <Typography variant='subtitle1' color='text.secondary'>
            {t('login:resetPassword.passwordChanged')}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item>
            <FormElement
              type='password'
              component='input'
              name={FormRecoveryValues.newPassword}
              label={t('common:formLabels.newPassword')}
              rules={{ ...FormRuler.requiredPassword }}
            />
            <FormElement
              type='password'
              component='input'
              name={FormRecoveryValues.passwordConfirm}
              label={t('common:formLabels.passwordConfirm')}
              rules={{ ...FormRuler.requiredPassword, ...FormRuler.comparePasswords(newPassword) }}
            />
          </Grid>

          <Grid item>
            <Button size='large' type='submit' fullWidth isLoading={isLoading}>
              {t('login:createPassword.submit')}
            </Button>
          </Grid>
        </>
      ),
    [isLoading, newPassword, state, t],
  );

  return (
    <StyledLoginForm onSubmit={handleSubmit} contextMethods={methods}>
      <Grid container direction='column' spacing={6}>
        <Grid item>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconArrow onClick={handleClick} color='#2374EE' />

            <Typography variant='h5'>{t('login:createPassword.title')}</Typography>
          </Stack>
        </Grid>

        {config}
      </Grid>
    </StyledLoginForm>
  );
};

export default FormRecoveryPassword;
