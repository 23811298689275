import { PropsWithChildren, useEffect, useState } from 'react';
import { Portal, Stack } from '@mui/material';

import Typography from '@/components/Typography';
import { TitleWrapper } from '@/components/PageTitle/PageTitleStyles';
import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';
import OnboardingTour from '@/components/OnboardingTour/OnboardingTour';

import IconArrow from '@/icons/IconArrow';

import { HEADER_TITLE_ID } from '@/resources/constants';

import useUserInfo from '@/hooks/useUserInfo';
import useOnboardingStatus from '@/hooks/useOnboardingStatus';

type PageTitleProps = PropsWithChildren<{
  onGoBack?: (() => void) | false;

  deleteCardsInTables?: boolean;
  onboardingSteps?: OnboardingSteps;
}>;

const PageTitle = ({
  children,
  onGoBack,
  onboardingSteps,
  deleteCardsInTables,
}: PageTitleProps) => {
  const [onboardingTourKey, setOnboardingTourKey] = useState(0);
  const [isOnboardingRunning, setOnboardingRunningState] = useState<boolean>(false);

  const { userInfo } = useUserInfo();
  const { status } = useOnboardingStatus();

  const selector = document.getElementById(HEADER_TITLE_ID);

  useEffect(() => {
    if (!onboardingSteps) return;
    if (status) return;

    setOnboardingRunningState(true);
  }, [onboardingSteps, status, userInfo]);

  const handleStartTour = () => {
    setOnboardingRunningState(true);
    setOnboardingTourKey((key) => key + 1);
  };

  if (!selector) return null;

  return (
    <Portal container={selector}>
      <Stack direction='row' spacing={6} alignItems={'center'}>
        {onGoBack && <IconArrow onClick={onGoBack} />}

        <TitleWrapper>
          <Typography variant='h6' color='text.primary'>
            {children}
          </Typography>

          {onboardingSteps?.length && (
            <OnboardingTour
              key={onboardingTourKey}
              deleteCardsInTables={Boolean(deleteCardsInTables)}
              onClick={handleStartTour}
              steps={onboardingSteps}
              run={isOnboardingRunning}
              spotlightPadding={12}
              continuous
            />
          )}
        </TitleWrapper>
      </Stack>
    </Portal>
  );
};

export default PageTitle;
