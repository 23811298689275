import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import { ApiUserRole } from '@/services/auth/auth.types';

const title: OnboardingSteps[0]['title'] = 'Offers.title';

export const onboardingOffersPage = [
  {
    title,
    target: 'page-offers',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'Offers.step1.title',
      message: 'Offers.step1.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    roleList: [
      ApiUserRole.LEAD_USER,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.OWNER,
    ],
    target: 'step-offers-2',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'Offers.step2.title',
      message: 'Offers.step2.message',
    },
  },
  {
    title,
    roleList: [
      ApiUserRole.LEAD_USER,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.OWNER,
    ],
    target: 'step-offers',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'Offers.step3.title',
      message: 'Offers.step3.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    roleList: [
      ApiUserRole.LEAD_USER,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.OWNER,
    ],
    target: 'step-offers-4',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'Offers.step4.title',
      message: 'Offers.step4.message',
    },
  },
  {
    title,
    target: 'step-offers-5',
    disableScrolling: true,
    disableScrollParentFix: true,
    placement: 'left',
    content: {
      title: 'Offers.step5.title',
      message: 'Offers.step5.message',
    },
  },
  {
    title,
    target: 'step-offers-6',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'Offers.step6.title',
      message: 'Offers.step6.message',
    },
  },
] as const satisfies OnboardingSteps;
