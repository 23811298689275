import CircularProgress from '@mui/material/CircularProgress';
import { css, styled } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledCircularProgress = styled(CircularProgress, transientProps)<{
  $isLeftCenter: boolean;
}>`
  position: absolute;

  ${({ $isLeftCenter }) =>
    $isLeftCenter &&
    css`
      left: 50%;
    `}
`;

export const StyledText = styled('span', transientProps)<{ $isLoading: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
`;
