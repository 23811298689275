import { styled } from '@mui/material';

import Form from '@/components/Form';

export const StyledLoginForm = styled(Form)`
  margin: ${({ theme }) => theme.spacing(4, 'auto', 4)};
  padding: ${({ theme }) => theme.spacing(6)};

  width: 100%;
  background: white;

  border-radius: ${({ theme }) => `${theme.shape.borderRadiusSecond}px`};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.other.outlineBorder};
` as typeof Form;
