import { styled } from '@mui/material';

import Select from '@/components/Select/Select';

import transientProps from '@/utils/transientProps';
import { BlockWrapper } from '@/utils/commonStyles';

export const EmptyTableWrapper = styled('div')`
  ${BlockWrapper};

  height: 154px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16px;

  .MuiTypography-root {
    ${({ theme }) => theme.typography.body2}
  }
`;

export const TableWrapper = styled('div')`
  margin-top: 16px;
`;

export const StyledSelect = styled(Select)`
  .MuiFormLabel-root {
    padding: 0;
  }
`;

export const DashboardsWrapper = styled('div', transientProps)<{ $isCurrentTab: boolean }>`
  display: ${({ $isCurrentTab }) => ($isCurrentTab ? 'auto' : 'none')};
`;
