import { styled, css, IconButton } from '@mui/material';

import Button from '@/components/Button';

import transientProps from '@/utils/transientProps';
import { BlockWrapper } from '@/utils/commonStyles';

export const StyledStatistic = styled('div', transientProps)<{
  $isFullWidth?: boolean;
  $isItemOfSeveralDashboards: boolean;
}>`
  margin-bottom: 16px;
  position: relative;

  ${({ $isFullWidth, $isItemOfSeveralDashboards }) =>
    $isFullWidth
      ? css`
          width: 100%;
          display: ${$isItemOfSeveralDashboards ? 'flex' : 'block'};
        `
      : css`
          width: calc(50% - 16px);
        `};
`;

export const StyledWrapper = styled('div')`
  ${BlockWrapper}
`;

export const DashboardSettingsWrapper = styled('div')`
  max-width: 538px;
  display: flex;
  margin-left: 16px;

  & > button {
    margin-left: 8px;
  }
`;

export const MainSettingsWrapper = styled('div')`
  width: 498px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > button {
    margin-top: 8px;
  }
`;

export const ParametersWithFilters = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  & > * + * {
    margin-top: 8px;
  }
`;

export const FiltersWrapper = styled('div', transientProps)<{ $filtersCount: number }>`
  display: grid;
  grid-template-rows: 40px 40px;
  gap: 8px;

  ${({ $filtersCount }) => css`
    ${$filtersCount === 3 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}
    ${$filtersCount === 2 &&
    css`
      grid-template-columns: 1fr;
    `}
  ${$filtersCount === 1 &&
    css`
      grid-template-rows: 40px;
    `}
  `}

  max-width: 100%;

  & > * {
    height: 40px;
    padding: 0;
    max-width: 474px !important;
  }

  & > *:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const StyledOptionsWrapper = styled('div', transientProps)<{
  $hasPadding?: boolean;
  $isJustifyCenter?: boolean;
}>`
  display: flex;
  align-items: center;

  margin-top: 8px;

  ${({ $isJustifyCenter }) =>
    $isJustifyCenter &&
    css`
      justify-content: center;
    `};

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 8px 12px;
      overflow-x: auto;
    `};

  .MuiTypography-root {
    width: 100%;
    text-align: center;
  }

  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  border-radius: ${({ theme }) => `${theme.shape.borderRadiusSecond}px`};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const ApplyButtons = styled('div')`
  display: flex;
  height: 20px;

  margin-top: 8px;

  & > .MuiTypography-root {
    flex: none;
    padding: 0 12px;
  }
`;

export const ViewTableButton = styled(Button)`
  align-self: flex-start;
`;

export const DeleteButton = styled(IconButton)`
  align-self: flex-start;
  padding: 4;
`;
