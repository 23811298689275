import { useTranslation } from 'react-i18next';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FocusEvent, useCallback, useEffect } from 'react';
import moment from 'moment';

import Loader from '@/components/Loader';
import Input from '@/components/Input';
import {
  FormContextType,
  SumWithCurrency,
} from '@/components/FormElementSumWithCurrency/FormElementSumWithCurrencyTypes';
import {
  FormElementContent,
  StyledSelect,
  StyledTypography,
} from '@/components/FormElementSumWithCurrency/FormElementSumWithCurrencyStyled';

import { SYSTEM_CURRENCY, dateFormatStartsWithYear } from '@/resources/constants';

import useFormValidation from '@/hooks/useFormValidation';
import useCurrencies from '@/hooks/useCurrencies';

import { FormElementSumWithCurrencyProps } from '@/types/form';
import { useLazyGetExchangeQuery } from '@/services/exchanges/exchanges';

let timeoutId: NodeJS.Timeout;

type ValueType = keyof SumWithCurrency;

const FormElementSumWithCurrency = ({
  name,
  rules,
  hideHelperText = false,
  description: propsDescription,
  component: _,
  dateOfOperation,
  isDisabledSelect = false,
  ...restProps
}: FormElementSumWithCurrencyProps) => {
  const { t } = useTranslation();

  const commonDescription = t('conversionIsPerformedAtRateOfSelectedSate');

  const { control, setValue, watch } = useFormContext<FormContextType>();
  const { fieldValidate } = useFormValidation(rules);

  const { currencies } = useCurrencies();
  const [getExchange, { isFetching, isError: isGetExchangeError }] = useLazyGetExchangeQuery();

  const formValues = watch(name);

  const render: ControllerProps<FormContextType>['render'] = useCallback(
    ({ field, fieldState }) => {
      const { isError, description: validateDescription } = fieldValidate(fieldState.error);

      const isCurrencyError = isError && !field.value?.currency;
      const isCurrencySumError = isError && !field.value?.currencySum;
      const isNotSystemCurrency =
        Boolean(field.value?.currency) && field.value?.currency !== SYSTEM_CURRENCY;

      const infoText =
        isNotSystemCurrency && Boolean(field.value?.currencySum) ? commonDescription : undefined;
      const description = isCurrencySumError ? validateDescription : propsDescription || infoText;

      const systemSumValue = { systemSum: formValues?.systemSum };

      const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        const cleanedValue = event.target.value.trim();
        field.onChange({ ...field.value, ...systemSumValue, currencySum: cleanedValue });
        field.onBlur();
      };

      const handleChangeValue = (key: ValueType, value: SumWithCurrency[ValueType]) => {
        const newValue = { ...field.value, ...systemSumValue, [key]: value };
        field.onChange(newValue);
      };

      return (
        <FormElementContent>
          <Input
            {...restProps}
            fullWidth
            value={field.value?.currencySum || ''}
            onBlur={handleOnBlur}
            error={isCurrencySumError}
            helperText={!hideHelperText ? description : undefined}
            onChange={(e) => handleChangeValue('currencySum', e.target.value)}
            InputProps={{
              endAdornment:
                isNotSystemCurrency &&
                !isGetExchangeError &&
                (isFetching ? (
                  <Loader isStatic size={20} />
                ) : (
                  Boolean(field.value?.currency && formValues?.systemSum) && (
                    <StyledTypography color='text.secondary'>{`~${formValues?.systemSum} $`}</StyledTypography>
                  )
                )),
            }}
          />
          <StyledSelect<false>
            fullWidth
            disableAutofill
            label={t('formLabels.currency')}
            options={currencies}
            isError={isCurrencyError}
            value={field.value?.currency}
            disabled={isDisabledSelect}
            onSelect={(value) => handleChangeValue('currency', value)}
          />
        </FormElementContent>
      );
    },
    [
      fieldValidate,
      commonDescription,
      propsDescription,
      formValues?.systemSum,
      restProps,
      hideHelperText,
      isGetExchangeError,
      isFetching,
      t,
      currencies,
      isDisabledSelect,
    ],
  );

  useEffect(() => {
    clearTimeout(timeoutId);

    const currency = formValues?.currency;
    const currencySum = formValues?.currencySum;

    if (!currencySum || !currency || currency === SYSTEM_CURRENCY) {
      setValue(`${name}.systemSum`, Number(currencySum || 0));
      return;
    }

    timeoutId = setTimeout(async () => {
      const date = moment(dateOfOperation).format(dateFormatStartsWithYear);
      const response = await getExchange({ value: Number(currencySum), currency, date });
      setValue(`${name}.systemSum`, response.data?.resultSum || 0);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [dateOfOperation, formValues?.currency, formValues?.currencySum, getExchange, name, setValue]);

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementSumWithCurrency;
