import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Checkbox, DialogActions } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { SYSTEM_CURRENCY } from '@/resources/constants';

import ucFirst from '@/utils/ucFirst';
import filterBoolean from '@/utils/filterBoolean';
import FormRuler from '@/utils/FormRuler';

import useUserCommand from '@/hooks/useUserCommand';
import useBoolState from '@/hooks/useBoolState';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { SelectItem } from '@/types/form';
import {
  ApiService,
  ApiServicePostBody,
  IntegrationService,
} from '@/services/services/services.types';
import {
  useCreateServiceMutation,
  useSyncServiceByIdMutation,
  useUpdateServiceByIdMutation,
} from '@/services/services/services';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalServiceData,
  FormModalServiceValues,
} from '@/modals/ModalService/ModalServiceTypes';
import { StyledFormControlLabel } from '@/modals/ModalService/ModalServiceStyled';

const integrationServiceOptions: SelectItem[] = [
  {
    label: ucFirst(IntegrationService.BROCARD),
    value: IntegrationService.BROCARD,
  },
];

const ModalService: StaticModalWrappedComponent<ApiService> = (props) => {
  const { t } = useTranslation(['common', 'service']);

  const hasAlreadyIntegrationWithService = Boolean(props.data?.apiIntegrationKey);

  const { state: isIntegrationWithService, toggle: setIntegrationWithServiceState } = useBoolState(
    hasAlreadyIntegrationWithService,
  );

  const { commandId } = useUserCommand();
  const [createService, { isLoading: isCreating }] = useCreateServiceMutation();
  const [updateService, { isLoading: isUpdating }] = useUpdateServiceByIdMutation();

  const [syncService] = useSyncServiceByIdMutation();
  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalServiceData) => {
      const body: ApiServicePostBody = {
        name: data[FormModalServiceValues.NAME],
        service: data[FormModalServiceValues.SERVICE],
        currency: data[FormModalServiceValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalServiceValues.SUM_WITH_CURRENCY].currencySum),
        cardPrice: data[FormModalServiceValues.SUM_WITH_CURRENCY].systemSum,
        commissionReturnFunds: Number(data[FormModalServiceValues.COMMISSION_RETURN_FUNDS]),
        commissionReplenishmentCard: Number(
          data[FormModalServiceValues.COMMISSION_REPLENISHMENT_CARD],
        ),
        commissionReplenishmentService: Number(
          data[FormModalServiceValues.COMMISSION_REPLENISHMENT_SERVICE],
        ),

        apiIntegration: !isIntegrationWithService
          ? undefined
          : {
              apiIntegrationKey: data[FormModalServiceValues.INTEGRATION_KEY],
              apiIntegrationName: data[FormModalServiceValues.INTEGRATION_NAME],
            },

        commandId: commandId,
        userIds: (data[FormModalServiceValues.USER_IDS] || [])
          .map((a) => a.value)
          .filter(filterBoolean),
      };

      const response = props.data
        ? await updateService({ ...body, id: props.data.id })
        : await createService(body);
      if (isIntegrationWithService && 'data' in response) {
        await syncService({ id: response.data.id });
      }
      if ('data' in response) {
        props.onClose();
      }
    },
    [createService, props, updateService, isIntegrationWithService],
  );
  const isEdit = Boolean(props.data);
  const title = isEdit ? t('service:editService') : t('service:addService');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  const defaultValues: FormModalServiceData = useMemo(
    () => ({
      [FormModalServiceValues.NAME]: props.data?.name || '',
      [FormModalServiceValues.SERVICE]: props.data?.service || '',
      [FormModalServiceValues.SUM_WITH_CURRENCY]: {
        systemSum: props.data?.cardPrice || 0,
        currency: props.data?.currency?.title || SYSTEM_CURRENCY,
        currencySum: String(props.data?.currencySum || 0),
      },
      [FormModalServiceValues.COMMISSION_RETURN_FUNDS]: String(
        props.data?.commissionReturnFunds || 0,
      ),
      [FormModalServiceValues.COMMISSION_REPLENISHMENT_CARD]: String(
        props.data?.commissionReplenishmentCard || 0,
      ),
      [FormModalServiceValues.COMMISSION_REPLENISHMENT_SERVICE]: String(
        props.data?.commissionReplenishmentService || 0,
      ),
      [FormModalServiceValues.USER_IDS]: (props.data?.users || []).map(({ id }) => ({
        id,
        value: id,
      })),
      [FormModalServiceValues.INTEGRATION_NAME]: props.data?.apiIntegrationName,
      [FormModalServiceValues.INTEGRATION_KEY]: props.data?.apiIntegrationKey || '',
    }),
    [props.data],
  );

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
        <FormElement
          component='input'
          label={t('common:tableLabels.name')}
          rules={FormRuler.requiredInput}
          name={FormModalServiceValues.NAME}
        />
        <FormElement
          component='input'
          label={t('common:tableLabels.service')}
          rules={FormRuler.requiredInput}
          name={FormModalServiceValues.SERVICE}
        />
        <StyledFormControlLabel
          label={t('service:apiIntegrationService')}
          control={
            <Checkbox
              checked={isIntegrationWithService}
              onChange={setIntegrationWithServiceState}
            />
          }
        />
        {isIntegrationWithService && (
          <>
            <FormElement
              disabled
              component='select'
              label={t('service:selectService')}
              values={integrationServiceOptions}
              name={FormModalServiceValues.INTEGRATION_NAME}
              rules={FormRuler.required}
            />
            <FormElement
              component='input'
              label={t('service:apiKey')}
              name={FormModalServiceValues.INTEGRATION_KEY}
              rules={FormRuler.required}
            />
          </>
        )}
        {!isIntegrationWithService ? (
          <>
            <FormElement
              isDecimalNumber
              disabled={isIntegrationWithService}
              component='input'
              label={t('common:formLabels.accountReplenishment')}
              rules={FormRuler.requiredNumberInput}
              name={FormModalServiceValues.COMMISSION_REPLENISHMENT_SERVICE}
            />
            <FormElement
              isDecimalNumber
              disabled={isIntegrationWithService}
              component='input'
              label={t('common:formLabels.cardReplenishment')}
              rules={FormRuler.requiredNumberInput}
              name={FormModalServiceValues.COMMISSION_REPLENISHMENT_CARD}
            />
            <FormElement
              isDecimalNumber
              disabled={isIntegrationWithService}
              component='input'
              label={t('common:formLabels.refundFunds')}
              rules={FormRuler.requiredNumberInput}
              name={FormModalServiceValues.COMMISSION_RETURN_FUNDS}
            />
            <FormElement
              isDecimalNumber
              disabled={isIntegrationWithService}
              component='sumWithCurrency'
              label={t('common:formLabels.cardCost')}
              rules={FormRuler.validateSumWithCurrency}
              name={FormModalServiceValues.SUM_WITH_CURRENCY}
            />

            <FormElement
              type='user'
              userId={undefined}
              component='picker'
              title={t('common:formLabels.addUser')}
              disableAutofill={isEdit}
              name={FormModalServiceValues.USER_IDS}
            />
          </>
        ) : (
          <Typography color='text.secondary' sx={{ marginTop: 4 }}>
            {t('service:apiIntegrationInfo')}
          </Typography>
        )}

        <DialogActions>
          <Button isLoading={isCreating || isUpdating} type='submit'>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiService>(ModalService);
