import { useState, useCallback, useEffect } from 'react';

import { TableProps, TableRowConfig } from '@/components/Table/TableTypes';

const useTableExpand = (onExpandRow?: TableProps['onExpandRow']) => {
  const [expandedList, setExpandedList] = useState<Array<TableRowConfig['id']>>([]);

  const handleExpandRow = useCallback((id: TableRowConfig['id'], isExpanded: boolean) => {
    setExpandedList((prev) => {
      if (isExpanded) return [...prev, id];
      if (prev.length === 1) return [];

      const index = prev.findIndex((a) => a === id);

      if (index === -1) return prev;

      const startArr = prev.splice(0, index);
      const endArr = prev.splice(index + 1, prev.length);

      return [...startArr, ...endArr];
    });
  }, []);

  useEffect(() => {
    if (!onExpandRow) return;
    onExpandRow(Boolean(expandedList.length));
  }, [expandedList.length, onExpandRow]);

  return {
    expandedList,
    handleExpandRow,
  };
};

export default useTableExpand;
