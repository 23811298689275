/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconPlus = ({ size = IconSize.s, color = 'white', ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} viewBox='0 0 24 24'>
      <path
        d='M12 4V12M12 12H20M12 12H4M12 12V20'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

export default IconPlus;
