import { useMemo } from 'react';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';

import { SelectItem } from '@/types/form';
import { UserState } from '@/types';

type HookResponse = SelectItem<string, UserState['command']>[];

// TODO: нейминг понять бы, но в голову локаничнее текущего пока не приходит
const useHighLevelUserCommands = (): {
  commands: HookResponse;
  commandsWithDolphinId: HookResponse;
} => {
  const { isHighLevelUser, isMultipleAccessToCommandUser, userInfo } = useUserInfo();

  const commands = useMemo(() => {
    if (isHighLevelUser) {
      return userInfo?.company.commands || [];
    }

    if (isMultipleAccessToCommandUser) {
      return [...(userInfo?.allowedCommands || []), userInfo?.command].filter(filterBoolean);
    }

    return [];
  }, [
    isHighLevelUser,
    isMultipleAccessToCommandUser,
    userInfo?.allowedCommands,
    userInfo?.command,
    userInfo?.company?.commands,
  ]);

  const commandsOptions: HookResponse = commands.map((a) => ({
    label: a.name,
    value: a.id,
    itemEntity: { commandId: a.id, isComandWithDolphin: Boolean(a.dolphinToken?.id) },
  }));

  const commandsOptionsWithDolphin = useMemo(
    () => commandsOptions.filter((command) => command.itemEntity?.isComandWithDolphin),
    [commandsOptions],
  );

  return { commands: commandsOptions, commandsWithDolphinId: commandsOptionsWithDolphin };
};

export default useHighLevelUserCommands;
