import { FormControlLabel, styled } from '@mui/material';

import Select from '@/components/Select';

export const StyledFormElementPicker = styled('div')`
  padding: 14px 24px;

  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.palette.other.outlineBorder};
`;

export const StyledFormElementPickerHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFormElementPickerBody = styled('div')`
  margin-top: 16px;

  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 8px;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

export const StyledSelect = styled(Select)`
  margin-right: 8px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 16px;
  width: 100%;
  border-radius: 6px;
  background: #fafafa;
`;
