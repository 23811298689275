import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';

import Select from '@/components/Select';
import {
  StyledFormElementPicker,
  StyledFormElementPickerHeader,
  StyledFormElementPickerBody,
} from '@/components/FormElementPicker/FormElementPickerStyled';
import {
  FormElementData,
  ValueObject,
} from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';
import FormElementConsumablePicker from '@/components/FormElementConsumablePicker';

import IconTrash from '@/icons/IconTrash';
import IconPlus from '@/icons/IconPlus';

import useUserCards from '@/hooks/useUserCards';
import { SelectValue } from '@/hooks/useControlSelectValue';
import useCommandMasters from '@/hooks/useCommandMasters';

import { FormElementPickerProps, SelectItem } from '@/types/form';

const FormElementPicker = ({
  name,
  type,
  title,
  userId,
  extraItems = [],
  disableAutofill = false,
  disableSelectMasters = false,
}: FormElementPickerProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext<FormElementData<string> | FormElementData<ValueObject>>();

  const [selectedOptions, setSelectedOptions] = useState<SelectValue<true>>([]);

  const { fields, append, remove, update } = useFieldArray({
    name,
    control,
  });

  useEffect(() => {
    if (!fields.length) return;

    const newSelectedOptions = fields.map((a) => a.value as string);
    setSelectedOptions(newSelectedOptions);
  }, [fields]);

  const { cards } = useUserCards(userId);
  const { masters } = useCommandMasters();

  const handleSelect = useCallback(
    (id: string, index: number) => (value: SelectValue) => {
      update(index, { id, value });
    },
    [update],
  );

  const handleDelete = useCallback(
    (_id: string, index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const handleAddItem = () => {
    append({ value: undefined, id: 'new' });
  };

  const getAvailableOptions = useCallback(
    (options: SelectItem[], value: string) => {
      return options.filter((item) =>
        item.value === value ? true : !selectedOptions.includes(item.value),
      );
    },
    [selectedOptions],
  );

  const configWithSelector = useMemo(() => {
    const label = type === 'user' ? t('roles.user') : t('formLabels.cardName');
    const options = type === 'user' ? [...masters] : [...cards];
    for (let i = 0; i < fields.length; i++) {
      if (!options.find((item) => item.value === fields[i].value)) {
        const item = extraItems.find((item) => item.value === fields[i].value);
        if (item) {
          options.unshift(item);
        }
      }
    }
    return fields.map(({ id, value }, i) => (
      <StyledFormElementPickerBody key={id}>
        <Select
          fullWidth
          size='small'
          label={label}
          value={value as string}
          onSelect={handleSelect(id, i)}
          disabled={disableSelectMasters}
          disableAutofill={disableAutofill}
          options={getAvailableOptions(options, value as string)}
        />
        <IconTrash onClick={handleDelete(id, i)} isDisabled={disableSelectMasters} />
      </StyledFormElementPickerBody>
    ));
  }, [
    type,
    t,
    masters,
    cards,
    fields,
    extraItems,
    handleSelect,
    disableSelectMasters,
    disableAutofill,
    getAvailableOptions,
    handleDelete,
  ]);

  const consumableConfiguration = useMemo(() => {
    return fields.map(({ id, value }, i) => (
      <FormElementConsumablePicker
        key={id}
        index={i}
        name={name}
        remove={remove}
        data={value as ValueObject}
        disableAutofill={disableAutofill}
      />
    ));
  }, [disableAutofill, fields, name, remove]);

  const config = type === 'consumable' ? consumableConfiguration : configWithSelector;

  return (
    <StyledFormElementPicker $isDisabled={disableSelectMasters}>
      <StyledFormElementPickerHeader>
        <Typography variant='h6'>{title}</Typography>
        <IconButton onClick={handleAddItem} color='primary' disabled={disableSelectMasters}>
          <IconPlus color={disableSelectMasters ? 'gray' : '#2374EE'} />
        </IconButton>
      </StyledFormElementPickerHeader>

      {config}
    </StyledFormElementPicker>
  );
};

export default FormElementPicker;
