import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';

import { TrackerFormValues, TrackerFormProps } from '@/components/TrackerForm/TrackerFormTypes';
import { StyledFormControlLabel } from '@/components/TrackerForm/TrackerFormStyled';
import FormElement from '@/components/FormElement';

import FormRuler from '@/utils/FormRuler';

import { SelectItem } from '@/types/form';
import { ApiCompanyTracker } from '@/services/companies/companies.types';

// Note: хардкод
const companyTrackerOptions: SelectItem[] = [
  {
    label: ApiCompanyTracker.KEITARO,
    value: ApiCompanyTracker.KEITARO,
  },
  {
    label: ApiCompanyTracker.BINOM,
    value: ApiCompanyTracker.BINOM,
  },
];

const TrackerForm = ({ isChecked, onChangeState, disabledSelect }: TrackerFormProps) => {
  const { t } = useTranslation('login');

  return (
    <>
      <StyledFormControlLabel
        $isChecked={isChecked}
        label={t('signUp.trackerForm.title')}
        control={
          <Checkbox checked={isChecked} onChange={onChangeState} disabled={disabledSelect} />
        }
      />

      {isChecked && (
        <>
          <FormElement
            disabled={disabledSelect}
            component='select'
            label={t('signUp.trackerForm.tracker')}
            values={companyTrackerOptions}
            name={TrackerFormValues.SERVICE}
            rules={FormRuler.required}
          />
          <FormElement
            component='input'
            label={t('signUp.trackerForm.url')}
            name={TrackerFormValues.API_URL}
            rules={{ ...FormRuler.url, ...FormRuler.required }}
          />
          <FormElement
            component='input'
            label={t('signUp.trackerForm.apiKey')}
            name={TrackerFormValues.API_KEY}
            rules={FormRuler.required}
          />
        </>
      )}
    </>
  );
};

export default TrackerForm;
