import { useTranslation } from 'react-i18next';

import useLocalizeCardStatus from '@/hooks/useLocalizeCardStatus';

import { ApiCardStatus } from '@/services/cards/cards.types';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

const useCardStatusFilter = () => {
  const { t } = useTranslation();

  const { Component, status } = usePageFilter('status', '');

  const { localizeCardStatus } = useLocalizeCardStatus();

  const options = [
    {
      label: localizeCardStatus(ApiCardStatus.OPEN),
      value: ApiCardStatus.OPEN,
    },
    {
      label: localizeCardStatus(ApiCardStatus.CLOSED),
      value: ApiCardStatus.CLOSED,
    },
  ];

  return {
    status: status || undefined,
    CardStatusFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.status')} options={options} />
    ),
  };
};

export default useCardStatusFilter;
