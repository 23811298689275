import { useTranslation } from 'react-i18next';

import useUserConsumableCategories from '@/hooks/useUserConsumableCategories';

import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

const useCategoryConsumablesFilter = () => {
  const { t } = useTranslation();

  const { Component, categoryId } = usePageFilter('categoryId', '');

  const { categories } = useUserConsumableCategories();

  return {
    categoryId: categoryId || undefined,
    CategoryConsumablesFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.category')} options={categories} />
    ),
  };
};

export default useCategoryConsumablesFilter;
