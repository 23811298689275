import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';
import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import { OperationMemberType } from '@/services/operations/operations.types';

export enum FormModalExpenseOperationValues {
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  NAME = 'name',
  DATE = 'date',
  COMMENT = 'comment',
  MEMBERS = 'members',
  RECEIPT_ID = 'receiptId',
}

export interface FormModalExpenseOperationData {
  [FormModalExpenseOperationValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalExpenseOperationValues.NAME]: string;
  [FormModalExpenseOperationValues.DATE]: string;
  [FormModalExpenseOperationValues.COMMENT]: string;
  [FormModalExpenseOperationValues.RECEIPT_ID]: string;
  [FormModalExpenseOperationValues.MEMBERS]: ElementObject<MembersModelCustom>[];
}

export interface MembersModel {
  id: string;
  percent: string;
  type: OperationMemberType;
}

export interface MembersModelCustom extends Omit<MembersModel, 'id'> {
  memberId: string;
}
