import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DialogActions, Stack } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import convertDistributions from '@/utils/convertDistributions';
import FormRuler from '@/utils/FormRuler';

import useUserCommand from '@/hooks/useUserCommand';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiConsumable } from '@/services/consumables/consumables.types';
import { useConsumableDistributeMutation } from '@/services/consumables/consumables';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalConsumableDistributionData,
  FormModalConsumableDistributionValues,
} from '@/modals/ModalConsumableDistribution/ModalConsumableDistributionTypes';

const ModalConsumableDistribution: StaticModalWrappedComponent<ApiConsumable> = (props) => {
  const { t } = useTranslation();
  const methods = useForm<FormModalConsumableDistributionData>();

  const { commandId } = useUserCommand();
  const [consumableDistribute, { isLoading }] = useConsumableDistributeMutation();

  const [handleSubmit] = useAwaitCallback(
    async ({ distributions, date }: FormModalConsumableDistributionData) => {
      // NOTE: Type instantiation is excessively deep and possibly infinite.ts(2589)
      // @ts-ignore
      const newDistributions = convertDistributions(
        distributions,
        props.data?.amountAvailableForDistribution || 0,
        methods,
        t,
      );

      if (newDistributions === 'error') return;

      const response = await consumableDistribute({
        date,
        commandId,
        id: props.data?.id || '',
        distributions: newDistributions,
      });

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, consumableDistribute, methods, props],
  );

  if (!props.data) {
    return null;
  }

  const { name, amountAvailableForDistribution, date } = props.data;

  return (
    <Modal {...props} title={`${t('buttonActions.distribute')} «${name}»`}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <Stack direction='column' spacing={4}>
          <Typography color='primary.main'>
            {t('tableLabels.amountOfAvailable')}: {amountAvailableForDistribution}
          </Typography>

          <FormElement
            label={t('tableLabels.date')}
            component='datepicker'
            minDate={date}
            name={FormModalConsumableDistributionValues.DATE}
            rules={{
              ...FormRuler.required,
              ...FormRuler.validateDate(date, false, 'day'),
            }}
          />
          <FormElement
            type='consumable'
            component='pickerWithSelect'
            maxLength={String(amountAvailableForDistribution).length}
            name={FormModalConsumableDistributionValues.DISTRIBUTIONS}
          />
        </Stack>

        <DialogActions>
          <Button type='submit' variant='contained' isLoading={isLoading}>
            {t('buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiConsumable>(ModalConsumableDistribution);
