class Formatter {
  public static formatters: Record<string, Record<string, Intl.NumberFormat>> = {};

  public static generateFormatterInstance = (maximumFractionDigits: number, currency: string) => {
    const options: Intl.NumberFormatOptions = {
      currency,
      style: 'currency',
      maximumFractionDigits,
      minimumFractionDigits: maximumFractionDigits,
    };

    const instance = new Intl.NumberFormat('ru-RU', options);
    Formatter.formatters[currency] = { [maximumFractionDigits]: instance };

    return instance;
  };

  public static getFormatter = (value: number, currency: string) => {
    const isFractionalNumber = Math.floor(value) < value;
    const maximumFractionDigits = isFractionalNumber ? String(value).split('.')[1].length : 0;

    return (
      Formatter.formatters[currency]?.[maximumFractionDigits] ||
      Formatter.generateFormatterInstance(maximumFractionDigits, currency)
    );
  };
}

export const formatNumber = (value?: number | string, currency = 'USD') => {
  const preparedValue = Number(value || 0);
  const formatter = Formatter.getFormatter(preparedValue, currency);

  return formatter.format(preparedValue);
};
