import { useFormContext, ControllerProps, Controller } from 'react-hook-form';
import { useCallback } from 'react';
import { FormHelperText } from '@mui/material';

import {
  StyledCheckbox,
  StyledCheckboxContainer,
} from '@/components/FormElement/FormElementStyled';

import useFormValidation from '@/hooks/useFormValidation';

import { FormElementCheckboxProps } from '@/types/form';

const FormElementCheckbox = ({
  size,
  name,
  label,
  rules,
  disabled,
  isAlignItemsStart,
}: FormElementCheckboxProps) => {
  const { control } = useFormContext();
  const { fieldValidate } = useFormValidation();

  const render: ControllerProps['render'] = useCallback(
    ({ field, fieldState }) => {
      const { isError, description } = fieldValidate(fieldState.error);

      return (
        <>
          <StyledCheckboxContainer
            label={label}
            disabled={disabled}
            checked={Boolean(field.value)}
            $isAlignItemsStart={isAlignItemsStart}
            control={<StyledCheckbox {...field} size={size} />}
          />

          {!!description && <FormHelperText error={isError}>{description}</FormHelperText>}
        </>
      );
    },
    [disabled, fieldValidate, isAlignItemsStart, label, size],
  );

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementCheckbox;
