import { useTranslation } from 'react-i18next';
import { useFormContext, Controller, ControllerProps } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { MuiChipsInput } from 'mui-chips-input';

import useFormValidation from '@/hooks/useFormValidation';

import { FormElementInputChipsProps } from '@/types/form';

export interface FormContextType {
  [name: string]: string[];
}

const FormElementInputChips = ({
  name,
  rules,
  description: propsDescription,
  component: _,
  ...restProps
}: FormElementInputChipsProps) => {
  const { t } = useTranslation(['common']);

  const [defaultValues, setDefaultValues] = useState<string[]>([]);

  const { control, watch } = useFormContext<FormContextType>();
  const { fieldValidate } = useFormValidation(rules);

  const formValues = watch(name);

  const render: ControllerProps<FormContextType>['render'] = useCallback(
    ({ field, fieldState }) => {
      const { isError, description: validateDescription } = fieldValidate(fieldState.error);

      const description =
        validateDescription ||
        propsDescription ||
        (Boolean(field.value.length) && t('common:valuesCannotBeDeleted'));

      return (
        <MuiChipsInput
          {...restProps}
          {...field}
          addOnBlur
          fullWidth
          hideClearAll
          disableEdition
          error={isError}
          helperText={description}
          disableDeleteOnBackspace
          placeholder={t('common:enterBrocardID')}
          renderChip={(Component, key, props) => {
            return (
              // @ts-ignore
              <Component
                {...props}
                key={key}
                disabled={defaultValues.some((a) => a === props.label)}
              />
            );
          }}
        />
      );
    },
    [defaultValues, fieldValidate, propsDescription, restProps, t],
  );

  useEffect(() => {
    if (!formValues.length) return;
    setDefaultValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementInputChips;
