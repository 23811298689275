import QueryString from 'query-string';
import axios, { AxiosError } from 'axios';

import i18next from '@/resources/i18next';
import { Headers, STORE_USER_TOKEN } from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';
import Alerter from '@/utils/Alerter/Alerter';

import { LanguagesCode } from '@/types';

const baseURL = (process.env.BASE_URL || '') + '/api';

export const paramsSerializer = (params: Record<string, any>) => {
  return QueryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });
};

class Api {
  static setToken(token: string) {
    Api.call.defaults.headers['x-access-token'] = token;
  }

  static call = axios.create({
    baseURL,
    paramsSerializer,
    responseType: 'json',
  });

  public static setLanguage(lang: LanguagesCode) {
    if (lang) {
      Api.call.defaults.headers[Headers.lang] = lang;
    } else {
      delete Api.call.defaults.headers[Headers.lang];
    }
  }

  static handleDefaultError = (
    error: AxiosError & { isCancel?: boolean },
    handleCustomErrors?: (error: AxiosError) => void,
  ) => {
    const { response } = error;

    if (response) {
      const { status } = response;
      const message =
        response.data?.message || response.data?.error || i18next.t('common:serverRequestError');

      switch (status) {
        case 401:
          return;

        case 500:
          Alerter.show(message || i18next.t('common:serverRequestError'));
          return;

        default:
          if (handleCustomErrors) {
            handleCustomErrors(error);
            return;
          }
          Alerter.show(message);
          return;
      }
    }

    try {
      // eslint-disable-next-line no-console
      Alerter.show(error as any);
    } catch (err) {
      alert(err);
    }

    // eslint-disable-next-line no-console
    console.error(error);
  };
}

Api.setToken(LocalStorage.get(STORE_USER_TOKEN) || '');

export default Api;
