import { css } from '@mui/material/styles';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

import { EntityStatusStatuses } from '@/components/EntityStatus/EntityStatusTypes';

import transientProps from '@/utils/transientProps';

export const StyledEntityStatus = styled(Typography, transientProps)<{
  $status: EntityStatusStatuses;
}>`
  padding: 0px 8px;
  text-align: center;
  border-radius: 12px;

  // Default
  color: #53bc64;
  background: rgba(76, 175, 80, 0.08);

  ${({ $status }) =>
    ($status === 'active' || $status === 'open') &&
    css`
      color: #53bc64;
      background: rgba(76, 175, 80, 0.08);
    `}

  ${({ $status, theme }) =>
    $status === 'closed' &&
    css`
      color: ${theme.palette.error.main};
      background: rgba(255, 90, 90, 0.08);
    `}
  
    ${({ $status, theme }) =>
    ($status === 'archived' || $status === 'default') &&
    css`
      color: ${theme.palette.text.secondary};
      background: rgba(238, 238, 238, 0.88);
    `}
`;
