import React, { useMemo, useEffect } from 'react';
import { Checkbox, IconButton, TableCell } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { TableRowProps } from '@/components/TableRow/TableRowTypes';
import { StyledTableCell, StyledTableRow } from '@/components/TableRow/TableRowStyled';
import ChildrenTableRow from '@/components/ChildrenTableRow';

import { createUniqueKey } from '@/utils/createUniqueKey';

import useBoolState from '@/hooks/useBoolState';

const TableRow = ({
  config,
  className,
  isLastRow,
  onRowExpand,
  isChildren = false,
  hasIconButton = false,
  isCustomChildrenConfig = false,
  adviseKeyForTableRow,
  ...restProps
}: TableRowProps) => {
  const { state: isExpanded, toggle: toggleExpandedState, setFalse } = useBoolState(false);

  useEffect(() => {
    if (!config.childrenConfig?.length) setFalse();
  }, [config.childrenConfig?.length, setFalse]);

  useEffect(() => {
    if (onRowExpand) {
      onRowExpand(config.id, isExpanded);
    }
  }, [config.id, isExpanded, onRowExpand]);

  const rowCells = useMemo(() => {
    const icon = (config.childrenConfig || hasIconButton) && (
      <IconButton
        sx={{
          color: '#CCCDD3',
          opacity: hasIconButton ? 0 : 1,
          marginLeft: config.expandCellPlace === 'right' ? 2 : 0,
          marginRight: config.expandCellPlace === 'right' ? 0 : 2,
        }}
        className={adviseKeyForTableRow}
        disabled={hasIconButton}
        aria-label='expand row'
        size='small'
        onClick={toggleExpandedState}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    );

    const expandedIndex = Math.max(config.expandCellIndex || 0, 0);
    const expandedPlace = config.expandCellPlace || 'left';

    const result = config.data.map((cell, i) => {
      const key = createUniqueKey();
      const isLastCell = config.data.length === i + 1;

      return (
        <StyledTableCell
          key={key}
          width={cell.width}
          align={cell.align}
          $isLastRow={isLastRow}
          $isLastCell={isLastCell}
          $isChildren={isChildren}
          $maxWidth={cell.maxWidth}
          $minWidth={cell.minWidth}
          $isCustomChildrenConfig={isCustomChildrenConfig}
        >
          {expandedIndex === i && expandedPlace === 'left' && icon}
          {cell.value}
          {expandedIndex === i && expandedPlace === 'right' && icon}
        </StyledTableCell>
      );
    });

    if ('withSelection' in restProps && restProps.withSelection) {
      result.unshift(
        <TableCell key={'body-checkbox'} padding='checkbox'>
          {!config.childrenConfig && (
            <Checkbox color='primary' onChange={restProps.onSelect} checked={restProps.isChecked} />
          )}
        </TableCell>,
      );
    }

    return result;
  }, [
    config.childrenConfig,
    config.expandCellPlace,
    config.expandCellIndex,
    config.data,
    hasIconButton,
    adviseKeyForTableRow,
    toggleExpandedState,
    isExpanded,
    restProps,
    isLastRow,
    isChildren,
    isCustomChildrenConfig,
  ]);

  const childrenRows = useMemo(() => {
    if (!config.childrenConfig) return null;

    return config.childrenConfig.map((rowConfig, i) => {
      const isLastRow = config.childrenConfig?.length === i + 1;
      return (
        <TableRow
          isChildren
          isLastRow={isLastRow}
          key={rowConfig.id}
          config={rowConfig}
          isChecked={false}
          onSelect={() => {}}
          withSelection={restProps.withSelection}
          isCustomChildrenConfig={isCustomChildrenConfig}
        />
      );
    });
  }, [config.childrenConfig, isCustomChildrenConfig, restProps.withSelection]);

  return (
    <React.Fragment>
      <StyledTableRow
        $isCustomChildrenConfig={isCustomChildrenConfig}
        onClick={config.onRowClick}
        $isPointer={Boolean(config.onRowClick)}
        $isChildren={isChildren}
        className={className}
        role='checkbox'
        aria-checked={false}
        hover
      >
        {rowCells}
      </StyledTableRow>

      {isExpanded && isCustomChildrenConfig && Boolean(childrenRows?.length) && <>{childrenRows}</>}

      {childrenRows &&
        childrenRows.length > 0 &&
        isExpanded &&
        config.childrenConfig &&
        !isCustomChildrenConfig && (
          <ChildrenTableRow
            childrenRows={childrenRows}
            colspanLength={config.data.length}
            childrenConfig={config.childrenConfig}
          />
        )}
    </React.Fragment>
  );
};

export default TableRow;
