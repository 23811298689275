import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

import { DashboardsFilters } from '@/pages/DashboardsPage/components/DashboardsFilters';
import { DashboardTable } from '@/pages/DashboardsPage/components/DashboardTable';
import { DashboardsWrapper } from '@/pages/DashboardsPage/DashboardsPageStyled';
import { DashboardSettings, PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { Statistic } from '@/components/Statistic/Statistic';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent/PageHeaderContent';
import Loader from '@/components/Loader';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';
import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import { useSettingsDashboardFilters } from '@/hooks/useSettingsDashboardFilters';
import { useDashboardsPageCurrentTab } from '@/hooks/useDashboardsPageCurrentTab';

import {
  ApiDashboardSettings,
  ApiDashboardSettingsGetResponse,
} from '@/services/dashboard/dashboard.types';
import {
  useDeleteDashboardSettingsMutation,
  useGetDashboardSettingsQuery,
} from '@/services/dashboard/dashboard';
import FilterFromTo from '@/filters/FilterFromTo';

const getEmptySettingsDashboard = (tab: PossibleTabs): DashboardSettings => {
  return {
    tab,
    isLocale: true,
    usersIds: [],
    parameters: [],
    commandsIds: [],
    id: String(Math.random()),
  };
};

const mapOriginSettings = (a: ApiDashboardSettings) => ({
  ...a,
  isLocale: false,
});

const getDefaultSettingsDashboards = (
  settings: ApiDashboardSettingsGetResponse | undefined,
): Record<PossibleTabs, DashboardSettings[]> => {
  const result = {
    [PossibleTabs.masters]: (settings?.[PossibleTabs.masters] || []).map(mapOriginSettings),
    [PossibleTabs.commands]: (settings?.[PossibleTabs.commands] || []).map(mapOriginSettings),
    [PossibleTabs.parameters]: (settings?.[PossibleTabs.parameters] || []).map(mapOriginSettings),
  };

  for (const k in result) {
    const key = k as PossibleTabs;

    if (result[key] && !result[key].length) {
      result[key].push(getEmptySettingsDashboard(key));
    }
  }

  return result;
};

type StatisticType<T extends boolean = false> = {
  deletingItems?: Record<string, boolean>;
  settings: T extends true ? DashboardSettings[] : DashboardSettings;

  createDeleteDashboardHandler?: (id: string) => () => void;
};

interface SingleStatisticProps extends StatisticType {
  hasTabs?: boolean;
}

export const SingleStatistic = ({ settings, hasTabs = true }: SingleStatisticProps) => {
  const { filters, updateFilters, isUpdating } = useSettingsDashboardFilters(settings);

  return (
    <>
      <FiltersWithSidebar
        // Note: проп hasFilterFromTo добавляет +1 к количеству children.
        // т.к. мы передаем всегда 2 children:
        // (FilterFromTo и {!isSeveralDashboards && <DashboardsFilters />}).
        // Нам нужно чтобы компонент FiltersWithSidebar думал что указан только
        // один child (FilterFromTo) когда DashboardsFilters не нужно отображать в сайдбаре
        hasFilterFromTo
        hasCommandSelect={false}
        isIntoDashboardsPage={hasTabs}
      >
        <FilterFromTo
          isClearable
          popperProps={{
            popperOptions: { placement: 'bottom-end' },
            // NOTE:
            // т.к. размер инпута меняется в сайдбаре, календарь
            // наезжает на инпут. Такоре решение скорее костыльное.
            // Возможно есть способ получше
            sx: { marginTop: '8px !important' },
          }}
        />

        <DashboardsFilters
          settings={settings}
          commandsIds={filters.commands}
          mastersIds={filters.masters}
          parameters={filters.parameters}
          inputSize='medium'
          onChangeFilter={updateFilters}
          isLoading={isUpdating}
        />
      </FiltersWithSidebar>

      <Statistic settings={settings} />

      <DashboardTable data={settings} />
    </>
  );
};

const MultipleStatistics = ({
  settings,
  createDeleteDashboardHandler,
  deletingItems,
}: StatisticType<true>) => {
  return (
    <>
      <FiltersWithSidebar hasCommandSelect={false} isIntoDashboardsPage>
        <FilterFromTo isClearable />
      </FiltersWithSidebar>

      {settings.map((dashboard) => {
        return (
          <Statistic
            key={dashboard.id}
            settings={dashboard}
            isSeveralDashboardsItem
            isDeleting={deletingItems?.[dashboard.id]}
            deleteDashboard={createDeleteDashboardHandler?.(dashboard?.id)}
          />
        );
      })}
    </>
  );
};

export const DashboardsPage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(['common', 'dashboard']);

  const { currentTab } = useDashboardsPageCurrentTab();
  const { data: settings, isLoading: isFetching } = useGetDashboardSettingsQuery();

  const [dashboardsSettings, setDashboardsSettings] = useState<
    Record<PossibleTabs, DashboardSettings[]>
  >({
    [PossibleTabs.masters]: [],
    [PossibleTabs.commands]: [],
    [PossibleTabs.parameters]: [],
  });

  const currentDashboardsSettings = dashboardsSettings[currentTab];

  const addDashboard = useCallback(() => {
    if (currentDashboardsSettings.length < 10) {
      setDashboardsSettings(() => {
        const result = {
          ...dashboardsSettings,
          [currentTab]: [...currentDashboardsSettings, getEmptySettingsDashboard(currentTab)],
        };

        return result;
      });
    }
  }, [currentDashboardsSettings, currentTab, dashboardsSettings]);

  const [deleteDashboardQuery] = useDeleteDashboardSettingsMutation();
  const [deletingItems, setDeletingItems] = useState<Record<string, boolean>>({});

  const createDeleteDashboardHandler = useCallback(
    (id: string) => async () => {
      const item = currentDashboardsSettings.find((d) => d.id === id);

      const deleteDashboardFromLayout = () =>
        setDashboardsSettings(() => {
          const result = {
            ...dashboardsSettings,
            [currentTab]: currentDashboardsSettings.filter((d) => d.id !== id),
          };

          return result;
        });

      if (!item?.isLocale) {
        try {
          setDeletingItems((state) => {
            const result = { ...state };
            result[id] = true;
            return result;
          });
          await deleteDashboardQuery({ id });
          deleteDashboardFromLayout();
        } catch {
          console.log('ошибка удаления');
        } finally {
          setDeletingItems((state) => {
            const result = { ...state };
            delete result[id];
            return result;
          });
        }
      } else {
        deleteDashboardFromLayout();
      }
    },
    [currentDashboardsSettings, currentTab, dashboardsSettings, deleteDashboardQuery],
  );

  useEffect(() => {
    if (isFetching) return;
    if (!settings) return;

    setDashboardsSettings(getDefaultSettingsDashboards(settings));
  }, [isFetching, settings]);

  if (isFetching) {
    return <Loader isCenter isStatic />;
  }

  return (
    <>
      <PageTitle>{t('sidebar.dashboard')}</PageTitle>

      <PageHeaderContent>
        <Button
          size='large'
          variant='outlined'
          onClick={addDashboard}
          disabled={
            currentDashboardsSettings.length === 10 ||
            currentDashboardsSettings[currentDashboardsSettings.length - 1]?.isLocale
          }
          startIcon={<IconPlus color={palette.primary.main} />}
        >
          {t('dashboard:oneDashboard')}
        </Button>
      </PageHeaderContent>

      {dashboardsSettings &&
        Object.keys(dashboardsSettings).map((a) => {
          return (
            <DashboardsWrapper $isCurrentTab={a === currentTab} key={a}>
              {dashboardsSettings[a].length > 1 ? (
                <MultipleStatistics
                  deletingItems={deletingItems}
                  settings={dashboardsSettings[a]}
                  createDeleteDashboardHandler={createDeleteDashboardHandler}
                />
              ) : (
                <SingleStatistic
                  // NOTE:
                  // применяем изменяемый key для
                  // remount компонента, чтобы он
                  // обновил данные
                  deletingItems={deletingItems}
                  key={dashboardsSettings[a][0]?.id || 'locale'}
                  createDeleteDashboardHandler={createDeleteDashboardHandler}
                  settings={dashboardsSettings[a][0] || getEmptySettingsDashboard(currentTab)}
                />
              )}
            </DashboardsWrapper>
          );
        })}
    </>
  );
};
