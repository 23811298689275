import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import Select from '@/components/Select';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';

import { onboardingPipesPage } from '@/resources/onboarding/pipesPage';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import usePagination from '@/hooks/usePagination';
import useLocalizePipeStatus from '@/hooks/useLocalizePipeStatus';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { ApiPipe, ApiPipeStatus } from '@/services/pipes/pipes.types';
import {
  useChangePipesArchiveStatusMutation,
  useChangePipeStatusByIdMutation,
  useGetPipesQuery,
} from '@/services/pipes/pipes';
import { ApiCard } from '@/services/cards/cards.types';
import ModalPipeConsumablesList from '@/modals/ModalPipeConsumablesList/ModalPipeConsumablesList';
import ModalPipe from '@/modals/ModalPipe/ModalPipe';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import usePipeStatusFilter, { getPipeStatusOptions } from '@/filters/usePipeStatusFilter';
import FilterArchive from '@/filters/FilterArchive';

const PipesPage = () => {
  const { t } = useTranslation(['common', 'pipes']);

  const { isMaster } = useUserInfo();

  const { commandId } = useUserCommand();
  const { status, PipeStatusFilter } = usePipeStatusFilter();

  const [changeArchivedStatus] = useChangePipesArchiveStatusMutation();
  const { isArchived, prepareArchiveColumns } = useArchiveHelpers(changeArchivedStatus, (name) =>
    t('pipes:confirmArchive', { name }),
  );
  const { pipes, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'pipes',
    useQuery: useGetPipesQuery,
    queryArg: { commandId, status, isArchived },
  });

  const [updatePipeStatus] = useChangePipeStatusByIdMutation();

  const { localizePipeStatus } = useLocalizePipeStatus();
  const pipeStatusOptions = getPipeStatusOptions(localizePipeStatus);

  const openCreatePipeModal = () => {
    ModalPipe.show();
  };

  const openEditPipeModal = (pipe: ApiPipe) => () => {
    ModalPipe.show(pipe);
  };

  const openModalPipeConsumables = (pipe: ApiPipe) => () => {
    ModalPipeConsumablesList.show(pipe);
  };

  const renderCards = useCallback((cards?: ApiCard[]) => {
    if (!cards || cards.length === 0) {
      return '-';
    }

    const content = cards.map((a) => <div key={a.card}>**{a.card.slice(-4)}</div>);

    return <div>{content}</div>;
  }, []);

  const handlePipeStatusChange = useCallback(
    (id: string) => (status: string) => {
      const typedStatus = status as ApiPipeStatus;

      if (typedStatus !== ApiPipeStatus.OFF) {
        updatePipeStatus({ id, status: typedStatus, commandId });
        return;
      }

      ModalConfirm.show({
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('pipes:confirmTurnOffPipe'),
        onContinue: () => updatePipeStatus({ id, status: typedStatus, commandId }),
      });
    },
    [commandId, t, updatePipeStatus],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiPipe[] = pipes?.list || [];

    return list.map((item) => {
      const actionProps: TableRowActionsProps = {
        isComment: true,
        isIconEdit: true,
        callout: item.comment,
        onEdit: openEditPipeModal(item),
      };
      prepareArchiveColumns(item, actionProps);
      return {
        id: item.id,
        data: [
          !isMaster && {
            label: t('common:roles.user'),
            value: getUserNickname(item.user),
          },
          {
            label: t('common:tableLabels.name'),
            value: item.name,
          },
          {
            label: t('common:tableLabels.offer'),
            value: item.offer.name,
          },
          {
            label: t('common:tableLabels.consumable'),
            value: <TableRowActions informationInModal={openModalPipeConsumables(item)} />,
          },
          {
            label: t('common:sidebar.cards'),
            value: renderCards(item.cards),
          },
          {
            label: t('common:tableLabels.status'),
            value: (
              <Select
                className={onboardingPipesPage[2].target}
                label={t('common:tableLabels.status')}
                size='small'
                width='168px'
                disabled={item.isArchived}
                value={item.status}
                options={pipeStatusOptions}
                onSelect={handlePipeStatusChange(item.id)}
              />
            ),
          },
          {
            label: t('common:tableLabels.costs'),
            value: formatNumber(item.costs),
          },
          {
            label: t('common:tableLabels.costsFB'),
            value: formatNumber(item.dolphinSpending),
          },
          {
            label: '',
            value: <TableRowActions {...actionProps} />,
            width: '100px',
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    handlePipeStatusChange,
    prepareArchiveColumns,
    isMaster,
    pipeStatusOptions,
    pipes?.list,
    renderCards,
    t,
  ]);

  return (
    <>
      <PageTitle onboardingSteps={onboardingPipesPage} deleteCardsInTables={config.length === 0}>
        {t('common:sidebar.pipes')}
      </PageTitle>

      <PageHeaderContent
        label={t('common:tableLabels.pipe')}
        adviseTarget={onboardingPipesPage[1].target}
        onClick={openCreatePipeModal}
      />

      <FiltersWithSidebar>
        <PipeStatusFilter disableAutofill />
        <FilterArchive />
      </FiltersWithSidebar>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(pipes?.meta)}
      />
    </>
  );
};

export default PipesPage;
