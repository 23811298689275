/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconTransferCart = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4231 15.5132C18.2001 15.5132 18.0193 15.694 18.0193 15.917V21.1093C18.0193 21.3324 18.2001 21.5132 18.4231 21.5132H23.6154C23.8385 21.5132 24.0193 21.3324 24.0193 21.1093V15.917C24.0193 15.694 23.8385 15.5132 23.6154 15.5132H18.4231ZM16.5193 15.917C16.5193 14.8656 17.3717 14.0132 18.4231 14.0132H23.6154C24.6669 14.0132 25.5193 14.8656 25.5193 15.917V21.1093C25.5193 22.1608 24.6669 23.0132 23.6154 23.0132H18.4231C17.3717 23.0132 16.5193 22.1608 16.5193 21.1093V15.917Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7886 25.1477C14.7886 24.7335 15.1244 24.3977 15.5386 24.3977H24.3225C24.822 24.3998 25.3006 24.5981 25.6552 24.9498L25.6618 24.9564L27.0348 26.3526C27.3253 26.6479 27.3213 27.1228 27.026 27.4132C26.7307 27.7036 26.2558 27.6997 25.9654 27.4044L24.5964 26.0124C24.5217 25.9396 24.4217 25.8984 24.3174 25.8977H15.5386C15.1244 25.8977 14.7886 25.5619 14.7886 25.1477Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.75 11.8784C10.75 11.4642 11.0858 11.1284 11.5 11.1284H12.6538C13.1588 11.1284 13.643 11.329 14.0001 11.686C14.3571 12.0431 14.5577 12.5273 14.5577 13.0323V22.84C14.5577 23.2542 14.2219 23.59 13.8077 23.59C13.3935 23.59 13.0577 23.2542 13.0577 22.84V13.0323C13.0577 12.9252 13.0151 12.8224 12.9394 12.7467C12.8637 12.671 12.761 12.6284 12.6538 12.6284H11.5C11.0858 12.6284 10.75 12.2926 10.75 11.8784Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3761 24.0673C14.1337 23.8498 13.8163 23.7347 13.4908 23.7464C13.1653 23.7581 12.857 23.8957 12.6308 24.1301C12.4047 24.3645 12.2783 24.6776 12.2783 25.0033C12.2783 25.329 12.4047 25.6421 12.6308 25.8765C12.857 26.1109 13.1653 26.2485 13.4908 26.2602C13.8163 26.2719 14.1337 26.1568 14.3761 25.9393C14.6174 25.7227 14.7657 25.4214 14.7901 25.0983C14.7937 25.035 14.7937 24.9716 14.7901 24.9083C14.7657 24.5852 14.6174 24.2839 14.3761 24.0673ZM15.5385 24.8591L16.2866 24.8052C16.2353 24.0928 15.9096 23.4281 15.3781 22.951C14.8465 22.474 14.1507 22.2217 13.4369 22.2474C12.7231 22.2731 12.0472 22.5747 11.5513 23.0887C11.0554 23.6027 10.7783 24.2891 10.7783 25.0033C10.7783 25.7175 11.0554 26.4039 11.5513 26.9179C12.0472 27.4319 12.7231 27.7335 13.4369 27.7592C14.1507 27.7849 14.8465 27.5326 15.3781 27.0556C15.9096 26.5785 16.2353 25.9138 16.2866 25.2014L16.2866 25.2014L16.2872 25.192C16.2947 25.0663 16.2947 24.9403 16.2872 24.8146L15.5385 24.8591Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9807 19.3784C19.9807 18.9642 20.3165 18.6284 20.7307 18.6284H21.8846C22.2988 18.6284 22.6346 18.9642 22.6346 19.3784C22.6346 19.7926 22.2988 20.1284 21.8846 20.1284H20.7307C20.3165 20.1284 19.9807 19.7926 19.9807 19.3784Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconTransferCart;
