/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconInfo = ({ color = '#2374EE', size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 20 20'>
      <g clipPath='url(#clip0_674_11732)'>
        <path
          d='M18.3328 9.99967C18.3328 14.6019 14.602 18.3327 9.99979 18.3327C5.39758 18.3327 1.66675 14.6019 1.66675 9.99967C1.66675 5.39746 5.39758 1.66663 9.99979 1.66663C14.602 1.66663 18.3328 5.39746 18.3328 9.99967Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.99976 14.1662V10.4164M9.99976 6.25V6.66667'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_674_11732'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconInfo;
