import moment, { unitOfTime } from 'moment';
import IntlPolyfill from 'intl';
import 'intl/locale-data/jsonp/ru-RU';
import i18next from 'i18next';

import { DateType } from '@/types';

Intl.NumberFormat = IntlPolyfill.NumberFormat;

const regex = /[,.]?0+(\s+.)?$/g;
const formatter = new Intl.NumberFormat('ru-RU', { currency: 'USD', style: 'currency' });

class Formatter {
  formatCurrency(value: number) {
    if (!value) value = 0;
    return formatter.format(value).replace(regex, '$1');
  }

  formatDate(date?: DateType, format = 'DD.MM.YYYY') {
    const result = moment(date);

    if (!date) return i18next.t('common:dateNotSpecified');
    if (!result.isValid()) return i18next.t('common:invalidDate');

    return result.format(format);
  }

  compareDates(
    date1: DateType,
    date2: DateType,
    isBefore: boolean,
    granularity: unitOfTime.StartOf,
  ) {
    const m = moment(date1);
    return isBefore ? m.isSameOrBefore(date2, granularity) : m.isSameOrAfter(date2, granularity);
  }

  getDuration(from: DateType, to: DateType) {
    const a = moment(from);
    const b = moment(to);

    const hours = a.diff(b, 'hours');
    b.add(hours, 'hours');

    const minutes = a.diff(b, 'minutes');
    b.add(minutes, 'minutes');

    const seconds = a.diff(b, 'seconds');

    return {
      hours,
      minutes,
      seconds,
    };
  }
}

export default new Formatter();
