import { ApiInviteRole } from '@/services/invites/invites.types';

export enum FormModalInviteValues {
  ROLE = 'role',
  EMAIL = 'email',
}

export interface FormModalInviteData {
  [FormModalInviteValues.EMAIL]: string;
  [FormModalInviteValues.ROLE]: ApiInviteRole;
}
