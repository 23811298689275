import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import usePageFilterAutocomplete, { HookComponentProps } from './usePageFilterAutocomplete';

import { offerGeoOptions } from '@/modals/ModalOffer/resources';

const useOffersGeoFilter = () => {
  const { t } = useTranslation();

  const { geo, Component } = usePageFilterAutocomplete('geo', '');

  return {
    geo: geo || undefined,
    OffersGeoFilter: <Multiple extends boolean>(
      props: Omit<HookComponentProps<Multiple>, 'options' | 'renderInput'>,
    ) => <Component {...props} title={t('tableLabels.geo')} options={offerGeoOptions} />,
  };
};

export default useOffersGeoFilter;
