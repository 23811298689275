import { PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import { ApiFilterParams, ApiHighLevelUserParams } from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiCommand } from '@/services/commands/commands.types';

export enum ApiOfferDashboardCategories {
  SPEND = 'costs',
  DOLPHIN_SPEND = 'dolphinSpending',
  PROFIT = 'profit',
  EXPECTED_ROI = 'expectedRoi',
  EXPECTED_ROMI = 'expectedRomi',
  CONFIRMED_ROI = 'confirmedRoi',
  CONFIRMED_ROMI = 'confirmedRomi',
  EXPECTED_INCOME = 'expectedIncome',
  CONFIRMED_INCOME = 'confirmedIncome',
}

export interface ApiDashboardGetQuery extends ApiHighLevelUserParams, ApiFilterParams {
  userId?: string;
  usersIds?: string[];

  commandsIds?: string[];
}

export type PossibleStatisticCategoriesDto = Record<PossibleStatisticCategories, number>;

export interface ApiDashboardData extends ApiDashboardCommonStatistics {
  users: ApiUser[];
  command: ApiCommand;
}

export interface ApiDashboardStatisticMember extends PossibleStatisticCategoriesDto {
  user: Omit<ApiUser, 'command'>;
}

export interface ApiDashboardStatisticByCommand extends ApiDashboardCommonStatistics {
  command: ApiCommand;
}
export interface ApiDashboardCommonStatistics extends PossibleStatisticCategoriesDto {
  date: string;
  isEmpty: boolean;
  members: ApiDashboardStatisticMember[];
  commands: ApiDashboardStatisticByCommand[];
}

export interface ApiDashboardStatistic {
  statistics: ApiDashboardCommonStatistics[];
  globalStatistics: PossibleStatisticCategoriesDto;

  users: ApiUser[];
  command: ApiCommand;
  commands: ApiCommand[];
}

export type ApiDashboardGetResponse = ApiDashboardStatistic;

//
//
// Settings
//
//

export interface ApiDashboardSettingsParameters {
  usersIds: string[];
  commandsIds: string[];
  parameters: PossibleStatisticCategories[];
}

export type ApiDashboardSettings = ApiDashboardSettingsParameters & {
  id: string;
  tab: PossibleTabs;
};

// GET

export type ApiDashboardSettingsGetParams = void;
export type ApiDashboardSettingsGetResponse = Record<PossibleTabs, ApiDashboardSettings[]>;
// POST

export type ApiDashboardSettingsPostBody = ApiDashboardSettingsParameters &
  Pick<ApiDashboardSettings, 'tab'>;
export type ApiDashboardSettingsPostResponse = ApiDashboardSettings;

// PATCH

export type ApiDashboardSettingsPatchBody = Partial<ApiDashboardSettingsParameters> &
  Pick<ApiDashboardSettings, 'id'>;
export type ApiDashboardSettingsPatchResponse = ApiDashboardSettings;

export type ApiDashboardAllSettingsPatchBody = Partial<ApiDashboardSettingsParameters> &
  Pick<ApiDashboardSettings, 'tab'>;
export type ApiDashboardAllSettingsPatchResponse = ApiDashboardSettingsGetResponse;

// DELETE

export type ApiDashboardSettingsDeleteParams = Pick<ApiDashboardSettings, 'id'>;
