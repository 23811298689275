import { styled } from '@mui/material';

export const StyledLoginPage = styled('div')`
  width: 100%;
  margin: 0 auto;
  max-width: 516px;
`;

export const StyledLoginPageContent = styled('div')`
  overflow: hidden;
  padding-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
`;
