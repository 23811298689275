export const servicesTags = {
  auth: 'AUTH' as const,
  leads: 'LEADS' as const,
  leadsStatuses: 'LEADS_STATUSES' as const,
  users: 'USERS' as const,
  nonCommandsUsers: 'NON_COMMANDS_USERS' as const,
  pipes: 'PIPES' as const,
  caps: 'CAPS' as const,
  cards: 'CARDS' as const,
  offers: 'OFFERS' as const,
  reports: 'REPORTS' as const,
  invites: 'INVITES' as const,
  tracker: 'TRACKER' as const,
  dolphin: 'DOLPHIN' as const,
  services: 'SERVICES' as const,
  commands: 'COMMANDS' as const,
  companies: 'COMPANIES' as const,
  dashboard: 'DASHBOARD' as const,
  operations: 'OPERATIONS' as const,
  operationsStatistic: 'OPERATIONS_STATISTIC' as const,
  consumables: 'CONSUMABLES' as const,
  consumableCategories: 'CONSUMABLE_CATEGORIES' as const,
  onboarding: 'ONBOARDING' as const,
};
