import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import { MembersModelCustom } from '@/modals/ModalExpenseOperation/ModalExpenseOperationTypes';

export enum FormModalConsumableDistributionValues {
  DATE = 'date',
  DISTRIBUTIONS = 'distributions',
}

export interface FormModalConsumableDistributionData {
  [FormModalConsumableDistributionValues.DATE]: string;
  [FormModalConsumableDistributionValues.DISTRIBUTIONS]: ElementObject<MembersModelCustom>[];
}
