import { useMemo } from 'react';

const useSelectOptions = <T>(arr: T[] = [], label: keyof T, value: keyof T) => {
  return useMemo(() => {
    const list = arr || [];

    return list.map((a) => ({
      label: String(a[label]).toLowerCase(),
      value: String(a[value]).toLowerCase(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arr.length, label, value]);
};

export default useSelectOptions;
