import moment from 'moment';
import { ChartData, DefaultDataPoint } from 'chart.js';
import assertNever from 'assert-never';

import { PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { PossibleStatisticCategories, statisticCategories } from '@/resources/constantsStatistic';
import { CHART_COLORS_LEFT } from '@/resources/constants';

import { UseStatisticDataResult } from '@/hooks/useStatisticData';
import useGetParameterColor from '@/hooks/useGetParameterColor';

const dateFormatForGraphic = 'D.M.YYYY';

export const useChartJs = (
  data: UseStatisticDataResult,
): ChartData<'line', DefaultDataPoint<'line'>, string> => {
  const { getParameterColor } = useGetParameterColor();

  const statistics = data.data?.statistics;

  if (!statistics) return { datasets: [] };

  const labels = statistics.map((a) => a.date).sort();

  const getDatasets = () => {
    const colors = CHART_COLORS_LEFT;

    const getCurrentDate = (label: string) => statistics.find((a) => a.date === label);

    const dataConfigurationByParameters = (value: PossibleStatisticCategories) => {
      return labels.map((label) => {
        const currentDate = getCurrentDate(label);
        return currentDate ? currentDate[value] : 0;
      });
    };

    const getCommonDataConfig = (index: number) => {
      const color = colors[index] || 'black';

      return {
        borderColor: color,
        backgroundColor: color,
      };
    };

    switch (data.tab) {
      case PossibleTabs.parameters: {
        return data.parameters
          ?.filter((a) => statisticCategories.includes(a as PossibleStatisticCategories))
          .map((a) => {
            const category = a as PossibleStatisticCategories;
            const color = getParameterColor(category);

            return {
              borderColor: color,
              backgroundColor: color,
              data: dataConfigurationByParameters(category),
            };
          });
      }
      case PossibleTabs.masters: {
        return data.mastersIds?.map((masterId, index) => {
          return {
            ...getCommonDataConfig(index),
            data: labels.map((label) => {
              const currentDate = getCurrentDate(label);

              return (
                currentDate?.members.find((member) => member.user.id === masterId)?.[
                  data.parameters?.[0] as PossibleStatisticCategories
                ] || 0
              );
            }),
          };
        });
      }
      case PossibleTabs.commands: {
        return data.commandsIds?.map((commandId, index) => {
          return {
            ...getCommonDataConfig(index),
            data: labels.map((label) => {
              const currentDate = getCurrentDate(label);

              return (
                currentDate?.commands.find((command) => command.command.id === commandId)?.[
                  data.parameters?.[0] as PossibleStatisticCategories
                ] || 0
              );
            }),
          };
        });
      }
      default: {
        assertNever(data.tab);
      }
    }
  };

  return {
    labels: labels.map((a) => moment(a).format(dateFormatForGraphic)),
    datasets: getDatasets() || [],
  };
};

export default useChartJs;
