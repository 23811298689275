import useUserCommand from '@/hooks/useUserCommand';
import useSelectOptions from '@/hooks/useSelectOptions';

import { useGetConsumableCategoriesQuery } from '@/services/consumableCategories/consumableCategories';

const useUserConsumableCategories = () => {
  const { commandId } = useUserCommand();
  const { data } = useGetConsumableCategoriesQuery({ commandId });

  const categories = useSelectOptions(data?.list, 'name', 'id');

  return { categories };
};

export default useUserConsumableCategories;
