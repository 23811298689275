import { Trans, useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import QueryString from 'query-string';
import { Alert, Box, Checkbox, Typography } from '@mui/material';

import { StyledFormControlLabel } from '@/components/TrackerForm/TrackerFormStyled';
import FormElement from '@/components/FormElement';
import {
  CompanyLeadsFormProps,
  CompanyLeadsFormValues,
} from '@/components/CompanyLeadsForm/CompanyLeadsFormTypes';

import FormRuler from '@/utils/FormRuler';

import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';

import { ApiPagination } from '@/types/api';
import { ApiIntegrationLead } from '@/services/leads/leads.types';

const query: ApiIntegrationLead = {
  id: 'string',
  ad: 'string',
  geo: 'string',
  sum: 'string',
  date: 'string',
  notes: 'string',
  email: 'string',
  offer: 'string',
  phone: 'string',
  buyer: 'string',
  subid: 'string',
  domain: 'string',
  status: 'string',
  campaing: 'string',
  lastName: 'string',
  firstName: 'string',
  advertising: 'string',
  description: 'string',
};

const meta: ApiPagination = {
  page: 0,
  pages: 0,
  limit: 0,
  total: 0,
};

const renderObject = (arr: [string, string | number][]) =>
  arr.map(([key, type]) => `"${key}": ${type === 'string' ? `"${type}"` : type}`).join(',\n    ');

const queryEntries = Object.entries(query);
const metaEntries = Object.entries(meta);

const QUERY = Object.fromEntries(queryEntries.map(([key]) => [key, `{${key}}`]));
const MODEL = `{
  "list": [{
    ${renderObject(queryEntries)}
  }],
  "meta": {
    ${renderObject(metaEntries)}
  }
}`;

const CompanyLeadsForm = ({
  isChecked,
  onChangeState,
  isWriteHeaders,
  onChangeHeadersState,
}: CompanyLeadsFormProps) => {
  const { apiKey } = useUserCompanyInfo();
  const { t } = useTranslation(['command']);

  const webhookExample = useMemo(() => {
    const a = QueryString.stringifyUrl({
      url: window.location.origin + '/api/leads/postback',
      query: {
        apiKey,
        ...QUERY,
      },
    });

    return window.decodeURI(a);
  }, [apiKey]);

  return (
    <>
      <StyledFormControlLabel
        $isChecked={isChecked}
        label={t('command:leadsForm.companyWithLeads')}
        control={<Checkbox checked={isChecked} onChange={onChangeState} />}
      />

      {isChecked && (
        <>
          <FormElement
            component='input'
            label={t('command:leadsForm.selfhostRequestUrl')}
            name={CompanyLeadsFormValues.LEADS_API_URL}
            rules={{ ...FormRuler.url, ...FormRuler.required }}
          />

          <StyledFormControlLabel
            $isChecked={isWriteHeaders}
            label={t('command:leadsForm.selfhostRequestWithHeaders')}
            control={<Checkbox checked={isWriteHeaders} onChange={onChangeHeadersState} />}
          />

          {isWriteHeaders && (
            <FormElement
              component='input'
              rules={FormRuler.required}
              name={CompanyLeadsFormValues.LEADS_API_HEADERS}
              label={t('command:leadsForm.selfhostRequestHeaders')}
            />
          )}

          <Alert severity='warning' sx={{ mt: 6 }}>
            {t('command:leadsForm.selfhostWaitedResponse.intro')}

            <Typography whiteSpace='pre' variant='body2'>
              {MODEL}
            </Typography>

            <Box mt={4}>
              <Trans
                ns='command'
                i18nKey='leadsForm.selfhostWaitedResponse.specializedDateFormat'
                components={{
                  bold: <strong />,
                }}
              />
            </Box>
          </Alert>

          <Alert severity='info' sx={{ mt: 6 }}>
            {t('command:leadsForm.webhookExample')}
            <Typography variant='body2'>{webhookExample}</Typography>
          </Alert>
        </>
      )}
    </>
  );
};

export default CompanyLeadsForm;
