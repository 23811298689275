import React from 'react';

import ErrorPage from '@/pages/ErrorPage';

import Alerter from '@/utils/Alerter/Alerter';

class ErrorBoundary extends React.Component<Record<string, any>, { isError: boolean }> {
  constructor(props: Record<string, any>) {
    super(props);

    this.state = { isError: false };
    this.resetErrorState = this.resetErrorState.bind(this);
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    Alerter.logError('App did catch', error, errorInfo);
    this.setState({ isError: true });
  }

  resetErrorState() {
    this.setState({ isError: false });
  }

  render() {
    if (this.state.isError) {
      return <ErrorPage resetError={this.resetErrorState} isErrorBoundary={this.state.isError} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
