import { useSelector } from 'react-redux';
import { ACTIONS, CallBackProps, STATUS } from 'react-joyride';
import { useCallback, useEffect, useState } from 'react';

import { selectSidebarState } from '@/redux/user/userGetters';

let isFirstRender = true;
let timeoutId: NodeJS.Timeout;

const RENDER_STEP_NUMBER = 1;
const DEFAULT_STEP_NUMBER = -1;

// NOTE: hook предназначен для перерендера первого шага онбординга при изменении состояния sidebar
const useOnboardingTour = () => {
  const [tourKey, setTourKey] = useState(0);
  const [activeStep, setActiveStep] = useState(DEFAULT_STEP_NUMBER);

  const isSidebarOpen = useSelector(selectSidebarState);

  const joyrideCallback = useCallback(({ status, index, action }: CallBackProps) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      isFirstRender = true;
      setActiveStep(DEFAULT_STEP_NUMBER);
      return;
    }

    if (status === STATUS.RUNNING && action === ACTIONS.UPDATE) {
      setActiveStep(index + 1);
    }
  }, []);

  useEffect(() => {
    if (timeoutId) clearInterval(timeoutId);

    if (activeStep !== RENDER_STEP_NUMBER) return;

    if (isFirstRender) {
      isFirstRender = false;
      return;
    }

    // NOTE: setTimeout нужен, потому что перерендер идет раньше,
    // чем обновилось состояние sidebar
    timeoutId = setTimeout(() => setTourKey((prevKey) => prevKey + 1), 200);
  }, [activeStep, isSidebarOpen]);

  useEffect(() => {
    return () => {
      isFirstRender = true;
    };
  }, []);

  return { joyrideCallback, tourKey };
};

export default useOnboardingTour;
