import { Menu, ListItemIcon, styled } from '@mui/material';

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: 24px;
    min-width: 210px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06), 0px 8px 32px rgba(0, 0, 0, 0.04);
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 24px;
  padding-right: 12px;
`;
