import { ApiCompanyTracker } from '@/services/companies/companies.types';

export enum FormModalCompanyValues {
  NAME = 'name',
  SERVICE = 'service',

  TRACKER_API_URL = 'trackerApiUrl',
  TRACKER_API_KEY = 'trackerApiKey',

  SELFHOST_LEADS_API_URL = 'leadsApiUrl',
  SELFHOST_LEADS_API_HEADERS = 'leadsApiHeaders',
}

export interface FormModalCompanyData {
  [FormModalCompanyValues.NAME]: string;
  [FormModalCompanyValues.SERVICE]: ApiCompanyTracker;
  [FormModalCompanyValues.TRACKER_API_URL]: string;
  [FormModalCompanyValues.TRACKER_API_KEY]: string;
  [FormModalCompanyValues.SELFHOST_LEADS_API_URL]: string;
  [FormModalCompanyValues.SELFHOST_LEADS_API_HEADERS]: string;
}
