/* eslint-disable max-len */

import { styled, css, useTheme } from '@mui/material';

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

import transientProps from '@/utils/transientProps';

const StyledIcon = styled(Icon, transientProps)<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      & > rect:hover {
        fill: #f5f5f5;
      }
    `}
`;

const IconTrash = ({
  size = IconSize.m,
  isDisabled = false,
  onClick,
  color,
  ...restProps
}: IconProps & { isDisabled?: boolean }) => {
  const theme = useTheme();

  return (
    <StyledIcon
      {...restProps}
      onClick={!isDisabled ? onClick : undefined}
      $isDisabled={isDisabled}
      viewBox='0 0 20 20'
    >
      <path
        d='M17.5 5.99999C18.0523 5.99999 18.5 5.55227 18.5 4.99999C18.5 4.44771 18.0523 3.99999 17.5 3.99999V5.99999ZM2.5 3.99999C1.94772 3.99999 1.5 4.44771 1.5 4.99999C1.5 5.55227 1.94772 5.99999 2.5 5.99999V3.99999ZM13.3333 4.99999V5.99999H14.3333V4.99999H13.3333ZM6.66667 4.99999H5.66667V5.99999H6.66667V4.99999ZM4.16667 5.99999H15.8333V3.99999H4.16667V5.99999ZM14.8333 4.99999V16.6667H16.8333V4.99999H14.8333ZM14.1667 17.3333H5.83333V19.3333H14.1667V17.3333ZM5.16667 16.6667V4.99999H3.16667V16.6667H5.16667ZM15.8333 5.99999H17.5V3.99999H15.8333V5.99999ZM4.16667 3.99999H2.5V5.99999H4.16667V3.99999ZM5.83333 17.3333C5.46514 17.3333 5.16667 17.0348 5.16667 16.6667H3.16667C3.16667 18.1394 4.36057 19.3333 5.83333 19.3333V17.3333ZM14.8333 16.6667C14.8333 17.0348 14.5349 17.3333 14.1667 17.3333V19.3333C15.6394 19.3333 16.8333 18.1394 16.8333 16.6667H14.8333ZM8.33333 2.66666H11.6667V0.666656H8.33333V2.66666ZM12.3333 3.33332V4.99999H14.3333V3.33332H12.3333ZM13.3333 3.99999H6.66667V5.99999H13.3333V3.99999ZM7.66667 4.99999V3.33332H5.66667V4.99999H7.66667ZM11.6667 2.66666C12.0349 2.66666 12.3333 2.96513 12.3333 3.33332H14.3333C14.3333 1.86056 13.1394 0.666656 11.6667 0.666656V2.66666ZM8.33333 0.666656C6.86057 0.666656 5.66667 1.86056 5.66667 3.33332H7.66667C7.66667 2.96513 7.96514 2.66666 8.33333 2.66666V0.666656Z'
        fill={color || theme.palette.error.main}
      />
    </StyledIcon>
  );
};

export default IconTrash;
