import styled from '@emotion/styled';

export const StyledWrapper = styled('div')`
  margin-left: 8px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledWrapperLeft = styled('div')`
  display: flex;
  flex-direction: column;
`;
