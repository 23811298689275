import { ApiUserRole } from '@/services/auth/auth.types';

export enum FormModalInviteFinancierValues {
  ROLE = 'role',
  EMAIL = 'email',
}

export interface FormModalInviteFinancierData {
  [FormModalInviteFinancierValues.EMAIL]: string;
  [FormModalInviteFinancierValues.ROLE]: ApiUserRole.COMPANY_FINANCIER;
}
