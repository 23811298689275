import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';

import { onboardingCapsPage } from '@/resources/onboarding/capsPage';

import { getUserNickname } from '@/utils/getUserNickname';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';

import { ApiCap, ApiCapStatus } from '@/services/caps/caps.types';
import { useDeleteCapsByOfferMutation, useGetCapsQuery } from '@/services/caps/caps';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import ModalCap from '@/modals/ModalCap/ModalCap';
import useStatusFilter from '@/filters/useStatusFilter';
import FilterStatus from '@/filters/FilterStatus';

const capsStatuses = [ApiCapStatus.NORMAL, ApiCapStatus.PROBLEMATIC];

const CapsPage = () => {
  const { t } = useTranslation(['common', 'caps']);
  const [loadingTarget, setLoadingTarget] = useState<Record<string, true>>({});

  const { isMaster } = useUserInfo();
  const { status } = useStatusFilter<ApiCapStatus>();

  const [deleteCaps, { isLoading: isDeleting }] = useDeleteCapsByOfferMutation();
  const { data: caps, isLoading } = useGetCapsQuery();

  // TODO: позже перейти на запросы статусные
  // const { data: caps, isLoading } = useGetCapsQuery({ status: status || ApiCapStatus.NORMAL });

  const openDeleteCupConfirmModal = useCallback(
    (data: ApiCap) => {
      ModalConfirm.show({
        onlyContinue: true,
        withAwaiting: true,
        onContinue: () => {
          setLoadingTarget((prevState) => {
            const newState = { ...prevState };
            newState[data.offerId] = true;
            return newState;
          });

          deleteCaps({ offerId: data.offerId, caps: data.caps.map((a) => a.id) }).finally(() =>
            setLoadingTarget((prevState) => {
              const newState = { ...prevState };
              delete newState[data.offerId];
              return newState;
            }),
          );
        },
        continueLabel: t('common:buttonActions.delete'),
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('caps:modal.subTitle', { value: data.offerName }),
      });
    },
    [deleteCaps, t],
  );

  const getChildrenConfig = useCallback(
    (caps: ApiCap['caps'] = []): TableConfig => {
      let arr = [...caps];

      if (status === ApiCapStatus.PROBLEMATIC) {
        arr = arr.filter((a) => a.isProblematic);
      }

      const companyCaps = arr.filter((a) => a.company.id);
      const comandsCaps = arr.filter((a) => a.command.id);
      const usersCaps = arr.filter((a) => a.user.id);

      arr = [...companyCaps, ...comandsCaps, ...usersCaps];

      return arr.map((a) => {
        return {
          id: a.id,
          data: [
            {
              label: '',
              value:
                (a.command.id && `Команда: ${a.command.name}`) ||
                (a.user.id && `Мастер: ${getUserNickname(a.user)}`) ||
                (a.company.id && `Компания: ${a.company.name}`),
            },
            {
              label: '',
              value: a.limit,
            },
            {
              label: '',
              value: a.deposits || 0,
            },
            {
              label: '',
              value: '',
            },
            {
              label: '',
              value: '',
            },
          ],
        };
      });
    },
    [status],
  );

  const config = useMemo<TableConfig>(() => {
    let arr = [...(caps?.list || [])];

    if (status === ApiCapStatus.PROBLEMATIC) {
      arr = arr.filter((a) => a.caps.filter((b) => b.isProblematic).length > 0);
    }

    return arr.map((a) => {
      const actionProps: TableRowActionsProps = {
        isIconEdit: true,
        isIconLoading: isDeleting && a.id in loadingTarget,
        onEdit: () => ModalCap.show(a),
        onDelete: () => openDeleteCupConfirmModal(a),
      };

      return {
        id: a.offerId,
        childrenConfig: !isMaster ? getChildrenConfig(a.caps) : undefined,
        data: [
          {
            label: t('common:tableLabels.offer'),
            value: a.offerName,
          },
          {
            label: t('common:tableLabels.limit'),
            value: a.limit,
          },
          {
            label: t('common:tableLabels.conversion'),
            value: a.deposits || 0,
          },
          {
            label: t('common:tableLabels.comment'),
            value: a.caps[0].comment || '-',
          },
          !isMaster && {
            width: '100px',
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    caps?.list,
    getChildrenConfig,
    isDeleting,
    isMaster,
    loadingTarget,
    openDeleteCupConfirmModal,
    status,
    t,
  ]);

  return (
    <>
      <PageTitle onboardingSteps={onboardingCapsPage}>{t('common:sidebar.caps')}</PageTitle>

      {!isMaster && (
        <PageHeaderContent
          label={t('caps:cap')}
          onClick={() => ModalCap.show()}
          adviseTarget={onboardingCapsPage[1].target}
        />
      )}

      <FiltersWithSidebar hasCommandSelect={false}>
        <FilterStatus
          defaultValue={ApiCapStatus.NORMAL}
          options={capsStatuses.map((a) => ({ label: t(`caps:filterStatus.${a}`), value: a }))}
        />
      </FiltersWithSidebar>

      <Table
        config={config}
        isLoading={isLoading}
        isCustomChildrenConfig
        // onChangePage={setPage}
        // onChangeLimit={setLimit}
        // pagination={getPagination(data?.meta)}
      />
    </>
  );
};

export default CapsPage;
