import { invalidatesListItem } from '@/services/utils';
import {
  ApiOfferReportHoldPatchBody,
  ApiOfferReportHoldPatchResponse,
  ApiOfferReportPostBody,
  ApiOfferReportPostResponse,
} from '@/services/reports/reports.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/reports';
const serviceTag = servicesTags.reports;

const reportsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    createOfferReport: build.mutation<ApiOfferReportPostResponse, ApiOfferReportPostBody>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/offer/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesListItem(serviceTag, [
        servicesTags.offers,
        servicesTags.operations,
      ]),
    }),
    updateOfferReportHold: build.mutation<
      ApiOfferReportHoldPatchResponse,
      ApiOfferReportHoldPatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/hold`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag, [
        servicesTags.offers,
        servicesTags.operations,
      ]),
    }),
  }),
});

export const { useCreateOfferReportMutation, useUpdateOfferReportHoldMutation } = reportsApi;

export default reportsApi;
