import { useLocation } from 'react-router-dom';

import useUserInfo from '@/hooks/useUserInfo';

import { useUpdateOnboardingStateMutation } from '@/services/onboarding/onboarding';

const useOnboardingStatus = () => {
  const { userInfo } = useUserInfo();
  const { pathname } = useLocation();

  const [updateOnboarding] = useUpdateOnboardingStateMutation();

  const onBoardingKey = pathname.replace('/', '') || 'index';
  const status = Boolean(userInfo?.onboardings?.[onBoardingKey]);

  const setOnboardingStatus = () => {
    updateOnboarding({
      page: onBoardingKey,
      isOnboarded: true,
    });
  };

  return { setOnboardingStatus, status, onBoardingKey };
};

export default useOnboardingStatus;
