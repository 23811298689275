import { Theme, styled, Drawer, CSSObject, List, css } from '@mui/material';

import Typography from '@/components/Typography';

import transientProps from '@/utils/transientProps';

const drawerWidth = {
  collapsed: 86,
  uncollapsed: 300,
};

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  width: drawerWidth.uncollapsed,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: drawerWidth.collapsed,
});

export const StyledSidebar = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open: isOpen }) => ({
  whiteSpace: 'nowrap',

  '.MuiPaper-root': {
    overflow: 'auto',
    padding: '0px',
    paddingTop: '16px',
  },

  ...(isOpen && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),

  ...(!isOpen && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const StyledSidebarContentWrapper = styled('div')`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledList = styled(List)`
  padding: 0 24px;
`;

export const StyledSection = styled(Typography)`
  & + & {
    margin-top: 16px;
  }
`;

export const StyledSectionLabel = styled(Typography, transientProps)<{ $isSidebarOpen: boolean }>`
  margin-bottom: 8px;
  padding: 0 8px;

  height: 28px;
  display: inline-block;

  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ $isSidebarOpen }) =>
    !$isSidebarOpen &&
    css`
      opacity: 0;
    `}
`;
