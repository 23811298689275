import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';
import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import { MembersModelCustom } from '@/modals/ModalExpenseOperation/ModalExpenseOperationTypes';

export enum FormModalConsumablesValues {
  NAME = 'name',
  DATE = 'date',
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  STORE = 'store',
  CATEGORY = 'categoryId',
  COMMENT = 'comment',
  DISTRIBUTIONS = 'distributions',
  AMOUNT = 'amount',
  FILE_ID = 'fileId',
  RECEIPT_ID = 'receiptId',
}

export interface FormModalConsumablesData {
  [FormModalConsumablesValues.NAME]: string;
  [FormModalConsumablesValues.CATEGORY]: string;
  [FormModalConsumablesValues.DATE]: string;
  [FormModalConsumablesValues.STORE]: string;
  [FormModalConsumablesValues.AMOUNT]: number;
  [FormModalConsumablesValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalConsumablesValues.COMMENT]: string;
  [FormModalConsumablesValues.FILE_ID]: string;
  [FormModalConsumablesValues.RECEIPT_ID]: string;
  [FormModalConsumablesValues.DISTRIBUTIONS]: ElementObject<MembersModelCustom>[];
}
