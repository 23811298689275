import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { ErrorPageProps } from '@/pages/ErrorPage/ErrorPageTypes';
import {
  StyledErrorPageWrapperTypography,
  StyledErrorPageGrid,
} from '@/pages/ErrorPage/ErrorPageStyled';

import Typography from '@/components/Typography';
import Button from '@/components/Button';

import Icon500 from '@/icons/Icon500';
import Icon404 from '@/icons/Icon404';

import routes from '@/resources/routes';

const ErrorPage = (props: ErrorPageProps) => {
  const { t } = useTranslation('common');

  const errorContent = useMemo(() => {
    if (!('isErrorBoundary' in props)) return null;

    const { resetError } = props;

    return (
      <>
        <Grid item>
          <Icon500 />
        </Grid>

        <Grid item sx={{ marginTop: '30px' }}>
          <Typography variant='h3' sx={{ textAlign: 'center' }}>
            {t('somethingWentWrong')}
          </Typography>
        </Grid>

        <Grid item>
          <StyledErrorPageWrapperTypography variant='h5'>
            {t('refreshPageOrVisitLater')}
          </StyledErrorPageWrapperTypography>
        </Grid>

        <StyledErrorPageGrid item>
          <Button onClick={resetError} fullWidth style={{ maxWidth: 270 }}>
            {t('buttonActions.reloadPage')}
          </Button>
        </StyledErrorPageGrid>
      </>
    );
  }, [props, t]);

  const notFoundContent = useMemo(() => {
    return (
      <>
        <Grid item>
          <Icon404 />
        </Grid>

        <Grid item sx={{ marginTop: '30px' }}>
          <Typography variant='h3' sx={{ textAlign: 'center' }}>
            {t('pageNotFound')}
          </Typography>
        </Grid>

        <Grid item>
          <StyledErrorPageWrapperTypography variant='h5'>
            {t('goBackToMainPage')}
          </StyledErrorPageWrapperTypography>
        </Grid>

        <StyledErrorPageGrid item>
          <Button fullWidth style={{ maxWidth: 270 }} href={routes.index.link}>
            {t('buttonActions.goBack')}
          </Button>
        </StyledErrorPageGrid>
      </>
    );
  }, [t]);

  return (
    <>
      <div style={{ padding: 24, display: 'flex', flex: 1 }}>
        <Grid container spacing={5} direction='column' alignItems='center' justifyContent='center'>
          {'isErrorBoundary' in props ? errorContent : notFoundContent}
        </Grid>
      </div>
    </>
  );
};

export default ErrorPage;
