import { useTranslation } from 'react-i18next';

import useLocalizeOperationCategory from '@/hooks/useLocalizeOperationCategory';

import { ApiOperationCategory } from '@/services/operations/operations.types';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

export const getOperationCategories = (
  localizeOperationCategory: (status: ApiOperationCategory) => string,
) => {
  const operationCategories = [
    {
      label: localizeOperationCategory(ApiOperationCategory.ALL),
      value: ApiOperationCategory.ALL,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.OTHER),
      value: ApiOperationCategory.OTHER,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.TRAFFIC),
      value: ApiOperationCategory.TRAFFIC,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.CONSUMABLE),
      value: ApiOperationCategory.CONSUMABLE,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.CARD_BUYING),
      value: ApiOperationCategory.CARD_BUYING,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.CARD_CLOSE),
      value: ApiOperationCategory.CARD_CLOSE,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.SERVICE_REPLENISHMENT),
      value: ApiOperationCategory.SERVICE_REPLENISHMENT,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.CARD_REPLENISHMENT),
      value: ApiOperationCategory.CARD_REPLENISHMENT,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.OFFER_INCOME),
      value: ApiOperationCategory.OFFER_INCOME,
    },
    {
      label: localizeOperationCategory(ApiOperationCategory.PIPE_CONSUMABLE),
      value: ApiOperationCategory.PIPE_CONSUMABLE,
    },
    // {
    //   label: localizeOperationCategory(ApiOperationCategory.INVESTMENT),
    //   value: ApiOperationCategory.INVESTMENT,
    // },
    // {
    //   label: localizeOperationCategory(ApiOperationCategory.REFUND),
    //   value: ApiOperationCategory.REFUND,
    // },
  ];

  return { operationCategories };
};

const useOperationCategoryFilter = () => {
  const { t } = useTranslation();

  const { Component, category } = usePageFilter('category', ApiOperationCategory.ALL);

  const { localizeOperationCategory } = useLocalizeOperationCategory();
  const { operationCategories } = getOperationCategories(localizeOperationCategory);

  return {
    category: category || undefined,
    OperationCategoryFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component
        {...props}
        label={t('tableLabels.category')}
        options={operationCategories.slice(1)}
      />
    ),
  };
};

export default useOperationCategoryFilter;
