import { useCallback, useEffect, useState } from 'react';

import { PossibleTablesCache } from '@/resources/routes';
import { limitPageOptions } from '@/resources/constants';

import getCachePaginationByKey from '@/utils/getCachePaginationByKey';
import LocalStorage from '@/utils/LocalStorage';

import useUpdateEffect from '@/hooks/useUpdateEffect';
import useQueryParam from '@/hooks/useQueryParam';

import { PaginationHookProps, PaginationHookResponse } from '@/types/hooks';
import { AnyObject, ApiPagination, ApiResponseWithPagination } from '@/types/api';

const usePagination = <
  CacheKey extends PossibleTablesCache,
  QueryArg,
  TagTypes extends string,
  ResultType extends ApiResponseWithPagination<AnyObject>,
>({
  cacheKey,
  useQuery,
  queryArg,
  useQueryOptions,
}: PaginationHookProps<CacheKey, QueryArg, TagTypes, ResultType>): PaginationHookResponse<
  CacheKey,
  ResultType
> => {
  const [initialValue] = useState(() => getCachePaginationByKey(cacheKey));

  const [page, setPage] = useQueryParam('page', String(initialValue.page));
  const [limit, setLimit] = useQueryParam('limit', String(initialValue.limit));

  let newQueryArg = { page, limit };
  if (queryArg) {
    newQueryArg = { ...newQueryArg, ...queryArg };
  }

  // TODO: возможно есть способ без «as»
  const { data, isLoading, isFetching } = useQuery(
    newQueryArg as unknown as QueryArg,
    useQueryOptions,
  );

  const setLimitWithPageClearing = useCallback(
    (value: string) => setLimit(value, 'replaceIn', true),
    [setLimit],
  );

  const getPagination = useCallback(
    (meta?: ApiPagination) => ({
      page: typeof meta?.page === 'number' ? meta?.page : 1,
      total: typeof meta?.total === 'number' ? meta?.total : Number(limitPageOptions[0].value),
      limit: typeof meta?.limit === 'number' ? meta?.limit : Number(limitPageOptions[0].value),
    }),
    [],
  );

  useEffect(() => {
    const dataPages = data?.meta.pages;
    if (!dataPages || Number(page) <= dataPages) return;
    setPage(String(dataPages));
  }, [data, data?.meta.pages, page, setPage]);

  useUpdateEffect(() => {
    if (!cacheKey) return;
    LocalStorage.set(cacheKey, { limit, page });
  }, [cacheKey, limit, page]);

  return {
    [cacheKey]: data,
    // todo: доработать статус загрузки - нет индикатора при изменении сущности, например при архивации
    isLoading:
      isLoading ||
      isFetching ||
      (data?.meta && Boolean(data.meta.pages) && data.meta.page > data.meta.pages),
    setPage,
    getPagination,
    setLimit: setLimitWithPageClearing,
    // TODO: возможно есть способ без «as»
  } as PaginationHookResponse<CacheKey, ResultType>;
};

export default usePagination;
