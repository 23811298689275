import { TableWithSettings } from '@/types/table';
import { AppThunk, LanguagesCode, UserState } from '@/types';
import userSlice from '@/redux/user';

const { setLanguage, setCommand, setSidebarState, setTableSettings } = userSlice.actions;

export const setInterfaceLanguageAction =
  (iso: LanguagesCode): AppThunk =>
  (dispatch) => {
    dispatch(setLanguage(iso));
  };

export const setCommandAction =
  (command: UserState['command']): AppThunk =>
  (dispatch) => {
    dispatch(setCommand(command));
  };

export const setSidebarStateAction =
  (state: boolean): AppThunk =>
  (dispatch) => {
    dispatch(setSidebarState(state));
  };

export const setTableSettingsAction =
  (table: TableWithSettings, value: string[], storageKey: string): AppThunk =>
  (dispatch) => {
    dispatch(setTableSettings({ value, storageKey, table }));
  };
