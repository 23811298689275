import { ApiResponse } from '@/types/api';
import { ApiCompany } from '@/services/companies/companies.types';
import { ApiCommand } from '@/services/commands/commands.types';

export enum ApiUserRole {
  USER = 'user',
  OWNER = 'owner',
  LEAD_USER = 'leader',
  SUPER_ADMIN = 'superadmin',
  COMPANY_FINANCIER = 'company financier',
  COMMAND_FINANCIER = 'command financier',
}

export interface ApiMe {
  id: string;
  role: ApiUserRole;

  email: string;
  firstName: string;
  lastName: string;

  command: ApiCommand;
  company: ApiCompany;

  isEnabled: boolean;
  isEmailConfirmed: boolean;
  allowedCommands?: ApiCommand[];

  onboardings: { [key: string]: boolean };

  updatedAt: string;
  createdAt: string;
}

// ===

export type ApiUsersMeGetResponse = ApiResponse<ApiMe>;

// ===

export type ApiAuthLoginPostBody = Pick<ApiMe, 'email'> & { password: string };

export type ApiAuthLoginPostResponse = ApiResponse<{
  user: ApiMe;
  accessToken: string;
}>;

// ===

export type ApiAuthRegisterOwnerPostBody = Pick<ApiMe, 'email' | 'firstName' | 'lastName'> & {
  password: string;
  company: string;
};

export type ApiAuthRegisterOwnerPostResponse = ApiResponse<{
  user: ApiMe;
  accessToken: string;
}>;

// ===

export type ApiAuthRegisterByInvitationPostBody = Pick<ApiMe, 'firstName' | 'lastName'> & {
  password: string;
  inviteHash: string;
};

export interface ApiAuthRegisterByInvitationPostResponse {
  user: ApiMe;
  accessToken: string;
}

// ===

export type ApiAuthForgotPasswordPostBody = Pick<ApiMe, 'email'> & {
  url: string;
};

export interface ApiAuthForgotPasswordPostResponse {
  redirectUrl: string;
}

// ===

export type ApiAuthRecoveryPasswordPostBody = {
  password: string;
  recoveryHash: string;
};
