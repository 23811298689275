import { ArchiveEnum } from '@/filters/useArchivedFilter';

const getBooleanArchivingStatus = (archived: ArchiveEnum) => {
  switch (archived) {
    case ArchiveEnum.ARCHIVED:
      return false;
    case ArchiveEnum.UNARCHIVED:
      return true;
    default:
      return undefined;
  }
};

export default getBooleanArchivingStatus;
