import { useTranslation } from 'react-i18next';

import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';

import { onboardingCompanyPage } from '@/resources/onboarding/companyPage';

import useUsersTable from '@/hooks/useUsersTable';
import useUserInfo from '@/hooks/useUserInfo';

import ModalInviteFinancier from '@/modals/ModalInviteFinancier/ModalInviteFinancier';
import FilterUserRole from '@/filters/FilterUserRole';
import FilterArchive from '@/filters/FilterArchive';

const CompanyPage = () => {
  const { t } = useTranslation(['common']);

  const { isOwner } = useUserInfo();
  const { config, isLoading } = useUsersTable(true);

  return (
    <>
      <PageTitle onboardingSteps={onboardingCompanyPage} deleteCardsInTables={config.length === 0}>
        {t('common:sidebar.allEmployees')}
      </PageTitle>

      {isOwner && (
        <PageHeaderContent label={t('common:roles.employee')} onClick={ModalInviteFinancier.show} />
      )}

      <FiltersWithSidebar hasCommandSelect={false}>
        <FilterUserRole maxWidth={240} />
        <FilterArchive />
      </FiltersWithSidebar>

      <Table config={config} isLoading={isLoading} adviseKey={onboardingCompanyPage[1].target} />
    </>
  );
};

export default CompanyPage;
