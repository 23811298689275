import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ConsumableCategoryStatus } from '@/services/consumableCategories/consumableCategories.types';

const useLocalizeConsumableCategory = () => {
  const { t } = useTranslation('consumables');

  const localizeConsumableCategory = useCallback(
    (status: ConsumableCategoryStatus) => {
      switch (status) {
        case ConsumableCategoryStatus.ACTIVE:
          return t('status.active');
        case ConsumableCategoryStatus.ARCHIVED:
          return t('status.archived');
        default:
          return status;
      }
    },
    [t],
  );

  return { localizeConsumableCategory };
};

export default useLocalizeConsumableCategory;
