import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { StyledSettingsTitleWrapper } from '@/pages/PageSettingsTable/PageSettingsTableStyled';
import PageSettingsTable from '@/pages/PageSettingsTable/PageSettingsTable';
import { possibleFacebookColumns } from '@/pages/FacebookPage/constants';

import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';
import EmptyMessageWithIcon from '@/components/EmptyMessageWithIcon';

import routes from '@/resources/routes';
import { onboardingFacebookPage } from '@/resources/onboarding/facebookPage';
import { dateFormatCommon, dateFormatStartsWithYear } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import Formatter from '@/utils/Formatter';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import useQueryParam from '@/hooks/useQueryParam';
import useLocalizePipeStatus from '@/hooks/useLocalizePipeStatus';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useBoolState from '@/hooks/useBoolState';

import {
  ApiDolphin,
  ApiDolphinAdvertisingCampaignsWithStatistics,
  ApiDolphinStatisticKey,
} from '@/services/facebook/facebook.types';
import {
  useGetDolphinStatisticsQuery,
  useUnlinkAdvertisingCampaignFromPipeMutation,
} from '@/services/facebook/facebook';
// eslint-disable-next-line max-len
import ModalLinkAdvertisingCampaignToPipe from '@/modals/ModalLinkAdvertisingCampaignToPipe/ModalLinkAdvertisingCampaignToPipe';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useFromToFilter from '@/filters/useFromToFilter';
import FilterFromTo from '@/filters/FilterFromTo';

const defaultFrom = moment().subtract(6, 'day').format(dateFormatCommon);
const defaultTo = moment().format(dateFormatCommon);
const queryArraySeparator = ',';

const FacebookPage = () => {
  const { t } = useTranslation(['common', 'facebook']);
  const [columnsQuery, setColumnsQuery] = useQueryParam('columns', '');

  const { isMaster, isMultipleAccessToCommandUser } = useUserInfo();
  const { commandId, isComandWithDolphin } = useUserCommand();
  const { commandsWithDolphinId } = useHighLevelUserCommands();
  const { to, from } = useFromToFilter(defaultFrom, defaultTo);
  const { localizePipeStatus } = useLocalizePipeStatus();

  const {
    state: isSettingsPageOpen,
    setTrue: showSettingsPage,
    setFalse: hideSettingsPage,
  } = useBoolState();

  const columnsData = useMemo(
    () => (columnsQuery.length ? (columnsQuery as string).split(queryArraySeparator) : []),
    [columnsQuery],
  );

  const { data, isError, error, isFetching } = useGetDolphinStatisticsQuery({
    commandId: isComandWithDolphin ? commandId : commandsWithDolphinId[0].value,
    dateFrom: Formatter.formatDate(from, dateFormatStartsWithYear),
    dateTo: Formatter.formatDate(to, dateFormatStartsWithYear),
    columns: columnsData.filter(Boolean) as ApiDolphinStatisticKey[],
  });

  const [unlinkAdvertisingCampaignFromPipe] = useUnlinkAdvertisingCampaignFromPipeMutation();

  const handleColumnsChange = useCallback(
    (column) => {
      setColumnsQuery((prev) => {
        const data = prev.split(queryArraySeparator);
        const index = data.findIndex((a) => a === column);

        if (index === -1) data.push(column);
        else data.splice(index, 1);

        return data.filter(Boolean).join(queryArraySeparator);
      });
    },
    [setColumnsQuery],
  );

  const openConfirmUnlinkAdvertisingCampaignModal = useCallback(
    (a: ApiDolphinAdvertisingCampaignsWithStatistics & { pipeName: string }) => () => {
      ModalConfirm.show({
        onlyContinue: true,
        withAwaiting: true,
        continueLabel: t('common:buttonActions.unlink'),
        title: t('notifications.confirmActionOnPage'),
        subTitle: t('facebook:confirmUnlink', { avdCampaign: a.name, pipe: a.pipeName }),
        onContinue: async () => {
          const response = await unlinkAdvertisingCampaignFromPipe({ id: a.id, commandId });
          if ('data' in response) {
            Alerter.show(
              t('facebook:advertisingCampaignSuccessfullyUnlinked'),
              AlerterTypes.success,
            );
          }
        },
      });
    },
    [commandId, t, unlinkAdvertisingCampaignFromPipe],
  );

  const generateChildrenConfig = useCallback(
    (dolphin: ApiDolphin): TableConfig => {
      const list = dolphin.dolphinAdvertisingCampaigns || [];
      const additionalColumns: { label: string; value: ApiDolphinStatisticKey }[] = [];

      for (let i = 0; i < possibleFacebookColumns.length; i++) {
        if (columnsData.includes(possibleFacebookColumns[i].value)) {
          additionalColumns.push({
            label: t(`common:tableLabels.${possibleFacebookColumns[i].label}`),
            value: possibleFacebookColumns[i].value,
          });
        }
      }

      return list.map((a) => {
        const additionalColumnsItem = additionalColumns.map((column) => ({
          ...column,
          value: a.statistics[column.value] || 0,
        }));

        return {
          id: a.id,
          data: [
            {
              label: t('common:tableLabels.campaign'),
              value: a.name,
            },
            {
              label: t('common:tableLabels.ctr'),
              value: a.statistics.ctr,
            },
            {
              label: t('common:buttonActions.consumption'),
              value: a.statistics.spend,
            },
            ...additionalColumnsItem,
            {
              label: '',
              value: (
                <TableRowActions
                  onUnlink={openConfirmUnlinkAdvertisingCampaignModal({
                    ...a,
                    pipeName: dolphin.name,
                  })}
                />
              ),
            },
          ],
        };
      });
    },
    [columnsData, openConfirmUnlinkAdvertisingCampaignModal, t],
  );

  const config = useMemo<TableConfig>(() => {
    const list = data?.list || [];

    return list.map((a) => {
      return {
        id: a.id,
        childrenConfig: generateChildrenConfig(a),
        data: [
          {
            label: t('common:tableLabels.pipe'),
            value: a.name,
          },
          {
            label: t('common:roles.user'),
            value: getUserNickname(a.user),
          },
          {
            label: t('common:tableLabels.offer'),
            value: a.offer.name,
          },
          {
            label: t('common:tableLabels.status'),
            value: localizePipeStatus(a.status),
          },
        ],
      };
    });
  }, [data?.list, generateChildrenConfig, localizePipeStatus, t]);

  const isTokenExpired = isError && 'status' in error && error.status === 409;

  if (isTokenExpired) {
    return (
      <>
        <PageTitle>{t('common:sidebar.facebook')}</PageTitle>
        <EmptyMessageWithIcon
          title={t('facebook:tokenExpired.title')}
          description={t('facebook:tokenExpired.description')}
          additionalDescription={t('facebook:tokenExpired.additionalDescription')}
          linkUrl={isMultipleAccessToCommandUser ? routes.commands.path : ''}
          linkText={t('facebook:tokenExpired.linkText')}
        />
      </>
    );
  }

  if (isSettingsPageOpen) {
    return (
      <>
        <PageTitle>
          <StyledSettingsTitleWrapper>
            <IconButton onClick={hideSettingsPage}>
              <ArrowBack />
            </IconButton>
            <span>{t('common:configureColumns')}</span>
          </StyledSettingsTitleWrapper>
        </PageTitle>

        <PageSettingsTable
          onChange={handleColumnsChange}
          selectedOptions={columnsData}
          options={possibleFacebookColumns}
        />
      </>
    );
  }

  return (
    <>
      <PageTitle onboardingSteps={onboardingFacebookPage}>{t('common:sidebar.facebook')}</PageTitle>

      <FiltersWithSidebar
        settingsButtonProps={{
          onClick: showSettingsPage,
          className: onboardingFacebookPage[2].target,
        }}
        hasFilterFromTo
      >
        <FilterFromTo defaultFrom={defaultFrom} defaultTo={defaultTo} />
      </FiltersWithSidebar>

      {!isMaster && (
        <PageHeaderContent
          adviseTarget={onboardingFacebookPage[1].target}
          label={t('facebook:advertisingCampaigns')}
          onClick={ModalLinkAdvertisingCampaignToPipe.show}
        />
      )}

      <Table config={config} isLoading={isFetching} />
    </>
  );
};

export default FacebookPage;
