import { useFormContext, ControllerProps, Controller } from 'react-hook-form';
import { useCallback } from 'react';
import { FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';

import { StyledFormControlLabel } from '@/components/FormElementRadioGroup/FormElementRadioGroupStyled';

import useFormValidation from '@/hooks/useFormValidation';

import { FormElementRadioGroupProps } from '@/types/form';

const FormElementRadioGroup = ({ name, rules, values, label }: FormElementRadioGroupProps) => {
  const { control } = useFormContext();
  const { fieldValidate } = useFormValidation();

  const render: ControllerProps['render'] = useCallback(
    ({ field: { ref, ...field }, fieldState }) => {
      const value = field.value;
      const { isError, description } = fieldValidate(fieldState.error);

      return (
        <>
          <FormControl sx={{ paddingLeft: 2 }}>
            {label && <FormLabel id={`${name}-radio-buttons-group`}>{label}</FormLabel>}
            <RadioGroup {...field} value={value} row>
              {values.map((item) => (
                <StyledFormControlLabel
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>

          {!!description && <FormHelperText error={isError}>{description}</FormHelperText>}
        </>
      );
    },
    [fieldValidate, label, name, values],
  );

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementRadioGroup;
