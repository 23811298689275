import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiOfferStatus } from '@/services/offers/offers.types';

const useLocalizeOfferStatus = () => {
  const { t } = useTranslation('offer');

  const localizeOfferStatus = useCallback(
    (status: ApiOfferStatus) => {
      switch (status) {
        case ApiOfferStatus.CREATED:
          return t('status.created');
        case ApiOfferStatus.IN_WORK:
          return t('status.inWork');
        case ApiOfferStatus.SUSPENDED:
          return t('status.suspended');
        case ApiOfferStatus.PROBLEM:
          return t('status.problem');
        case ApiOfferStatus.CLOSED:
          return t('status.closed');
        default:
          return status;
      }
    },
    [t],
  );

  return { localizeOfferStatus };
};

export default useLocalizeOfferStatus;
