import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContentText } from '@mui/material';

import Modal from '@/components/Modal';
import Button from '@/components/Button';

import useAwaitCallback from '@/hooks/useAwaitCallback';

import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { ModalConfirmProps } from '@/modals/ModalConfirm/ModalConfirmTypes';

const ModalConfirm: StaticModalWrappedComponent<ModalConfirmProps> = (props) => {
  const { t } = useTranslation();

  const {
    onCancel,
    onContinue,
    onlyContinue = false,
    withAwaiting = false,

    cancelLabel = t('buttonActions.no'),
    continueLabel = t('buttonActions.yes'),
    title = t('modalConfirm.title'),
    subTitle = t('modalConfirm.subTitle'),
  } = props.data || {};

  const [createClickHandler, isLoading] = useAwaitCallback(
    async (type: 'cancel' | 'continue') => {
      if (type === 'continue' && onContinue) {
        const continueResult = onContinue();

        if (withAwaiting) {
          await continueResult;
        }
      } else if (type === 'cancel' && onCancel) {
        onCancel();
      }

      props.onClose();
    },
    [onCancel, onContinue, props, withAwaiting],
  );

  if (!props.data) return null;

  return (
    <Modal {...props} maxWidth={props.data.maxWidth} hasEmptyPadding title={title}>
      {subTitle && <DialogContentText sx={{ paddingLeft: '8px' }}>{subTitle}</DialogContentText>}

      <DialogActions>
        {!onlyContinue && (
          <Button variant='outlined' color='error' onClick={() => createClickHandler('cancel')}>
            {cancelLabel}
          </Button>
        )}

        <Button
          color='primary'
          isLoading={isLoading}
          onClick={() => createClickHandler('continue')}
        >
          {continueLabel}
        </Button>
      </DialogActions>
    </Modal>
  );
};

export default withStaticModal<ModalConfirmProps>(ModalConfirm);
