import { LoadingTextProps } from '@/components/LoadingText/LoadingTextTypes';
import { StyledText, StyledCircularProgress } from '@/components/LoadingText/LoadingTextStyled';

const LoadingText = ({ text, isLoading = false, isLeftCenter = false }: LoadingTextProps) => {
  return (
    <>
      <StyledText $isLoading={isLoading}>{text}</StyledText>
      {isLoading && (
        <StyledCircularProgress $isLeftCenter={isLeftCenter} color='inherit' size={24} />
      )}
    </>
  );
};

export default LoadingText;
