import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { PossibleTabs, dashboardTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import Tabs from '@/components/Tabs/Tabs';

import useUserInfo from '@/hooks/useUserInfo';
import { useDashboardsPageCurrentTab } from '@/hooks/useDashboardsPageCurrentTab';

const leaderTabs = [PossibleTabs.parameters, PossibleTabs.masters];

const DashboardsTabs = () => {
  const { t } = useTranslation('dashboard');
  const { isMaster, isLeader } = useUserInfo();
  const { currentTab, setCurrentTab } = useDashboardsPageCurrentTab();

  const options = useMemo(() => {
    const tabs = isLeader
      ? dashboardTabs.filter((a) => leaderTabs.includes(a.value))
      : dashboardTabs;

    return tabs.map((a) => ({ ...a, label: t(`tabs.${a.value}`) }));
  }, [isLeader, t]);

  if (isMaster) return null;

  return <Tabs value={currentTab} setValue={setCurrentTab} options={options} isWithoutBackground />;
};

export default DashboardsTabs;
