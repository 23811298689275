export enum FormModalOfferReportValues {
  DATE = 'date',
  DEPOSITS = 'deposits',
  INSTALLATIONS = 'installations',
  REGISTRATIONS = 'registrations',
  USER_ID = 'userId',
  REJECTED = 'rejected',
  HOLD = 'hold',
}

export interface FormModalOfferReportData {
  [FormModalOfferReportValues.DATE]: string;
  [FormModalOfferReportValues.DEPOSITS]: string;
  [FormModalOfferReportValues.INSTALLATIONS]: string;
  [FormModalOfferReportValues.REGISTRATIONS]: string;
  [FormModalOfferReportValues.USER_ID]: string;
  [FormModalOfferReportValues.REJECTED]: string;
  [FormModalOfferReportValues.HOLD]: string;
}
