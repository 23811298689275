import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { MenuProps } from '@/components/Menu/MenyTypes';
import { StyledMenu } from '@/components/Menu/MenuStyled';

import { useLogoutUserMutation } from '@/services/auth/auth';

const Menu = ({ anchorEl, handleCloseUserMenu, anchorElUser }: MenuProps) => {
  const { pathname } = useLocation();
  const [logoutUser] = useLogoutUserMutation();

  useEffect(() => {
    handleCloseUserMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleUserLogout = useCallback(async () => {
    await logoutUser();
  }, [logoutUser]);

  const menuListItems = useMemo(() => {
    return <MenuItem onClick={handleUserLogout}>Log out</MenuItem>;
  }, [handleUserLogout]);

  return (
    <>
      <StyledMenu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuListItems}
      </StyledMenu>
    </>
  );
};

export default Menu;
