import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

export enum FormModalCardConsumptionValues {
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  DATE = 'date',
}

export interface FormModalCardConsumptionData {
  [FormModalCardConsumptionValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalCardConsumptionValues.DATE]: string;
}
