import { useTranslation } from 'react-i18next';
import { ChangeEvent, useMemo, useState } from 'react';
import { Checkbox, capitalize } from '@mui/material';

import {
  Search,
  StyledInputBase,
  SearchIconWrapper,
  StyledPageSettings,
  StyledFiltersWrapper,
  PageSettingsTableCheckbox,
} from '@/pages/PageSettingsTable/PageSettingsTableStyled';
import { possibleFacebookColumns } from '@/pages/FacebookPage/constants';

import EmptyMessage from '@/components/EmptyMessage';

import IconSearch from '@/icons/IconSearch';

import { ApiDolphinStatisticKey } from '@/services/facebook/facebook.types';

interface SettingsPageProps {
  options: typeof possibleFacebookColumns;
  selectedOptions: string[];
  onChange: (column: ApiDolphinStatisticKey, checked: boolean) => void;
}

const PageSettingsTable = ({ options, selectedOptions, onChange }: SettingsPageProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const items = useMemo(() => {
    const result = options.filter((el) =>
      t(`tableLabels.${el.label}`).toLowerCase().includes(search.toLowerCase()),
    );

    return result.map((el) => ({
      value: el.value,
      label: capitalize(t(`tableLabels.${el.label}`)),
    }));
  }, [options, search, t]);

  const handleChange =
    (column: ApiDolphinStatisticKey) => (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(column, checked);
    };

  return (
    <>
      <StyledPageSettings>
        <Search>
          <SearchIconWrapper>
            <IconSearch />
          </SearchIconWrapper>
          <StyledInputBase placeholder={t('search')} onChange={(e) => setSearch(e.target.value)} />
        </Search>

        <StyledFiltersWrapper>
          {items.length ? (
            items.map((el) => (
              <PageSettingsTableCheckbox
                key={el.value}
                label={el.label}
                control={
                  <Checkbox
                    value={el.value}
                    onChange={handleChange(el.value)}
                    checked={selectedOptions.includes(el.value)}
                  />
                }
              />
            ))
          ) : (
            <EmptyMessage message={t('emptySearchMessage')} />
          )}
        </StyledFiltersWrapper>
      </StyledPageSettings>
    </>
  );
};

export default PageSettingsTable;
