import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';

import useQueryParam from '@/hooks/useQueryParam';

import { CommonSelectItem } from '@/types/form';

export type HookComponentProps<Multiple extends boolean = false> = AutocompleteProps<
  CommonSelectItem<ReactNode>,
  Multiple,
  false,
  false,
  'div'
>;

interface Hook {
  <T extends string, R extends string>(key: T, initialValue: R): Record<T, R> & {
    Component: <Multiple extends boolean = false>(
      props: Partial<HookComponentProps<Multiple>>,
    ) => JSX.Element;
  };
}

const usePageFilterAutocomplete: Hook = (key, initialValue) => {
  const [query, setQuery] = useQueryParam(key, initialValue);
  const [value, setValue] = useState<CommonSelectItem<ReactNode> | null>(null);

  const handleValueSelect = useCallback(
    (value: CommonSelectItem<ReactNode> | null) => {
      setQuery(value?.value || '', 'replaceIn', true);
      setValue(value);
    },
    [setQuery],
  );

  return {
    [key]: query,
    Component: (props: HookComponentProps) => {
      const mutableProps: Partial<HookComponentProps> = useMemo(() => {
        const commonProps: Partial<HookComponentProps> = {
          size: 'small',
          fullWidth: true,
          componentsProps: {
            paper: {
              sx: {
                padding: 0,
                width: '100%',
              },
            },
          },
          sx: {
            maxWidth: 240,
          },
        };

        if (!props) {
          return commonProps;
        }

        return { ...commonProps, ...props };
      }, [props]);

      return (
        <Autocomplete
          {...mutableProps}
          id={props.title}
          value={value || undefined}
          onChange={(_, value) => handleValueSelect(value)}
          disablePortal
          options={props.options}
          renderInput={(params) => <TextField {...params} label={mutableProps.title} />}
        />
      );
    },
  } as any;
};

export default usePageFilterAutocomplete;
