import { useTranslation } from 'react-i18next';

import { OffersOption } from '@/types/filters';
import { ApiOfferStatus } from '@/services/offers/offers.types';
import useStatusFilter from '@/filters/useStatusFilter';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

export const getOfferStatusOptions = (localizeOfferStatus: {
  (status: ApiOfferStatus): string;
}) => [
  {
    label: localizeOfferStatus(ApiOfferStatus.CREATED),
    value: ApiOfferStatus.CREATED,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.IN_WORK),
    value: ApiOfferStatus.IN_WORK,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.SUSPENDED),
    value: ApiOfferStatus.SUSPENDED,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.PROBLEM),
    value: ApiOfferStatus.PROBLEM,
  },
  {
    label: localizeOfferStatus(ApiOfferStatus.CLOSED),
    value: ApiOfferStatus.CLOSED,
  },
];

const useOffersStatusFilter = (options: OffersOption[]) => {
  const { t } = useTranslation();

  const { status } = useStatusFilter<ApiOfferStatus>();

  const { Component } = usePageFilter('status', '');

  return {
    status: status || undefined,
    OffersStatusFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.status')} options={options} />
    ),
  };
};

export default useOffersStatusFilter;
