import { css, ListItem, Typography } from '@mui/material';
import styled from '@emotion/styled';

import transientProps from '@/utils/transientProps';

interface StyledSidebarItemProps {
  $isActive?: boolean;
}

export const StyledSidebarItemSimpleDeep = styled(ListItem, transientProps)<StyledSidebarItemProps>`
  transition: all 250ms;

  // TODO: доделать
  ${({ theme, $isActive }) =>
    $isActive
      ? css`
          background: ${theme.palette.other.outlineBorder};
        `
      : css`
          background: ${theme.palette.other.outlineBorder};
          &:hover {
            background: ${theme.palette.background.paper};
          }
        `};

  a {
    transition: all 250ms;
    white-space: break-spaces;

    /* padding-top: 8px;
    padding-bottom: 8px; */
  }
`;

export const StyledSidebarItemSimpleDeepPopper = styled(
  Typography,
  transientProps,
)<StyledSidebarItemProps>`
  white-space: break-spaces;
  // padding: 8px 16px;
  cursor: pointer;

  transition: all 250ms;

  ${({ theme, $isActive }) =>
    $isActive
      ? css`
          background: ${theme.palette.secondary.light};
        `
      : css`
          &:hover {
            background: ${theme.palette.warning.main};
          }
        `};
`;
