import { DistributionModel } from '@/services/consumables/consumables.types';
import { ApiConsumableCategory } from '@/services/consumableCategories/consumableCategories.types';

export enum FormModalConsumableReturnValues {
  AMOUNT = 'amount',
  DATE = 'date',
}

export interface FormModalConsumableReturnData {
  [FormModalConsumableReturnValues.AMOUNT]: string;
  [FormModalConsumableReturnValues.DATE]: string;
}

export interface ModalConsumableReturnProps {
  consumableId: string;
  distribution: DistributionModel;
  category: ApiConsumableCategory;
}
