import { useTranslation } from 'react-i18next';

import Select from '@/components/Select';

import useUserRoles from '@/hooks/useUserRoles';
import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { FilterSelectProps } from '@/types/filters';
import useUserRoleFilter from '@/filters/useUserRoleFilter';

const FilterUserRole = <Multiple extends boolean = false>(props: FilterSelectProps<Multiple>) => {
  const { t } = useTranslation(['common']);

  const userRolesOptions = useUserRoles(true);
  const filterProps = useFilterProps(props);

  const { userRole, setUserRole } = useUserRoleFilter();

  const handleValueSelect = <Multiple extends boolean>(value: SelectValue<Multiple>) =>
    setUserRole(value as SelectValue<false>, 'replaceIn', true);

  return (
    <Select
      {...filterProps}
      isClearable
      isControlFromProp
      label={t('common:role')}
      options={userRolesOptions}
      onSelect={handleValueSelect}
      value={userRole}
    />
  );
};

export default FilterUserRole;
