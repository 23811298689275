import { Line } from 'react-chartjs-2';
import { styled, css } from '@mui/material';

import EmptyMessage from '@/components/EmptyMessage';

import transientProps from '@/utils/transientProps';

export const StyledWrapper = styled('div', transientProps)<{
  $isFetching?: boolean;
  $showScroll?: boolean;
}>`
  flex: 1;

  ${({ $isFetching }) =>
    $isFetching &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${({ $isFetching, $showScroll }) =>
    !$isFetching &&
    $showScroll &&
    css`
      min-width: 200%;
    `}

  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => `${theme.shape.borderRadiusSecond}px`};
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
`;

export const StyledLineChart = styled(Line)`
  // Note: контроль размеров LineChart (обычный height не воспринимает)
  max-height: 238px;
`;

export const StyledEmptyMessage = styled(EmptyMessage)`
  position: relative;
  top: 50%;
`;

export const StyledScrollWrapper = styled('div')`
  overflow-y: auto;
  flex: 1;
`;
