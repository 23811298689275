import { Stack, styled } from '@mui/material';

export const StyledContentWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContent = styled(Stack)`
  max-width: 70%;

  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const StyledButtonWrapper = styled('div')`
  margin-top: 20px;
  max-width: 270px;
  width: 100%;
`;
