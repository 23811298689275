import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { ApiResponse } from '@/types/api';
import { tagTypes } from '@/services/common/common';

type tagType = (typeof tagTypes)[number];

type Entity = { id: string | number };
type ApiResponseWithEntitiesWithId = ApiResponse<{ list: Entity[] }>;

export const providesList =
  (tag: tagType) => (result: ApiResponseWithEntitiesWithId | undefined) => {
    if (!result) {
      return [{ type: tag, id: 'LIST' }];
    }

    return [{ type: tag, id: 'LIST' }, ...result.list.map(({ id }) => ({ type: tag, id }))];
  };

export const providesListItem = (tag: tagType) => (result: ApiResponse<Entity> | undefined) => {
  if (!result) {
    return [];
  }

  return [{ type: tag, id: result.id }];
};

export const invalidatesTags =
  (type: tagType | tagType[]) => (result: ApiResponse<any> | undefined) => {
    const tags = typeof type === 'string' ? [type] : type;
    return result ? tags : [];
  };

export const invalidatesListItem =
  <P extends Entity>(type: tagType, additional: tagType[] = []) =>
  (_result: any, error: FetchBaseQueryError | undefined, arg: P | Entity['id']) => {
    if (error) {
      return [];
    }

    const id = typeof arg === 'object' ? arg.id : arg;

    return [...additional.map((type) => ({ type })), { type, id }];
  };
