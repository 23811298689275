import { useMemo } from 'react';

import FormElementSwitch from '@/components/FormElementSwitch';
import FormElementSumWithCurrency from '@/components/FormElementSumWithCurrency';
import FormElementSelect from '@/components/FormElementSelect';
import FormElementRadioGroup from '@/components/FormElementRadioGroup';
import FormElementPickerWithSelect from '@/components/FormElementPickerWithSelect';
import FormElementPicker from '@/components/FormElementPicker';
import FormElementInputChips from '@/components/FormElementInputChips';
import FormElementInput from '@/components/FormElementInput';
import FormElementFileUpload from '@/components/FormElementFileUpload';
import FormElementDatePicker from '@/components/FormElementDatepicker';
import FormElementCheckbox from '@/components/FormElementCheckbox';
import FormElementAutocomplete from '@/components/FormElementAutocomplete';
import { FormElementProps } from '@/components/FormElement/FormElementTypes';
import { StyledFormElement } from '@/components/FormElement/FormElementStyled';

const isMarkedRequiredFields = false;

const FormElement = ({ name, rules, className, ...restProps }: FormElementProps) => {
  if (isMarkedRequiredFields) {
    if (restProps.component !== 'checkbox') {
      if (rules?.required && 'label' in restProps && restProps.label) {
        restProps.label = restProps.label + '*';
      }
    }
  }

  const Component = useMemo(() => {
    if (restProps.component === 'picker') {
      return <FormElementPicker name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'pickerWithSelect') {
      return <FormElementPickerWithSelect name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'switch') {
      return <FormElementSwitch name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'select') {
      return <FormElementSelect name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'autocomplete') {
      return <FormElementAutocomplete name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'checkbox') {
      return <FormElementCheckbox name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'radioGroup') {
      return <FormElementRadioGroup name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'datepicker') {
      return <FormElementDatePicker name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'fileUpload') {
      return <FormElementFileUpload name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'sumWithCurrency') {
      return <FormElementSumWithCurrency name={name} rules={rules} {...restProps} />;
    }

    if (restProps.component === 'inputChips') {
      return <FormElementInputChips name={name} rules={rules} {...restProps} />;
    }

    return <FormElementInput name={name} rules={rules} {...restProps} />;
  }, [name, restProps, rules]);

  return <StyledFormElement className={className}>{Component}</StyledFormElement>;
};

export default FormElement;
