import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiUserRole } from '@/services/auth/auth.types';

const useLocalizeUserRole = () => {
  const { t } = useTranslation();

  const localizeUserRole = useCallback(
    (userRole: ApiUserRole) => {
      switch (userRole) {
        case ApiUserRole.OWNER:
          return t('roles.owner');
        case ApiUserRole.USER:
          return t('roles.user');
        case ApiUserRole.LEAD_USER:
          return t('roles.lead');
        case ApiUserRole.SUPER_ADMIN:
          return t('roles.admin');
        case ApiUserRole.COMMAND_FINANCIER:
          return t('roles.commandFinancier');
        case ApiUserRole.COMPANY_FINANCIER:
          return t('roles.companyFinancier');
        default:
          return userRole;
      }
    },
    [t],
  );

  return { localizeUserRole };
};

export default useLocalizeUserRole;
