import { useMemo, useState } from 'react';

import { StyledLoginPage, StyledLoginPageContent } from '@/pages/LoginPage/LoginPageStyled';
import FormRecoveryPassword from '@/pages/LoginPage/FormRecoveryPassword/FormRecoveryPassword';
import FormLogin from '@/pages/LoginPage/FormLogin/FormLogin';
import FormForgotPassword from '@/pages/LoginPage/FormForgotPassword/FormForgotPassword';

import useQueryParam from '@/hooks/useQueryParam';

const LoginPage = () => {
  const [recoveryHash, setRecoveryHash] = useQueryParam('recoveryHash', '');
  const [isForgotPassword, setForgotPasswordState] = useState(false);

  const currentForm = useMemo(() => {
    if (recoveryHash) {
      return (
        <FormRecoveryPassword
          recoveryHash={recoveryHash}
          setRecoveryHash={setRecoveryHash}
          setForgotPasswordState={setForgotPasswordState}
        />
      );
    }

    if (isForgotPassword) {
      return <FormForgotPassword setForgotPasswordState={setForgotPasswordState} />;
    }

    return <FormLogin setForgotPasswordState={setForgotPasswordState} />;
  }, [isForgotPassword, setRecoveryHash, recoveryHash]);

  return (
    <StyledLoginPage>
      <StyledLoginPageContent>{currentForm}</StyledLoginPageContent>
    </StyledLoginPage>
  );
};

export default LoginPage;
