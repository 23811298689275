import { useCallback, useMemo, useState } from 'react';

import { DashboardSettings, PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo, { getUserBooleanRoles } from '@/hooks/useUserInfo';

import {
  ApiDashboardSettingsParameters,
  ApiDashboardSettingsPostBody,
} from '@/services/dashboard/dashboard.types';
import {
  usePatchAllDashboardSettingsMutation,
  usePatchDashboardSettingsMutation,
  usePostDashboardSettingsMutation,
} from '@/services/dashboard/dashboard';

export const useSettingsDashboardFilters = (settings: DashboardSettings) => {
  const { isLeader, isMaster, userInfo } = useUserInfo();

  const [parameters, setParameters] = useState(settings?.parameters || []);
  const [usersIds, setMastersIds] = useState(settings?.usersIds?.filter(filterBoolean) || []);
  const [commandsIds, setCommandsIds] = useState(
    settings?.commandsIds?.filter(filterBoolean) || [],
  );

  const [postFilters, { isLoading: isPostLoading }] = usePostDashboardSettingsMutation();
  const [patchFilters, { isLoading: isPatchLoading }] = usePatchDashboardSettingsMutation();
  const [patchAllFilters, { isLoading: isAllDashboardsPatchLoading }] =
    usePatchAllDashboardSettingsMutation();

  const checkFulledFields = useCallback(
    (data: Partial<ApiDashboardSettingsParameters>) => {
      const isMastersIds = Boolean(data?.usersIds?.length);
      const isParameters = Boolean(data?.parameters?.length);
      const isCommandsIds = Boolean(data?.commandsIds?.length);

      if (isMaster) return isParameters;
      if (isLeader) return isParameters && isMastersIds;

      if (settings.tab === PossibleTabs.commands) return isParameters && isCommandsIds;

      return isParameters && isMastersIds && isCommandsIds;
    },
    [isLeader, isMaster, settings.tab],
  );

  const handleChangeFilters = useCallback(
    (data: Partial<ApiDashboardSettingsParameters>) => {
      const { isMultipleAccessToCommandUser, isMaster } = getUserBooleanRoles(userInfo);

      // TODO: костыль и временное решение
      data.usersIds = data.usersIds?.filter(Boolean);
      data.parameters = data.parameters?.filter(Boolean);
      data.commandsIds = data.commandsIds?.filter(Boolean);

      data.usersIds && setMastersIds(data.usersIds);
      data.parameters && setParameters(data.parameters);
      data.commandsIds && setCommandsIds(data.commandsIds);

      if (settings?.id && checkFulledFields(data)) {
        const a = {
          parameters: data.parameters,
          usersIds: isMaster ? [userInfo!.id] : data.usersIds,
          commandsIds: isMultipleAccessToCommandUser ? data.commandsIds : [userInfo!.command.id],
        };

        if (settings.isLocale) {
          // NOTE:
          // валидация выше все проверяет
          // тут уже должно быть все корректно
          postFilters({ ...a, tab: settings.tab } as ApiDashboardSettingsPostBody);
        } else {
          patchFilters({ ...a, id: settings.id });
        }
      }
    },
    [
      checkFulledFields,
      patchFilters,
      postFilters,
      settings.id,
      settings.isLocale,
      settings.tab,
      userInfo,
    ],
  );

  const handleChangeAllDashboards = useCallback(
    (data: Partial<ApiDashboardSettingsParameters>) => {
      if (data.usersIds) setMastersIds(data.usersIds);
      if (data.parameters) setParameters(data.parameters);
      if (data.commandsIds) setCommandsIds(data.commandsIds);

      patchAllFilters({
        tab: settings.tab,
        usersIds: data.usersIds,
        parameters: data.parameters,
        commandsIds: data.commandsIds,
      });
    },
    [patchAllFilters, settings.tab],
  );

  return useMemo(() => {
    return {
      filters: {
        masters: usersIds,
        commands: commandsIds,
        parameters: parameters,
      },
      updateFilters: handleChangeFilters,
      updateAllFilters: handleChangeAllDashboards,
      isUpdating: isPatchLoading || isPostLoading || isAllDashboardsPatchLoading,
    };
  }, [
    commandsIds,
    handleChangeAllDashboards,
    handleChangeFilters,
    isAllDashboardsPatchLoading,
    isPatchLoading,
    isPostLoading,
    usersIds,
    parameters,
  ]);
};
