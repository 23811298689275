import { useTranslation } from 'react-i18next';
import { useCallback, Suspense } from 'react';
import { DialogActions } from '@mui/material';

import TrackerForm from '@/components/TrackerForm';
import Modal from '@/components/Modal';
import Loader from '@/components/Loader';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import CompanyLeadsForm from '@/components/CompanyLeadsForm/CompanyLeadsForm';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useBoolState from '@/hooks/useBoolState';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiCompanyByIdPatchBody } from '@/services/companies/companies.types';
import { useUpdateCompanyByIdMutation } from '@/services/companies/companies';
import withStaticModal, { StaticModalWrappedComponent, Entity } from '@/modals/withStaticModal';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import {
  FormModalCompanyData,
  FormModalCompanyValues,
} from '@/modals/ModalCompany/ModalCompanyTypes';

const ModalCompany: StaticModalWrappedComponent<Entity> = (props) => {
  const { t } = useTranslation(['common', 'command']);
  const { company, name, tracker, selfhost } = useUserCompanyInfo();

  const { state: companyWithTracker, toggle: toggleTrackerState } = useBoolState(
    Boolean(tracker?.apiUrl),
  );

  const { state: companyWithSelfhost, toggle: toggleSelfhostState } = useBoolState(
    Boolean(selfhost),
  );

  const { state: isWriteHeaders, toggle: setHeadersWriteState } = useBoolState(
    Boolean(selfhost?.leadsHeaders),
  );

  const [updateCompany, { isLoading }] = useUpdateCompanyByIdMutation();

  const defaultValues = {
    [FormModalCompanyValues.NAME]: name,
    [FormModalCompanyValues.SERVICE]: tracker?.service,
    [FormModalCompanyValues.TRACKER_API_URL]: tracker?.apiUrl,
    [FormModalCompanyValues.TRACKER_API_KEY]: tracker?.apiKey,
    [FormModalCompanyValues.SELFHOST_LEADS_API_URL]: selfhost?.leadsUrl,
    [FormModalCompanyValues.SELFHOST_LEADS_API_HEADERS]: selfhost?.leadsHeaders,
  };

  const updateCompanyData = useCallback(
    async (data: FormModalCompanyData, companyId: string) => {
      const body: ApiCompanyByIdPatchBody = {
        id: companyId,
        name: data[FormModalCompanyValues.NAME],
        tracker: !companyWithTracker
          ? null
          : {
              apiKey: data[FormModalCompanyValues.TRACKER_API_KEY],
              apiUrl: data[FormModalCompanyValues.TRACKER_API_URL],
              service: data[FormModalCompanyValues.SERVICE],
            },
        selfhost: !companyWithSelfhost
          ? null
          : {
              leadsUrl: data[FormModalCompanyValues.SELFHOST_LEADS_API_URL],
              leadsHeaders: !isWriteHeaders
                ? ''
                : data[FormModalCompanyValues.SELFHOST_LEADS_API_HEADERS],
            },
      };

      const response = await updateCompany(body);

      if ('data' in response) {
        Alerter.show(t('common:notifications.updated'), AlerterTypes.success);
        props.onClose();
      }
    },
    [companyWithSelfhost, companyWithTracker, isWriteHeaders, props, t, updateCompany],
  );

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCompanyData) => {
      if (!company) {
        return Alerter.show(t('command:companyNotFound'), AlerterTypes.error);
      }

      if (!companyWithTracker && tracker) {
        ModalConfirm.show({
          maxWidth: 'xs',
          cancelLabel: t('common:buttonActions.abort'),
          continueLabel: t('common:buttonActions.continue'),
          title: t('command:confirmUpdate'),
          onContinue: () => updateCompanyData(data, company.id),
          subTitle: t('command:alertWhenUntyingTracker'),
        });

        return;
      }

      updateCompanyData(data, company.id);
    },
    [companyWithTracker, updateCompanyData, company],
  );

  return (
    <Modal {...props} title={t('command:editCompany')}>
      <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
        <FormElement
          component='input'
          label={t('common:formLabels.companyName')}
          name={FormModalCompanyValues.NAME}
          rules={FormRuler.requiredInput}
        />

        <Suspense fallback={<Loader />}>
          <TrackerForm
            isChecked={companyWithTracker}
            onChangeState={toggleTrackerState}
            disabledSelect={Boolean(tracker?.service)}
          />
        </Suspense>

        <Suspense fallback={<Loader />}>
          <CompanyLeadsForm
            isChecked={companyWithSelfhost}
            isWriteHeaders={isWriteHeaders}
            onChangeState={toggleSelfhostState}
            onChangeHeadersState={setHeadersWriteState}
          />
        </Suspense>

        <DialogActions>
          <Button type='submit' isLoading={isLoading}>
            {t('common:buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<Entity>(ModalCompany);
