import { useParams } from 'react-router';

interface PossibleParams {
  id?: string;
}

export const usePageParams = () => {
  const params = useParams<PossibleParams>();

  // NOTE:
  // Требуется, если нет отдельного /create Route сущности
  // Такое может быть, чтобы не было перерендера компонента, в случае
  // Обновления URL страницы с ID сущности на лету, при создании пошагового создания

  const id = params.id === 'create' ? undefined : params.id;

  return {
    ...params,

    id,
    isCreation: !id,
  };
};
