import { styled, Stack } from '@mui/material';

export const StyledStatisticBlock = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(2, 4)};
  margin-bottom: 16px;

  overflow-x: auto;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  background-color: white;
`;
