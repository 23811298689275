import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import useUserCommand from '@/hooks/useUserCommand';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';

import { setCommandAction } from '@/redux/user/userActions';

const EffectAppInit = () => {
  const dispatch = useDispatch();

  const { commandId } = useUserCommand();
  const { commands } = useHighLevelUserCommands();

  useEffect(() => {
    if (commandId || !commands.length) return;

    const defaultCommand = commands[0];

    dispatch(
      setCommandAction({
        commandId: defaultCommand.value,
        isComandWithDolphin: Boolean(defaultCommand.itemEntity?.isComandWithDolphin),
      }),
    );
  }, [commandId, commands, dispatch]);

  return null;
};

export default EffectAppInit;
