import { cloneElement, CSSProperties, MouseEvent, ReactElement, useState } from 'react';
import { Popper as LibraryPopper, Typography } from '@mui/material';
import { PopperPlacementType } from '@mui/material';

import { ZINDEX } from '@/resources/constants';

interface PopperProps {
  text: string;
  children: ReactElement<HTMLDivElement>;
  placement?: PopperPlacementType;
  width?: number | string;
  maxWidth?: number | string;
  textStyles?: CSSProperties;
}

const Popper = ({
  children,
  text,
  width = '100%',
  // Note: длина самого длинного слова
  maxWidth = 324,
  placement = 'top',
  textStyles,
}: PopperProps) => {
  const [isOpenedPopper, setOpenedPopperState] = useState(false);
  const [anchorPopperElement, setAnchorPopperElement] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    setOpenedPopperState(true);
    setAnchorPopperElement(e.currentTarget);
  };

  const handleMouseLeave = () => {
    setOpenedPopperState(false);
  };

  return (
    <>
      {/* @ts-ignore */}
      <LibraryPopper
        placement={placement}
        style={{
          zIndex: ZINDEX.POPPER,
          pointerEvents: 'none',
        }}
        open={isOpenedPopper}
        anchorEl={anchorPopperElement}
      >
        <Typography
          variant='body2'
          style={{
            width,
            maxWidth,
            padding: 16,
            color: 'white',
            whiteSpace: 'pre-line',
            marginBottom: 8,
            borderRadius: 16,
            background: 'rgba(60, 60, 60, 0.85)',
            ...textStyles,
          }}
        >
          {text}
        </Typography>
      </LibraryPopper>

      {children &&
        cloneElement(children, {
          // TODO: Типизация
          // @ts-ignore
          style: { cursor: 'pointer' },
          onMouseLeave: handleMouseLeave,
          onMouseEnter: handleMouseEnter,
        })}
    </>
  );
};

export default Popper;
