import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { TableWithSettings } from '@/types/table';
import { selectTableSettings } from '@/redux/user/userGetters';

const useColumnVisibility = (tableName: TableWithSettings) => {
  const visibleColumns = useSelector(selectTableSettings(tableName));

  const checkColumnVisibility = useCallback(
    (columnName: string) => {
      return visibleColumns.findIndex((a: string) => a === columnName) !== -1;
    },
    [visibleColumns],
  );

  return { checkColumnVisibility };
};

export default useColumnVisibility;
