import { styled, Grid, css } from '@mui/material';

import transientProps from '@/utils/transientProps';

interface StyledGridProps {
  $withoutPadding?: boolean;
  $isCustomPadding?: boolean;
}

export const StyledGrid = styled(Grid, transientProps)<StyledGridProps>`
  ${({ $withoutPadding }) =>
    !$withoutPadding &&
    css`
      &.MuiGrid-item {
        padding-top: 0px;
      }
    `}

  ${({ $isCustomPadding }) =>
    $isCustomPadding &&
    css`
      &.MuiGrid-item {
        padding-top: 12px;
      }
    `}
`;

export const StyledLocalizationWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(2, 4)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadiusSecond}px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
`;
