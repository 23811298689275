import {
  ApiCurrenciesGetResponse,
  ApiExchangeGetQuery,
  ApiExchangeGetResponse,
} from '@/services/exchanges/exchanges.types';
import commonApi from '@/services/common/common';

const baseUrl = '/exchanges';

const exchangesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<ApiCurrenciesGetResponse, void>({
      query: () => `${baseUrl}/currencies`,
    }),
    getExchange: build.query<ApiExchangeGetResponse['data'], ApiExchangeGetQuery>({
      query: (params) => ({ url: `${baseUrl}/exchange`, params }),
      transformResponse(response: ApiExchangeGetResponse) {
        return response.data;
      },
    }),
  }),
});

export const { useGetCurrenciesQuery, useLazyGetExchangeQuery } = exchangesApi;

export default exchangesApi;
