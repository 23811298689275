import useQueryParam from '@/hooks/useQueryParam';

export enum ArchiveEnum {
  ALL = 'all',
  UNARCHIVED = 'false',
  ARCHIVED = 'true',
}

const useArchivedFilter = () => {
  const queryName = 'archived';
  const [archived, setArchived] = useQueryParam(queryName, ArchiveEnum.UNARCHIVED);

  return {
    setArchived,
    archived: archived as ArchiveEnum,
  };
};

export default useArchivedFilter;
