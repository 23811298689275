import { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

import Typography from '@/components/Typography';
import { StatisticBlockProps } from '@/components/StatisticBlock/StatisticBlockTypes';
import { StyledStatisticBlock } from '@/components/StatisticBlock/StatisticBlockStyled';
import Loader from '@/components/Loader';

const StatisticBlock = ({
  isLoading,
  hasData = false,
  children,
  title,
}: PropsWithChildren<StatisticBlockProps>) => {
  return (
    <StyledStatisticBlock alignItems={isLoading || !hasData ? 'center' : undefined}>
      {isLoading ? (
        <Loader isStatic size={20} />
      ) : (
        <Stack spacing={1}>
          {title && hasData && (
            <Typography variant='body2' fontWeight={500}>
              {title}
            </Typography>
          )}
          <Stack spacing={6} direction='row'>
            {children}
          </Stack>
        </Stack>
      )}
    </StyledStatisticBlock>
  );
};

export default StatisticBlock;
