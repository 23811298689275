import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useCallback, ChangeEvent, useMemo } from 'react';
import { TextField } from '@mui/material';

import Typography from '@/components/Typography';
import Select from '@/components/Select';
// eslint-disable-next-line max-len
import { StyledFormElementPickerBody } from '@/components/FormElementPickerWithSelect/FormElementPickerWithSelectStyled';
import {
  FormElementConsumablePickerProps,
  FormElementData,
  ValueObject,
} from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';
import { StyledWrapper } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerStyled';

import IconTrash from '@/icons/IconTrash';

import useUserConsumablesByCategory from '@/hooks/useUserConsumablesByCategory';
import useUserConsumableCategories from '@/hooks/useUserConsumableCategories';

const FormElementConsumablePicker = ({
  name,
  index,
  remove,
  data = {},
  disableAutofill,
}: FormElementConsumablePickerProps) => {
  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    setError,
    getValues,
  } = useFormContext<FormElementData<ValueObject>>();

  const { t } = useTranslation();

  const userId = watch('userId');
  const categoryId = watch(`${name}[${index}].value.category`);
  const consumableId = watch(`${name}[${index}].value.consumable`);

  const consumablesField = getValues(name);
  const consumablesByCategory = useMemo(
    () =>
      consumablesField
        .filter((a) => a.value?.category === categoryId)
        .map((a) => a.value?.consumable),
    [categoryId, consumablesField],
  );

  const { categories } = useUserConsumableCategories();
  const { consumables } = useUserConsumablesByCategory(
    data.category || categoryId,
    userId ? String(userId) : undefined,
  );

  const countFieldName = `${name}${index}`;

  const handleDelete = useCallback(() => {
    remove(index);
    clearErrors(countFieldName);
  }, [clearErrors, countFieldName, index, remove]);

  const handleSelectCategory = useCallback(
    (value: string) => {
      const fieldName = `${name}[${index}].value.category`;
      // @ts-ignore
      setValue(fieldName, value);
    },
    [index, name, setValue],
  );

  const handleSelectConsumable = useCallback(
    (value: string) => {
      const fieldName = `${name}[${index}].value.consumable`;
      // @ts-ignore
      setValue(fieldName, value);
    },
    [index, name, setValue],
  );

  const checkIsAvailableCount = useCallback(
    (count: string) => {
      const currentConsumable = consumables.find((a) => a.value === consumableId);

      if (!currentConsumable || currentConsumable.availableCount >= Number(count)) {
        clearErrors(countFieldName);
        return;
      }

      setError(countFieldName, {
        message: t('cannotChooseMore', { count: currentConsumable.availableCount }),
      });
    },
    [clearErrors, consumableId, consumables, countFieldName, setError, t],
  );

  const handleUpdateCount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      checkIsAvailableCount(value);

      const fieldName = `${name}[${index}].value.count`;
      // @ts-ignore
      setValue(fieldName, value);
    },
    [checkIsAvailableCount, index, name, setValue],
  );

  const getAvailableOptions = useCallback(() => {
    return consumables.filter((item) => {
      return item.value === consumableId ? true : !consumablesByCategory.includes(item.value);
    });
  }, [consumables, consumableId, consumablesByCategory]);

  return (
    <>
      <StyledFormElementPickerBody>
        <StyledWrapper>
          <Select
            fullWidth
            size='small'
            label={t('tableLabels.category')}
            options={categories}
            value={data.category}
            disableAutofill={disableAutofill}
            onSelect={handleSelectCategory}
          />

          <Select
            fullWidth
            size='small'
            label={t('tableLabels.consumables')}
            value={data.consumable}
            options={getAvailableOptions()}
            disableAutofill={disableAutofill}
            onSelect={handleSelectConsumable}
          />

          <TextField
            label={t('tableLabels.quantity')}
            size='small'
            value={data.count}
            onChange={handleUpdateCount}
            error={Boolean(errors[countFieldName]?.message)}
          />
        </StyledWrapper>

        <IconTrash onClick={handleDelete} />
      </StyledFormElementPickerBody>

      {errors[countFieldName]?.message && (
        <Typography variant='subtitle2' color='error' sx={{ marginTop: 1 }}>
          {errors[countFieldName]?.message}
        </Typography>
      )}
    </>
  );
};

export default FormElementConsumablePicker;
