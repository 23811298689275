import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { useLazyGetCompaniesQuery } from '@/services/companies/companies';
import { ApiCommand, ApiCommandPostBody } from '@/services/commands/commands.types';
import {
  useCreateCommandMutation,
  useUpdateCommandByIdMutation,
} from '@/services/commands/commands';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalCommandData,
  FormModalCommandValues,
} from '@/modals/ModalCommand/ModalCommandTypes';

const ModalCommand: StaticModalWrappedComponent<ApiCommand> = (props) => {
  const { t } = useTranslation(['common', 'command']);
  const { isSuperAdmin } = useUserInfo();

  const [getCompanies, { data: companies }] = useLazyGetCompaniesQuery();
  const [createCommand, { isLoading: isCreating }] = useCreateCommandMutation();
  const [updateCommand, { isLoading: isUpdating }] = useUpdateCommandByIdMutation();

  useEffect(() => {
    if (!isSuperAdmin) return;
    getCompanies();
  }, [getCompanies, isSuperAdmin]);

  const companiesOptions = useMemo(() => {
    if (!isSuperAdmin) return [];

    const list = companies?.list || [];
    return list?.map((a) => ({ value: a.id, label: a.name }));
  }, [companies?.list, isSuperAdmin]);

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCommandData) => {
      const body: ApiCommandPostBody = {
        name: data[FormModalCommandValues.NAME],
        companyId: data[FormModalCommandValues.COMPANY_ID],
      };

      const response = props.data
        ? await updateCommand({ ...body, id: props.data.id })
        : await createCommand(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [createCommand],
  );

  const isEdit = Boolean(props.data);
  const title = isEdit ? t('command:editCommand') : t('command:addCommand');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} defaultValues={props.data}>
        {isSuperAdmin && (
          <FormElement
            component='select'
            label={t('common:selectCommand')}
            values={companiesOptions}
            name={FormModalCommandValues.COMPANY_ID}
            rules={FormRuler.required}
          />
        )}

        <FormElement
          component='input'
          label={t('common:formLabels.commandName')}
          name={FormModalCommandValues.NAME}
          rules={FormRuler.requiredInput}
        />

        <DialogActions>
          <Button isLoading={isCreating || isUpdating} type='submit'>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCommand>(ModalCommand);
