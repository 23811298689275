import { ApiHighLevelUserParams, ApiResponse, ApiResponseWithPagination, Void } from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiPipeStatus } from '@/services/pipes/pipes.types';
import { ApiOffer } from '@/services/offers/offers.types';

export enum ApiDolphinStatisticKey {
  spend = 'spend',
  ctr = 'ctr',
  impressions = 'impressions',
  link_click = 'link_click',
  link_click_cr = 'link_click_cr',
  mobile_app_install = 'mobile_app_install',
  outbound_click = 'outbound_click',
  outbound_click_cr = 'outbound_click_cr',
  outbound_click_cpa = 'outbound_click_cpa',
  video_view = 'video_view',
  video_view_cpa = 'video_view_cpa',
  video_30_sec_watched_actions = 'video_30_sec_watched_actions',
  fb_mobile_add_payment_info_cpa = 'fb_mobile_add_payment_info_cpa',
  fb_pixel_add_payment_info = 'fb_pixel_add_payment_info',
  fb_pixel_add_payment_info_cpa = 'fb_pixel_add_payment_info_cpa',
  fb_pixel_add_payment_info_cr = 'fb_pixel_add_payment_info_cr',
  video_view_cr = 'video_view_cr',
  video_30_sec_watched_actions_cr = 'video_30_sec_watched_actions_cr',
  video_30_sec_watched_actions_cpa = 'video_30_sec_watched_actions_cpa',
  fb_mobile_add_payment_info = 'fb_mobile_add_payment_info',
  fb_mobile_add_payment_info_cr = 'fb_mobile_add_payment_info_cr',
  fb_mobile_add_to_cart_cpa = 'fb_mobile_add_to_cart_cpa',
  fb_mobile_add_to_cart_cr = 'fb_mobile_add_to_cart_cr',
  fb_mobile_add_to_wishlist_cpa = 'fb_mobile_add_to_wishlist_cpa',
  fb_mobile_add_to_wishlist_cr = 'fb_mobile_add_to_wishlist_cr',
  mobile_app_install_cpa = 'mobile_app_install_cpa',
  mobile_app_install_cr = 'mobile_app_install_cr',
  fb_mobile_add_to_cart = 'fb_mobile_add_to_cart',
  fb_pixel_add_to_cart = 'fb_pixel_add_to_cart',
  fb_pixel_add_to_cart_cpa = 'fb_pixel_add_to_cart_cpa',
  fb_pixel_add_to_cart_cr = 'fb_pixel_add_to_cart_cr',
  fb_mobile_add_to_wishlist = 'fb_mobile_add_to_wishlist',
  fb_pixel_add_to_wishlist = 'fb_pixel_add_to_wishlist',
  fb_pixel_add_to_wishlist_cpa = 'fb_pixel_add_to_wishlist_cpa',
  fb_pixel_add_to_wishlist_cr = 'fb_pixel_add_to_wishlist_cr',
  fb_mobile_level_achieved = 'fb_mobile_level_achieved',
  fb_mobile_level_achieved_cpa = 'fb_mobile_level_achieved_cpa',
  fb_mobile_level_achieved_cr = 'fb_mobile_level_achieved_cr',
  fb_pixel_complete_registration = 'fb_pixel_complete_registration',
  fb_pixel_complete_registration_cpa = 'fb_pixel_complete_registration_cpa',
  fb_pixel_complete_registration_cr = 'fb_pixel_complete_registration_cr',
  fb_mobile_tutorial_completion = 'fb_mobile_tutorial_completion',
  fb_mobile_tutorial_completion_cpa = 'fb_mobile_tutorial_completion_cpa',
  fb_mobile_tutorial_completion_cr = 'fb_mobile_tutorial_completion_cr',
  fb_mobile_achievement_unlocked = 'fb_mobile_achievement_unlocked',
  fb_mobile_activate_app = 'fb_mobile_activate_app',
  fb_mobile_achievement_unlocked_cpa = 'fb_mobile_achievement_unlocked_cpa',
  fb_mobile_activate_app_cpa = 'fb_mobile_activate_app_cpa',
  fb_mobile_achievement_unlocked_cr = 'fb_mobile_achievement_unlocked_cr',
  fb_mobile_activate_app_cr = 'fb_mobile_activate_app_cr',
  app_use = 'app_use',
  app_use_cpa = 'app_use_cpa',
  app_use_cr = 'app_use_cr',
  games_plays_cpa = 'games_plays_cpa',
  games_plays = 'games_plays',
  games_plays_cr = 'games_plays_cr',
  comment = 'comment',
  comment_cpa = 'comment_cpa',
  comment_cr = 'comment_cr',
  like = 'like',
  like_cpa = 'like_cpa',
  like_cr = 'like_cr',
  fb_pixel_lead = 'fb_pixel_lead',
  fb_pixel_lead_cpa = 'fb_pixel_lead_cpa',
  fb_pixel_lead_cr = 'fb_pixel_lead_cr',
  fb_mobile_initiated_checkout = 'fb_mobile_initiated_checkout',
  fb_pixel_initiate_checkout = 'fb_pixel_initiate_checkout',
  fb_mobile_initiated_checkout_cpa = 'fb_mobile_initiated_checkout_cpa',
  fb_pixel_initiate_checkout_cpa = 'fb_pixel_initiate_checkout_cpa',
  fb_mobile_initiated_checkout_cr = 'fb_mobile_initiated_checkout_cr',
  fb_pixel_initiate_checkout_cr = 'fb_pixel_initiate_checkout_cr',
  reach = 'reach',
  fb_mobile_rate = 'fb_mobile_rate',
  fb_mobile_rate_cpa = 'fb_mobile_rate_cpa',
  fb_mobile_rate_cr = 'fb_mobile_rate_cr',
  fb_mobile_search = 'fb_mobile_search',
  fb_pixel_search = 'fb_pixel_search',
  fb_mobile_search_cpa = 'fb_mobile_search_cpa',
  fb_pixel_search_cpa = 'fb_pixel_search_cpa',
  fb_mobile_search_cr = 'fb_mobile_search_cr',
  fb_pixel_search_cr = 'fb_pixel_search_cr',
  fb_pixel_purchase = 'fb_pixel_purchase',
  fb_mobile_purchase = 'fb_mobile_purchase',
  fb_mobile_purchase_cpa = 'fb_mobile_purchase_cpa',
  fb_mobile_purchase_cr = 'fb_mobile_purchase_cr',
  fb_pixel_purchase_cpa = 'fb_pixel_purchase_cpa',
  fb_pixel_purchase_cr = 'fb_pixel_purchase_cr',
  fb_mobile_spent_credits = 'fb_mobile_spent_credits',
  credit_spent = 'credit_spent',
  fb_mobile_spent_credits_cpa = 'fb_mobile_spent_credits_cpa',
  credit_spent_cpa = 'credit_spent_cpa',
  fb_mobile_spent_credits_cr = 'fb_mobile_spent_credits_cr',
  credit_spent_cr = 'credit_spent_cr',
  apps_on_level = 'apps_on_level',
  video_play_actions = 'video_play_actions',
  video_p100_watched_actions = 'video_p100_watched_actions',
  video_p100_watched_actions_cpa = 'video_p100_watched_actions_cpa',
  video_p100_watched_actions_cr = 'video_p100_watched_actions_cr',
  video_p25_watched_actions = 'video_p25_watched_actions',
  video_p25_watched_actions_cr = 'video_p25_watched_actions_cr',
  video_p50_watched_actions = 'video_p50_watched_actions',
  video_p50_watched_actions_cpa = 'video_p50_watched_actions_cpa',
  video_p50_watched_actions_cr = 'video_p50_watched_actions_cr',
  video_p75_watched_actions = 'video_p75_watched_actions',
  video_p75_watched_actions_cpa = 'video_p75_watched_actions_cpa',
  video_p75_watched_actions_cr = 'video_p75_watched_actions_cr',
  video_p95_watched_actions = 'video_p95_watched_actions',
  video_p95_watched_actions_cpa = 'video_p95_watched_actions_cpa',
  video_p95_watched_actions_cr = 'video_p95_watched_actions_cr',
  video_play_actions_cpa = 'video_play_actions_cpa',
  purchase_roas = 'purchase_roas',
  checkin = 'checkin',
  checkin_cpa = 'checkin_cpa',
  checkin_cr = 'checkin_cr',
  link_click_cpa = 'link_click_cpa',
  cpm = 'cpm',
  frequency = 'frequency',
  fp_on_level = 'fp_on_level',
  adset_targeting = 'adset_targeting',
  video_avg_time_watched_actions = 'video_avg_time_watched_actions',
  video_avg_time_watched_actions_cpa = 'video_avg_time_watched_actions_cpa',
  video_avg_time_watched_actions_cr = 'video_avg_time_watched_actions_cr',
  post_cpa = 'post_cpa',
  post_cr = 'post_cr',
  fb_mobile_complete_registration_cr = 'fb_mobile_complete_registration_cr',
  results = 'results',
  results_cpa = 'results_cpa',
  results_cr = 'results_cr',
  post = 'post',
  fb_mobile_complete_registration_cpa = 'fb_mobile_complete_registration_cpa',
  post_reaction = 'post_reaction',
  post_reaction_cpa = 'post_reaction_cpa',
  post_reaction_cr = 'post_reaction_cr',
  rsvp = 'rsvp',
  rsvp_cpa = 'rsvp_cpa',
  rsvp_cr = 'rsvp_cr',
  fb_mobile_complete_registration = 'fb_mobile_complete_registration',
  landing_page_view_cpa = 'landing_page_view_cpa',
  landing_page_view_cr = 'landing_page_view_cr',
  photo_view = 'photo_view',
  photo_view_cpa = 'photo_view_cpa',
  photo_view_cr = 'photo_view_cr',
  other = 'other',
  other_cpa = 'other_cpa',
  other_cr = 'other_cr',
  landing_page_view = 'landing_page_view',
  fb_pixel_view_content_cr = 'fb_pixel_view_content_cr',
  fb_mobile_content_view_cr = 'fb_mobile_content_view_cr',
  fb_pixel_view_content_cpa = 'fb_pixel_view_content_cpa',
  fb_mobile_content_view_cpa = 'fb_mobile_content_view_cpa',
  fb_pixel_view_content = 'fb_pixel_view_content',
  fb_mobile_content_view = 'fb_mobile_content_view',
  video_play_actions_cr = 'video_play_actions_cr',
}

export interface ApiDolphin {
  id: string;
  name: string;
  status: ApiPipeStatus;
  user: ApiUser;
  offer: ApiOffer;
  dolphinAdvertisingCampaigns: ApiDolphinAdvertisingCampaignsWithStatistics[];
}

export interface ApiDolphinAdvertisingCampaigns {
  id: string;
  name: string;
  isAlreadyAdded: boolean;
}

export interface ApiDolphinAdvertisingCampaignsWithStatistics
  extends ApiDolphinAdvertisingCampaigns {
  statistics: Partial<Record<ApiDolphinStatisticKey, number>>;
}

// === GET ADVERTISING CAMPAIGNS ↴

export type ApiDolphinAdvertisingCampaignsGetQuery = Void<ApiHighLevelUserParams>;
export type ApiDolphinAdvertisingCampaignsGetResponse =
  ApiResponseWithPagination<ApiDolphinAdvertisingCampaigns>;

// === LINK ADVERTISING CAMPAIGNS ↴

export type ApiDolphinAdvertisingCampaignsPostBody = ApiHighLevelUserParams & {
  pipeId: string;
  campaignId: string;
};
export type ApiDolphinAdvertisingCampaignsPostResponse =
  ApiResponse<ApiDolphinAdvertisingCampaigns>;

// === GET STATISTIC ↴

export type ApiDolphinStatisticsGetQuery = Void<
  ApiHighLevelUserParams & { dateFrom: string; dateTo: string; columns: ApiDolphinStatisticKey[] }
>;
export type ApiDolphinStatisticsGetResponse = ApiResponseWithPagination<ApiDolphin>;

// === UNLINK ADVERTISING CAMPAIGNS ↴

export type ApiUnlinkAdvertisingCampaignFromPipeBody = ApiHighLevelUserParams &
  Pick<ApiDolphinAdvertisingCampaigns, 'id'>;
