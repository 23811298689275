import { DialogActions, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)`
  margin-bottom: 36px;
  padding: 0 8px;
`;

export const StyledWrapper = styled('div')`
  padding-bottom: 32px;

  & > * + * {
    margin-top: 12px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  width: 100%;

  bottom: 0;
  position: absolute;

  background: ${({ theme }) => theme.palette.common.white};
`;
