import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

import { ApiOperationCorrectType } from '@/services/operations/operations.types';

export enum FormModalCorrectOperationValues {
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  DATE = 'date',
  TYPE = 'type',
  REASON = 'reason',
}

export interface FormModalCorrectOperationData {
  [FormModalCorrectOperationValues.DATE]: string;
  [FormModalCorrectOperationValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalCorrectOperationValues.REASON]: string;
  [FormModalCorrectOperationValues.TYPE]: ApiOperationCorrectType;
}
