import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from '@/components/Typography';
import { StyledUserInfoBlock } from '@/components/Sidebar/components/SidebarUserInfo/SidebarUserInfoStyled';

import getAppVersion from '@/utils/getAppVersion';

import useUserInfo from '@/hooks/useUserInfo';
import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';

import { selectSidebarState } from '@/redux/user/userGetters';

const SidebarUserInfo = () => {
  const { t } = useTranslation();

  const { localizeUserRole } = useLocalizeUserRole();
  const { userInfo, isMultipleAccessToCommandUser } = useUserInfo();

  const isSidebarOpen = useSelector(selectSidebarState);

  if (!userInfo || !isSidebarOpen) return null;

  return (
    <StyledUserInfoBlock>
      <Typography color='text.secondary'>
        {t('sidebar.version', { version: getAppVersion() })}
      </Typography>

      <Typography color='text.secondary'>
        {t('role')}: {localizeUserRole(userInfo.role)}
      </Typography>

      {userInfo.command && !isMultipleAccessToCommandUser && (
        <Typography color='text.secondary'>
          {t('roles.team')}: {userInfo.command.name}
        </Typography>
      )}

      <Typography color='text.secondary'>
        {t('sidebar.email')}: {userInfo?.email}
      </Typography>
    </StyledUserInfoBlock>
  );
};

export default SidebarUserInfo;
