import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiOperationCategory } from '@/services/operations/operations.types';

const useLocalizeOperationCategory = () => {
  const { t } = useTranslation('operations');

  const localizeOperationCategory = useCallback(
    (status: ApiOperationCategory) => {
      switch (status) {
        case ApiOperationCategory.ALL:
          return t('category.all');
        case ApiOperationCategory.OTHER:
          return t('category.other');
        case ApiOperationCategory.TRAFFIC:
          return t('category.traffic');
        case ApiOperationCategory.CONSUMABLE:
          return t('category.consumable');
        case ApiOperationCategory.CARD_CLOSE:
          return t('category.cardClose');
        case ApiOperationCategory.CARD_BUYING:
          return t('category.cardBuying');
        case ApiOperationCategory.SERVICE_REPLENISHMENT:
          return t('category.serviceReplenishment');
        case ApiOperationCategory.CARD_REPLENISHMENT:
          return t('category.cardReplenishment');
        case ApiOperationCategory.OFFER_INCOME:
          return t('category.offerIncome');
        case ApiOperationCategory.REFUND:
          return t('category.refund');
        case ApiOperationCategory.PIPE_CONSUMABLE:
          return t('category.pipeConsumable');
        case ApiOperationCategory.INVESTMENT:
          return t('category.investment');
        default:
          return status;
      }
    },
    [t],
  );

  return { localizeOperationCategory };
};

export default useLocalizeOperationCategory;
