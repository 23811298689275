import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

export enum FormModalServiceReplenishmentValues {
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  DATE = 'date',
  USER = 'user',
}

export interface FormModalServiceReplenishmentData {
  [FormModalServiceReplenishmentValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalServiceReplenishmentValues.DATE]: string;
  [FormModalServiceReplenishmentValues.USER]: string;
}
