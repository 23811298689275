import { styled } from '@mui/material/styles';

export const StyledUserInfoBlock = styled('div')`
  margin-top: 32px;
  padding: 24px;

  border-top: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  background-color: ${({ theme }) => theme.palette.secondary.main};

  .MuiTypography-root + .MuiTypography-root {
    margin-top: 8px;
  }
`;
