import { invalidatesTags, providesList } from '@/services/utils';
import {
  ApiLeadsGetResponse,
  ApiLeadsGetQuery,
  ApiLeadsSynchronizationGetResponse,
  ApiLeadsLeadDeleteBody,
  ApiLeadsLeadDeleteResponse,
  ApiLeadsStatusesResponse,
  ApiLeadsStatusesQuery,
  ApiLeadsGetStatisticsResponse,
  ApiLeadsGetStatisticsQuery,
} from '@/services/leads/leads.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/leads';
const serviceTag = servicesTags.leads;
const statusesTag = servicesTags.leadsStatuses;

const offersApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getLeads: build.query<ApiLeadsGetResponse, ApiLeadsGetQuery>({
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getLeadsStatistics: build.query<ApiLeadsGetStatisticsResponse, ApiLeadsGetStatisticsQuery>({
      query: (params) => ({ url: `${baseUrl}/statistics`, params: params || undefined }),
    }),
    synchronizationLeads: build.mutation<ApiLeadsSynchronizationGetResponse, void>({
      query: () => ({ url: `${baseUrl}/synchronization` }),
      invalidatesTags: invalidatesTags([serviceTag, statusesTag]),
    }),
    deleteLeadById: build.mutation<ApiLeadsLeadDeleteResponse, ApiLeadsLeadDeleteBody>({
      query: ({ id }) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    getAllDistinctLeadStatuses: build.query<ApiLeadsStatusesResponse, ApiLeadsStatusesQuery>({
      query: () => ({ url: `${baseUrl}/statuses` }),
      providesTags: [statusesTag],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useDeleteLeadByIdMutation,
  useGetLeadsStatisticsQuery,
  useSynchronizationLeadsMutation,
  useGetAllDistinctLeadStatusesQuery,
} = offersApi;

export default offersApi;
