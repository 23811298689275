import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

import { IntegrationService } from '@/services/services/services.types';

export enum FormModalServiceValues {
  NAME = 'name',
  SERVICE = 'service',
  USER_IDS = 'userIds',
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  INTEGRATION_KEY = 'apiIntegrationKey',
  INTEGRATION_NAME = 'apiIntegrationName',
  COMMISSION_RETURN_FUNDS = 'commissionReturnFunds',
  COMMISSION_REPLENISHMENT_CARD = 'commissionReplenishmentCard',
  COMMISSION_REPLENISHMENT_SERVICE = 'commissionReplenishmentService',
}

export interface FormModalServiceData {
  [FormModalServiceValues.NAME]: string;
  [FormModalServiceValues.SERVICE]: string;
  [FormModalServiceValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalServiceValues.INTEGRATION_KEY]?: string;
  [FormModalServiceValues.COMMISSION_RETURN_FUNDS]: string;
  [FormModalServiceValues.INTEGRATION_NAME]?: IntegrationService;
  [FormModalServiceValues.COMMISSION_REPLENISHMENT_CARD]: string;
  [FormModalServiceValues.COMMISSION_REPLENISHMENT_SERVICE]: string;

  [FormModalServiceValues.USER_IDS]: { value?: string }[];
}
