import filterBoolean from '@/utils/filterBoolean';

import { ApiOperationCategory } from '@/services/operations/operations.types';
import { ApiOfferDashboardCategories } from '@/services/dashboard/dashboard.types';

export type PossibleStatisticCategories = ApiOperationCategory | ApiOfferDashboardCategories;

export const statisticOperationsCategories: ApiOperationCategory[] = Object.values(
  ApiOperationCategory,
)
  .filter(
    (a) =>
      ![
        ApiOperationCategory.OFFER_INCOME,
        ApiOperationCategory.REFUND,
        ApiOperationCategory.INVESTMENT,
        ApiOperationCategory.CARD_CLOSE,
        ApiOperationCategory.CARD_BUYING,
        ApiOperationCategory.CARD_REPLENISHMENT,
      ].includes(a),
  )
  .filter(filterBoolean);

export const statisticOfferCategories: ApiOfferDashboardCategories[] = Object.values(
  ApiOfferDashboardCategories,
);

export const statisticCategories: Array<PossibleStatisticCategories> = [
  ...statisticOperationsCategories,
  ...statisticOfferCategories,
];
