import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import filterBoolean from '@/utils/filterBoolean';
import FormRuler from '@/utils/FormRuler';

import useUserOffers from '@/hooks/useUserOffers';
import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import useSelectOptions from '@/hooks/useSelectOptions';
import useCommandMasters from '@/hooks/useCommandMasters';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { SelectItem } from '@/types/form';
import { ApiPipe, ApiPipePostBody } from '@/services/pipes/pipes.types';
import { useCreatePipeMutation, useUpdatePipeByIdMutation } from '@/services/pipes/pipes';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { FormModalPipeData, FormModalPipeValues } from '@/modals/ModalPipe/ModalPipeTypes';

const ModalPipe: StaticModalWrappedComponent<ApiPipe> = (props) => {
  const { t } = useTranslation(['common', 'pipes']);

  const defaultValues = useMemo(
    () =>
      props.data && {
        ...props.data,
        [FormModalPipeValues.USER]: '',
        [FormModalPipeValues.OFFER_ID]: '',
        [FormModalPipeValues.CONSUMABLES]: (props.data.consumables || []).map(
          ({ id, amountOfUsed, category }) => ({
            id,
            value: {
              category: category.id,
              consumable: id,
              count: String(amountOfUsed),
            },
          }),
        ),
        [FormModalPipeValues.CARD_IDS]: (props.data.cards || []).map(({ id }) => ({
          id,
          value: id,
        })),
      },
    [props.data],
  );

  const methods = useForm<FormModalPipeData>({ defaultValues });
  const userId = methods.watch(FormModalPipeValues.USER);

  const { commandId } = useUserCommand();

  const { isMaster } = useUserInfo();
  const { masters } = useCommandMasters();
  const { offers } = useUserOffers(userId);

  const [createPipe, { isLoading: isCreating }] = useCreatePipeMutation();
  const [updatePipe, { isLoading: isUpdating }] = useUpdatePipeByIdMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalPipeData) => {
      const consumables = (data[FormModalPipeValues.CONSUMABLES] || [])
        .filter((a) => a.value.count && a.value.consumable)
        .map((a) => ({
          consumableId: String(a.value.consumable),
          amount: Number(a.value.count),
        }));

      const cardIds = (data[FormModalPipeValues.CARD_IDS] || [])
        .map((a) => a.value)
        .filter(filterBoolean);

      const body: ApiPipePostBody = {
        cardIds,
        consumables,

        commandId,
        date: data[FormModalPipeValues.DATE],
        name: data[FormModalPipeValues.NAME],
        userId: data[FormModalPipeValues.USER],
        comment: data[FormModalPipeValues.COMMENT],
        offerId: data[FormModalPipeValues.OFFER_ID],
      };

      const response = props.data?.id
        ? await updatePipe({ ...body, id: props.data.id })
        : await createPipe(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [createPipe, props, updatePipe],
  );

  const isEdit = Boolean(props.data);
  const selectedCards: SelectItem[] = useSelectOptions(props.data?.cards || [], 'name', 'id');
  const modalTitle = isEdit ? t('common:buttonActions.edit') : t('pipes:addPipe');
  const buttonTitle = isEdit ? t('common:buttonActions.save') : t('common:buttonActions.add');

  useEffect(() => {
    if (!methods.formState.dirtyFields.user) return;
    methods.setValue(FormModalPipeValues.OFFER_ID, '');
  }, [methods, userId]);

  useEffect(() => {
    if (!isEdit) return;
    if (!masters.length) return;

    const user = masters.find((a) => a.value === props.data?.user.id);
    if (user) methods.setValue(FormModalPipeValues.USER, user.value);
  }, [isEdit, masters, methods, props.data?.user.id]);

  useEffect(() => {
    if (!isEdit) return;
    if (!offers.length) return;

    methods.setValue(FormModalPipeValues.OFFER_ID, props.data!.offer.id);
  }, [isEdit, methods, offers.length, props?.data]);

  return (
    <Modal {...props} title={modalTitle}>
      <Form contextMethods={methods} onSubmit={handleSubmit}>
        {!isEdit && (
          <FormElement
            component='datepicker'
            label={t('common:formLabels.dateAdded')}
            rules={FormRuler.required}
            name={FormModalPipeValues.DATE}
          />
        )}

        {!isMaster && (
          <FormElement
            label={t('common:roles.user')}
            values={masters}
            component='select'
            rules={FormRuler.required}
            name={FormModalPipeValues.USER}
          />
        )}

        <FormElement
          label={t('common:tableLabels.offer')}
          values={offers}
          component='select'
          rules={FormRuler.required}
          disableAutofill={isEdit}
          name={FormModalPipeValues.OFFER_ID}
        />

        <FormElement
          label={t('common:tableLabels.name')}
          component='input'
          rules={FormRuler.required}
          name={FormModalPipeValues.NAME}
        />

        <FormElement
          component='input'
          label={t('common:tableLabels.comment')}
          name={FormModalPipeValues.COMMENT}
        />

        <FormElement
          type='card'
          userId={userId}
          extraItems={selectedCards}
          component='picker'
          title={t('common:buttonActions.addCard')}
          disableAutofill={isEdit}
          name={FormModalPipeValues.CARD_IDS}
        />

        {!isEdit && (
          <FormElement
            userId={userId}
            type='consumable'
            component='picker'
            title={t('common:buttonActions.addConsumable')}
            disableAutofill={isEdit}
            disableSelectMasters={isEdit}
            name={FormModalPipeValues.CONSUMABLES}
          />
        )}

        <DialogActions>
          <Button isLoading={isCreating || isUpdating} type='submit'>
            {buttonTitle}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiPipe>(ModalPipe);
