import { ApiPaginationParams, ApiResponse, ApiResponseWithPagination, Void } from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiCommand } from '@/services/commands/commands.types';

export type ApiCompany = {
  id: string;
  name: string;
  apiKey: string;
  commands: Omit<ApiCommand, 'users'>[];

  users?: ApiUser[];
  tracker?: ApiTracker | null;
  selfhost?: ApiSelfhost | null;
};

export type ApiTracker = {
  apiKey: string;
  apiUrl: string;
  service: ApiCompanyTracker;
};

export type ApiSelfhost = {
  leadsUrl: string;
  leadsHeaders: string;
};

export enum ApiCompanyTracker {
  KEITARO = 'keitaro',
  BINOM = 'binom',
}

// === READ ↴

export type ApiCompaniesGetQuery = Void<ApiPaginationParams>;
export type ApiCompaniesGetResponse = ApiResponseWithPagination<ApiCompany>;

// === READ BY ID ↴

export type ApiCompanyByIdGetQuery = { id: string };
export type ApiCompanyByIdGetResponse = ApiResponse<ApiCompany>;

// === CREATE ↴

export type ApiCompanyPostBody = Pick<ApiCompany, 'name'>;
export type ApiCompanyPostResponse = ApiResponse<ApiCompany>;

// === UPDATE ↴

export type ApiCompanyByIdPatchBody = Omit<ApiCompany, 'apiKey' | 'commands'>;
export type ApiCompanyByIdPatchResponse = ApiCompanyPostResponse;
