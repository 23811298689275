import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SingleStatistic } from '@/pages/DashboardsPage/DashboardsPage';

import PageTitle from '@/components/PageTitle';
import Loader from '@/components/Loader';

import routes from '@/resources/routes';

import { usePageParams } from '@/hooks/usePageParams';
import { useDashboardsPageCurrentTab } from '@/hooks/useDashboardsPageCurrentTab';

import { useGetDashboardSettingsQuery } from '@/services/dashboard/dashboard';

export const DashboardPage = () => {
  const { id } = usePageParams();
  const { currentTab } = useDashboardsPageCurrentTab();
  const { replace, location } = useHistory();
  const { t } = useTranslation('dashboard');

  const { data: settings, isLoading: isFetching } = useGetDashboardSettingsQuery();

  const currentSetting = settings?.[currentTab].find((setting) => setting.id === id);

  if (isFetching) return <Loader isCenter isStatic />;

  const goBack = () => replace(`${routes.dashboards.path}${location.search}`);

  if (!currentSetting) {
    goBack();
    return null;
  }

  return (
    <>
      <PageTitle onGoBack={goBack}>{t('oneDashboard')}</PageTitle>

      <SingleStatistic
        hasTabs={false}
        key={currentSetting?.id || 'local'}
        settings={{ ...currentSetting, isLocale: false }}
      />
    </>
  );
};
