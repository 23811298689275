import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { defaultValuesWithCurrency } from '@/resources/constants';

import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import useCommandMasters from '@/hooks/useCommandMasters';

import {
  ApiService,
  ApiServiceByIdReplenishmentPatchBody,
} from '@/services/services/services.types';
import { useReplenishmentServiceByIdMutation } from '@/services/services/services';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalServiceReplenishmentData,
  FormModalServiceReplenishmentValues,
} from '@/modals/ModalServiceReplenishment/ModalServiceReplenishmentTypes';

const ModalServiceReplenishment: StaticModalWrappedComponent<ApiService> = (props) => {
  const { t } = useTranslation(['common', 'service']);

  const { isMaster } = useUserInfo();

  const { commandId } = useUserCommand();
  const { masters } = useCommandMasters({ serviceId: props.data?.id });
  const [replenishmentService, { isLoading }] = useReplenishmentServiceByIdMutation();

  const methods = useForm<FormModalServiceReplenishmentData>({
    defaultValues: defaultValuesWithCurrency,
  });
  const dateOfOperation = methods.watch(FormModalServiceReplenishmentValues.DATE);

  const handleSubmit = async (data: FormModalServiceReplenishmentData) => {
    if (!props.data) return;

    const body: ApiServiceByIdReplenishmentPatchBody = {
      commandId,
      id: props.data.id,
      date: data[FormModalServiceReplenishmentValues.DATE],
      userId: data[FormModalServiceReplenishmentValues.USER],
      sum: data[FormModalServiceReplenishmentValues.SUM_WITH_CURRENCY].systemSum,
      currency: data[FormModalServiceReplenishmentValues.SUM_WITH_CURRENCY].currency,
      currencySum: Number(data[FormModalServiceReplenishmentValues.SUM_WITH_CURRENCY].currencySum),
    };

    const response = await replenishmentService(body);

    if ('data' in response) {
      props.onClose();
    }
  };

  if (!props.data) {
    return null;
  }

  return (
    <Modal {...props} title={t('service:topUpAccount', { value: props.data.name })}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        {!isMaster && (
          <FormElement
            label={t('common:roles.user')}
            values={masters}
            component='select'
            rules={FormRuler.required}
            name={FormModalServiceReplenishmentValues.USER}
          />
        )}

        <FormElement
          isNumber
          component='sumWithCurrency'
          dateOfOperation={dateOfOperation}
          label={t('common:tableLabels.sum')}
          rules={FormRuler.validateSumWithCurrency}
          name={FormModalServiceReplenishmentValues.SUM_WITH_CURRENCY}
        />

        <FormElement
          label={t('common:tableLabels.date')}
          component='datepicker'
          maxDate={new Date().toISOString()}
          rules={FormRuler.required}
          name={FormModalServiceReplenishmentValues.DATE}
        />

        <Typography marginTop={4} variant='body1' color='text.secondary'>
          {t('common:commission', { value: props.data.commissionReplenishmentService })}
        </Typography>

        <DialogActions>
          <Button isLoading={isLoading} type='submit'>
            {t('common:buttonActions.create')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal(ModalServiceReplenishment);
