import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserCommand from '@/hooks/useUserCommand';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiCard } from '@/services/cards/cards.types';
import { useCloseCardByIdMutation } from '@/services/cards/cards';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';

const ModalCardClose: StaticModalWrappedComponent<ApiCard> = (props) => {
  const { t } = useTranslation(['common', 'cards']);

  const { commandId } = useUserCommand();
  const [closeCard, { isLoading }] = useCloseCardByIdMutation();

  const [handleSubmit] = useAwaitCallback(async ({ date }: { date: string }) => {
    if (!props.data) return;

    const response = await closeCard({
      date,
      commandId,
      id: props.data.id,
    });

    if ('data' in response) {
      props.onClose();
    }
  }, []);

  if (!props.data) {
    return null;
  }

  const date = props.data.lastReplenishmentDate || props.data.date;

  return (
    <Modal {...props} title={t('cards:closeCard', { value: props.data.name })}>
      <Form onSubmit={handleSubmit}>
        <FormElement
          name='date'
          label={t('common:tableLabels.date')}
          minDate={date}
          component='datepicker'
          rules={{
            ...FormRuler.required,
            ...FormRuler.validateDate(date, false, 'day'),
          }}
        />

        <DialogActions>
          <Button color='error' type='submit' isLoading={isLoading}>
            {t('common:buttonActions.close')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCard>(ModalCardClose);
