import { useEffect, useMemo } from 'react';

import useUserCommand from '@/hooks/useUserCommand';

import { useLazyGetConsumableQuery } from '@/services/consumables/consumables';

const useUserConsumablesByCategory = (
  categoryId = '',
  masterId = '',
  mode: 'all' | 'available' = 'all',
  currentUsed = 0,
) => {
  const { commandId } = useUserCommand();
  const [getConsumables, { data }] = useLazyGetConsumableQuery();

  useEffect(() => {
    if (!categoryId) return;
    getConsumables({ categoryId, commandId, masterId });
  }, [categoryId, commandId, getConsumables, masterId]);

  const consumables = useMemo(() => {
    const list = data?.list || [];

    return list.map((a) => ({
      value: String(a.id).toLowerCase(),
      label: String(a.name).toLowerCase(),
      availableCount:
        mode === 'all' ? a.amountOfAvailable + a.amountOfUsed : a.amountOfAvailable + currentUsed,
    }));
  }, [currentUsed, data?.list, mode]);

  return { consumables, originalConsumables: data?.list || [] };
};

export default useUserConsumablesByCategory;
