import { TFuncKey } from 'i18next';

import { ApiDolphinStatisticKey } from '@/services/facebook/facebook.types';

export const possibleFacebookColumns: {
  label: TFuncKey<'common', 'tableLabels'>;
  value: ApiDolphinStatisticKey;
}[] = [
  {
    label: 'impressions',
    value: ApiDolphinStatisticKey['impressions'],
  },
  {
    label: 'link_click',
    value: ApiDolphinStatisticKey['link_click'],
  },
  {
    label: 'link_click_cr',
    value: ApiDolphinStatisticKey['link_click_cr'],
  },
  {
    label: 'mobile_app_install',
    value: ApiDolphinStatisticKey['mobile_app_install'],
  },
  {
    label: 'outbound_click',
    value: ApiDolphinStatisticKey['outbound_click'],
  },
  {
    label: 'outbound_click_cr',
    value: ApiDolphinStatisticKey['outbound_click_cr'],
  },
  {
    label: 'outbound_click_cpa',
    value: ApiDolphinStatisticKey['outbound_click_cpa'],
  },
  {
    label: 'video_view',
    value: ApiDolphinStatisticKey['video_view'],
  },
  {
    label: 'video_view_cpa',
    value: ApiDolphinStatisticKey['video_view_cpa'],
  },
  {
    label: 'video_30_sec_watched_actions',
    value: ApiDolphinStatisticKey['video_30_sec_watched_actions'],
  },
  {
    label: 'fb_mobile_add_payment_info_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_add_payment_info_cpa'],
  },
  {
    label: 'fb_pixel_add_payment_info',
    value: ApiDolphinStatisticKey['fb_pixel_add_payment_info'],
  },
  {
    label: 'fb_pixel_add_payment_info_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_add_payment_info_cpa'],
  },
  {
    label: 'fb_pixel_add_payment_info_cr',
    value: ApiDolphinStatisticKey['fb_pixel_add_payment_info_cr'],
  },
  {
    label: 'video_view_cr',
    value: ApiDolphinStatisticKey['video_view_cr'],
  },
  {
    label: 'video_30_sec_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_30_sec_watched_actions_cr'],
  },
  {
    label: 'video_30_sec_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_30_sec_watched_actions_cpa'],
  },
  {
    label: 'fb_mobile_add_payment_info',
    value: ApiDolphinStatisticKey['fb_mobile_add_payment_info'],
  },
  {
    label: 'fb_mobile_add_payment_info_cr',
    value: ApiDolphinStatisticKey['fb_mobile_add_payment_info_cr'],
  },
  {
    label: 'fb_mobile_add_to_cart_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_cart_cpa'],
  },
  {
    label: 'fb_mobile_add_to_cart_cr',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_cart_cr'],
  },
  {
    label: 'fb_mobile_add_to_wishlist_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_wishlist_cpa'],
  },
  {
    label: 'fb_mobile_add_to_wishlist_cr',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_wishlist_cr'],
  },
  {
    label: 'mobile_app_install_cpa',
    value: ApiDolphinStatisticKey['mobile_app_install_cpa'],
  },
  {
    label: 'mobile_app_install_cr',
    value: ApiDolphinStatisticKey['mobile_app_install_cr'],
  },
  {
    label: 'fb_mobile_add_to_cart',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_cart'],
  },
  {
    label: 'fb_pixel_add_to_cart',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_cart'],
  },
  {
    label: 'fb_pixel_add_to_cart_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_cart_cpa'],
  },
  {
    label: 'fb_pixel_add_to_cart_cr',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_cart_cr'],
  },
  {
    label: 'fb_mobile_add_to_wishlist',
    value: ApiDolphinStatisticKey['fb_mobile_add_to_wishlist'],
  },
  {
    label: 'fb_pixel_add_to_wishlist',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_wishlist'],
  },
  {
    label: 'fb_pixel_add_to_wishlist_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_wishlist_cpa'],
  },
  {
    label: 'fb_pixel_add_to_wishlist_cr',
    value: ApiDolphinStatisticKey['fb_pixel_add_to_wishlist_cr'],
  },
  {
    label: 'fb_mobile_level_achieved',
    value: ApiDolphinStatisticKey['fb_mobile_level_achieved'],
  },
  {
    label: 'fb_mobile_level_achieved_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_level_achieved_cpa'],
  },
  {
    label: 'fb_mobile_level_achieved_cr',
    value: ApiDolphinStatisticKey['fb_mobile_level_achieved_cr'],
  },
  {
    label: 'fb_pixel_complete_registration',
    value: ApiDolphinStatisticKey['fb_pixel_complete_registration'],
  },
  {
    label: 'fb_pixel_complete_registration_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_complete_registration_cpa'],
  },
  {
    label: 'fb_pixel_complete_registration_cr',
    value: ApiDolphinStatisticKey['fb_pixel_complete_registration_cr'],
  },
  {
    label: 'fb_mobile_tutorial_completion',
    value: ApiDolphinStatisticKey['fb_mobile_tutorial_completion'],
  },
  {
    label: 'fb_mobile_tutorial_completion_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_tutorial_completion_cpa'],
  },
  {
    label: 'fb_mobile_tutorial_completion_cr',
    value: ApiDolphinStatisticKey['fb_mobile_tutorial_completion_cr'],
  },
  {
    label: 'fb_mobile_achievement_unlocked',
    value: ApiDolphinStatisticKey['fb_mobile_achievement_unlocked'],
  },
  {
    label: 'fb_mobile_activate_app',
    value: ApiDolphinStatisticKey['fb_mobile_activate_app'],
  },
  {
    label: 'fb_mobile_achievement_unlocked_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_achievement_unlocked_cpa'],
  },
  {
    label: 'fb_mobile_activate_app_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_activate_app_cpa'],
  },
  {
    label: 'fb_mobile_achievement_unlocked_cr',
    value: ApiDolphinStatisticKey['fb_mobile_achievement_unlocked_cr'],
  },
  {
    label: 'fb_mobile_activate_app_cr',
    value: ApiDolphinStatisticKey['fb_mobile_activate_app_cr'],
  },
  {
    label: 'app_use',
    value: ApiDolphinStatisticKey['app_use'],
  },
  {
    label: 'app_use_cpa',
    value: ApiDolphinStatisticKey['app_use_cpa'],
  },
  {
    label: 'app_use_cr',
    value: ApiDolphinStatisticKey['app_use_cr'],
  },
  {
    label: 'games_plays_cpa',
    value: ApiDolphinStatisticKey['games_plays_cpa'],
  },
  {
    label: 'games_plays',
    value: ApiDolphinStatisticKey['games_plays'],
  },
  {
    label: 'games_plays_cr',
    value: ApiDolphinStatisticKey['games_plays_cr'],
  },
  {
    label: 'comment',
    value: ApiDolphinStatisticKey['comment'],
  },
  {
    label: 'comment_cpa',
    value: ApiDolphinStatisticKey['comment_cpa'],
  },
  {
    label: 'comment_cr',
    value: ApiDolphinStatisticKey['comment_cr'],
  },
  {
    label: 'like',
    value: ApiDolphinStatisticKey['like'],
  },
  {
    label: 'like_cpa',
    value: ApiDolphinStatisticKey['like_cpa'],
  },
  {
    label: 'like_cr',
    value: ApiDolphinStatisticKey['like_cr'],
  },
  {
    label: 'fb_pixel_lead',
    value: ApiDolphinStatisticKey['fb_pixel_lead'],
  },
  {
    label: 'fb_pixel_lead_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_lead_cpa'],
  },
  {
    label: 'fb_pixel_lead_cr',
    value: ApiDolphinStatisticKey['fb_pixel_lead_cr'],
  },
  {
    label: 'fb_mobile_initiated_checkout',
    value: ApiDolphinStatisticKey['fb_mobile_initiated_checkout'],
  },
  {
    label: 'fb_pixel_initiate_checkout',
    value: ApiDolphinStatisticKey['fb_pixel_initiate_checkout'],
  },
  {
    label: 'fb_mobile_initiated_checkout_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_initiated_checkout_cpa'],
  },
  {
    label: 'fb_pixel_initiate_checkout_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_initiate_checkout_cpa'],
  },
  {
    label: 'fb_mobile_initiated_checkout_cr',
    value: ApiDolphinStatisticKey['fb_mobile_initiated_checkout_cr'],
  },
  {
    label: 'fb_pixel_initiate_checkout_cr',
    value: ApiDolphinStatisticKey['fb_pixel_initiate_checkout_cr'],
  },
  {
    label: 'reach',
    value: ApiDolphinStatisticKey['reach'],
  },
  {
    label: 'fb_mobile_rate',
    value: ApiDolphinStatisticKey['fb_mobile_rate'],
  },
  {
    label: 'fb_mobile_rate_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_rate_cpa'],
  },
  {
    label: 'fb_mobile_rate_cr',
    value: ApiDolphinStatisticKey['fb_mobile_rate_cr'],
  },
  {
    label: 'fb_mobile_search',
    value: ApiDolphinStatisticKey['fb_mobile_search'],
  },
  {
    label: 'fb_pixel_search',
    value: ApiDolphinStatisticKey['fb_pixel_search'],
  },
  {
    label: 'fb_mobile_search_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_search_cpa'],
  },
  {
    label: 'fb_pixel_search_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_search_cpa'],
  },
  {
    label: 'fb_mobile_search_cr',
    value: ApiDolphinStatisticKey['fb_mobile_search_cr'],
  },
  {
    label: 'fb_pixel_search_cr',
    value: ApiDolphinStatisticKey['fb_pixel_search_cr'],
  },
  {
    label: 'fb_pixel_purchase',
    value: ApiDolphinStatisticKey['fb_pixel_purchase'],
  },
  {
    label: 'fb_mobile_purchase',
    value: ApiDolphinStatisticKey['fb_mobile_purchase'],
  },
  {
    label: 'fb_mobile_purchase_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_purchase_cpa'],
  },
  {
    label: 'fb_mobile_purchase_cr',
    value: ApiDolphinStatisticKey['fb_mobile_purchase_cr'],
  },
  {
    label: 'fb_pixel_purchase_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_purchase_cpa'],
  },
  {
    label: 'fb_pixel_purchase_cr',
    value: ApiDolphinStatisticKey['fb_pixel_purchase_cr'],
  },
  {
    label: 'fb_mobile_spent_credits',
    value: ApiDolphinStatisticKey['fb_mobile_spent_credits'],
  },
  {
    label: 'credit_spent',
    value: ApiDolphinStatisticKey['credit_spent'],
  },
  {
    label: 'fb_mobile_spent_credits_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_spent_credits_cpa'],
  },
  {
    label: 'credit_spent_cpa',
    value: ApiDolphinStatisticKey['credit_spent_cpa'],
  },
  {
    label: 'fb_mobile_spent_credits_cr',
    value: ApiDolphinStatisticKey['fb_mobile_spent_credits_cr'],
  },
  {
    label: 'credit_spent_cr',
    value: ApiDolphinStatisticKey['credit_spent_cr'],
  },
  {
    label: 'apps_on_level',
    value: ApiDolphinStatisticKey['apps_on_level'],
  },
  {
    label: 'video_play_actions',
    value: ApiDolphinStatisticKey['video_play_actions'],
  },
  {
    label: 'video_p100_watched_actions',
    value: ApiDolphinStatisticKey['video_p100_watched_actions'],
  },
  {
    label: 'video_p100_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_p100_watched_actions_cpa'],
  },
  {
    label: 'video_p100_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_p100_watched_actions_cr'],
  },
  {
    label: 'video_p25_watched_actions',
    value: ApiDolphinStatisticKey['video_p25_watched_actions'],
  },
  {
    label: 'video_p25_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_p25_watched_actions_cr'],
  },
  {
    label: 'video_p50_watched_actions',
    value: ApiDolphinStatisticKey['video_p50_watched_actions'],
  },
  {
    label: 'video_p50_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_p50_watched_actions_cpa'],
  },
  {
    label: 'video_p50_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_p50_watched_actions_cr'],
  },
  {
    label: 'video_p75_watched_actions',
    value: ApiDolphinStatisticKey['video_p75_watched_actions'],
  },
  {
    label: 'video_p75_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_p75_watched_actions_cpa'],
  },
  {
    label: 'video_p75_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_p75_watched_actions_cr'],
  },
  {
    label: 'video_p95_watched_actions',
    value: ApiDolphinStatisticKey['video_p95_watched_actions'],
  },
  {
    label: 'video_p95_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_p95_watched_actions_cpa'],
  },
  {
    label: 'video_p95_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_p95_watched_actions_cr'],
  },
  {
    label: 'video_play_actions_cpa',
    value: ApiDolphinStatisticKey['video_play_actions_cpa'],
  },
  {
    label: 'purchase_roas',
    value: ApiDolphinStatisticKey['purchase_roas'],
  },
  {
    label: 'checkin',
    value: ApiDolphinStatisticKey['checkin'],
  },
  {
    label: 'checkin_cpa',
    value: ApiDolphinStatisticKey['checkin_cpa'],
  },
  {
    label: 'checkin_cr',
    value: ApiDolphinStatisticKey['checkin_cr'],
  },
  {
    label: 'link_click_cpa',
    value: ApiDolphinStatisticKey['link_click_cpa'],
  },
  {
    label: 'cpm',
    value: ApiDolphinStatisticKey['cpm'],
  },
  {
    label: 'frequency',
    value: ApiDolphinStatisticKey['frequency'],
  },
  {
    label: 'fp_on_level',
    value: ApiDolphinStatisticKey['fp_on_level'],
  },
  {
    label: 'adset_targeting',
    value: ApiDolphinStatisticKey['adset_targeting'],
  },
  {
    label: 'video_avg_time_watched_actions',
    value: ApiDolphinStatisticKey['video_avg_time_watched_actions'],
  },
  {
    label: 'video_avg_time_watched_actions_cpa',
    value: ApiDolphinStatisticKey['video_avg_time_watched_actions_cpa'],
  },
  {
    label: 'video_avg_time_watched_actions_cr',
    value: ApiDolphinStatisticKey['video_avg_time_watched_actions_cr'],
  },
  {
    label: 'post_cpa',
    value: ApiDolphinStatisticKey['post_cpa'],
  },
  {
    label: 'post_cr',
    value: ApiDolphinStatisticKey['post_cr'],
  },
  {
    label: 'fb_mobile_complete_registration_cr',
    value: ApiDolphinStatisticKey['fb_mobile_complete_registration_cr'],
  },
  {
    label: 'results',
    value: ApiDolphinStatisticKey['results'],
  },
  {
    label: 'results_cpa',
    value: ApiDolphinStatisticKey['results_cpa'],
  },
  {
    label: 'results_cr',
    value: ApiDolphinStatisticKey['results_cr'],
  },
  {
    label: 'post',
    value: ApiDolphinStatisticKey['post'],
  },
  {
    label: 'fb_mobile_complete_registration_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_complete_registration_cpa'],
  },
  {
    label: 'post_reaction',
    value: ApiDolphinStatisticKey['post_reaction'],
  },
  {
    label: 'post_reaction_cpa',
    value: ApiDolphinStatisticKey['post_reaction_cpa'],
  },
  {
    label: 'post_reaction_cr',
    value: ApiDolphinStatisticKey['post_reaction_cr'],
  },
  {
    label: 'rsvp',
    value: ApiDolphinStatisticKey['rsvp'],
  },
  {
    label: 'rsvp_cpa',
    value: ApiDolphinStatisticKey['rsvp_cpa'],
  },
  {
    label: 'rsvp_cr',
    value: ApiDolphinStatisticKey['rsvp_cr'],
  },
  {
    label: 'fb_mobile_complete_registration',
    value: ApiDolphinStatisticKey['fb_mobile_complete_registration'],
  },
  {
    label: 'landing_page_view_cpa',
    value: ApiDolphinStatisticKey['landing_page_view_cpa'],
  },
  {
    label: 'landing_page_view_cr',
    value: ApiDolphinStatisticKey['landing_page_view_cr'],
  },
  {
    label: 'photo_view',
    value: ApiDolphinStatisticKey['photo_view'],
  },
  {
    label: 'photo_view_cpa',
    value: ApiDolphinStatisticKey['photo_view_cpa'],
  },
  {
    label: 'photo_view_cr',
    value: ApiDolphinStatisticKey['photo_view_cr'],
  },
  {
    label: 'other',
    value: ApiDolphinStatisticKey['other'],
  },
  {
    label: 'other_cpa',
    value: ApiDolphinStatisticKey['other_cpa'],
  },
  {
    label: 'other_cr',
    value: ApiDolphinStatisticKey['other_cr'],
  },
  {
    label: 'landing_page_view',
    value: ApiDolphinStatisticKey['landing_page_view'],
  },
  {
    label: 'fb_pixel_view_content_cr',
    value: ApiDolphinStatisticKey['fb_pixel_view_content_cr'],
  },
  {
    label: 'fb_mobile_content_view_cr',
    value: ApiDolphinStatisticKey['fb_mobile_content_view_cr'],
  },
  {
    label: 'fb_pixel_view_content_cpa',
    value: ApiDolphinStatisticKey['fb_pixel_view_content_cpa'],
  },
  {
    label: 'fb_mobile_content_view_cpa',
    value: ApiDolphinStatisticKey['fb_mobile_content_view_cpa'],
  },
  {
    label: 'fb_pixel_view_content',
    value: ApiDolphinStatisticKey['fb_pixel_view_content'],
  },
  {
    label: 'fb_mobile_content_view',
    value: ApiDolphinStatisticKey['fb_mobile_content_view'],
  },
  {
    label: 'video_play_actions_cr',
    value: ApiDolphinStatisticKey['video_play_actions_cr'],
  },
];
