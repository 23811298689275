import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';

import { TypographyProps } from '@/components/Typography/TypographyTypes';
import { StyledTypography } from '@/components/Typography/TypographyStyled';

const Typography = ({
  href,
  exact,
  children,
  isNavLink,
  isTargetBlank,
  color = 'text.primary',
  ...restProps
}: TypographyProps) => {
  const linkProps = href
    ? {
        href,
        exact: exact,
        underline: 'none',
        component: isNavLink ? NavLink : Link,
        target: isTargetBlank ? '_blank' : undefined,
      }
    : {};

  return (
    <StyledTypography {...restProps} {...linkProps} color={color}>
      {children}
    </StyledTypography>
  );
};

export default Typography;
