import { useMemo } from 'react';

import { useGetAllDistinctLeadStatusesQuery } from '@/services/leads/leads';

const useLeadStatuses = () => {
  const { data } = useGetAllDistinctLeadStatusesQuery();
  const leadStatuses = useMemo(
    () =>
      (data?.list || []).map((a) => ({
        label: a,
        value: a,
      })),
    [data],
  );

  return { leadStatuses };
};

export default useLeadStatuses;
