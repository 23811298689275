import { useMemo } from 'react';

import { SelectItem } from '@/types/form';
import { useGetCurrenciesQuery } from '@/services/exchanges/exchanges';

const useCurrencies = () => {
  const { data } = useGetCurrenciesQuery();

  const currencies = useMemo<SelectItem[]>(
    () =>
      (data?.list || []).map((a) => ({
        label: `${a.title} ${a.symbol}`,
        value: a.title,
      })),
    [data?.list],
  );

  return { currencies };
};

export default useCurrencies;
