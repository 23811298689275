import { styled } from '@mui/system';

export const StyledWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  & > div {
    margin-top: 16px;
  }
`;
