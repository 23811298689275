import useQueryParam from '@/hooks/useQueryParam';

// TODO: доработать для multiple (объединить с useStatusesFilter)

const useStatusFilter = <T extends string>(defaultValue?: T) => {
  const queryName = 'status';
  const [status, setStatus] = useQueryParam(queryName, defaultValue || '');

  return {
    setStatus,
    status: status as T,
  };
};

export default useStatusFilter;
