import { ApiArchiveParams, ApiResponse } from '@/types/api';
import { invalidatesTags, invalidatesListItem, providesList } from '@/services/utils';
import {
  ApiChangePipeStatusResponse,
  ApiPipesGetResponse,
  ApiPipesGetQuery,
  ApiPipePostResponse,
  ApiPipePostBody,
  ApiPipeByIdPatchResponse,
  ApiPipeByIdPatchBody,
  ApiChangePipeStatusPatchBody,
  ApiPipeConsumablePostResponse,
  ApiPipeConsumablePostBody,
  ApiPipeConsumableByIdPatchAmountResponse,
  ApiPipeConsumableByIdPatchAmountBody,
  ApiPipeConsumableByIdPatchArchiveBody,
  ApiPipeConsumableByIdPatchArchiveResponse,
} from '@/services/pipes/pipes.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/pipes';
const serviceTag = servicesTags.pipes;

const pipesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getPipes: build.query<ApiPipesGetResponse, ApiPipesGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    createPipe: build.mutation<ApiPipePostResponse, ApiPipePostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.offers, servicesTags.cards]),
    }),
    updatePipeById: build.mutation<ApiPipeByIdPatchResponse, ApiPipeByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag, [servicesTags.offers, servicesTags.cards]),
    }),
    changePipeStatusById: build.mutation<ApiChangePipeStatusResponse, ApiChangePipeStatusPatchBody>(
      {
        query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/status`, method: 'PATCH', body }),
        invalidatesTags: invalidatesTags([serviceTag, servicesTags.cards]),
      },
    ),

    createPipeConsumable: build.mutation<ApiPipeConsumablePostResponse, ApiPipeConsumablePostBody>({
      query: ({ pipeId, ...body }) => ({
        body,
        method: 'POST',
        url: `${baseUrl}/${pipeId}/consumable`,
      }),
      invalidatesTags: invalidatesTags([
        serviceTag,
        servicesTags.offers,
        servicesTags.operations,
        servicesTags.operationsStatistic,
      ]),
    }),
    updatePipeConsumable: build.mutation<
      ApiPipeConsumableByIdPatchAmountResponse,
      ApiPipeConsumableByIdPatchAmountBody
    >({
      query: ({ pipeId, id, ...body }) => ({
        body,
        method: 'PATCH',
        url: `${baseUrl}/${pipeId}/consumable/${id}/amount`,
      }),
      invalidatesTags: invalidatesTags([
        serviceTag,
        servicesTags.offers,
        servicesTags.operations,
        servicesTags.operationsStatistic,
      ]),
    }),
    deletePipeConsumable: build.mutation<
      ApiPipeConsumableByIdPatchArchiveResponse,
      ApiPipeConsumableByIdPatchArchiveBody
    >({
      query: ({ pipeId, id, ...params }) => ({
        method: 'PATCH',
        params: params || undefined,
        url: `${baseUrl}/${pipeId}/consumable/${id}/archive`,
      }),
      invalidatesTags: invalidatesTags([
        serviceTag,
        servicesTags.offers,
        servicesTags.operations,
        servicesTags.operationsStatistic,
      ]),
    }),

    changePipesArchiveStatus: build.mutation<ApiResponse<null>, ApiArchiveParams>({
      query: ({ id, ...params }) => ({
        url: `${baseUrl}/${id}/change-archive-status`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.pipes]),
    }),
  }),
});

export const {
  useGetPipesQuery,
  useCreatePipeMutation,
  useUpdatePipeByIdMutation,
  useChangePipeStatusByIdMutation,

  useCreatePipeConsumableMutation,
  useUpdatePipeConsumableMutation,
  useDeletePipeConsumableMutation,
  useChangePipesArchiveStatusMutation,
} = pipesApi;

export default pipesApi;
