import { STORE_INTERFACE_LANGUAGE } from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';

import { Languages } from '@/types';

const getLanguage = (fallbackLng?: Languages): Languages | undefined => {
  const language = LocalStorage.get(STORE_INTERFACE_LANGUAGE) as Languages;

  if (Object.values(Languages).includes(language)) {
    return language;
  }

  return fallbackLng;
};

export default getLanguage;
