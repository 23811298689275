import { ApiPaginationParams, ApiResponse, ApiResponseWithPagination, Void } from '@/types/api';
import { ApiCommand } from '@/services/commands/commands.types';

export enum ConsumableCategoryStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type ApiConsumableCategory = {
  id: string;
  name: string;
  status: ConsumableCategoryStatus;

  command: ApiCommand;
};

// === READ ↴

export type ApiConsumableCategoriesGetQuery = Void<ApiPaginationParams & { commandId?: string }>;
export type ApiConsumableCategoriesGetResponse = ApiResponseWithPagination<ApiConsumableCategory>;

// === READ BY ID ↴

export type ApiConsumableCategoryByIdGetQuery = { id: string };
export type ApiConsumableCategoryByIdGetResponse = ApiResponse<ApiConsumableCategory>;

// === CREATE ↴

export type ApiConsumableCategoryPostBody = Pick<ApiConsumableCategory, 'name'> & {
  commandId?: string;
};
export type ApiConsumableCategoryPostResponse = ApiResponse<ApiConsumableCategory>;

// === UPDATE ↴

export type ApiConsumableCategoryByIdPatchBody = ApiConsumableCategoryPostBody &
  Pick<ApiConsumableCategory, 'id'>;
export type ApiConsumableCategoryByIdPatchResponse = ApiConsumableCategoryPostResponse;
