/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconCard = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 15.25C10 13.5931 11.3431 12.25 13 12.25H25C26.6569 12.25 28 13.5931 28 15.25V22.75C28 24.4069 26.6569 25.75 25 25.75H13C11.3431 25.75 10 24.4069 10 22.75V15.25ZM13 13.75C12.1716 13.75 11.5 14.4216 11.5 15.25V22.75C11.5 23.5784 12.1716 24.25 13 24.25H25C25.8284 24.25 26.5 23.5784 26.5 22.75V15.25C26.5 14.4216 25.8284 13.75 25 13.75H13Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 16C13 15.5858 13.3358 15.25 13.75 15.25H18.25C18.6642 15.25 19 15.5858 19 16C19 16.4142 18.6642 16.75 18.25 16.75H13.75C13.3358 16.75 13 16.4142 13 16Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 22C22 21.5858 22.3358 21.25 22.75 21.25H24.25C24.6642 21.25 25 21.5858 25 22C25 22.4142 24.6642 22.75 24.25 22.75H22.75C22.3358 22.75 22 22.4142 22 22Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconCard;
