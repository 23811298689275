import { providesList, invalidatesListItem } from '@/services/utils';
import {
  ApiUsersNonCommandsGetResponse,
  ApiUsersNonCommandsGetQuery,
  ApiUserNonCommandByIdPatchResponse,
  ApiUserNonCommandByIdPatchBody,
} from '@/services/nonCommandsUsers/nonCommandsUsers.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/users';
const serviceTag = servicesTags.nonCommandsUsers;

const nonCommandsUsersApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getNonCommandUsers: build.query<ApiUsersNonCommandsGetResponse, ApiUsersNonCommandsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}/non-command`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    updateNonCommandUserById: build.mutation<
      ApiUserNonCommandByIdPatchResponse,
      ApiUserNonCommandByIdPatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/non-command/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const { useGetNonCommandUsersQuery, useUpdateNonCommandUserByIdMutation } =
  nonCommandsUsersApi;

export default nonCommandsUsersApi;
