import { css, Table, TableCell, TableRow } from '@mui/material';
import styled from '@emotion/styled';

import transientProps from '@/utils/transientProps';

export const StyledTable = styled(Table)`
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
`;

export const StyledTableCell = styled(TableCell, transientProps)<{ $isLastCell?: boolean }>`
  padding: 6px 16px;
  font-weight: 500;

  ${({ $isLastCell }) =>
    $isLastCell &&
    css`
      width: 100%;
    `}
`;

export const StyledTableRow = styled(TableRow)`
  background-color: white;
`;
