import { styled } from '@mui/material';

export const StyledWrapper = styled('div')`
  width: 100%;
  display: flex;

  & > div + div {
    margin-left: 8px;
  }
`;
