import { EntityId } from '@reduxjs/toolkit';

import {
  ApiEntityWithCurrency,
  ApiFilterParams,
  ApiHighLevelUserParams,
  ApiOperationWithCurrency,
  ApiPaginationParams,
  ApiResponse,
  ApiResponseWithPagination,
  Void,
} from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiService } from '@/services/services/services.types';
import { ApiPipe } from '@/services/pipes/pipes.types';
import { ApiOffer } from '@/services/offers/offers.types';
import { ApiConsumable } from '@/services/consumables/consumables.types';
import { ApiCommand } from '@/services/commands/commands.types';
import { ApiCard } from '@/services/cards/cards.types';
import { ApiFile } from '@/redux/files/files.types';

export enum ApiOperationCorrectType {
  buy = 'buy',
  refund = 'refund',
}

export enum ApiOperationCategory {
  ALL = '',

  OTHER = 'other',
  TRAFFIC = 'traffic',
  CONSUMABLE = 'consumable',
  SERVICE_REPLENISHMENT = 'SERVICE_REPLENISHMENT',

  CARD_BUYING = 'buyingcard',
  CARD_CLOSE = 'CLOSE_CARD',
  CARD_REPLENISHMENT = 'CARD_REPLENISHMENT',

  PIPE_CONSUMABLE = 'PIPE_CONSUMABLE',

  OFFER_INCOME = 'OFFER_INCOME',
  REFUND = 'refund',
  INVESTMENT = 'investment',
}

export enum ApiTransactionName {
  SERVICE_REPLENISHMENT = 'SERVICE_REPLENISHMENT',
  SERVICE_REPLENISHMENT_COMMISSION = 'SERVICE_REPLENISHMENT_COMMISSION',
  CORRECT_SERVICE_REPLENISHMENT = 'CORRECT_SERVICE_REPLENISHMENT',
  CORRECT_SERVICE_REPLENISHMENT_COMMISSION = 'CORRECT_SERVICE_REPLENISHMENT_COMMISSION',
  CARD_BUYING = 'CARD_BUYING',
  CORRECT_CARD_BUYING = 'CORRECT_CARD_BUYING',
  OFFER_INCOME = 'OFFER_INCOME',
  CARD_REPLENISHMENT = 'CARD_REPLENISHMENT',
  CARD_REPLENISHMENT_COMMISSION = 'CARD_REPLENISHMENT_COMMISSION',
  CARD_REPLENISHMENT_CONSUMPTION = 'CARD_REPLENISHMENT_CONSUMPTION',
  CORRECT_CARD_REPLENISHMENT = 'CORRECT_CARD_REPLENISHMENT',
  CORRECT_CARD_REPLENISHMENT_COMMISSION = 'CORRECT_CARD_REPLENISHMENT_COMMISSION',
  CORRECT_CARD_REPLENISHMENT_CONSUMPTION = 'CORRECT_CARD_REPLENISHMENT_CONSUMPTION',
  CARD_CLOSE_COMMISSION = 'CARD_CLOSE_COMMISSION',
  CARD_CLOSE_CONSUMPTION = 'CARD_CLOSE_CONSUMPTION',
  OTHER_MASTER = 'OTHER_MASTER',
  OTHER_COMMAND = 'OTHER_COMMAND',
  CORRECT_OTHER = 'CORRECT_OTHER',
  CONSUMABLE_BUY_MASTER = 'CONSUMABLE_BUY_MASTER',
  CONSUMABLE_BUY_COMMAND = 'CONSUMABLE_BUY_COMMAND',
  CONSUMABLE_RETURN_MASTER = 'CONSUMABLE_RETURN_MASTER',
  CONSUMABLE_RETURN_COMMAND = 'CONSUMABLE_RETURN_COMMAND',
  CONSUMABLE_DISTRIBUTE = 'CONSUMABLE_DISTRIBUTE',
  CORRECT_CONSUMABLE_MASTER = 'CORRECT_CONSUMABLE_MASTER',
  CORRECT_CONSUMABLE_COMMAND = 'CORRECT_CONSUMABLE_COMMAND',
  TRAFFIC = 'TRAFFIC',
  CORRECT_TRAFFIC = 'CORRECT_TRAFFIC',
  PIPE_CONSUMABLE = 'PIPE_CONSUMABLE',
  ADDITION_PIPE_CONSUMABLE = 'ADDITION_PIPE_CONSUMABLE',
  SUBSTRACTION_PIPE_CONSUMABLE = 'SUBSTRACTION_PIPE_CONSUMABLE',
  DELETE_PIPE_CONSUMABLE = 'DELETE_PIPE_CONSUMABLE',
  INVESTMENT = 'INVESTMENT',
  REFUND_MASTER = 'REFUND_MASTER',
  REFUND_COMMAND = 'REFUND_COMMAND',
  CORRECT_CONSUMABLE_BUY = 'CORRECT_CONSUMABLE_BUY',
  CORRECT_OPERATION = 'CORRECT_OPERATION',
}

interface ApiTransaction extends ApiEntityWithCurrency {
  id: string;
  name: ApiTransactionName;

  sum: number;
  date: string;
  reason?: string;

  user: ApiUser;
  command: ApiCommand;
  isCommission: boolean;
  isInformation: boolean;
  isDistribution: boolean;
  transactionType: ApiOperationCorrectType;
}

interface ApiTransactionWithTrafficDetails extends ApiTransaction {
  trafficDescription: {
    id: string;
    card?: ApiCard;
    pipe?: ApiPipe;
  };
}

interface ApiTransactionWithPipeConsumableDetails extends ApiTransaction {
  pipeConsumableDescription: {
    id: string;
    pipe: ApiPipe;
    amount: string;
    pipeConsumable: {
      userConsumable: {
        consumable: ApiConsumable;
      };
    };
  };
}

interface ApiTransactionWithOfferDetails extends ApiTransaction {
  offerDescription: {
    id: string;
    offer?: ApiOffer;
  };
}

interface ApiTransactionWithCardDetails extends ApiTransaction {
  cardDescription: {
    id: string;
    card?: ApiCard;
  };
}

interface ApiTransactionWithServiceDetails extends ApiTransaction {
  serviceDescription: {
    id: string;
    service?: ApiService;
  };
}

interface ApiTransactionWithServiceCardDetails extends ApiTransaction {
  serviceCardDescription: {
    id: string;
    service: ApiService;
    card: ApiCard;
  };
}

interface ApiOperationBase extends ApiEntityWithCurrency {
  id: string;
  externalId?: string;
  sum: number;
  date: string;
  name: string;
  relatedEntityName?: string;
  comment: string;
  receipt: ApiFile;
  command: ApiCommand;
  category: ApiOperationCategory;
}

interface ApiOperationWithCommonTransactions extends ApiOperationBase {
  category: Exclude<
    ApiOperationCategory,
    | ApiOperationCategory.CARD_BUYING
    | ApiOperationCategory.SERVICE_REPLENISHMENT
    | ApiOperationCategory.TRAFFIC
    | ApiOperationCategory.PIPE_CONSUMABLE
    | ApiOperationCategory.CARD_CLOSE
    | ApiOperationCategory.CARD_REPLENISHMENT
    | ApiOperationCategory.OFFER_INCOME
  >;
  transactions: ApiTransaction[];
}

interface ApiOperationWithOfferTransactions extends ApiOperationBase {
  category: ApiOperationCategory.OFFER_INCOME;
  transactions: ApiTransactionWithOfferDetails[];
}

interface ApiOperationWithCardTransactions extends ApiOperationBase {
  category: ApiOperationCategory.CARD_REPLENISHMENT;
  transactions: ApiTransactionWithCardDetails[];
}

interface ApiOperationWithPipeTrafficTransactions extends ApiOperationBase {
  category: ApiOperationCategory.TRAFFIC;
  transactions: ApiTransactionWithTrafficDetails[];
}

interface ApiOperationWithPipeConsumableTransactions extends ApiOperationBase {
  category: ApiOperationCategory.PIPE_CONSUMABLE;
  transactions: ApiTransactionWithPipeConsumableDetails[];
}

interface ApiOperationWithServiceTransactions extends ApiOperationBase {
  category: ApiOperationCategory.SERVICE_REPLENISHMENT | ApiOperationCategory.CARD_CLOSE;
  transactions: ApiTransactionWithServiceDetails[];
}

interface ApiOperationWithServiceCardTransactions extends ApiOperationBase {
  category: ApiOperationCategory.CARD_BUYING;
  transactions: ApiTransactionWithServiceCardDetails[];
}

export type ApiOperation =
  | ApiOperationWithCardTransactions
  | ApiOperationWithOfferTransactions
  | ApiOperationWithCommonTransactions
  | ApiOperationWithServiceTransactions
  | ApiOperationWithServiceCardTransactions
  | ApiOperationWithPipeTrafficTransactions
  | ApiOperationWithPipeConsumableTransactions;

// === READ ↴

export type ApiOperationsGetQuery = Void<
  ApiPaginationParams &
    ApiHighLevelUserParams &
    ApiFilterParams & {
      category?: ApiOperationCategory | '';
    }
>;
export type ApiOperationsGetResponse = ApiResponseWithPagination<ApiOperation>;

// === READ STATISTIC ↴

export type ApiOperationsGetStatisticQuery = Void<ApiHighLevelUserParams & ApiFilterParams>;
export type ApiOperationsGetStatisticResponse = ApiResponse<
  Record<ApiOperationCategory | 'all', number>
>;

// === CREATE ↴

export enum OperationMemberType {
  COMMAND = 'command',
  USER = 'user',
}

export interface ApiOperationPostBody extends ApiHighLevelUserParams, ApiOperationWithCurrency {
  name: string;
  sum: number;
  date: string;
  comment: string;
  receiptId: string;

  members?: {
    id: string;
    percent: number;
  }[];
}
export type ApiOperationPostResponse = ApiResponse<ApiOperation>;

// === CORRECT ↴

export type ApiOperationCorrectPatchBody = ApiHighLevelUserParams &
  ApiOperationWithCurrency & {
    id: string;
    sum: number;
    date: string;
    reason: string;
    type: ApiOperationCorrectType;
  };

export type ApiOperationCorrectPatchResponse = ApiOperationPostResponse;

export type ApiDeleteOperationParams = { id: EntityId; commandId: string };
