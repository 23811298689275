/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconTwoProfiles = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.375 13.375C15.4122 13.375 14.5938 14.1856 14.5938 15.233C14.5938 16.2803 15.4122 17.0909 16.375 17.0909C17.3378 17.0909 18.1562 16.2803 18.1562 15.233C18.1562 14.1856 17.3378 13.375 16.375 13.375ZM13.0938 15.233C13.0938 13.3997 14.5419 11.875 16.375 11.875C18.2081 11.875 19.6562 13.3997 19.6562 15.233C19.6562 17.0662 18.2081 18.5909 16.375 18.5909C14.5419 18.5909 13.0938 17.0662 13.0938 15.233ZM20.5 12.625C20.5 12.2108 20.8358 11.875 21.25 11.875C23.1306 11.875 24.7 13.3541 24.7 15.233C24.7 17.1118 23.1306 18.5909 21.25 18.5909C20.8358 18.5909 20.5 18.2551 20.5 17.8409C20.5 17.4267 20.8358 17.0909 21.25 17.0909C22.3517 17.0909 23.2 16.2348 23.2 15.233C23.2 14.2311 22.3517 13.375 21.25 13.375C20.8358 13.375 20.5 13.0392 20.5 12.625ZM10 23.546C10 21.3104 11.7045 19.4091 13.9131 19.4091H18.8369C21.0455 19.4091 22.75 21.3104 22.75 23.546C22.75 24.9134 21.7123 26.125 20.3101 26.125H12.4399C11.0377 26.125 10 24.9134 10 23.546ZM13.9131 20.9091C12.6279 20.9091 11.5 22.0406 11.5 23.546C11.5 24.1989 11.9755 24.625 12.4399 24.625H20.3101C20.7745 24.625 21.25 24.1989 21.25 23.546C21.25 22.0406 20.1221 20.9091 18.8369 20.9091H13.9131ZM23.126 20.1591C23.126 19.7449 23.4618 19.4091 23.876 19.4091C26.1563 19.4091 28 21.264 28 23.546C28 24.9601 26.8674 26.125 25.4474 26.125H24.625C24.2108 26.125 23.875 25.7892 23.875 25.375C23.875 24.9608 24.2108 24.625 24.625 24.625H25.4474C26.0185 24.625 26.5 24.1522 26.5 23.546C26.5 22.087 25.3225 20.9091 23.876 20.9091C23.4618 20.9091 23.126 20.5733 23.126 20.1591Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconTwoProfiles;
