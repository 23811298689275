import { useTranslation } from 'react-i18next';

import useUserCommand from '@/hooks/useUserCommand';
import useAuthorization from '@/hooks/useAuthorization';

import { useGetPartnersQuery } from '@/services/offers/offers';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

const useOfferPartnersFilter = () => {
  const { t } = useTranslation();
  const { commandId } = useUserCommand();
  const { userInfo } = useAuthorization();

  const { partner, Component } = usePageFilter('partner', '');
  const { data } = useGetPartnersQuery({ commandId, companyId: userInfo?.company.id });

  const options = (data?.partners || []).map((el) => ({
    label: el,
    value: el,
  }));

  return {
    partner: partner || undefined,
    OfferPartnersFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.partner')} options={options} />
    ),
  };
};

export default useOfferPartnersFilter;
