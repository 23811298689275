/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconSearch = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} customWidth={18} customHeight={18} viewBox='0 0 20 20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.18538 2.50016C5.4931 2.50016 2.49992 5.49335 2.49992 9.18563C2.49992 12.8779 5.4931 15.8711 9.18538 15.8711C11.0836 15.8711 12.7959 15.0811 14.0139 13.8096C15.1649 12.608 15.8708 10.9802 15.8708 9.18563C15.8708 5.49335 12.8777 2.50016 9.18538 2.50016ZM0.833252 9.18563C0.833252 4.57287 4.57263 0.833496 9.18538 0.833496C13.7981 0.833496 17.5375 4.57287 17.5375 9.18563C17.5375 11.141 16.8647 12.9406 15.7391 14.3636L18.9399 17.7622C19.2554 18.0972 19.2396 18.6246 18.9046 18.9401C18.5695 19.2557 18.0421 19.2399 17.7266 18.9048L14.5791 15.5628C13.1244 16.7942 11.2412 17.5378 9.18538 17.5378C4.57263 17.5378 0.833252 13.7984 0.833252 9.18563Z'
        fill='#989FA6'
      />
    </Icon>
  );
};

export default IconSearch;
