import { TableWithSettings } from '@/types/table';
import { RootState, UserState } from '@/types';

export const selectSidebarState = (state: RootState) => (state.user as UserState).isOpenSidebar;

export const selectUserLanguage = (state: RootState) => (state.user as UserState).language;

export const selectCommand = (state: RootState) => (state.user as UserState).command;

export const selectTableSettings = (key: TableWithSettings) => (state: RootState) =>
  (state.user as UserState).tableSettings[key];
