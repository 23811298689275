import { useCallback } from 'react';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import useLocalizeOperationCategory from '@/hooks/useLocalizeOperationCategory';
import useLocalizeOfferCategory from '@/hooks/useLocalizeOfferCategory';

import { ApiOperationCategory } from '@/services/operations/operations.types';
import { ApiOfferDashboardCategories } from '@/services/dashboard/dashboard.types';

const useLocalizeStatisticCategory = () => {
  const { localizeOperationCategory } = useLocalizeOperationCategory();
  const { localizeOfferCategory } = useLocalizeOfferCategory();

  const localizeStatisticCategory = useCallback(
    (value: PossibleStatisticCategories) => {
      if (Object.values(ApiOperationCategory).includes(value as ApiOperationCategory)) {
        return localizeOperationCategory(value as ApiOperationCategory);
      }

      return localizeOfferCategory(value as ApiOfferDashboardCategories);
    },
    [localizeOfferCategory, localizeOperationCategory],
  );

  return { localizeStatisticCategory };
};

export default useLocalizeStatisticCategory;
