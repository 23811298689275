import { ApiOkResponse } from '@/types/api';
import { invalidatesTags, providesList } from '@/services/utils';
import {
  ApiDolphinAdvertisingCampaignsGetResponse,
  ApiDolphinAdvertisingCampaignsGetQuery,
  ApiDolphinAdvertisingCampaignsPostBody,
  ApiDolphinAdvertisingCampaignsPostResponse,
  ApiDolphinStatisticsGetResponse,
  ApiDolphinStatisticsGetQuery,
  ApiUnlinkAdvertisingCampaignFromPipeBody,
} from '@/services/facebook/facebook.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/dolphin';
const dolphinAdvCampaignsUrl = `${baseUrl}/advertising-campaigns`;
const serviceTag = servicesTags.dolphin;

const dolphinApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getDolphinStatistics: build.query<
      ApiDolphinStatisticsGetResponse,
      ApiDolphinStatisticsGetQuery
    >({
      query: (params) => ({ url: `${baseUrl}/statistics`, params: params || undefined }),
      providesTags: invalidatesTags(serviceTag),
    }),
    getDolphinAdvertisingCampaigns: build.query<
      ApiDolphinAdvertisingCampaignsGetResponse,
      ApiDolphinAdvertisingCampaignsGetQuery
    >({
      query: (params) => ({ url: dolphinAdvCampaignsUrl, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    linkAdvertisingCampaignToPipe: build.mutation<
      ApiDolphinAdvertisingCampaignsPostResponse,
      ApiDolphinAdvertisingCampaignsPostBody
    >({
      query: (body) => ({ url: dolphinAdvCampaignsUrl, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    unlinkAdvertisingCampaignFromPipe: build.mutation<
      ApiOkResponse,
      ApiUnlinkAdvertisingCampaignFromPipeBody
    >({
      query: ({ id, ...body }) => ({
        url: `${dolphinAdvCampaignsUrl}/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
  }),
});

export const {
  useGetDolphinStatisticsQuery,
  useGetDolphinAdvertisingCampaignsQuery,
  useLinkAdvertisingCampaignToPipeMutation,
  useUnlinkAdvertisingCampaignFromPipeMutation,
} = dolphinApi;

export default dolphinApi;
