import queryString from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Headers } from '@/resources/constants';

import { RootState } from '@/types';
import { servicesTags } from '@/services';

export const tagTypes = Object.values(servicesTags);

const commonApi = createApi({
  tagTypes,
  reducerPath: 'API',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'bracket' }),
    prepareHeaders(headers, { getState }) {
      const lang = (getState() as RootState).user.language;
      headers.set(Headers.lang, lang);
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default commonApi;
