import { useDispatch, useSelector } from 'react-redux';

import {
  StyledSidebarHeader,
  StyledIconArrow,
  LogoWrapper,
} from '@/components/Sidebar/components/SidebarHeader/SidebarHeaderStyled';

import IconLogo from '@/icons/IconLogo';

import { selectSidebarState } from '@/redux/user/userGetters';
import { setSidebarStateAction } from '@/redux/user/userActions';

const SidebarHeader = () => {
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector(selectSidebarState);

  const handleChangeSidbarState = () => {
    dispatch(setSidebarStateAction(!isSidebarOpen));
  };

  return (
    <StyledSidebarHeader $isSidebarOpen={isSidebarOpen}>
      {isSidebarOpen && (
        <LogoWrapper>
          <IconLogo />
        </LogoWrapper>
      )}
      <StyledIconArrow $isOpen={isSidebarOpen} onClick={handleChangeSidbarState} />
    </StyledSidebarHeader>
  );
};

export default SidebarHeader;
