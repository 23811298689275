import { PossibleTablesCache } from '@/resources/routes';
import { limitPageOptions } from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';

import { CachePagination } from '@/types/api';

const getCachePaginationByKey = (cacheKey: PossibleTablesCache): CachePagination => {
  const cache = LocalStorage.get<CachePagination>(cacheKey);

  return {
    page: String(cache?.page || 1),
    limit: String(cache?.limit || limitPageOptions[0].value),
  };
};

export default getCachePaginationByKey;
