import { CommonSelectItem } from '@/types/form';
import { ApiDashboardSettings } from '@/services/dashboard/dashboard.types';

export enum PossibleTabs {
  parameters = 'parameters',
  masters = 'masters',
  commands = 'commands',
}

export const dashboardTabs = [
  { label: PossibleTabs.parameters, value: PossibleTabs.parameters },
  { label: PossibleTabs.masters, value: PossibleTabs.masters },
  { label: PossibleTabs.commands, value: PossibleTabs.commands },
] satisfies CommonSelectItem<PossibleTabs>[];

export type DashboardSettings = ApiDashboardSettings & { isLocale: boolean };
