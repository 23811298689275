/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconTextFile = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.25 13C12.25 11.7574 13.2574 10.75 14.5 10.75H20.5C20.6989 10.75 20.8897 10.829 21.0303 10.9697L25.5303 15.4697C25.671 15.6103 25.75 15.8011 25.75 16V25C25.75 26.2426 24.7426 27.25 23.5 27.25H14.5C13.2574 27.25 12.25 26.2426 12.25 25V13ZM14.5 12.25C14.0858 12.25 13.75 12.5858 13.75 13V25C13.75 25.4142 14.0858 25.75 14.5 25.75H23.5C23.9142 25.75 24.25 25.4142 24.25 25V16.3107L20.1893 12.25H14.5ZM15.0735 16.8571C15.0735 16.4429 15.4093 16.1071 15.8235 16.1071H18.3382C18.7524 16.1071 19.0882 16.4429 19.0882 16.8571C19.0882 17.2714 18.7524 17.6071 18.3382 17.6071H15.8235C15.4093 17.6071 15.0735 17.2714 15.0735 16.8571ZM15.0735 19.7143C15.0735 19.3001 15.4093 18.9643 15.8235 18.9643H22.1765C22.5907 18.9643 22.9265 19.3001 22.9265 19.7143C22.9265 20.1285 22.5907 20.4643 22.1765 20.4643H15.8235C15.4093 20.4643 15.0735 20.1285 15.0735 19.7143ZM15.0735 22.5714C15.0735 22.1572 15.4093 21.8214 15.8235 21.8214H22.1765C22.5907 21.8214 22.9265 22.1572 22.9265 22.5714C22.9265 22.9856 22.5907 23.3214 22.1765 23.3214H15.8235C15.4093 23.3214 15.0735 22.9856 15.0735 22.5714Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconTextFile;
