import styled from '@emotion/styled';

export const StyledPageFilters = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.default};

  display: flex;
  align-items: center;

  margin-bottom: 24px;

  && > * + * {
    margin-left: 20px;
  }

  && > * {
    flex: 1;
    display: flex;
  }

  .MuiFormControl-root {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledFiltersWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
`;

export const StyledFiltersLevel = styled('div')`
  width: 100%;

  display: flex;
  justify-content: space-between;

  && > * {
    height: auto;

    flex: 1;
    display: flex;
    margin-left: 16px;

    max-width: 1000px !important;

    :first-child {
      margin-left: 0px;
    }
  }
`;
