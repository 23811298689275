import { useState, useCallback } from 'react';

import { TableConfig, TableRowConfig } from '@/components/Table/TableTypes';

const useTableSelect = (config: TableConfig) => {
  const [selectedList, setSelectedList] = useState<Array<TableRowConfig['id']>>([]);

  const handleSelectRow = useCallback(
    (id: TableRowConfig['id']) => () => {
      setSelectedList((prev) => {
        const arr = [...prev];
        const foundIndex = arr.findIndex((a) => a === id);

        if (foundIndex === -1) {
          arr.push(id);
        } else {
          arr.splice(foundIndex, 1);
        }

        return arr;
      });
    },
    [],
  );

  const handleSelectAll = useCallback(() => {
    setSelectedList((prev) => {
      if (prev.length > 0) return [];
      return config.map((a) => a.id);
    });
  }, [config]);

  return {
    selectedList,
    handleSelectRow,
    handleSelectAll,
  };
};

export default useTableSelect;
