/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconCopy = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.75 13C10.75 11.7574 11.7574 10.75 13 10.75H19.75C20.9926 10.75 22 11.7574 22 13V13.75C22 14.1642 21.6642 14.5 21.25 14.5C20.8358 14.5 20.5 14.1642 20.5 13.75V13C20.5 12.5858 20.1642 12.25 19.75 12.25H13C12.5858 12.25 12.25 12.5858 12.25 13V19.75C12.25 20.1642 12.5858 20.5 13 20.5H13.75C14.1642 20.5 14.5 20.8358 14.5 21.25C14.5 21.6642 14.1642 22 13.75 22H13C11.7574 22 10.75 20.9926 10.75 19.75V13ZM16 18.25C16 17.0074 17.0074 16 18.25 16H25C26.2426 16 27.25 17.0074 27.25 18.25V25C27.25 26.2426 26.2426 27.25 25 27.25H18.25C17.0074 27.25 16 26.2426 16 25V18.25ZM18.25 17.5C17.8358 17.5 17.5 17.8358 17.5 18.25V25C17.5 25.4142 17.8358 25.75 18.25 25.75H25C25.4142 25.75 25.75 25.4142 25.75 25V18.25C25.75 17.8358 25.4142 17.5 25 17.5H18.25Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconCopy;
