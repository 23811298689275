import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Fade, ListItem, Popper, Typography } from '@mui/material';

import SidebarItemSimple from '@/components/SidebarItemSimple';
import SidebarItemIcon from '@/components/SidebarItemIcon';
import {
  StyledPopperWrapper,
  StyledPopperContainer,
  StyledSidebarItemHardExpandLess,
  StyledSidebarItemHardExpandMore,
} from '@/components/SidebarItemHard/SidebarItemHardStyled';

import { ZINDEX } from '@/resources/constants';

import { useAnyPermissionGranted } from '@/hooks/usePermissionGranted';
import useActiveRoute from '@/hooks/useActiveRoute';

import { SidebarItemWithItemsProps } from '@/types/sidebar';

const SidebarItemHard = ({ name, icon, items, style }: SidebarItemWithItemsProps) => {
  const id = `popper-navigation-${name}`;
  const isOpenedSidebarState = true;

  const [isOpenedPopper, setOpenedPopperState] = useState(false);
  const [isCollapsedItems, setCollapsedItemsState] = useState(true);
  const [anchorPopperElement, setAnchorPopperElement] = useState<null | HTMLElement>(null);

  // const allPermissions = useMemo(
  //   () => items.reduce((res: RolePermission[], cur) => [...res, ...(cur.permissions || [])], []),
  //   [items],
  // );

  const allPermissions: string[] = [];
  const isActiveRoute = useActiveRoute(items);
  const hasAvailable = useAnyPermissionGranted(allPermissions) || allPermissions.length === 0;

  const toggleOpenedState = useCallback(() => {
    if (!isOpenedSidebarState) return;
    setCollapsedItemsState((prev) => !prev);
  }, [isOpenedSidebarState]);

  const handleMouseEnter = useCallback(({ currentTarget }: MouseEvent<HTMLDivElement>) => {
    setOpenedPopperState(true);
    setAnchorPopperElement(currentTarget);
  }, []);

  const handleMouseLeave = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const relatedTarget = e.relatedTarget as HTMLElement | null;
      if (relatedTarget?.closest && relatedTarget?.closest(`#${id}`)) return;

      setOpenedPopperState(false);
    },
    [id],
  );

  const itemsCollapse = useMemo(
    () => items.map((a) => <SidebarItemSimple isDeep key={a.path} {...a} />),
    [items],
  );

  const itemsPopper = useMemo(
    () =>
      items.map((a) => (
        <SidebarItemSimple onClick={handleMouseLeave} isDeep isDeepPopper key={a.path} {...a} />
      )),
    [handleMouseLeave, items],
  );

  useEffect(() => {
    if (!isOpenedSidebarState) {
      setCollapsedItemsState(true);
    }
  }, [isOpenedSidebarState]);

  if (!hasAvailable) {
    return null;
  }

  return (
    <>
      <ListItem
        button
        style={style}
        onClick={toggleOpenedState}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {icon && <SidebarItemIcon $isActive={isActiveRoute}>{icon}</SidebarItemIcon>}
        <Typography variant='subtitle2'>{name}</Typography>
        {!isCollapsedItems ? (
          <StyledSidebarItemHardExpandLess />
        ) : (
          <StyledSidebarItemHardExpandMore />
        )}
      </ListItem>

      <Collapse in={!isCollapsedItems} timeout='auto' unmountOnExit>
        {itemsCollapse}
      </Collapse>

      {!isOpenedSidebarState && (
        // @ts-ignore
        <Popper
          id={id}
          open={isOpenedPopper}
          anchorEl={anchorPopperElement}
          onMouseLeave={handleMouseLeave}
          transition
          placement='right'
          style={{ zIndex: ZINDEX.SIDEBAR_POPPER }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <StyledPopperWrapper>
                <StyledPopperContainer>{itemsPopper}</StyledPopperContainer>
              </StyledPopperWrapper>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};

export default SidebarItemHard;
