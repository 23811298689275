import { styled } from '@mui/material';

import Select from '@/components/Select';

export const StyledSelect = styled(Select)`
  min-width: 72px;
` as typeof Select;

export const StyledWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(4, 3)};

  width: 100%;
  display: flex;

  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
`;
