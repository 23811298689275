import { useEffect, useMemo } from 'react';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';

import { ApiService } from '@/services/services/services.types';
import { useLazyGetServicesQuery } from '@/services/services/services';

const useUserServices = (userId?: string | null) => {
  const { commandId } = useUserCommand();
  const { isMaster, userInfo } = useUserInfo();
  const [getServices, { data: services }] = useLazyGetServicesQuery();

  const foundUserId = isMaster ? userInfo?.id : userId;

  useEffect(() => {
    if (!foundUserId) return;
    getServices({ userId: foundUserId, commandId });
  }, [getServices, foundUserId, commandId]);

  return useMemo(() => {
    const list: ApiService[] = services?.list || [];

    return list.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  }, [services?.list]);
};

export default useUserServices;
