/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconCalendar = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 24 24'>
      <path
        d='M9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1H9ZM7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5H7ZM17 1C17 0.447715 16.5523 0 16 0C15.4477 0 15 0.447715 15 1H17ZM15 5C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5H15ZM5 4H19V2H5V4ZM20 5V19H22V5H20ZM19 20H5V22H19V20ZM4 19V5H2V19H4ZM5 20C4.79386 20 4.53874 19.8967 4.3197 19.6745C4.09874 19.4504 4 19.1919 4 19H2C2 19.8123 2.37805 20.5538 2.89547 21.0786C3.41482 21.6054 4.1597 22 5 22V20ZM20 19C20 19.1919 19.9013 19.4504 19.6803 19.6745C19.4613 19.8967 19.2061 20 19 20V22C19.8403 22 20.5852 21.6054 21.1045 21.0786C21.622 20.5538 22 19.8123 22 19H20ZM19 4C19.2061 4 19.4613 4.10333 19.6803 4.32551C19.9013 4.54963 20 4.80814 20 5H22C22 4.1877 21.622 3.44621 21.1045 2.92138C20.5852 2.3946 19.8403 2 19 2V4ZM5 2C4.1597 2 3.41482 2.3946 2.89547 2.92138C2.37805 3.44621 2 4.1877 2 5H4C4 4.80814 4.09874 4.54963 4.3197 4.32551C4.53874 4.10333 4.79386 4 5 4V2ZM7 1V5H9V1H7ZM15 1V5H17V1H15ZM21 8H3V10H21V8Z'
        fill='#CCCDD3'
      />
    </Icon>
  );
};

export default IconCalendar;
