import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import { SelectItem } from '@/types/form';

export enum FormModalCapValues {
  OFFER = 'offer',
  COMMENT = 'comment',
  COMPANY_LIMIT = 'companyLimit',
  COMMAND_LIMIT = 'commandLimit',
  PERCENTAGE_FOR_ALERTS = 'percentageForAlerts',
  DISTRIBUTION_TO_COMMAND = 'distributionToCommand',
  DISTRIBUTION_TO_MASTERS = 'distributionToMasters',
}

interface CustomFormElementValue {
  type: string;
  percent: string;
  memberId: string;
}

export interface FormModalCapData {
  [FormModalCapValues.OFFER]: SelectItem;
  [FormModalCapValues.COMMENT]: string;
  [FormModalCapValues.COMPANY_LIMIT]: string;
  [FormModalCapValues.COMMAND_LIMIT]: string;
  [FormModalCapValues.PERCENTAGE_FOR_ALERTS]: string;
  [FormModalCapValues.DISTRIBUTION_TO_MASTERS]: ElementObject<CustomFormElementValue>[];

  [FormModalCapValues.DISTRIBUTION_TO_COMMAND]?: ElementObject<CustomFormElementValue>[];
}
