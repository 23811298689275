/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconFacebook = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.2586 19.9375L22.6473 17.2228H20.2158V15.4612C20.2158 14.7185 20.5554 13.9946 21.6445 13.9946H22.75V11.6834C22.75 11.6834 21.7467 11.5 20.7875 11.5C18.7849 11.5 17.476 12.8001 17.476 15.1538V17.2228H15.25V19.9375H17.476V26.5C17.8587 26.5 18.3799 26.5 18.8459 26.5C19.3119 26.5 19.6929 26.5 20.2158 26.5V19.9375H22.2586Z'
        fill='#1877F2'
      />
    </Icon>
  );
};

export default IconFacebook;
