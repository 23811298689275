import { debounce } from 'throttle-debounce';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { TextFieldProps, IconButton, TextField, styled, PopperProps } from '@mui/material';

import { IconSize } from '@/components/Icon/IconTypes';

import IconClose from '@/icons/IconClose';
import IconCalendar from '@/icons/IconCalendar';

import { dateFormatCommon, dateInputFormat, DATE_REGEXP } from '@/resources/constants';

import { selectUserLanguage } from '@/redux/user/userGetters';
import { HookComponentProps } from '@/filters/usePageFilter';
import useFromToFilter from '@/filters/useFromToFilter';

type DateKey = 'from' | 'to';

const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-adornedEnd {
    padding-right: 0px;
  }
` as typeof DatePicker;

type FilterFromToProps = HookComponentProps & {
  defaultFrom?: string;
  defaultTo?: string;
  disabled?: boolean;
  popperProps?: Partial<PopperProps>;
};

const FilterFromTo = ({
  maxWidth,
  isClearable,
  defaultFrom,
  defaultTo,
  disabled = false,
  popperProps,
}: FilterFromToProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const { to, from, setTo, setFrom } = useFromToFilter(defaultFrom, defaultTo);

  const language = useSelector(selectUserLanguage);

  const handleValueClear = useCallback(
    (key: DateKey) => () => {
      const method = key === 'from' ? setFrom : setTo;
      method('', 'replaceIn', true);
    },
    [setFrom, setTo],
  );

  const shouldDisableDate = useCallback(
    (key: DateKey) => (date: Date | null) => {
      if (!from && key === 'to') return false;
      if (!to && key === 'from') return false;

      return key === 'to'
        ? moment(date).isBefore(moment(from).subtract(1, 'day'))
        : moment(to).isBefore(moment(date));
    },
    [from, to],
  );

  const handleValueSelect = useCallback(
    (key: DateKey) =>
      debounce(500, (result: Date | null) => {
        const newValue = result === null ? '' : moment(result).format(dateFormatCommon);

        const isInvalidDate = Boolean(newValue.replace(DATE_REGEXP, ''));
        const isDisabledDate = shouldDisableDate(key)(result);

        if (isInvalidDate || isDisabledDate) return;

        const method = key === 'from' ? setFrom : setTo;
        method(newValue, 'replaceIn', true);
      }),
    [shouldDisableDate, setFrom, setTo],
  );

  const getInputProps = useCallback(
    (
      params: TextFieldProps,
      isClearable = false,
      key: 'from' | 'to',
      value: string | null,
    ): TextFieldProps['InputProps'] => {
      const textFieldValue = params.inputProps?.value || '';

      return {
        ...params.InputProps,
        endAdornment: (
          <>
            {isClearable && (value || textFieldValue) && (
              <IconButton
                onClick={() => {
                  if (value) {
                    handleValueClear(key)();
                  } else {
                    if (!params.inputProps?.onChange) return;

                    const event = new Event('input', { bubbles: true });
                    const customEvent = { ...event, target: { ...inputRef.current, value: '' } };

                    // NOTE:
                    // Манипуляции с нативным ивентом,
                    // В целом, кажется, мы сделали почти все, что могли
                    params.inputProps.onChange(customEvent as any);
                  }
                }}
              >
                <IconClose size={IconSize.s} />
              </IconButton>
            )}
            {params?.InputProps?.endAdornment}
          </>
        ),
      };
    },
    [handleValueClear],
  );

  const renderPicker = useCallback(
    (key: 'from' | 'to') => {
      const val = key === 'to' ? to : from;
      const value = val || (null as any);

      const label = key === 'to' ? t('formLabels.to') : t('formLabels.from');
      const components = { OpenPickerIcon: IconCalendar };

      return (
        <StyledDatePicker
          label={label}
          value={value}
          disabled={disabled}
          inputFormat={dateInputFormat}
          onChange={handleValueSelect(key)}
          components={components}
          shouldDisableDate={shouldDisableDate(key)}
          dayOfWeekFormatter={(date) => date.slice(0, 2)}
          PopperProps={popperProps}
          renderInput={({ error, ...params }) => (
            <TextField
              {...params}
              size='small'
              inputRef={(instance: any) => {
                // @ts-ignore
                // Поддержка кастомного ref
                inputRef.current = instance;

                // NOTE:
                // Поддержка пропсов DatePicker
                if (typeof params.inputRef === 'function') {
                  params.inputRef(instance);
                } else {
                  params.inputRef = instance;
                }
              }}
              sx={{ maxWidth: maxWidth || '240px' }}
              InputProps={getInputProps(params, isClearable, key, value)}
            />
          )}
        />
      );
    },
    [
      disabled,
      from,
      getInputProps,
      handleValueSelect,
      isClearable,
      maxWidth,
      popperProps,
      shouldDisableDate,
      t,
      to,
    ],
  );

  useEffect(() => {
    // TODO: вызывать при изменении языка
    moment.locale(language);
  }, [language]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      {renderPicker('from')}
      {renderPicker('to')}
    </LocalizationProvider>
  );
};

export default FilterFromTo;
