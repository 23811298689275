import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'Cards.title';

export const onboardingCardsPage = [
  {
    title,
    target: 'page-cards',
    placement: 'right',
    content: {
      title: 'Cards.step1.title',
      message: 'Cards.step1.message',
    },
  },
  {
    title,
    target: `step-cards`,
    content: {
      title: 'Cards.step2.title',
      message: 'Cards.step2.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: 'step-card-3',
    placement: 'left',
    content: {
      title: 'Cards.step3.title',
      message: 'Cards.step3.message',
    },
  },
] as const satisfies OnboardingSteps;
