import { Optional } from '@/types';
import { ApiUser } from '@/services/users/users.types';

export type ModalUserProps = ApiUser & { commandId: string };

export enum FormModalUserValues {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TRACKER_ID = 'trackerId',
  SELFHOST_ID = 'selfhostId',
  ALLOWED_COMMANDS_IDS = 'financierCommandsId',
  SERVICE_INTEGRATIONS = 'servicesIntegrations',
}

export interface FormModalUserData<DEFAULT extends boolean = false> {
  [FormModalUserValues.LAST_NAME]: DEFAULT extends false ? string : Optional<string>;
  [FormModalUserValues.FIRST_NAME]: DEFAULT extends false ? string : Optional<string>;
  [FormModalUserValues.TRACKER_ID]: DEFAULT extends false ? string : Optional<string>;
  [FormModalUserValues.SELFHOST_ID]: DEFAULT extends false ? string : Optional<string>;
  [FormModalUserValues.SERVICE_INTEGRATIONS]: string[];
  [FormModalUserValues.ALLOWED_COMMANDS_IDS]: string[];
}
