import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { Grid, Stack } from '@mui/material';

import { FormForgotPasswordProps } from '@/pages/LoginPage/FormForgotPassword/FormForgotPasswordTypes';

import Typography from '@/components/Typography';
import FormElement from '@/components/FormElement';
import Button from '@/components/Button/Button';
import { StyledLoginForm } from '@/components/AuthPageForm';

import IconArrow from '@/icons/IconArrow';

import FormRuler from '@/utils/FormRuler';

import useBoolState from '@/hooks/useBoolState';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { useForgotPasswordMutation } from '@/services/auth/auth';

const FormForgotPassword = ({ setForgotPasswordState }: FormForgotPasswordProps) => {
  const { t } = useTranslation(['login', 'common']);

  const { state, setTrue } = useBoolState(false);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleClick = useCallback(() => {
    setForgotPasswordState(false);
  }, [setForgotPasswordState]);

  const [handleSubmit] = useAwaitCallback(async (data) => {
    const url = window.location.origin;
    const response = await forgotPassword({ ...data, url });

    if ('data' in response) {
      setTrue();
    }
  }, []);

  const config = useMemo(
    () =>
      state ? (
        <Grid item>
          <Typography variant='body1'>{t('login:resetPassword.description')}</Typography>
        </Grid>
      ) : (
        <>
          <Grid item>
            <FormElement
              name='email'
              component='input'
              label={t('common:formLabels.email')}
              rules={{ ...FormRuler.email, ...FormRuler.required }}
            />
          </Grid>

          <Grid item>
            <Button size='large' type='submit' fullWidth isLoading={isLoading}>
              {t('common:buttonActions.continue')}
            </Button>
          </Grid>
        </>
      ),
    [isLoading, state, t],
  );

  const title = state ? t('login:resetPassword.title.p2') : t('login:resetPassword.title.p1');

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <Grid container direction='column' spacing={6}>
        <Grid item>
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconArrow onClick={handleClick} color='#2374EE' />

            <Typography variant='h5'>{title}</Typography>
          </Stack>
        </Grid>

        {config}
      </Grid>
    </StyledLoginForm>
  );
};

export default FormForgotPassword;
