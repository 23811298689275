import { styled, Tab, Box, css } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledTabsTab = styled(Tab)`
  width: 50%;
`;

export const StyledTabs = styled(Box, transientProps)<{ $isWithoutBackground: boolean }>`
  width: 100%;
  max-width: 508px;

  ${({ $isWithoutBackground }) =>
    $isWithoutBackground &&
    css`
      & button {
        width: auto;
        flex: none;
      }

      .MuiTabs-root {
        background: none;
        border-radius: 0;
        border: 0;
      }
    `}
`;
