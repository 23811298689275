import { ChartOptions } from 'chart.js';

import LocalStorage from '@/utils/LocalStorage';

import { SelectItem } from '@/types/form';
import { ApiPagination } from '@/types/api';
import { Languages } from '@/types';

export const STORAGE_USER_INFO = 'STORAGE_USER_INFO';
export const STORE_USER_TOKEN = 'STORE_USER_TOKEN';
export const STORE_SIDEBAR_STATE = 'STORE_SIDEBAR_STATE';
export const STORE_INTERFACE_LANGUAGE = 'STORE_INTERFACE_LANGUAGE';
export const STORE_USER_COMMAND = 'STORE_USER_COMMAND';

export const DEFAULT_LANGUAGE = Languages.ru;

export const isDevelop = process.env.NODE_ENV === 'development';
export const isDebugMode = Boolean(LocalStorage.get('isDebugMode') || isDevelop);

export const HEADER_TITLE_ID = 'headerTitle';
export const HEADER_ADDITIONAL_CONTENT_ID = 'headerAdditionalContent';

export const HEADER_BACK_BUTTON_ID = 'backButton';

export const ROUTER_CONTENT_ID = 'routerContent';

export const dateInputFormat = 'DD.MM.YYYY';
export const dateFormatCommon = 'DD-MM-YYYY';
export const dateFormatStartsWithYear = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

export const DATE_REGEXP = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;

export const SUPPORT_CONTACT = 'peppersup';
export const SUPPORT_CONTACT_LINK = `https://t.me/${SUPPORT_CONTACT}`;

export const SYSTEM_CURRENCY = 'USD';
export const defaultValuesWithCurrency = { sumWithCurrency: { currency: SYSTEM_CURRENCY } };

export const limitPageOptions: SelectItem[] = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];

export const EXAMPLE_PAGINATION: ApiPagination = {
  page: 1,
  pages: 1,
  limit: 10,
  total: 10,
};

export const minLength = {
  password: 8,
  common: 1,
};

export const maxLength = {
  password: 16,
  common: 64,
  report: 100,
};

export const buttonWidth = {
  close: '74px',
  archive: '130px',
};

export const CHART_COLORS_LEFT = [
  '#217AFF',
  '#7BC8FF',
  '#5A3FFE',
  '#AA46F9',
  '#009CB1',
  '#6B84FF',
  '#8243FC',
  '#5571D5',
  '#7676e3',
  '#0d89d1',
  '#8159b5',
  '#FF4A4A',
  '#F8EE11',
  '#F37909',
];

const ticks = {
  color: '#999999',
  font: { size: 12, lineHeight: '166%', weight: '400' },
};

export const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  layout: { padding: { left: 8, top: 32, bottom: 22, right: 24 } },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: { ...ticks, maxRotation: 0 },
      grid: {
        color: '#FFFFFF',
        borderColor: 'rgba(228, 228, 228, 0.2)',
      },
    },
    y: {
      ticks: { ...ticks, padding: 10 },
      grid: {
        color: 'rgba(228, 228, 228, 0.2)',
        borderColor: '#FFFFFF',
      },
    },
  },
};

export enum ZINDEX {
  SIDEBAR_ITEM = 5,
  SIDEBAR_POPPER = 100,
  // NOTE:
  // У header z-index: 1100 (нативно из либы)
  // У sidebar z-index: 1200 (нативно из либы)
  LOADER = 1000,
  ONBOARDING = 1220,
  POPPER = 1250,
  NOTIFICATION = 100000,
  OVERLAY = 99999,
}

type ComparatorValue = { date: string };
export const sortByDate =
  (direction: 'ASC' | 'DESC') => (a: ComparatorValue, b: ComparatorValue) => {
    const result = direction === 'ASC' ? b.date > a.date : a.date > b.date;
    return result ? -1 : 1;
  };

export class FileUploadTypes {
  static text = 'text/*,application/pdf';
  static textOrImage = `${FileUploadTypes.text},image/*`;
}

export enum Headers {
  lang = 'lang',
}

export const TOUR_STEP_ANCHOR = 'step-table-header-';

export const LIST_POPPER_HEIGHT = 170;
