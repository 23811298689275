import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import { STORAGE_USER_INFO } from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';

import {
  ApiOnboardingStatePatchBody,
  ApiOnboardingStatePatchResponse,
} from '@/services/onboarding/onboarding.types';
import commonApi from '@/services/common/common';
import { ApiMe } from '@/services/auth/auth.types';
import authApi from '@/services/auth/auth';

const baseUrl = '/users/onboarding';

const onboardingApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    updateOnboardingState: build.mutation<
      ApiOnboardingStatePatchResponse,
      ApiOnboardingStatePatchBody
    >({
      query: ({ page, ...body }) => ({ url: `${baseUrl}/${page}`, method: 'PATCH', body }),
      async onQueryStarted({ page }, { dispatch }) {
        try {
          dispatch(
            authApi.util.updateQueryData('getMe', undefined, (draft) => {
              const updatedPost: MaybeDrafted<ApiMe> = {
                ...draft,
                onboardings: { ...draft.onboardings, [page]: true },
              };

              const updatedUserInfo = Object.assign(draft, updatedPost);
              LocalStorage.set(STORAGE_USER_INFO, updatedUserInfo);
            }),
          );
        } catch (error) {
          console.log(`Error onboarding api ${page}`, error);
        }
      },
    }),
  }),
});

export const { useUpdateOnboardingStateMutation } = onboardingApi;

export default onboardingApi;
