import filterBoolean from '@/utils/filterBoolean';

import useQueryParam from '@/hooks/useQueryParam';

const useStatusesFilter = () => {
  const queryName = 'statuses';
  const [statuses, setStatuses] = useQueryParam(queryName, '');

  const value = statuses.split(',').filter(filterBoolean);

  return {
    setStatuses,
    statuses: value.length ? value : undefined,
  };
};

export default useStatusesFilter;
