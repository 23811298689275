import { useMemo } from 'react';

import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import useUserInfo from '@/hooks/useUserInfo';

import { ApiUserRole } from '@/services/auth/auth.types';

export const filterTourCards = (cards: OnboardingSteps, role: ApiUserRole | undefined) => {
  if (!role || !cards.length) return [];
  return cards.filter((el) => !el.roleList || el.roleList.includes(role));
};

// Некоторые подсказки невозможно показать т.к таблица к которой они привязаны пустая,
// в таком случае подсказка удаляется
export const removeStep = (cards: OnboardingSteps) => cards.filter((el) => !el.hideWithoutData);

const useTourCards = (cards: OnboardingSteps, isNeedFilterByDataCondition = false) => {
  const { userInfo } = useUserInfo();

  const onboardingItems = useMemo(() => {
    const filteredCardsByRoles = filterTourCards(cards, userInfo?.role);
    if (!filteredCardsByRoles?.length) return [];

    const filteredCardsByData = isNeedFilterByDataCondition
      ? removeStep(filteredCardsByRoles)
      : filteredCardsByRoles;
    if (!filteredCardsByData?.length) return [];

    return filteredCardsByData.map((el) => ({
      ...el,
      target: `.${[el.target, el.additionalTarget].filter(Boolean).join(' ')}`,
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    }));
  }, [cards, isNeedFilterByDataCondition, userInfo?.role]);

  return onboardingItems;
};
export default useTourCards;
