import { IconButton, styled, css } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledTableRowActions = styled('div')`
  max-width: 100%;

  display: inline-flex;
  align-items: center;

  && > * + * {
    margin-left: 12px;
  }
`;

export const StyledTableCell = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledIconButton = styled(IconButton, transientProps)<{ $isLoading?: boolean }>`
  @keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      & > svg {
        animation: 1s linear 0s normal none infinite running rot;
      }
    `}
`;
