import { useDispatch, useSelector } from 'react-redux';
import { MouseEvent } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import { LanguagesCode } from '@/types';
import { selectUserLanguage } from '@/redux/user/userGetters';
import { setInterfaceLanguageAction } from '@/redux/user/userActions';

const Localization = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectUserLanguage);

  const handleChangeLanguage = (_e: MouseEvent<HTMLElement>, value: LanguagesCode) => {
    dispatch(setInterfaceLanguageAction(value || language));
  };

  return (
    <ToggleButtonGroup
      exclusive
      color='primary'
      sx={{ ml: 4 }}
      value={language}
      onChange={handleChangeLanguage}
    >
      <ToggleButton value='ru'>РУ</ToggleButton>
      <ToggleButton value='en'>EN</ToggleButton>
      <ToggleButton value='ua'>UA</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Localization;
