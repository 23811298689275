import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DialogActions } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { defaultValuesWithCurrency } from '@/resources/constants';

import FormRuler from '@/utils/FormRuler';

import useUserCommand from '@/hooks/useUserCommand';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiCard, ApiCardByIdReplenishmentPatchBody } from '@/services/cards/cards.types';
import { useReplenishmentCardByIdMutation } from '@/services/cards/cards';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalCardReplenishmentData,
  FormModalCardReplenishmentValues,
} from '@/modals/ModalCardReplenishment/ModalCardReplenishmentTypes';

const ModalCardReplenishment: StaticModalWrappedComponent<ApiCard> = (props) => {
  const { t } = useTranslation(['common', 'cards']);

  const methods = useForm<FormModalCardReplenishmentData>({
    defaultValues: defaultValuesWithCurrency,
  });
  const dateOfOperation = methods.watch(FormModalCardReplenishmentValues.DATE);

  const { commandId } = useUserCommand();
  const [replenishmentCard, { isLoading }] = useReplenishmentCardByIdMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCardReplenishmentData) => {
      if (!props.data) {
        return;
      }

      const body: ApiCardByIdReplenishmentPatchBody = {
        commandId,
        id: props.data.id,
        date: data[FormModalCardReplenishmentValues.DATE],
        sum: data[FormModalCardReplenishmentValues.SUM_WITH_CURRENCY].systemSum,
        currency: data[FormModalCardReplenishmentValues.SUM_WITH_CURRENCY].currency,
        currencySum: Number(data[FormModalCardReplenishmentValues.SUM_WITH_CURRENCY].currencySum),
      };

      const response = await replenishmentCard(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, props, replenishmentCard],
  );

  return (
    <Modal {...props} title={t('cards:cardReplenishment', { value: props.data?.name })}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          label={t('common:tableLabels.sum')}
          component='sumWithCurrency'
          rules={FormRuler.validateSumWithCurrency}
          dateOfOperation={dateOfOperation}
          name={FormModalCardReplenishmentValues.SUM_WITH_CURRENCY}
        />

        <FormElement
          label={t('common:tableLabels.date')}
          component='datepicker'
          minDate={props.data?.date}
          name={FormModalCardReplenishmentValues.DATE}
          maxDate={new Date().toISOString()}
          rules={{
            ...FormRuler.required,
            ...FormRuler.validateDate(props.data?.date, false, 'day'),
          }}
        />

        <Typography marginTop={4} variant='body1' color='text.secondary'>
          {t('common:commission', { value: props.data?.service.commissionReplenishmentCard })}
        </Typography>

        <DialogActions>
          <Button isLoading={isLoading} type='submit'>
            {t('common:buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};
export default withStaticModal<ApiCard>(ModalCardReplenishment);
