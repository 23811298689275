import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Grid from '@mui/material/Grid';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import Button from '@/components/Button';

import IconPlus from '@/icons/IconPlus';

import routes from '@/resources/routes';
import { onboardingCommandsPage } from '@/resources/onboarding/commandsPage';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserInfo from '@/hooks/useUserInfo';
import usePagination from '@/hooks/usePagination';

import { ApiCommand } from '@/services/commands/commands.types';
import { useGetCommandsQuery } from '@/services/commands/commands';
import { ApiUserRole } from '@/services/auth/auth.types';
import ModalInvite from '@/modals/ModalInvite/ModalInvite';
import ModalDolphin from '@/modals/ModalDolphin/ModalDolphin';
import ModalCompany from '@/modals/ModalCompany/ModalCompany';
import ModalCommand from '@/modals/ModalCommand/ModalCommand';

const CommandsPage = () => {
  const { t } = useTranslation(['common', 'command', 'facebook']);

  const history = useHistory();

  const { isOwner } = useUserInfo();

  const { commands, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'commands',
    useQuery: useGetCommandsQuery,
  });

  const openCreateCommandModal = () => {
    ModalCommand.show();
  };

  const openInviteModal = (command: ApiCommand) => () => {
    ModalInvite.show(command);
  };

  const openEditCommandModal = (command: ApiCommand) => () => {
    ModalCommand.show(command);
  };

  const openEditCompanyModal = () => {
    ModalCompany.show();
  };

  const config: TableConfig = useMemo(() => {
    const list: ApiCommand[] = commands?.list || [];

    return list.map((a, i) => {
      const actionProps: TableRowActionsProps = {
        customWrapperStyle: {
          width: '100%',
          justifyContent: 'flex-end',
        },
        buttonsConfig: [
          {
            textForButton: t('common:roles.employee'),
            onClick: openInviteModal(a),
            startIcon: <IconPlus className={onboardingCommandsPage[4].target} />,
          },
        ],
      };

      const isCommandWithDolphin = Boolean(a.dolphinToken?.id);

      return {
        id: a.id,
        onRowClick: () => history.push(routes.commandById.link(a.id)),
        data: [
          {
            label: 'ID',
            value: i + 1,
            isHiddenLabel: true,
          },
          {
            label: t('common:roles.team'),
            value: (
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={8} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {a.name}
                </Grid>
                <Grid item xs={4}>
                  <TableRowActions isIconEdit onEdit={openEditCommandModal(a)} />
                </Grid>
              </Grid>
            ),
          },
          {
            label: t('common:sidebar.employees'),
            value: a.users.length,
          },
          {
            label: t('common:roles.lead'),
            value:
              a.users
                .filter(({ role }) => role === ApiUserRole.LEAD_USER)
                .map((a) => getUserNickname(a))
                .join(', ') || t('command:absent'),
          },
          {
            label: t('facebook:tokenDolphin'),
            style: { paddingLeft: 25 },
            value: (
              <TableRowActions
                buttonsConfig={[
                  {
                    textForButton: t(
                      `common:buttonActions.${isCommandWithDolphin ? 'edit' : 'add'}`,
                    ),
                    onClick: () => ModalDolphin.show(a),
                    size: 'small',
                    variant: 'text',
                  },
                ]}
              />
            ),
          },
          {
            label: 'Actions',
            style: {
              width: 0,
            },
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
          },
        ],
      };
    });
  }, [commands?.list, history, t]);

  return (
    <>
      <PageTitle onboardingSteps={onboardingCommandsPage} deleteCardsInTables={config.length === 0}>
        {t('common:sidebar.commands')}
      </PageTitle>

      <PageHeaderContent
        label={t('common:roles.team')}
        onClick={openCreateCommandModal}
        adviseTarget={onboardingCommandsPage[3].target}
      >
        {isOwner && (
          <Button
            size='large'
            variant='outlined'
            onClick={openEditCompanyModal}
            className={onboardingCommandsPage[1].target}
          >
            {t('command:editCompany')}
          </Button>
        )}
      </PageHeaderContent>
      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        adviseKey={onboardingCommandsPage[5].target}
        pagination={getPagination(commands?.meta)}
      />
    </>
  );
};

export default CommandsPage;
