import { EntityStatusProps } from '@/components/EntityStatus/EntityStatusTypes';
import { StyledEntityStatus } from '@/components/EntityStatus/EntityStatusStyled';

const EntityStatus = ({ statusCode = 'default', children }: EntityStatusProps) => {
  return (
    <StyledEntityStatus variant='body2' $status={statusCode}>
      {children}
    </StyledEntityStatus>
  );
};

export default EntityStatus;
