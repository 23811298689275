import SidebarItemSimple from '@/components/SidebarItemSimple';
import SidebarItemHard from '@/components/SidebarItemHard';

import { SidebarItemProps } from '@/types/sidebar';

const SidebarItem = ({ ...props }: SidebarItemProps) => {
  if ('items' in props) {
    return <SidebarItemHard {...props} />;
  }

  return <SidebarItemSimple {...props} />;
};

export default SidebarItem;
