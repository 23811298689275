import { useSelector } from 'react-redux';

import useUserInfo from '@/hooks/useUserInfo';

import { UserState } from '@/types';
import { selectCommand } from '@/redux/user/userGetters';

// NOTE
// Получение command пользователя

const useUserCommand = (): UserState['command'] => {
  const command = useSelector(selectCommand);
  const { isMultipleAccessToCommandUser, userInfo } = useUserInfo();

  return isMultipleAccessToCommandUser
    ? command
    : {
        commandId: userInfo?.command.id || '',
        isComandWithDolphin: Boolean(userInfo?.command.dolphinToken?.id),
      };
};

export default useUserCommand;
