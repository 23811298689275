import moment from 'moment';

import { dateFormatCommon } from '@/resources/constants';

import useQueryParam, { SetQueryFn } from '@/hooks/useQueryParam';

type FromToFilterHook = (
  defaultFrom?: string,
  defaultTo?: string,
) => {
  setTo: SetQueryFn;
  setFrom: SetQueryFn;
  to: string | undefined;
  from: string | undefined;
};

const useFromToFilter: FromToFilterHook = (defaultFrom = '', defaultTo = '') => {
  const [to, setTo] = useQueryParam('to', defaultTo);
  const [from, setFrom] = useQueryParam('from', defaultFrom);

  const formatValueTo = to && moment(to, dateFormatCommon).utc(true).toISOString();
  const formatValueFrom = from && moment(from, dateFormatCommon).utc(true).toISOString();

  return {
    setTo,
    setFrom,
    to: formatValueTo || undefined,
    from: formatValueFrom || undefined,
  };
};

export default useFromToFilter;
