import { css, styled } from '@mui/material';

import PageFilters from '@/components/PageFilters';
import Button from '@/components/Button';

import transientProps from '@/utils/transientProps';

export const FiltersButton = styled(Button)`
  max-width: 120px;
  width: 100%;
  height: 40px;

  display: flex;

  background: ${({ theme }) => theme.palette.grey[100]};
  color: ${({ theme }) => theme.palette.text.secondary};

  & > svg {
    margin-right: 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.grey[200]};
  }
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;

  & > button + button {
    margin-left: 16px;
  }
`;

export const SettingsButton = styled(Button)`
  max-width: 198px !important;
  width: 100%;
  height: 40px;

  padding: 6px 16px;
  margin-left: 16px;
`;

export const MainFilters = styled('div', transientProps)<{ $isIntoDashboardsPage?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
`;

export const StyledPageFilters = styled(PageFilters, transientProps)<{
  $hasCommandSelect?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ $hasCommandSelect }) =>
    $hasCommandSelect &&
    css`
      justify-content: space-between;
    `}
`;

export const MainPageFilters = styled('div', transientProps)<{
  $hasCommandSelect?: boolean;
  $intoDashboardsPage?: boolean;
}>`
  width: 100%;
  display: flex;

  & > * {
    max-width: ${({ $intoDashboardsPage }) => ($intoDashboardsPage ? 262 : 252)}px;
    width: 100%;
  }

  & > div + div {
    margin-left: ${({ $intoDashboardsPage }) => ($intoDashboardsPage ? 12 : 20)}px;
  }

  ${({ $hasCommandSelect, $intoDashboardsPage }) =>
    ($hasCommandSelect || $intoDashboardsPage) &&
    css`
      justify-content: end;
    `}
`;
