import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import assertNever from 'assert-never';

import { DashboardSettings, PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { SelectProps } from '@/components/Select/SelectTypes';
import Select from '@/components/Select';
import Loader from '@/components/Loader';

import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import useUserInfo from '@/hooks/useUserInfo';
import useStatisticCategoriesOptions from '@/hooks/useStatisticCategoriesOptions';
import { useSettingsDashboardFilters } from '@/hooks/useSettingsDashboardFilters';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useCommandMasters from '@/hooks/useCommandMasters';

interface DashboardsFiltersProps {
  inputSize?: 'small' | 'medium';
  isLoading?: boolean;

  settings: DashboardSettings;
  parameters: string[];
  mastersIds: string[];
  commandsIds: string[];

  onChangeFilter: ReturnType<typeof useSettingsDashboardFilters>['updateFilters'];
}

// Note: что-то вроде синтаксического сахара.
// тип для читаемости
enum PossibleCustomFilters {
  parameter = 'parameter',
  masters = PossibleTabs.masters,
  commands = PossibleTabs.commands,
}

export const DashboardsFilters = ({
  commandsIds,
  mastersIds,
  parameters,
  onChangeFilter,
  inputSize,
  isLoading,
  settings,
}: DashboardsFiltersProps) => {
  const { t } = useTranslation(['dashboard', 'common']);
  const { masters } = useCommandMasters({ commandId: commandsIds[0] });

  const { commands } = useHighLevelUserCommands();
  const { statisticCategoriesOptions } = useStatisticCategoriesOptions();
  const { isMultipleAccessToCommandUser, isMaster, isLeader } = useUserInfo();

  const getSelect = useCallback(
    (filter: PossibleCustomFilters, isMultiple: boolean) => {
      const handleChange = (filter: PossibleCustomFilters, value: string[]) => {
        const typedParameters = parameters as PossibleStatisticCategories[];

        const getData = (): Parameters<typeof onChangeFilter>['0'] => {
          if (settings.tab === PossibleTabs.commands)
            return { parameters: typedParameters, commandsIds, usersIds: [] };

          return { parameters: typedParameters, usersIds: mastersIds, commandsIds };
        };

        const data = getData();

        if (filter === PossibleCustomFilters.masters) data.usersIds = value;
        if (filter === PossibleCustomFilters.commands) {
          data.commandsIds = value;
          data.usersIds = undefined;
        }
        if (filter === PossibleCustomFilters.parameter)
          data.parameters = value as PossibleStatisticCategories[];

        if (isMaster) {
          delete data.commandsIds;
          delete data.usersIds;
        }

        if (isLeader) {
          delete data.commandsIds;
        }

        onChangeFilter(data);
      };

      const isDisabledMastersFilter = !commandsIds[0] && isMultipleAccessToCommandUser;

      const commonProps = {
        isMultiple: false,
        disableAutofill: true,
        withCanAwait: true,
        isClearable: true,
        size: inputSize,
        options: [],
      };

      const getSelector = (props: SelectProps<typeof isMultiple>) => {
        return <Select<typeof isMultiple> {...props} />;
      };

      if (isMultiple) {
        const commonMultipleProps: SelectProps<true> = {
          ...commonProps,
          isMultiple: true,
        };

        switch (filter) {
          case PossibleCustomFilters.parameter: {
            commonMultipleProps.options = statisticCategoriesOptions;
            commonMultipleProps.value = parameters;
            commonMultipleProps.label = t('dashboard:parameters');
            commonMultipleProps.onSelect = (values: string[]) =>
              handleChange(PossibleCustomFilters.parameter, values);

            return getSelector(commonMultipleProps);
          }
          case PossibleCustomFilters.masters: {
            if (isMaster) return null;

            commonMultipleProps.options = masters;
            commonMultipleProps.value = mastersIds;
            commonMultipleProps.label = t('dashboard:masters');
            commonMultipleProps.disabled = isDisabledMastersFilter;
            commonMultipleProps.onSelect = (values: string[]) =>
              handleChange(PossibleCustomFilters.masters, values);

            return getSelector(commonMultipleProps);
          }
          case PossibleCustomFilters.commands: {
            if (!isMultipleAccessToCommandUser) return null;

            commonMultipleProps.options = commands;
            commonMultipleProps.value = commandsIds;
            commonMultipleProps.label = t('dashboard:commands');
            commonMultipleProps.onSelect = (values: string[]) =>
              handleChange(PossibleCustomFilters.commands, values);
            commonMultipleProps.isClearable = false;

            return getSelector(commonMultipleProps);
          }
        }
      } else {
        const commonSingleProps: SelectProps = commonProps;

        switch (filter) {
          case PossibleCustomFilters.parameter: {
            commonSingleProps.options = statisticCategoriesOptions;
            commonSingleProps.value = parameters[0];
            commonSingleProps.label = t('dashboard:parameter');
            commonSingleProps.onSelect = (value: string) =>
              handleChange(PossibleCustomFilters.parameter, [value]);

            return getSelector(commonSingleProps);
          }
          case PossibleCustomFilters.masters: {
            if (isMaster) return null;

            commonSingleProps.options = masters;
            commonSingleProps.label = t('common:roles.user');
            commonSingleProps.value = mastersIds[0];
            commonSingleProps.onSelect = (value: string) => {
              handleChange(PossibleCustomFilters.masters, [value]);
            };

            commonSingleProps.disabled = isDisabledMastersFilter;

            return getSelector(commonSingleProps);
          }
          case PossibleCustomFilters.commands: {
            if (!isMultipleAccessToCommandUser) return null;

            commonSingleProps.options = commands;
            commonSingleProps.value = commandsIds[0];
            commonSingleProps.onSelect = (value: string) => {
              handleChange(PossibleCustomFilters.commands, [value]);
            };
            commonSingleProps.label = t('common:roles.team');
            commonSingleProps.isClearable = false;

            return getSelector(commonSingleProps);
          }
        }
      }
    },
    [
      commands,
      commandsIds,
      inputSize,
      isLeader,
      isMaster,
      isMultipleAccessToCommandUser,
      masters,
      mastersIds,
      onChangeFilter,
      parameters,
      settings.tab,
      statisticCategoriesOptions,
      t,
    ],
  );

  if (isLoading && settings.isLocale) {
    return <Loader isStatic isCenter />;
  }

  switch (settings.tab) {
    case PossibleTabs.parameters: {
      return (
        <>
          {getSelect(PossibleCustomFilters.commands, false)}
          {getSelect(PossibleCustomFilters.masters, false)}
          {getSelect(PossibleCustomFilters.parameter, true)}
        </>
      );
    }
    case PossibleTabs.masters: {
      if (isMaster) return null;

      return (
        <>
          {getSelect(PossibleCustomFilters.commands, false)}
          {getSelect(PossibleCustomFilters.parameter, false)}
          {getSelect(PossibleCustomFilters.masters, true)}
        </>
      );
    }
    case PossibleTabs.commands: {
      if (!isMultipleAccessToCommandUser) return null;

      return (
        <>
          {getSelect(PossibleCustomFilters.parameter, false)}
          {getSelect(PossibleCustomFilters.commands, true)}
        </>
      );
    }
    default: {
      assertNever(settings.tab);
    }
  }
};
