import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import routes from '@/resources/routes';

import clearAllCache from '@/utils/clearAllCache';

import { useLogoutUserMutation } from '@/services/auth/auth';

const useLogoutUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [logoutUser] = useLogoutUserMutation();

  return useCallback(async () => {
    const response = await logoutUser();

    if ('data' in response) {
      clearAllCache(dispatch);
      history.replace(routes.index.path);
    }
  }, [dispatch, history, logoutUser]);
};

export default useLogoutUser;
