import { useTranslation } from 'react-i18next';
import React, { Children, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

import DashboardsTabs from '@/pages/DashboardsPage/components/DashboardsTabs';

import {
  ButtonsWrapper,
  FiltersButton,
  MainFilters,
  MainPageFilters,
  SettingsButton,
  StyledPageFilters,
} from '@/components/FiltersWithSidebar/FiltersWithSidebarStyled';
import { FiltersSidebar } from '@/components/FiltersWithSidebar/FiltersSidebar/FiltersSidebar';
import { CommandSelect } from '@/components/CommandSelect/CommandSelect';
import { ButtonProps } from '@/components/Button/ButtonTypes';

import IconFilter from '@/icons/IconFilter';

import useUserInfo from '@/hooks/useUserInfo';

interface FiltersWithSidebarProps {
  children?: React.ReactNode;
  settingsButtonProps?: ButtonProps;
  hasFilterFromTo?: boolean;
  hasCommandSelect?: boolean;

  isIntoDashboardsPage?: boolean;

  className?: string;
}

const tooMuchCount = 2;

export const FiltersWithSidebar = ({
  children,
  settingsButtonProps,
  hasFilterFromTo = false,
  hasCommandSelect = true,
  isIntoDashboardsPage,
  className,
}: FiltersWithSidebarProps) => {
  const [isOpenFilters, setOpenFiltersState] = useState(false);

  const { t } = useTranslation(['common']);
  const { isMaster } = useUserInfo();

  const childrenCount = Children.count(children);
  const realChildrenCount = hasFilterFromTo ? childrenCount + 1 : childrenCount;
  const isTooMuchChildren = realChildrenCount > tooMuchCount;
  const hasSettingsButton = Boolean(settingsButtonProps);

  const hasDashboardsTabs = Boolean(isIntoDashboardsPage && !isMaster);

  // NOTE: чтобы появилсась кнопка настроек,
  // нужно передать ей пропсы при вызове компонента
  const SettingsButtonLayout = hasSettingsButton && (
    <SettingsButton size='small' startIcon={<SettingsIcon />} {...settingsButtonProps}>
      {settingsButtonProps?.children || t('common:configureColumns')}
    </SettingsButton>
  );

  const CommandSelectLayout = hasCommandSelect && <CommandSelect />;

  const TabsForDashboardsPage = hasDashboardsTabs && <DashboardsTabs />;

  return isTooMuchChildren ? (
    <MainFilters $isIntoDashboardsPage={hasDashboardsTabs}>
      {CommandSelectLayout}
      {TabsForDashboardsPage}
      <ButtonsWrapper>
        <FiltersButton
          className={className}
          onClick={() => setOpenFiltersState(true)}
          startIcon={<IconFilter />}
        >
          {t('buttonActions.filters')}
        </FiltersButton>
        {SettingsButtonLayout}
        <FiltersSidebar open={isOpenFilters} close={() => setOpenFiltersState(false)}>
          {children}
        </FiltersSidebar>
      </ButtonsWrapper>
    </MainFilters>
  ) : (
    <StyledPageFilters $hasCommandSelect={hasCommandSelect}>
      {CommandSelectLayout}
      {TabsForDashboardsPage}
      <MainPageFilters $intoDashboardsPage={hasDashboardsTabs} $hasCommandSelect={hasCommandSelect}>
        {children}
        {SettingsButtonLayout}
      </MainPageFilters>
    </StyledPageFilters>
  );
};
