import { ListItemIcon } from '@mui/material';
import styled from '@emotion/styled';

import transientProps from '@/utils/transientProps';

export const SidebarItemIcon = styled(ListItemIcon, transientProps)<{ $isActive?: boolean }>`
  rect {
    transition: all 250ms;
    fill: ${({ $isActive, theme }) => $isActive && theme.palette.secondary.dark};
  }

  path {
    transition: all 250ms;
    fill: ${({ $isActive, theme }) => $isActive && theme.palette.primary.main};
  }
`;

export default SidebarItemIcon;
