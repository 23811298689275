import { styled } from '@mui/material';

import Typography from '@/components/Typography';
import Select from '@/components/Select';

export const FormElementContent = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.spacing(4)};
  max-width: 160px;
` as typeof Select;

export const StyledTypography = styled(Typography)`
  width: 100%;
  text-align: end;
`;
