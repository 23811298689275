import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableWithSettings } from '@/types/table';

const excludedOfferColumns = ['Income', 'Roi', 'Romi'];
const excludedLeadColumns = ['date', 'lastName', 'firstName', 'phone', 'master', 'offer', 'status'];

// TODO: можно вынести название всех колонок отдельно, чтобы не строить кучу условий
const useLocalizeTableColumns = (tableName: TableWithSettings) => {
  const { t } = useTranslation(['common', 'offer', 'leads']);

  const localizeTableColumns = useCallback(
    (columnId: string) => {
      if (tableName === TableWithSettings.OFFERS) {
        const isOfferColumnName = excludedOfferColumns.some((a) => columnId.includes(a));
        const namespace = isOfferColumnName
          ? `offer:category.${columnId}`
          : `common:tableLabels.${columnId}`;
        // @ts-ignore
        return t(namespace);
      }

      if (tableName === TableWithSettings.LEADS) {
        const isLeadColumnName = excludedLeadColumns.some((a) => columnId === a);
        const namespace = isLeadColumnName
          ? `leads:table.${columnId}`
          : `common:tableLabels.${columnId}`;
        // @ts-ignore
        return t(namespace);
      }

      return columnId;
    },
    [t, tableName],
  );

  return { localizeTableColumns };
};

export default useLocalizeTableColumns;
