import {
  ApiHighLevelUserParams,
  ApiOperationWithCurrency,
  ApiPaginationParams,
  ApiResponse,
  ApiResponseWithPagination,
  Void,
} from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiService } from '@/services/services/services.types';
import { ApiPipe } from '@/services/pipes/pipes.types';
import { ArchiveEnum } from '@/filters/useArchivedFilter';

export enum ApiCardStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export type ApiCard = {
  id: string;
  externalId?: string;
  name: string;
  card: string;
  balance: string;

  date: string;
  lastReplenishmentDate: string;

  user: ApiUser;
  service: ApiService;
  status: ApiCardStatus;
  pipes: Omit<ApiPipe, 'user' | 'offer' | 'cards' | 'consumables'>[];

  isArchived: boolean;
};

// === READ ↴

export type ApiCardsGetQuery = Void<
  ApiPaginationParams &
    ApiHighLevelUserParams & { userId?: string; status?: ApiCardStatus; isArchived?: ArchiveEnum }
>;
export type ApiCardsGetResponse = ApiResponseWithPagination<ApiCard>;

// === READ BY ID ↴

export type ApiCardByIdGetQuery = { id: string };
export type ApiCardByIdGetResponse = ApiResponse<ApiCard>;

// === CREATE ↴

export type ApiCardPostBody = Pick<ApiCard, 'name' | 'card'> & {
  date: string;
  serviceId: string;
  userId?: string;
};
export type ApiCardPostResponse = ApiResponse<ApiCard>;

// === UPDATE ↴

export type ApiCardByIdPatchBody = ApiCardPostBody & { id: string };
export type ApiCardByIdPatchResponse = ApiCardPostResponse;

// === REPLENISHMENT ↴

export type ApiCardByIdReplenishmentPatchBody = ApiHighLevelUserParams &
  ApiOperationWithCurrency & {
    id: string;
    sum: number;
    date: string;
  };
export type ApiCardByIdReplenishmentPatchResponse = ApiCardPostResponse;

// === TRAFFIC COSTS ↴

export type ApiCardByIdTrafficPatchBody = ApiHighLevelUserParams &
  ApiOperationWithCurrency & {
    id: string;
    sum: number;
    date: string;
  };
export type ApiCardByIdTrafficPatchResponse = ApiCardPostResponse;

// === CLOSE ↴

export type ApiCloseCardByIdPatchBody = ApiHighLevelUserParams & { id: string; date: string };
export type ApiCloseCardByIdPatchResponse = ApiResponse<
  Omit<ApiCard, 'user' | 'service' | 'pipes'>
>;
