import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import {
  ApiInviteByIdGetQuery,
  ApiInviteByIdGetResponse,
  ApiInviteByIdPatchBody,
  ApiInviteByIdPatchResponse,
  ApiInvitePostBody,
  ApiInvitePostResponse,
  ApiInvitesGetQuery,
  ApiInvitesGetResponse,
} from '@/services/invites/invites.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

type EntityId = string | number;

const baseUrl = '/invites';
const serviceTag = servicesTags.invites;

const invitesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getInvites: build.query<ApiInvitesGetResponse, ApiInvitesGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getInviteByHash: build.query<ApiInviteByIdGetResponse, ApiInviteByIdGetQuery>({
      query: ({ hash }) => `${baseUrl}/findByHash/${hash}`,
      providesTags: providesListItem(serviceTag),
    }),

    createInvite: build.mutation<ApiInvitePostResponse, ApiInvitePostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateInviteById: build.mutation<ApiInviteByIdPatchResponse, ApiInviteByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    deleteInviteById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const {
  useGetInvitesQuery,
  useLazyGetInviteByHashQuery,
  useCreateInviteMutation,
  useUpdateInviteByIdMutation,
  useDeleteInviteByIdMutation,
} = invitesApi;

export default invitesApi;
