import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import { ApiUserRole } from '@/services/auth/auth.types';

const title: OnboardingSteps[0]['title'] = 'caps.title';

export const onboardingCapsPage = [
  {
    title,
    target: 'page-caps',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'caps.step1.title',
      message: 'caps.step1.message',
    },
  },
  {
    title,
    roleList: [
      ApiUserRole.LEAD_USER,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.OWNER,
    ],
    target: 'step-caps',
    content: {
      title: 'caps.step2.title',
      message: 'caps.step2.message',
    },
  },
] as const satisfies OnboardingSteps;
