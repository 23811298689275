import { EntityId } from '@reduxjs/toolkit';

import {
  ApiEntityWithCurrency,
  ApiHighLevelUserParams,
  ApiOperationWithCurrency,
  ApiPaginationParams,
  ApiResponse,
  ApiResponseWithPagination,
  Void,
} from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiCard } from '@/services/cards/cards.types';
import { ArchiveEnum } from '@/filters/useArchivedFilter';

export type ApiService = ApiEntityWithCurrency & {
  id: string;
  balance: number;
  isArchived: boolean;

  users: ApiUser[];
  cards: ApiCard[];

  name: string;
  service: string;
  cardPrice: number;
  commissionReturnFunds: number;
  commissionReplenishmentCard: number;
  commissionReplenishmentService: number;

  apiIntegrationName?: IntegrationService;
  apiIntegrationKey?: string;
};

export enum IntegrationService {
  BROCARD = 'brocard',
}

// === READ ↴

export type ApiServicesGetQuery = Void<
  ApiPaginationParams &
    ApiHighLevelUserParams & {
      userId?: string;
      commandId?: string;
      isArchived?: ArchiveEnum;
    }
>;
export type ApiServicesGetResponse = ApiResponseWithPagination<ApiService>;

// === CREATE ↴

export type ApiServicePostBody = Pick<
  ApiService,
  | 'name'
  | 'service'
  | 'cardPrice'
  | 'commissionReturnFunds'
  | 'commissionReplenishmentCard'
  | 'commissionReplenishmentService'
> &
  ApiOperationWithCurrency & {
    userIds: string[];
    commandId?: string;
    apiIntegration?: Pick<ApiService, 'apiIntegrationKey' | 'apiIntegrationName'>;
  };
export type ApiServicePostResponse = ApiResponse<ApiService>;

// === UPDATE ↴

export type ApiServiceByIdPatchBody = ApiServicePostBody & { id: string };
export type ApiServiceByIdPatchResponse = ApiServicePostResponse;
export type ApiServiceSyncByIdBody = { id: EntityId };

// === REPLENISHMENT ↴

export type ApiServiceByIdReplenishmentPatchBody = ApiHighLevelUserParams &
  ApiOperationWithCurrency & {
    id: string;
    sum: number;
    date: string;
    userId?: string;
  };
export type ApiServiceByIdReplenishmentPatchResponse = ApiServicePostResponse;
