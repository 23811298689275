export enum FormModalOfferUpdateHoldValues {
  DATE = 'date',
  HOLD = 'hold',
  REASON = 'reason',
}

export interface FormModalOfferUpdateHoldData {
  [FormModalOfferUpdateHoldValues.HOLD]: string;
  [FormModalOfferUpdateHoldValues.DATE]: string;
  [FormModalOfferUpdateHoldValues.REASON]: string;
}

export enum ApiReportType {
  PAYOUT = 'payout',
  REJECTED = 'rejected',
}
