import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import Typography from '@/components/Typography';
import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import Popper from '@/components/Popper/Popper';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import OperationsInfo from '@/components/OperationsInfo';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';

import { onboardingOperationPage } from '@/resources/onboarding/operationPage';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import Formatter from '@/utils/Formatter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import usePagination from '@/hooks/usePagination';
import useLocalizeOperationName from '@/hooks/useLocalizeOperationName';
import useLocalizeOperationCategory from '@/hooks/useLocalizeOperationCategory';
import useDownloadFile from '@/hooks/useDownloadFile';

import {
  ApiOperation,
  ApiOperationCategory,
  ApiOperationCorrectType,
  ApiTransactionName,
} from '@/services/operations/operations.types';
import {
  useDeleteOperationMutation,
  useGetOperationsQuery,
} from '@/services/operations/operations';
import ModalExpenseOperation from '@/modals/ModalExpenseOperation/ModalExpenseOperation';
import ModalCorrectOperation from '@/modals/ModalCorrectOperation/ModalCorrectOperation';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useFilterOperationCategory from '@/filters/useOperationCategoryFilter';
import useFromToFilter from '@/filters/useFromToFilter';
import FilterFromTo from '@/filters/FilterFromTo';

function isAbleToDelete(item: ApiOperation) {
  return item.category === ApiOperationCategory.OTHER;
}

const OperationsPage = () => {
  const { t } = useTranslation(['common', 'operations']);

  const { handleDownloadFile } = useDownloadFile();
  const { localizeOperationName } = useLocalizeOperationName();
  const { localizeOperationCategory } = useLocalizeOperationCategory();

  const { isMaster, isHighLevelCommandUser } = useUserInfo();
  const { commandId } = useUserCommand();
  const { to, from } = useFromToFilter();
  const { category, OperationCategoryFilter } = useFilterOperationCategory();

  const [deleteOperation] = useDeleteOperationMutation();
  const { operations, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'operations',
    useQuery: useGetOperationsQuery,
    queryArg: {
      to,
      from,
      category,
      commandId,
    },
  });

  const openCreateExpenseOperationModal = () => {
    ModalExpenseOperation.show();
  };

  const openCorrectOperationModal = (operation: ApiOperation) => () => {
    ModalCorrectOperation.show(operation);
  };

  const generateChildrenConfig = useCallback(
    (operation: ApiOperation) => {
      // TODO: требуется оптимизация. Сейчас 2 цикла вместо 1

      const columns = operation.transactions.map((a) => {
        let color: string | undefined = undefined;
        const isDistributeCondition = !isMaster && a.isDistribution;

        if (a.transactionType && !isDistributeCondition && !a.isInformation) {
          color = a.transactionType === ApiOperationCorrectType.buy ? 'red' : 'green';
        }

        let reason = '';
        if (
          (a.name === ApiTransactionName.CORRECT_OPERATION ||
            a.name === ApiTransactionName.CORRECT_CONSUMABLE_BUY) &&
          !!a.reason
        ) {
          reason = t('operations:forReason', { reason: a.reason });
        }

        const name = `${t(`operations:transaction.${a.name}`)} ${reason}`;

        return {
          id: a.id,
          data: [
            {
              label: t('common:tableLabels.date'),
              value: Formatter.formatDate(a.date),
            },
            {
              label: t('common:tableLabels.sum'),
              value: (
                <span style={{ color }}>{formatNumber(a.currencySum, a.currency?.title)}</span>
              ),
            },
            {
              label: `${t('common:tableLabels.sum')} ${t('common:in')} $`,
              value: <span style={{ color }}>{formatNumber(a.sum)}</span>,
            },
            {
              label: t('common:tableLabels.title'),
              value: name || '-',
            },
            {
              label: t('common:roles.user'),
              value: getUserNickname(a.user),
            },
          ],
        };
      });

      for (let i = 0; i < operation.transactions.length; i++) {
        if (operation.category === ApiOperationCategory.CARD_REPLENISHMENT) {
          const a = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.card'),
            value: a.cardDescription?.card?.card || '',
          });
        }

        if (operation.category === ApiOperationCategory.PIPE_CONSUMABLE) {
          const a = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.pipe'),
            value: a.isInformation ? '-' : a.pipeConsumableDescription?.pipe?.name || '',
          });

          columns[i].data.push({
            label: t('common:tableLabels.consumables'),
            value: a.isInformation
              ? '-'
              : a.pipeConsumableDescription?.pipeConsumable?.userConsumable?.consumable?.name || '',
          });

          columns[i].data.push({
            label: t('common:tableLabels.quantity'),
            value: a.isInformation
              ? '-'
              : `${a.pipeConsumableDescription?.amount || 0} ${t('common:pieces')}`,
          });
        }

        if (operation.category === ApiOperationCategory.TRAFFIC) {
          const a = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.card'),
            value: a.trafficDescription?.card?.card || '',
          });

          columns[i].data.push({
            label: t('common:tableLabels.pipe'),
            value: a.trafficDescription?.pipe?.name || '',
          });
        }

        if (
          operation.category === ApiOperationCategory.CARD_CLOSE ||
          operation.category === ApiOperationCategory.SERVICE_REPLENISHMENT
        ) {
          const transaction = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.serviceReplenishment'),
            value: transaction.serviceDescription?.service?.service || '',
          });
        }

        if (operation.category === ApiOperationCategory.CARD_BUYING) {
          const transaction = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.serviceReplenishment'),
            // eslint-disable-next-line max-len
            // todo: тут кривой тайпскрипт - может быть кейс когда serviceCardDescription пустой (выше видно что эту же проблему решили аналогично - добавили optional chaining)
            value: transaction.serviceCardDescription?.service?.service || '',
          });
        }

        if (operation.category === ApiOperationCategory.OFFER_INCOME) {
          const transaction = operation.transactions[i];

          columns[i].data.push({
            label: t('common:tableLabels.offerName'),
            value: transaction.offerDescription?.offer?.name || '',
          });
        }
      }

      return columns;
    },
    [isMaster, t],
  );

  const onDelete = useCallback(
    (item: ApiOperation) => {
      ModalConfirm.show({
        onlyContinue: true,
        continueLabel: t('buttonActions.delete'),
        title: t('notifications.confirmActionOnPage'),
        subTitle: t('operations:confirmDelete', { name: item.name }),
        onContinue: () => deleteOperation({ commandId: commandId!, id: item.id }),
      });
    },
    [commandId, deleteOperation, t],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiOperation[] = operations?.list || [];

    return list.map((item) => {
      const isExternal = Boolean(item.externalId);
      const validCategory = ![
        ApiOperationCategory.CARD_CLOSE,
        ApiOperationCategory.OFFER_INCOME,
        ApiOperationCategory.PIPE_CONSUMABLE,
      ].includes(item.category);
      const actionProps: TableRowActionsProps = {
        isComment: true,
        callout: item.comment,
        buttonsConfig: [
          {
            className: onboardingOperationPage[3].target,
            textForButton: t('common:buttonActions.correct'),
            onClick: !isExternal && validCategory && openCorrectOperationModal(item),
          },
        ],
      };
      if (isHighLevelCommandUser) {
        actionProps.onDelete = isAbleToDelete(item) && (() => onDelete(item));
      }
      return {
        id: item.id,
        childrenConfig: generateChildrenConfig(item),
        data: [
          {
            label: t('common:tableLabels.date'),
            value: Formatter.formatDate(item.date),
          },
          {
            label: t('common:tableLabels.category'),
            value: localizeOperationCategory(item.category),
          },
          {
            label: t('common:tableLabels.name'),
            value: localizeOperationName(item),
          },
          {
            label: t('common:tableLabels.sum'),
            value: formatNumber(item.currencySum, item.currency?.title),
          },
          {
            label: `${t('common:tableLabels.sum')} ${t('common:in')} $`,
            value: formatNumber(item.sum),
          },
          {
            label: t('common:tableLabels.receipt'),
            value: !item.receipt ? (
              '-'
            ) : (
              <Popper text={item.receipt.originalName}>
                <Typography
                  color='#2374EE'
                  onClick={() => handleDownloadFile(item.receipt)}
                  variant='body2'
                >
                  {t('common:buttonActions.download')}
                </Typography>
              </Popper>
            ),
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
            width: '216px',
          },
        ],
      };
    });
  }, [
    operations?.list,
    t,
    isHighLevelCommandUser,
    generateChildrenConfig,
    localizeOperationCategory,
    localizeOperationName,
    onDelete,
    handleDownloadFile,
  ]);

  return (
    <>
      <PageTitle
        onboardingSteps={onboardingOperationPage}
        deleteCardsInTables={config.length === 0}
      >
        {t('common:sidebar.operations')}
      </PageTitle>

      <PageHeaderContent
        label={t('common:tableLabels.operation')}
        onClick={openCreateExpenseOperationModal}
        adviseTarget={onboardingOperationPage[1].target}
      />

      <FiltersWithSidebar hasFilterFromTo>
        <FilterFromTo isClearable />
        <OperationCategoryFilter disableAutofill />
      </FiltersWithSidebar>

      {Boolean(config.length) && <OperationsInfo />}

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(operations?.meta)}
        adviseKeyForTableRow={onboardingOperationPage[2].target}
      />
    </>
  );
};

export default OperationsPage;
