/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconBell = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3466 16.3363C13.3466 13.2414 15.8875 10.75 19 10.75C22.1125 10.75 24.6534 13.2414 24.6534 16.3363V19.0079C24.6638 19.0203 24.6745 19.0331 24.6855 19.0463C24.8146 19.201 24.991 19.4155 25.18 19.6546C25.5417 20.1119 26.0042 20.7264 26.232 21.1758C26.4925 21.6897 26.6522 22.5025 26.2843 23.2572C25.8896 24.0671 25.0174 24.5593 23.7263 24.6442L23.7017 24.6458H22.0791C21.7341 26.1429 20.3782 27.25 18.7737 27.25C17.1692 27.25 15.8133 26.1429 15.4683 24.6458H14.2983L14.2737 24.6442C12.9826 24.5593 12.1105 24.0671 11.7157 23.2572C11.3478 22.5025 11.5075 21.6897 11.7681 21.1758C11.9959 20.7264 12.4583 20.1119 12.82 19.6546C13.009 19.4155 13.1854 19.201 13.3145 19.0463C13.3256 19.0331 13.3363 19.0203 13.3466 19.0079V16.3363ZM14.6647 19.7724L14.6619 19.7757L14.6519 19.7873L14.6124 19.8337C14.5778 19.8744 14.5277 19.9337 14.4662 20.0074C14.343 20.155 14.1753 20.359 13.9965 20.585C13.6227 21.0578 13.2553 21.5593 13.106 21.854C12.9893 22.0841 12.9604 22.3873 13.064 22.6C13.1401 22.756 13.3959 23.0782 14.3485 23.1458H23.6515C24.6041 23.0782 24.8599 22.756 24.936 22.6C25.0396 22.3873 25.0107 22.0841 24.8941 21.854C24.7447 21.5593 24.3773 21.0578 24.0035 20.585C23.8248 20.359 23.657 20.155 23.5338 20.0074C23.4724 19.9337 23.4222 19.8744 23.3877 19.8337L23.3481 19.7873L23.3382 19.7757L23.3353 19.7724L23.1534 19.5613V16.3363C23.1534 14.0892 21.3036 12.25 19 12.25C16.6964 12.25 14.8466 14.0892 14.8466 16.3363V19.5613L14.6647 19.7724ZM17.0448 24.6458C17.3371 25.2929 17.9963 25.75 18.7737 25.75C19.5511 25.75 20.2103 25.2929 20.5026 24.6458H17.0448Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconBell;
