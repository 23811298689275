import { useCallback } from 'react';

import useUserInfo from '@/hooks/useUserInfo';

import { ApiUser } from '@/services/users/users.types';
import { ApiUserRole } from '@/services/auth/auth.types';

const useCheckingAccessToArchiving = (isCompanyPage = false) => {
  const { isOwner, isCompanyFinancier, userInfo } = useUserInfo();

  return useCallback(
    (user: ApiUser): boolean => {
      const isMe = user.id === userInfo?.id;

      if (isMe) return false;
      if (!isCompanyPage || isOwner) return true;
      if (isCompanyFinancier) return user.role !== ApiUserRole.OWNER;

      return user.command?.id === userInfo?.command?.id;
    },
    [isCompanyFinancier, isCompanyPage, isOwner, userInfo?.command?.id, userInfo?.id],
  );
};

export default useCheckingAccessToArchiving;
