import useQueryParam from '@/hooks/useQueryParam';

const useMasterFilter = <T extends boolean = false>(key = '', isMultiple: T = false as T) => {
  const queryName = `masterId${key}`;
  const [masterId, setMasterId] = useQueryParam(queryName, '');

  return {
    setMasterId,
    masterId: (isMultiple
      ? masterId
      : (masterId as string) || undefined) as unknown as T extends true ? string[] : string,
  };
};

export default useMasterFilter;
