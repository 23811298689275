import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { FocusEvent, useCallback } from 'react';

import Input from '@/components/Input';

import useFormValidation from '@/hooks/useFormValidation';

import { FormElementInputProps } from '@/types/form';

const FormElementInput = ({
  name,
  rules,
  hideHelperText = false,
  description: propsDescription,
  component: _,
  ...restProps
}: FormElementInputProps) => {
  const { control } = useFormContext();
  const { fieldValidate } = useFormValidation(rules);

  const render: ControllerProps['render'] = useCallback(
    ({ field: { ref, ...field }, fieldState }) => {
      const value = field.value || '';
      const { isError, description: validateDescription } = fieldValidate(fieldState.error);

      const description = !hideHelperText ? validateDescription || propsDescription : undefined;

      const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        const cleanedValue = event.target.value.trim();
        field.onChange(cleanedValue);
        field.onBlur();
      };

      return (
        <Input
          {...restProps}
          {...field}
          fullWidth
          value={value}
          inputRef={ref}
          onBlur={handleOnBlur}
          error={isError}
          helperText={description}
        />
      );
    },
    [fieldValidate, hideHelperText, propsDescription, restProps],
  );

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementInput;
