import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import IconWallet from '@/icons/IconWallet';
import IconTwoProfiles from '@/icons/IconTwoProfiles';
import IconTrendUp from '@/icons/IconTrendUp';
import IconTransferCart from '@/icons/IconTransferCart';
import IconTextFile from '@/icons/IconTextFile';
import IconProfilePlus from '@/icons/IconProfilePlus';
import IconMagnet from '@/icons/IconMagnet';
import IconFacebook from '@/icons/IconFacebook';
import IconDownload from '@/icons/IconDownload';
import IconCopy from '@/icons/IconCopy';
import IconCard from '@/icons/IconCard';
import IconBlankText from '@/icons/IconBlankText';
import IconBell from '@/icons/IconBell';

import routes from '@/resources/routes';
import { onboardingServicePage } from '@/resources/onboarding/servicePage';
import { onboardingPipesPage } from '@/resources/onboarding/pipesPage';
import { onboardingOperationPage } from '@/resources/onboarding/operationPage';
import { onboardingOffersPage } from '@/resources/onboarding/offersPage';
import { onboardingFacebookPage } from '@/resources/onboarding/facebookPage';
import { onboardingDashboardPage } from '@/resources/onboarding/dashboardPage';
import { onboardingConsumablesPage } from '@/resources/onboarding/consumablesPage';
import { onboardingConsumableCategoriesPage } from '@/resources/onboarding/consumableCategoriesPage';
import { onboardingCompanyPage } from '@/resources/onboarding/companyPage';
import { onboardingCommandsPage } from '@/resources/onboarding/commandsPage';
import { onboardingCardsPage } from '@/resources/onboarding/cardsPage';
import { onboardingCapsPage } from '@/resources/onboarding/capsPage';

import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useClosedFacebookPageState from '@/hooks/useFacebookPageState';

import { SidebarRoutingHookResponse, SidebarRoutingItem } from '@/types/hooks';
import { selectCommand } from '@/redux/user/userGetters';

const useSidebarRouting = (): SidebarRoutingHookResponse => {
  const { t } = useTranslation();

  const { selfhost } = useUserCompanyInfo();
  const { isLeader, isOwner, isMaster, isMultipleAccessToCommandUser } = useUserInfo();

  const { commandId: multipleCommandId } = useSelector(selectCommand);

  const isClosedFacebookPage = useClosedFacebookPageState();

  // TODO вынести отдельно
  const isClosedLeadsPage = !selfhost?.leadsUrl;

  return useMemo<SidebarRoutingHookResponse>(() => {
    const managementDefaultConfig: SidebarRoutingItem = {
      label: t('sidebar.management'),
      items: [
        isOwner && {
          name: t('sidebar.commands'),
          path: routes.commands.link,
          adviseKey: onboardingCommandsPage[0].target,
          icon: <IconTwoProfiles />,
        },
      ].filter(filterBoolean),
    };

    const analyticsConfig: SidebarRoutingItem = {
      label: t('sidebar.analytics'),
      items: [
        {
          name: t('sidebar.dashboard'),
          path: routes.dashboards.link,
          adviseKey: onboardingDashboardPage[0].target,
          icon: <IconTrendUp />,
        },
        !isClosedFacebookPage && {
          name: t('sidebar.facebook'),
          path: routes.facebook.link,
          adviseKey: onboardingFacebookPage[0].target,
          icon: <IconFacebook />,
        },
        {
          name: t('sidebar.offers'),
          path: routes.offers.link,
          adviseKey: onboardingOffersPage[0].target,
          icon: <IconBlankText />,
        },
        !isClosedLeadsPage && {
          name: t('sidebar.leads'),
          path: routes.leads.link,
          adviseKey: undefined,
          icon: <IconMagnet />,
        },
      ].filter(filterBoolean),
    };

    const managementConfig: SidebarRoutingItem = {
      label: managementDefaultConfig.label,
      items: [
        ...managementDefaultConfig.items,
        isLeader && {
          name: t('sidebar.employees'),
          path: routes.commandById.link(),
          adviseKey: undefined,
          icon: <IconTwoProfiles />,
        },
        !isMaster && {
          name: t('sidebar.allEmployees'),
          path: routes.company.link,
          adviseKey: onboardingCompanyPage[0].target,
          icon: <IconProfilePlus />,
        },
        {
          name: t('sidebar.caps'),
          path: routes.caps.link,
          adviseKey: onboardingCapsPage[0].target,
          icon: <IconBell />,
        },
      ].filter(filterBoolean),
    };

    const financeConfig: SidebarRoutingItem = {
      label: t('sidebar.finance'),
      items: [
        {
          name: t('sidebar.pipes'),
          path: routes.pipes.link,
          adviseKey: onboardingPipesPage[0].target,
          icon: <IconDownload />,
        },
        {
          name: t('sidebar.consumables'),
          path: routes.consumables.link,
          adviseKey: onboardingConsumablesPage[0].target,
          icon: <IconTransferCart />,
        },
        (isOwner || isLeader) && {
          name: t('sidebar.consumableCategories'),
          path: routes.consumableCategories.link,
          adviseKey: onboardingConsumableCategoriesPage[0].target,
          icon: <IconCopy />,
        },
        {
          name: t('sidebar.operations'),
          path: routes.operations.link,
          adviseKey: onboardingOperationPage[0].target,
          icon: <IconTextFile />,
        },
        {
          name: t('sidebar.services'),
          path: routes.services.link,
          adviseKey: onboardingServicePage[0].target,
          icon: <IconWallet />,
        },
        {
          name: t('sidebar.cards'),
          path: routes.cards.link,
          adviseKey: onboardingCardsPage[0].target,
          icon: <IconCard />,
        },
      ].filter(filterBoolean),
    };

    if (isMultipleAccessToCommandUser && !multipleCommandId) return [managementDefaultConfig];

    return [analyticsConfig, managementConfig, financeConfig];
  }, [
    isClosedFacebookPage,
    isClosedLeadsPage,
    isLeader,
    isMaster,
    isMultipleAccessToCommandUser,
    isOwner,
    multipleCommandId,
    t,
  ]);
};

export default useSidebarRouting;
