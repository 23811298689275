import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Checkbox } from '@mui/material';

import { OffersPageCheckbox } from '@/pages/OffersPage/OffersPageStyled';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig, TableRowConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import Select from '@/components/Select';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';
import Button from '@/components/Button';

import IconRefresh from '@/icons/IconRefresh';
import IconPlus from '@/icons/IconPlus';

import { onboardingOffersPage } from '@/resources/onboarding/offersPage';
import { excludedLabels } from '@/resources/constantsTableSettings';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import filterBoolean from '@/utils/filterBoolean';
import Formatter from '@/utils/Formatter';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import usePagination from '@/hooks/usePagination';
import useLocalizeOfferStatus from '@/hooks/useLocalizeOfferStatus';
import useColumnVisibility from '@/hooks/useColumnVisibility';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { TableWithSettings } from '@/types/table';
import { ApiReport, ApiReportType } from '@/services/reports/reports.types';
import { ApiOffer, ApiOfferStatus } from '@/services/offers/offers.types';
import {
  useGetOffersQuery,
  useUpdateOfferStatusByIdMutation,
  useChangeOfferArchiveStatusMutation,
  useSynchronizationAllOffersFromTrackerMutation,
} from '@/services/offers/offers';
import { ApiCompanyTracker } from '@/services/companies/companies.types';
import ModalSettingTableColumns from '@/modals/ModalSettingTableColumns/ModalSettingTableColumns';
import ModalOfferReportUpdateHold from '@/modals/ModalOfferReportUpdateHold/ModalOfferReportUpdateHold';
import ModalOfferReport from '@/modals/ModalOfferReport/ModalOfferReport';
import ModalOfferFromTracker from '@/modals/ModalOfferFromTracker/ModalOfferFromTracker';
import { offerGeoOptions } from '@/modals/ModalOffer/resources';
import ModalOffer from '@/modals/ModalOffer/ModalOffer';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useOffersStatusFilter, { getOfferStatusOptions } from '@/filters/useOfferStatusFilter';
import useOfferPartnersFilter from '@/filters/useOfferPartnersFilter';
import useOfferInformationFilter, { OfferInformation } from '@/filters/useOfferInformationFilter';
import useOffersGeoFilter from '@/filters/useOfferGeoFilter';
import useFromToFilter from '@/filters/useFromToFilter';
import FilterFromTo from '@/filters/FilterFromTo';
import FilterArchive from '@/filters/FilterArchive';

const OffersPage = () => {
  const { t } = useTranslation(['common', 'offer']);
  const [allTimeFlag, setAllTimeFlag] = useState<boolean>(false);

  const { localizeOfferStatus } = useLocalizeOfferStatus();
  const offerStatusOptions = getOfferStatusOptions(localizeOfferStatus);

  const { status, OffersStatusFilter } = useOffersStatusFilter(offerStatusOptions);
  const { geo, OffersGeoFilter } = useOffersGeoFilter();
  const { partner, OfferPartnersFilter } = useOfferPartnersFilter();

  const { isMaster, userInfo, trackerInfo } = useUserInfo();
  const { checkColumnVisibility } = useColumnVisibility(TableWithSettings.OFFERS);

  const { information, OperationCategoryFilter } = useOfferInformationFilter(isMaster);

  const { commandId } = useUserCommand();
  const { to, from, setFrom } = useFromToFilter();

  const [changeArchiveStatus] = useChangeOfferArchiveStatusMutation();
  const { isArchived, prepareArchiveColumns } = useArchiveHelpers(changeArchiveStatus, (name) =>
    t('offer:confirmArchive', { name }),
  );

  const { offers, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'offers',
    useQuery: useGetOffersQuery,
    queryArg: {
      to,
      geo,
      from,
      status,
      partner,
      commandId,
      isArchived,
    },
  });
  const [updateOfferStatus] = useUpdateOfferStatusByIdMutation();
  const [synchronizationAllOffersFromTracker, { isLoading: isSyncLoading }] =
    useSynchronizationAllOffersFromTrackerMutation();

  const openCreateOfferModal = () => {
    ModalOffer.show();
  };

  const handleEditOfferModal = (offer: ApiOffer) => () => {
    ModalOffer.show(offer);
  };

  const openAddOfferFromTrackerModal = () => {
    ModalOfferFromTracker.show();
  };

  const openCreateReportModal = (offer: ApiOffer) => () => {
    ModalOfferReport.show(offer);
  };

  const openUpdateHoldModal = (report: ApiReport) => () => {
    ModalOfferReportUpdateHold.show(report);
  };

  const openSettingTableColumnsModal = () => {
    ModalSettingTableColumns.show({
      tableName: TableWithSettings.OFFERS,
    });
  };

  const onOffersFromTrackerSynchronization = async () => {
    const response = await synchronizationAllOffersFromTracker({ commandId });

    if ('data' in response) {
      const minutes = trackerInfo?.service === ApiCompanyTracker.BINOM ? 30 : 5;
      Alerter.show(t('offer:synchronized', { minutes }), AlerterTypes.success);
    }
  };

  const handleOfferStatusChange = useCallback(
    (id: string) => (status: string) => {
      const typedStatus = status as ApiOfferStatus;

      if (typedStatus !== ApiOfferStatus.CLOSED) {
        updateOfferStatus({ id, status: typedStatus, commandId });
        return;
      }

      ModalConfirm.show({
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('offer:confirmCloseOffer'),
        onContinue: () => updateOfferStatus({ id, status: typedStatus, commandId }),
      });
    },
    [commandId, t, updateOfferStatus],
  );

  const generateChildrenConfig = useCallback(
    (offer: ApiOffer) => {
      const typedInformation = information as OfferInformation;

      const isMastersReportsView = typedInformation === OfferInformation.REPORTS;
      const isMastersExpensesView = typedInformation === OfferInformation.MASTERS_EXPENSES;

      if (
        !information ||
        (isMaster && isMastersExpensesView) ||
        (isMastersReportsView && !offer.reports?.length) ||
        (isMastersExpensesView && !offer.pipes?.length)
      ) {
        return undefined;
      }

      if (isMastersExpensesView) {
        return offer.pipes.map((a) => ({
          id: a.id,
          data: [
            {
              label: t('common:roles.user'),
              value: getUserNickname(a.user),
            },
            {
              label: t('common:tableLabels.costs'),
              value: formatNumber(a.costs),
            },
            {
              label: t('common:tableLabels.pipe'),
              value: a.name,
            },
          ],
        }));
      }

      if (isMastersReportsView) {
        return offer.reports.map((a) => ({
          id: a.id,
          data: [
            {
              label: t('common:tableLabels.date'),
              value: Formatter.formatDate(a.date),
            },
            {
              label: t('common:tableLabels.reportType'),
              value:
                a.type === ApiReportType.CONFIRMED ? t('common:confirmed') : t('common:expected'),
            },
            {
              label: t('common:tableLabels.user'),
              value: getUserNickname(a.user),
            },
            {
              label: t('common:tableLabels.deposits'),
              value: a.deposits,
            },
            {
              label: t('common:tableLabels.registrations'),
              value: a.registrations,
            },
            {
              label: t('common:tableLabels.installations'),
              value: a.installations,
            },
            {
              label: t('common:tableLabels.hold'),
              value: a.hold + '%',
            },
            {
              label: t('common:tableLabels.rejected'),
              value: a.rejected + '%',
            },
            {
              label: '',
              value: (
                <TableRowActions
                  buttonsConfig={[
                    {
                      textForButton: t('common:buttonActions.correction'),
                      onClick: a.hold > 0 && openUpdateHoldModal(a),
                    },
                  ]}
                />
              ),
            },
          ],
        }));
      }
    },
    [information, isMaster, t],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiOffer[] = offers?.list || [];

    const getCalloutMessages = (offer: ApiOffer) =>
      [
        !offer.geo && t('offer:calloutForProblemOffer.geo'),
        !offer.paymentAmount && t('offer:calloutForProblemOffer.paymentAmount'),
        !offer.partner && t('offer:calloutForProblemOffer.partner'),
      ].filter(filterBoolean);

    const isShowInfoColumn = list.some((a) => Boolean(getCalloutMessages(a).length));

    return list
      .filter((el) => !(el.isArchived && isArchived === 'false'))
      .map((a) => {
        const isClosed = a.status === ApiOfferStatus.CLOSED;

        const actionProps: TableRowActionsProps = {
          isComment: true,
          isIconEdit: true,
          callout: a.comment,
        };

        if (!isMaster) {
          actionProps.onEdit = !isClosed && handleEditOfferModal(a);
          actionProps.buttonsConfig = [
            {
              textForButton: t('common:tableLabels.report'),
              onClick: openCreateReportModal(a),
              startIcon: <IconPlus />,
            },
          ];
        }

        prepareArchiveColumns(a, actionProps);

        const calloutMessages = getCalloutMessages(a);

        const result: TableRowConfig = {
          id: a.id,
          childrenConfig: generateChildrenConfig(a),
          data: [
            isShowInfoColumn && {
              label: 'ActionsInfo',
              isHiddenLabel: true,
              value: (
                <TableRowActions
                  callout={
                    Boolean(calloutMessages.length) &&
                    `${t('offer:calloutForProblemOffer.p1')}\n ${calloutMessages.join('\n')} \n ${t(
                      'offer:calloutForProblemOffer.p5',
                    )}`
                  }
                  calloutType='error'
                  calloutWidth={340}
                />
              ),
            },
            {
              columnId: 'name',
              label: t('common:tableLabels.name'),
              value: a.name || '-',
            },
            {
              columnId: 'partner',
              label: t('common:tableLabels.partner'),
              value: a.partner || '-',
            },
            {
              columnId: 'platform',
              label: t('common:tableLabels.platform'),
              value: a.platform || '-',
            },
            {
              columnId: 'geo',
              label: t('common:tableLabels.geo'),
              value: offerGeoOptions.find(({ value }) => value === a.geo)?.label || '-',
            },
            {
              columnId: 'payment',
              label: t('common:tableLabels.payment'),
              value: `${a.paymentModel.toUpperCase()}, ${formatNumber(a.paymentAmount)}`,
            },
            {
              columnId: 'status',
              label: t('common:tableLabels.status'),
              value: (
                <Select
                  label={t('common:tableLabels.status')}
                  size='small'
                  width='168px'
                  value={a.status}
                  disabled={isMaster || a.isArchived}
                  options={offerStatusOptions}
                  onSelect={handleOfferStatusChange(a.id)}
                />
              ),
            },
            {
              columnId: 'costs',
              label: t('common:tableLabels.costs'),
              value: formatNumber(a.costs),
            },
            {
              columnId: 'confirmedIncome',
              label: t('offer:category.confirmedIncome'),
              value: formatNumber(a.confirmedIncome),
            },
            {
              columnId: 'expectedIncome',
              label: t('offer:category.expectedIncome'),
              value: formatNumber(a.expectedIncome),
            },
            {
              columnId: 'confirmedRoi',
              label: t('offer:category.confirmedRoi'),
              value: a.confirmedRoi + '%',
            },
            {
              columnId: 'confirmedRomi',
              label: t('offer:category.confirmedRomi'),
              value: a.confirmedRomi + '%',
            },
            {
              columnId: 'expectedRoi',
              label: t('offer:category.expectedRoi'),
              value: a.expectedRoi + '%',
            },
            {
              columnId: 'expectedRomi',
              label: t('offer:category.expectedRomi'),
              value: a.expectedRomi + '%',
            },
            {
              columnId: 'rejected',
              label: t('common:tableLabels.rejected'),
              value: formatNumber(a.rejected),
            },
            {
              columnId: 'hold',
              label: t('common:tableLabels.hold'),
              value: formatNumber(a.hold),
            },
            {
              columnId: 'installations',
              label: t('common:tableLabels.installations'),
              value: a.expectedInstallations,
            },
            {
              columnId: 'confirmedDeposits',
              label: t('common:tableLabels.confirmedDeposits'),
              value: a.confirmedDeposits,
            },
            {
              columnId: 'expectedDeposits',
              label: t('common:tableLabels.expectedDeposits'),
              value: a.expectedDeposits,
            },
            {
              columnId: 'registrations',
              label: t('common:tableLabels.registrations'),
              value: a.expectedRegistrations,
            },
            // {
            //   label: 'Прибыль на конец месяца',
            //   value: formatNumber(a.monthProfit,)
            // },
            {
              columnId: 'expectedProfit',
              label: t('common:tableLabels.expectedProfit'),
              value: formatNumber(a.expectedProfit),
            },
            {
              columnId: 'confirmedProfit',
              label: t('common:tableLabels.confirmedProfit'),
              value: formatNumber(a.confirmedProfit),
            },
            {
              label: 'Actions',
              isHiddenLabel: true,
              value: <TableRowActions {...actionProps} />,
              width: '100px',
            },
          ].filter(filterBoolean),
        };

        result.data = result.data.filter(
          (a) => checkColumnVisibility(String(a.columnId)) || excludedLabels.includes(a.label),
        );

        return result;
      });
  }, [
    offers?.list,
    t,
    isArchived,
    isMaster,
    prepareArchiveColumns,
    generateChildrenConfig,
    offerStatusOptions,
    handleOfferStatusChange,
    checkColumnVisibility,
  ]);

  const handleChangeTimeFlag = () => {
    setAllTimeFlag(!allTimeFlag);

    if (to || from) {
      // NOTE: Обнуление from и to
      setFrom('', undefined, undefined, true);
    }
  };

  return (
    <>
      <PageTitle onboardingSteps={onboardingOffersPage} deleteCardsInTables={!trackerInfo}>
        {t('common:sidebar.offers')}
      </PageTitle>

      {!isMaster && (
        <PageHeaderContent
          onClick={openCreateOfferModal}
          adviseTarget={onboardingOffersPage[2].target}
          label={t('common:tableLabels.offer')}
        >
          {trackerInfo && (
            <Button
              size='large'
              variant='outlined'
              isLoading={isSyncLoading}
              onClick={onOffersFromTrackerSynchronization}
              startIcon={<IconRefresh color='#2374EE' />}
              className={onboardingOffersPage[3].target}
            >
              {t('common:buttonActions.synchronize')}
            </Button>
          )}

          {userInfo?.company.tracker && (
            <Button
              size='large'
              variant='outlined'
              onClick={openAddOfferFromTrackerModal}
              startIcon={<IconPlus color='#2374EE' />}
              className={onboardingOffersPage[1].target}
            >
              {t('offer:addFromTracker')}
            </Button>
          )}
        </PageHeaderContent>
      )}

      <FiltersWithSidebar
        settingsButtonProps={{
          className: onboardingOffersPage[4].target,
          onClick: openSettingTableColumnsModal,
        }}
        hasFilterFromTo
        className={onboardingOffersPage[5].target}
      >
        <FilterFromTo isClearable disabled={allTimeFlag} />
        <OffersPageCheckbox
          label={t('formLabels.allTime')}
          checked={allTimeFlag}
          onChange={handleChangeTimeFlag}
          control={<Checkbox />}
        />
        <OffersStatusFilter disableAutofill />
        <OperationCategoryFilter />
        <FilterArchive />
        <OffersGeoFilter />
        <OfferPartnersFilter disableAutofill isLimitedHeightMenu />
      </FiltersWithSidebar>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(offers?.meta)}
      />
    </>
  );
};

export default OffersPage;
