import { css } from '@mui/material/styles';
import { TableRow, TableCell } from '@mui/material';
import styled from '@emotion/styled';

import transientProps from '@/utils/transientProps';

export const StyledTableRow = styled(TableRow, transientProps)<{
  $isChildren?: boolean;
  $isPointer?: boolean;
  $isCustomChildrenConfig?: boolean;
}>`
  ${({ $isPointer }) =>
    $isPointer &&
    css`
      cursor: pointer;
    `}

  ${({ $isChildren, $isCustomChildrenConfig }) =>
    $isChildren &&
    !$isCustomChildrenConfig &&
    css`
      background-color: white !important;
    `}
`;

export const StyledTableCell = styled(TableCell, transientProps)<{
  $isLastCell?: boolean;
  $isLastRow?: boolean;
  $isChildren?: boolean;
  $maxWidth?: string;
  $minWidth?: string;
  $isCustomChildrenConfig?: boolean;
}>`
  ${({ $isChildren }) =>
    $isChildren &&
    css`
      padding: 6px 16px;
    `}

  ${({ $isCustomChildrenConfig, $isChildren }) =>
    $isChildren &&
    $isCustomChildrenConfig &&
    css`
      padding: 10px 16px;
    `}


  ${({ $isLastCell, $isChildren }) =>
    !$isLastCell &&
    $isChildren &&
    css`
      white-space: nowrap;
    `}

    ${({ $isLastRow, $isChildren }) =>
    !$isLastRow &&
    $isChildren &&
    css`
      border-bottom: 1px solid #eaeef1;
    `}
    
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
`;
