import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'ConsumableWarehouse.title';

export const onboardingConsumablesPage = [
  {
    title,
    target: 'page-consumables',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'ConsumableWarehouse.step1.title',
      message: 'ConsumableWarehouse.step1.message',
    },
  },
  {
    title,
    target: 'step-consumables',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'ConsumableWarehouse.step2.title',
      message: 'ConsumableWarehouse.step2.message',
    },
  },
] as const satisfies OnboardingSteps;
