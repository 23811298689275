import { useMemo } from 'react';

import { statisticCategories } from '@/resources/constantsStatistic';

import useLocalizeStatisticCategory from '@/hooks/useLocalizeStatisticCategory';

const useStatisticCategoriesOptions = () => {
  const { localizeStatisticCategory } = useLocalizeStatisticCategory();

  const statisticCategoriesOptions = useMemo(
    () =>
      statisticCategories.map((value) => ({
        value,
        label: localizeStatisticCategory(value),
      })),
    [localizeStatisticCategory],
  );

  return { statisticCategoriesOptions };
};

export default useStatisticCategoriesOptions;
