import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import assertNever from 'assert-never';
import { useTheme } from '@mui/material';

import { DashboardsFilters } from '@/pages/DashboardsPage/components/DashboardsFilters';
import { PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import StatisticParameters from '@/components/StatisticParameters';
import { StatisticProps } from '@/components/Statistic/StatisticTypes';
import {
  StyledStatistic,
  StyledOptionsWrapper,
  DashboardSettingsWrapper,
  MainSettingsWrapper,
  ParametersWithFilters,
  FiltersWrapper,
  StyledWrapper,
  ViewTableButton,
  DeleteButton,
} from '@/components/Statistic/StatisticStyled';
import Loader from '@/components/Loader';
import LineChart from '@/components/LineChart';
import { IconSize } from '@/components/Icon/IconTypes';

import IconTrash from '@/icons/IconTrash2';
import IconArrow from '@/icons/IconArrow';

import routes from '@/resources/routes';
import { CHART_COLORS_LEFT } from '@/resources/constants';

import { getParametersCount } from '@/utils/dashboard/getParametersCount';

import useUserInfo from '@/hooks/useUserInfo';
import useStatisticData from '@/hooks/useStatisticData';
import { useSettingsDashboardFilters } from '@/hooks/useSettingsDashboardFilters';
import useLocalizeStatisticCategory from '@/hooks/useLocalizeStatisticCategory';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useGetParameterColor from '@/hooks/useGetParameterColor';
import useCommandMasters from '@/hooks/useCommandMasters';

const summation = (values?: (number | undefined)[]) =>
  values?.reduce((a, b) => (a || 0) + (b || 0), 0);

export const Statistic = ({
  settings,
  isDeleting,
  deleteDashboard,
  isSeveralDashboardsItem = false,
}: StatisticProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(['dashboard', 'common']);

  const { isMaster, isLeader, isMultipleAccessToCommandUser } = useUserInfo();
  const { filters, updateFilters, isUpdating } = useSettingsDashboardFilters(settings);

  const { commands } = useHighLevelUserCommands();
  const { masters } = useCommandMasters({ commandId: settings?.commandsIds?.[0] });

  const data = useStatisticData(settings);
  const { getParameterColor } = useGetParameterColor();
  const { localizeStatisticCategory } = useLocalizeStatisticCategory();

  const formattedParameters = useMemo(() => {
    const colors = CHART_COLORS_LEFT;
    const parametersWithCount = getParametersCount(data.data?.statistics || []);

    const getColor = (index: number) => colors[index] || 'black';

    switch (settings.tab) {
      case PossibleTabs.parameters: {
        return settings?.parameters.map((parameter) => {
          return {
            count: parametersWithCount[parameter] || 0,
            name: localizeStatisticCategory(parameter),
            color: getParameterColor(parameter),
          };
        });
      }
      case PossibleTabs.masters: {
        return settings?.usersIds?.map((userId, index) => {
          return {
            count:
              summation(
                data.data?.statistics.map(
                  (stat) =>
                    stat.members.find((m) => m.user.id === userId)?.[settings.parameters[0]],
                ),
              ) || 0,
            name: masters.find((master) => master.value === userId)?.label || '',
            color: getColor(index),
          };
        });
      }
      case PossibleTabs.commands: {
        return settings?.commandsIds?.map((commandId, index) => {
          return {
            count:
              summation(
                data.data?.statistics.map(
                  (stat) =>
                    stat.commands.find((c) => c.command.id === commandId)?.[
                      settings?.parameters[0]
                    ],
                ),
              ) || 0,
            name: commands.find((command) => command.value === commandId)?.label || '',
            color: getColor(index),
          };
        });
      }
    }
  }, [
    commands,
    data.data?.statistics,
    getParameterColor,
    localizeStatisticCategory,
    masters,
    settings?.commandsIds,
    settings.parameters,
    settings.tab,
    settings?.usersIds,
  ]);

  const parametersInfo = useMemo(() => {
    const getEmptyMessage = () => {
      const getTextSelectParameters = (isMultiple: boolean) =>
        t(`dashboard:selectParameter${isMultiple ? 's' : ''}`);
      const getTextSelectUsers = (isMultiple: boolean) =>
        t(`dashboard:selectUser${isMultiple ? 's' : ''}`);
      const getTextSelectCommands = (isMultiple: boolean) =>
        t(`dashboard:selectCommand${isMultiple ? 's' : ''}`);

      const isCommandsIds = Boolean(filters.commands.length);
      const isMastersIds = Boolean(filters.masters.length);
      const isParameters = Boolean(filters.parameters.length);

      switch (settings.tab) {
        case PossibleTabs.parameters: {
          if (!isCommandsIds && isMultipleAccessToCommandUser) return getTextSelectCommands(false);
          if (!isMastersIds && !isMaster) return getTextSelectUsers(true);
          return getTextSelectParameters(true);
        }
        case PossibleTabs.masters: {
          if (!isCommandsIds && isMultipleAccessToCommandUser) return getTextSelectCommands(false);
          if (!isParameters) return getTextSelectParameters(false);
          return getTextSelectUsers(true);
        }
        case PossibleTabs.commands: {
          if (!isParameters) return getTextSelectParameters(false);
          return getTextSelectCommands(true);
        }
      }
    };

    return (
      <StyledOptionsWrapper $hasPadding>
        <StatisticParameters
          parameters={formattedParameters || []}
          emptyMessage={getEmptyMessage()}
          isShowEmptyMessage={!formattedParameters?.length}
        />
      </StyledOptionsWrapper>
    );
  }, [
    settings.tab,
    filters.commands.length,
    filters.masters.length,
    filters.parameters.length,
    formattedParameters,
    isMaster,
    isMultipleAccessToCommandUser,
    t,
  ]);

  // TODO: общая логика повторяется в DashboardFilters
  // можно попробовать вынести
  // Note: жесткая привязка к дизайну,
  // думаю есть способ получше
  const filtersCount = useMemo(() => {
    switch (settings.tab) {
      case PossibleTabs.parameters: {
        if (isMaster) return 1;
        if (isLeader) return 2;
        if (isMultipleAccessToCommandUser) return 3;

        return 0;
      }

      case PossibleTabs.masters: {
        if (isLeader) return 2;
        if (isMultipleAccessToCommandUser) return 3;
        return 0;
      }

      case PossibleTabs.commands:
        return 2;

      default: {
        return assertNever(settings.tab);
      }
    }
  }, [settings.tab, isLeader, isMaster, isMultipleAccessToCommandUser]);

  // TODO: Логика передачи параметров на все дашборды, вернуть
  // const getApplyToAllLayout = useMemo(() => {
  //   // NOTE:
  //   // пока идет отображение текущих выбранных параметров
  //   // потом будет логика сложнее в связке с текущими селектами
  //   if (!settings?.parameters.length) return null;

  //   const mainParams: Partial<ApiDashboardSettingsByTabLocal> = { tab: currentTab };

  //   switch (currentTab) {
  //     case PossibleTabs.parameters: {
  //       mainParams.parameters = filters.parameters;
  //       break;
  //     }
  //     case PossibleTabs.commands: {
  //       mainParams.commandsIds = filters.commands;
  //       break;
  //     }
  //     case PossibleTabs.masters: {
  //       mainParams.usersIds = filters.masters;
  //       break;
  //     }
  //     default: {
  //       assertNever(currentTab);
  //     }
  //   }

  //   return (
  //     <ApplyButtons>
  //       <Typography
  //         onClick={() => updateAllFilters(mainParams)}
  //         variant='body2'
  //         color={palette.primary.main}
  //       >
  //         {t('common:buttonActions.applyForAll')}
  //       </Typography>
  //     </ApplyButtons>
  //   );
  // }, [
  //   currentTab,
  //   filters.commands,
  //   filters.masters,
  //   filters.parameters,
  //   palette.primary.main,
  //   settings?.parameters.length,
  //   t,
  //   updateAllFilters,
  // ]);

  return (
    <StyledStatistic $isItemOfSeveralDashboards={isSeveralDashboardsItem} $isFullWidth>
      {(isDeleting || isUpdating) && <Loader isCenter />}

      <LineChart data={data} isLoading={data.isLoading || isDeleting || isUpdating} />

      {isSeveralDashboardsItem ? (
        <>
          <DashboardSettingsWrapper>
            <MainSettingsWrapper>
              <ParametersWithFilters>
                <StyledWrapper>
                  <FiltersWrapper $filtersCount={filtersCount}>
                    <DashboardsFilters
                      settings={settings}
                      inputSize='small'
                      mastersIds={filters.masters}
                      commandsIds={filters.commands}
                      parameters={filters.parameters}
                      onChangeFilter={updateFilters}
                      isLoading={isUpdating}
                    />
                  </FiltersWrapper>
                  {/* {getApplyToAllLayout} */}
                </StyledWrapper>
                {parametersInfo}
              </ParametersWithFilters>

              <ViewTableButton
                variant='outlined'
                disabled={settings.isLocale}
                href={routes.dashboard.link(settings.id)}
                saveQuery
                endIcon={
                  <IconArrow direction='right' size={IconSize.s} color={palette.primary.main} />
                }
              >
                {t('common:buttonActions.viewTable')}
              </ViewTableButton>
            </MainSettingsWrapper>

            <DeleteButton onClick={deleteDashboard} size='small'>
              <IconTrash color={palette.error.main} size={IconSize.s_32} />
            </DeleteButton>
          </DashboardSettingsWrapper>
        </>
      ) : (
        parametersInfo
      )}
    </StyledStatistic>
  );
};
