import { Dialog, DialogTitle, IconButton } from '@mui/material';

import { ModalProps } from '@/components/Modal/ModalTypes';
import { StyledDialogContent } from '@/components/Modal/ModalStyled';

import IconClose from '@/icons/IconClose';

// TODO: пересмотреть структуру
const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  maxHeight,
  maxWidth = 'sm',
  hasEmptyPadding = false,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>
        {title}
        <IconButton onClick={onClose}>
          <IconClose />
        </IconButton>
      </DialogTitle>

      <StyledDialogContent $maxHeight={maxHeight} $hasEmptyPadding={hasEmptyPadding}>
        {children}
      </StyledDialogContent>
    </Dialog>
  );
};

export default Modal;
