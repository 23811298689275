import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';
import moment from 'moment';
import assertNever from 'assert-never';

import { DashboardSettings, PossibleTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { TableConfig, TableRowConfig, TableRowConfigColumn } from '@/components/Table/TableTypes';

import { PossibleStatisticCategories, statisticCategories } from '@/resources/constantsStatistic';
import { dateInputFormat } from '@/resources/constants';

import filterUnique from '@/utils/filterUnique';
import CorrectMath from '@/utils/CorrectMath';

import useStatisticData from '@/hooks/useStatisticData';
import useLocalizeStatisticCategory from '@/hooks/useLocalizeStatisticCategory';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useGetFormattedStatisticValue from '@/hooks/useGetFormattedStatisticValue';
import useCommandMasters from '@/hooks/useCommandMasters';

import {
  ApiDashboardData,
  ApiDashboardStatistic,
  PossibleStatisticCategoriesDto,
} from '@/services/dashboard/dashboard.types';

type ExcludedApiDashboardKeys = Exclude<keyof ApiDashboardData, PossibleStatisticCategories>;
const excludedKeys: ExcludedApiDashboardKeys[] = ['command', 'date', 'isEmpty', 'members', 'users'];

const isPossibleStatisticCategory = (value: string): value is PossibleStatisticCategories => {
  return (excludedKeys as string[]).indexOf(value) === -1;
};

const useDashboardConfig = (settings: DashboardSettings) => {
  const { t } = useTranslation('dashboard');
  const data = useStatisticData(settings);

  const { commands } = useHighLevelUserCommands();
  const { masters } = useCommandMasters({
    commandId: data.commandsIds?.[0],
  });

  const { localizeStatisticCategory } = useLocalizeStatisticCategory();
  const { getFormattedStatisticValue } = useGetFormattedStatisticValue();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parameters = (data.parameters as PossibleStatisticCategories[]) || [];

  const displayedParameters = useMemo(() => {
    if (settings.tab === PossibleTabs.parameters) {
      return parameters.filter((parameter) =>
        statisticCategories.includes(parameter as PossibleStatisticCategories),
      );
    } else return parameters;
  }, [settings.tab, parameters]);

  const isDisplayStatistics = useCallback(
    (statistic: Partial<PossibleStatisticCategoriesDto & { isEmpty: boolean }>) => {
      if (statistic.isEmpty) return false;
      if (settings.tab === PossibleTabs.parameters)
        return displayedParameters.some(
          (parameter) => statistic[parameter as PossibleStatisticCategories] !== 0,
        );
      return true;
    },
    [settings.tab, displayedParameters],
  );

  // Note: отображение статистики
  const getPreparedStatistics = useCallback(
    (data?: ApiDashboardStatistic) => {
      if (!data || !data.statistics.length) return [];

      const { users, command } = data;

      return data.statistics.filter(isDisplayStatistics).map((a) => ({ ...a, users, command }));
    },
    [isDisplayStatistics],
  );

  // Note: фильтрация по дате
  const getPreparedGroupedData = useCallback((data: ApiDashboardData[]) => {
    const uniqueDays = data.map((a) => a.date).filter(filterUnique);

    return uniqueDays.map((date) => {
      const days = data.filter((a) => a.date === date);

      if (days.length === 1) {
        return days[0];
      }

      return days.reduce((result, current) => {
        for (const key in result) {
          if (isPossibleStatisticCategory(key)) {
            result[key] = CorrectMath.addition(result[key], current[key]);
          }

          if (key === 'members') {
            result.members = [...result.members, ...current.members];
          }
        }

        return result;
      });
    });
  }, []);

  // Note: принимает либо параметр, либо команду, либо мастера
  const getFirstColumn = useCallback(
    (parameter: PossibleStatisticCategories | string): TableRowConfigColumn => {
      const parametersCase = {
        columnId: 'index',
        label: t('parameter'),
        value: localizeStatisticCategory(parameter as PossibleStatisticCategories),
      };

      switch (settings.tab) {
        case PossibleTabs.parameters: {
          return parametersCase;
        }
        case PossibleTabs.masters: {
          return { columnId: 'index', label: t('masters'), value: parameter };
        }
        case PossibleTabs.commands: {
          return {
            columnId: 'index',
            label: t('commands'),
            value: parameter,
          };
        }
        default: {
          return assertNever(settings.tab);
        }
      }
    },
    [localizeStatisticCategory, t, settings.tab],
  );

  const config: TableConfig = useMemo(() => {
    const preparedGroupedData = getPreparedGroupedData(getPreparedStatistics(data.data!));

    // Note: отдает массив выбранных фильтров для отображения в первой колонке таблицы
    const getFilterNames = () => {
      switch (settings.tab) {
        case PossibleTabs.parameters: {
          return parameters.filter((parameter) =>
            statisticCategories.includes(parameter as PossibleStatisticCategories),
          );
        }
        case PossibleTabs.commands: {
          return (
            settings?.commandsIds?.map(
              (id) => commands.find((com) => com.value === id)?.label || '',
            ) || []
          );
        }
        case PossibleTabs.masters: {
          return (
            settings?.usersIds?.map((id) => masters.find((m) => m.value === id)?.label || '') || []
          );
        }
        default: {
          assertNever(settings.tab);
        }
      }
    };

    return getFilterNames().map((filter) => {
      const result: TableRowConfig = {
        id: filter,
        data: [
          getFirstColumn(filter),
          ...preparedGroupedData.map((uniqueData) => {
            const getCurrentValue = () => {
              switch (settings.tab) {
                case PossibleTabs.parameters: {
                  return uniqueData[filter as PossibleStatisticCategories] || 0;
                }
                case PossibleTabs.commands: {
                  return (
                    uniqueData.commands.find(
                      (command) =>
                        command.command.id === commands.find((c) => c.label === filter)?.value,
                    )?.[parameters[0]] || 0
                  );
                }
                case PossibleTabs.masters: {
                  return (
                    uniqueData.members.find(
                      (member) => member.user.firstName === filter.split(' ')[0],
                    )?.[parameters[0]] || 0
                  );
                }
                default: {
                  assertNever(settings.tab);
                }
              }
            };
            return {
              columnId: uniqueData.date,
              label: moment(uniqueData.date).format(dateInputFormat),
              value: getFormattedStatisticValue(filter, getCurrentValue()),
            };
          }),
        ],
      };
      return result;
    });
  }, [
    commands,
    data.data,
    getFirstColumn,
    getFormattedStatisticValue,
    getPreparedGroupedData,
    getPreparedStatistics,
    masters,
    parameters,
    settings?.commandsIds,
    settings.tab,
    settings?.usersIds,
  ]);

  return { config, isLoading: data.isLoading };
};

export default useDashboardConfig;
