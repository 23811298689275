/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconEmptyState = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} customWidth={160} customHeight={160} viewBox='0 0 161 161'>
      <path
        d='M80.5 160.5C124.683 160.5 160.5 124.683 160.5 80.5C160.5 36.3172 124.683 0.5 80.5 0.5C36.3172 0.5 0.5 36.3172 0.5 80.5C0.5 124.683 36.3172 160.5 80.5 160.5Z'
        fill='#EDF1F8'
      />
      <path
        d='M128.5 160.5H32.5V57.0333C37.0248 57.0282 41.3628 55.2285 44.5623 52.029C47.7619 48.8295 49.5616 44.4914 49.5667 39.9666H111.433C111.429 42.2081 111.868 44.4283 112.727 46.4987C113.586 48.5691 114.847 50.4486 116.437 52.0285C118.017 53.619 119.897 54.8803 121.967 55.7394C124.038 56.5985 126.258 57.0383 128.5 57.0333V160.5Z'
        fill='white'
      />
      <path
        d='M80.5 109.3C94.6385 109.3 106.1 97.8385 106.1 83.7C106.1 69.5615 94.6385 58.1 80.5 58.1C66.3615 58.1 54.9 69.5615 54.9 83.7C54.9 97.8385 66.3615 109.3 80.5 109.3Z'
        fill='#DEE5F0'
      />
      <path
        d='M89.551 95.7681L80.5 86.7172L71.449 95.7681L68.432 92.7511L77.483 83.7002L68.432 74.6492L71.449 71.6322L80.5 80.6832L89.551 71.6322L92.568 74.6492L83.517 83.7002L92.568 92.7511L89.551 95.7681Z'
        fill='white'
      />
      <path
        d='M94.3666 115.7H66.6333C64.866 115.7 63.4333 117.133 63.4333 118.9C63.4333 120.667 64.866 122.1 66.6333 122.1H94.3666C96.1339 122.1 97.5666 120.667 97.5666 118.9C97.5666 117.133 96.1339 115.7 94.3666 115.7Z'
        fill='#DEE5F0'
      />
      <path
        d='M103.967 128.5H57.0333C55.266 128.5 53.8333 129.933 53.8333 131.7C53.8333 133.467 55.266 134.9 57.0333 134.9H103.967C105.734 134.9 107.167 133.467 107.167 131.7C107.167 129.933 105.734 128.5 103.967 128.5Z'
        fill='#DEE5F0'
      />
    </Icon>
  );
};

export default IconEmptyState;
