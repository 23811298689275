import { Redirect } from 'react-router-dom';

import ServicesPage from '@/pages/ServicesPage';
import PipesPage from '@/pages/PipesPage';
import OperationsPage from '@/pages/OperationsPage/OperationsPage';
import OffersPage from '@/pages/OffersPage/OffersPage';
import LeadsPage from '@/pages/LeadsPage/LeadsPage';
import FacebookPage from '@/pages/FacebookPage';
import { DashboardsPage } from '@/pages/DashboardsPage/DashboardsPage';
import { DashboardPage } from '@/pages/DashboardPage/DashboardPage';
import ConsumablesPage from '@/pages/ConsumablesPage';
import ConsumableCategoriesPage from '@/pages/ConsumableCategoriesPage/ConsumableCategoriesPage';
import CompanyPage from '@/pages/CompanyPage/CompanyPage';
import CommandsPage from '@/pages/CommandsPage/CommandsPage';
import CommandPage from '@/pages/CommandPage/CommandPage';
import CardsPage from '@/pages/CardsPage';
import CapsPage from '@/pages/CapsPage';

import { ApiUserRole } from '@/services/auth/auth.types';

export enum DictionaryEntityType {
  'stores' = 'stores',
  'services' = 'services',
  'statuses' = 'statuses',
}

const routes = {
  index: {
    path: '/',
    link: '/',
    component: () => <Redirect to={'/dashboards'} />,
    exact: true,
    closeAccess: [],
  },
  dashboards: {
    path: '/dashboards',
    link: '/dashboards',
    component: DashboardsPage,
    exact: true,
    closeAccess: [],
  },
  dashboard: {
    path: '/dashboards/:id',
    link: (id: string) => `/dashboards/${id}`,
    component: DashboardPage,
    exact: true,
    closeAccess: [],
  },
  company: {
    exact: true,
    path: '/company',
    link: '/company',
    component: CompanyPage,
    closeAccess: [ApiUserRole.USER],
  },
  commands: {
    exact: true,
    path: '/commands',
    link: '/commands',
    component: CommandsPage,
    closeAccess: [ApiUserRole.USER, ApiUserRole.LEAD_USER, ApiUserRole.COMMAND_FINANCIER],
  },
  // TODO: доработать, command не доступен овнеру
  commandById: {
    exact: true,
    path: ['/commands/:id', '/command'],
    link: (id?: string | number) => (id ? `/commands/${id}` : '/command'),
    component: CommandPage,
    closeAccess: [ApiUserRole.USER],
  },
  leads: {
    exact: true,
    path: '/leads',
    link: '/leads',
    component: LeadsPage,
    closeAccess: [],
  },
  offers: {
    exact: true,
    path: '/offers',
    link: '/offers',
    component: OffersPage,
    closeAccess: [],
  },
  services: {
    exact: true,
    path: '/services',
    link: '/services',
    component: ServicesPage,
    closeAccess: [],
  },
  operations: {
    exact: true,
    path: '/operations',
    link: '/operations',
    component: OperationsPage,
    closeAccess: [],
  },
  consumableCategories: {
    exact: true,
    path: '/consumablesCategories',
    link: '/consumablesCategories',
    component: ConsumableCategoriesPage,
    closeAccess: [ApiUserRole.USER],
  },
  pipes: {
    exact: true,
    path: '/pipes',
    link: '/pipes',
    component: PipesPage,
    closeAccess: [],
  },
  facebook: {
    exact: true,
    path: '/facebook',
    link: '/facebook',
    component: FacebookPage,
    closeAccess: [],
  },
  cards: {
    exact: true,
    path: '/cards',
    link: '/cards',
    component: CardsPage,
    closeAccess: [],
  },
  consumables: {
    exact: true,
    path: '/consumables',
    link: '/consumables',
    component: ConsumablesPage,
    closeAccess: [],
  },
  caps: {
    exact: true,
    path: '/caps',
    link: '/caps',
    component: CapsPage,
    closeAccess: [],
  },
};

const routesNames = Object.keys(routes);

export type PossibleTablesCache = (typeof routesNames)[number];

export default routes;
