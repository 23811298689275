import { IconButton, styled } from '@mui/material';

export const StyledBackButton = styled('div')`
  &:not(:empty) {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

export const StyledAdditionalContent = styled('div')`
  &:not(:empty) {
    margin-left: ${({ theme }) => theme.spacing(4)};
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledLogoutButton = styled(IconButton)`
  margin-right: 12px;
`;
