import InputMask from 'react-input-mask';
import { ChangeEvent, useMemo } from 'react';
import TextField from '@mui/material/TextField';

import { InputProps } from '@/components/Input/InputTypes';

const Input = ({
  size,
  mask,
  label,
  type,
  disabled,
  isNumber,
  isDecimalNumber,
  isNegativeNumber,
  maxLength,
  maskPlaceholder,
  value = '',
  variant = 'outlined',
  ...restProps
}: InputProps) => {
  const numberProps = useMemo(() => {
    if (isNumber) {
      return {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.replace(/[^\d]/gm, '');
        },
      };
    }

    if (isDecimalNumber) {
      return {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          // TODO: Возможно стоит поискать другое решение и стоит покрыть тестами
          e.target.value = e.target.value.replace(/^\.|[^\d+\\.]/gm, '');

          e.target.value = e.target.value.replace(/\+/gm, '');
          e.target.value = e.target.value.replace(/\.{2,}/gm, '.');
          e.target.value = e.target.value.replace(/(\..*)\./gm, '$1');
          e.target.value = e.target.value.replace(/(\d+\.\d{0,2}).*/gm, '$1');
        },
      };
    }

    if (isNegativeNumber) {
      return {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          e.target.value = e.target.value.replace(/(?!^)-|[^\d+\\-]/gm, '');
        },
      };
    }

    return {};
  }, [isNumber, isDecimalNumber, isNegativeNumber]);

  if (mask) {
    return (
      <InputMask
        {...restProps}
        mask={mask}
        value={String(value)}
        maskPlaceholder={maskPlaceholder}
        // NOTE
        // Пропсы от родительского компонента
        // передаются дочернему без типизации
        // @ts-ignore
        size={size}
        label={label}
        variant='outlined'
        disabled={disabled}
        inputProps={{ maxLength }}
      >
        <TextField fullWidth />
      </InputMask>
    );
  }

  return (
    <TextField
      {...restProps}
      type={type}
      size={size}
      value={value}
      label={label}
      variant={variant}
      disabled={disabled}
      inputProps={{ maxLength, ...numberProps }}
    />
  );
};

export default Input;
