export enum FormModalCardValues {
  CARD = 'card',
  NAME = 'name',
  DATE = 'date',
  USER = 'user',
  SERVICE_ID = 'serviceId',
}

export interface FormModalCardData {
  [FormModalCardValues.DATE]: string;
  [FormModalCardValues.CARD]: string;
  [FormModalCardValues.NAME]: string;
  [FormModalCardValues.SERVICE_ID]: string;
  [FormModalCardValues.USER]: string;
}
