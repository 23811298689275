/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconDeleteChip = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 25 24'>
      <path
        fill='black'
        fillOpacity='0.08'
        d='M21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25V22.75C18.4371 22.75 23.25 17.9371 23.25 12H21.75ZM12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12H1.75C1.75 17.9371 6.56294 22.75 12.5 22.75V21.25ZM3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75V1.25C6.56294 1.25 1.75 6.06294 1.75 12H3.25ZM12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12H23.25C23.25 6.06294 18.4371 1.25 12.5 1.25V2.75Z'
      />
      <path
        fill='black'
        fillOpacity='0.08'
        d='M8.96967 14.4697C8.67678 14.7626 8.67678 15.2374 8.96967 15.5303C9.26256 15.8232 9.73744 15.8232 10.0303 15.5303L8.96967 14.4697ZM16.0303 9.53033C16.3232 9.23744 16.3232 8.76256 16.0303 8.46967C15.7374 8.17678 15.2626 8.17678 14.9697 8.46967L16.0303 9.53033ZM14.9697 15.5303C15.2626 15.8232 15.7374 15.8232 16.0303 15.5303C16.3232 15.2374 16.3232 14.7626 16.0303 14.4697L14.9697 15.5303ZM10.0303 8.46967C9.73744 8.17678 9.26256 8.17678 8.96967 8.46967C8.67678 8.76256 8.67678 9.23744 8.96967 9.53033L10.0303 8.46967ZM10.0303 15.5303L13.0303 12.5303L11.9697 11.4697L8.96967 14.4697L10.0303 15.5303ZM13.0303 12.5303L16.0303 9.53033L14.9697 8.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L14.9697 15.5303L16.0303 14.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L10.0303 8.46967L8.96967 9.53033L11.9697 12.5303L13.0303 11.4697Z'
      />
    </Icon>
  );
};

export default IconDeleteChip;
