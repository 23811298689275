import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import { TOUR_STEP_ANCHOR } from '@/resources/constants';

const title: OnboardingSteps[0]['title'] = 'AllEmployees.title';

export const onboardingCompanyPage = [
  {
    title,
    target: 'page-allEmployees',
    placement: 'right',
    content: {
      title: 'AllEmployees.step1.title',
      message: 'AllEmployees.step1.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: `${TOUR_STEP_ANCHOR}company`,
    additionalTarget: ':nth-last-child(2)',
    placement: 'left',
    content: {
      title: 'AllEmployees.step2.title',
      message: 'AllEmployees.step2.message',
    },
  },
] as const satisfies OnboardingSteps;
