import { createLogger } from 'redux-logger';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { isDebugMode } from '@/resources/constants';

import commonApi from '@/services/common/common';
import userSlice from '@/redux/user';
import apiErrorHandler from '@/redux/apiErrorHandler';

export const reducer = combineReducers({
  [commonApi.reducerPath]: commonApi.reducer,
  user: userSlice.reducer,
});

const store = configureStore({
  reducer,
  middleware: (defaultMiddleware) =>
    defaultMiddleware({ serializableCheck: false })
      .concat(
        createLogger({
          collapsed: true,
          timestamp: true,
          predicate: () => isDebugMode,
        }),
      )
      .concat([apiErrorHandler, commonApi.middleware]),
});

export default store;
