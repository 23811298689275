import { AxiosError } from 'axios';

import Api from '@/utils/Api';
import Alerter from '@/utils/Alerter/Alerter';

import { AppThunk } from '@/types';

// @ts-ignore
type MergeParams<T, K> = [...T, ...K];

type ActionWithMiddleware = (...args: any) => AppThunk;

type ApiReduxAction<F extends ActionWithMiddleware> = (
  ...args: MergeParams<Parameters<F>, [withThrow?: boolean]>
) => ReturnType<ReturnType<F>>;

function actionRedux<F extends ActionWithMiddleware>(fn: F): ApiReduxAction<F> {
  // @ts-ignore
  return function (...actionArgs) {
    const { length, name } = fn;
    const withThrow = actionArgs.slice(length)[0] as boolean | undefined;

    return async function (...reduxArgs: Parameters<AppThunk>) {
      try {
        return await fn(...actionArgs)(...reduxArgs);
      } catch (error) {
        if (withThrow) throw error;
        Api.handleDefaultError(error as AxiosError);
        Alerter.logError(`ApiReduxAction ${name}`);
      }

      return undefined;
    };
  };
}

export default actionRedux;
