interface UserInfo {
  firstName?: string;
  lastName?: string;
}

export const getUserNickname = (userInfo?: UserInfo) => {
  if (!userInfo) return '-';

  const { firstName, lastName } = userInfo;

  if (!firstName && !lastName) return '-';

  return [firstName, lastName].filter(Boolean).join(' ');
};
