import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';

import { SelectItem } from '@/types/form';
import { ApiUser, ApiUsersGetQuery } from '@/services/users/users.types';
import { useLazyGetUsersQuery } from '@/services/users/users';
import { ApiUserRole } from '@/services/auth/auth.types';

interface Filters {
  serviceId?: string;
  commandId?: string;
}

// TODO: Роль суперадмина не отработана

export const filterPossibleMasters = (a: ApiUser) =>
  [ApiUserRole.USER, ApiUserRole.LEAD_USER].includes(a.role);

const useCommandMasters = (filters: Filters = {}) => {
  const { t } = useTranslation(['common']);
  const { commandId } = useUserCommand();

  const { isMaster, userInfo } = useUserInfo();
  const [getUsers, { data }] = useLazyGetUsersQuery();

  useEffect(() => {
    if (!commandId || isMaster) return;

    const query: ApiUsersGetQuery = { commandId };
    if (filters.serviceId) query.serviceId = filters.serviceId;
    if (filters.commandId) query.commandId = filters.commandId;

    getUsers(query);
  }, [commandId, filters.commandId, filters.serviceId, getUsers, isMaster]);

  const masters = useMemo(() => {
    const list: ApiUser[] = data?.list || [];

    const result: SelectItem[] = list.filter(filterPossibleMasters).map((a) => ({
      value: a.id,
      label: getUserNickname(a),
      suggestion: a.role === userInfo?.id ? t('common:you') : '',
    }));

    return result;
  }, [data?.list, t, userInfo?.id]);

  return { masters };
};

export default useCommandMasters;
