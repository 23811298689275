/* eslint-disable max-len */

import { styled, css } from '@mui/material';

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

import transientProps from '@/utils/transientProps';

const StyledIcon = styled(Icon, transientProps)<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      & > rect:hover {
        fill: #f5f5f5;
      }
    `}
`;

const IconTrash = ({
  size = IconSize.m,
  isDisabled = false,
  onClick,
  ...restProps
}: IconProps & { isDisabled?: boolean }) => {
  return (
    <StyledIcon
      {...restProps}
      onClick={!isDisabled ? onClick : undefined}
      $isDisabled={isDisabled}
      customHeight={40}
      customWidth={40}
      viewBox='0 0 40 40'
    >
      <rect width='40' height='40' rx='8' fill='#FAFAFA' />
      <path
        d='M27.5 16.0001C28.0523 16.0001 28.5 15.5524 28.5 15.0001C28.5 14.4478 28.0523 14.0001 27.5 14.0001V16.0001ZM12.5 14.0001C11.9477 14.0001 11.5 14.4478 11.5 15.0001C11.5 15.5524 11.9477 16.0001 12.5 16.0001V14.0001ZM23.3333 15.0001V16.0001H24.3333V15.0001H23.3333ZM16.6667 15.0001H15.6667V16.0001H16.6667V15.0001ZM14.1667 16.0001H25.8333V14.0001H14.1667V16.0001ZM24.8333 15.0001V26.6667H26.8333V15.0001H24.8333ZM24.1667 27.3334H15.8333V29.3334H24.1667V27.3334ZM15.1667 26.6667V15.0001H13.1667V26.6667H15.1667ZM25.8333 16.0001H27.5V14.0001H25.8333V16.0001ZM14.1667 14.0001H12.5V16.0001H14.1667V14.0001ZM15.8333 27.3334C15.4651 27.3334 15.1667 27.0349 15.1667 26.6667H13.1667C13.1667 28.1395 14.3606 29.3334 15.8333 29.3334V27.3334ZM24.8333 26.6667C24.8333 27.0349 24.5349 27.3334 24.1667 27.3334V29.3334C25.6394 29.3334 26.8333 28.1395 26.8333 26.6667H24.8333ZM18.3333 12.6667H21.6667V10.6667H18.3333V12.6667ZM22.3333 13.3334V15.0001H24.3333V13.3334H22.3333ZM23.3333 14.0001H16.6667V16.0001H23.3333V14.0001ZM17.6667 15.0001V13.3334H15.6667V15.0001H17.6667ZM21.6667 12.6667C22.0349 12.6667 22.3333 12.9652 22.3333 13.3334H24.3333C24.3333 11.8607 23.1394 10.6667 21.6667 10.6667V12.6667ZM18.3333 10.6667C16.8606 10.6667 15.6667 11.8607 15.6667 13.3334H17.6667C17.6667 12.9652 17.9651 12.6667 18.3333 12.6667V10.6667Z'
        fill='#CCCDD3'
      />
    </StyledIcon>
  );
};

export default IconTrash;
