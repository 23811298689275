import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiOfferDashboardCategories } from '@/services/dashboard/dashboard.types';

const useLocalizeOfferCategory = () => {
  const { t } = useTranslation('offer');

  const localizeOfferCategory = useCallback(
    (category: ApiOfferDashboardCategories) => {
      switch (category) {
        case ApiOfferDashboardCategories.CONFIRMED_INCOME:
          return t('category.confirmedIncome');
        case ApiOfferDashboardCategories.EXPECTED_INCOME:
          return t('category.expectedIncome');
        case ApiOfferDashboardCategories.PROFIT:
          return t('category.profit');
        case ApiOfferDashboardCategories.SPEND:
          return t('category.spend');
        case ApiOfferDashboardCategories.DOLPHIN_SPEND:
          return t('category.dolphinSpend');
        case ApiOfferDashboardCategories.CONFIRMED_ROI:
          return t('category.confirmedRoi');
        case ApiOfferDashboardCategories.EXPECTED_ROI:
          return t('category.expectedRoi');
        case ApiOfferDashboardCategories.CONFIRMED_ROMI:
          return t('category.confirmedRomi');
        case ApiOfferDashboardCategories.EXPECTED_ROMI:
          return t('category.expectedRomi');
        default:
          return category;
      }
    },
    [t],
  );

  return { localizeOfferCategory };
};

export default useLocalizeOfferCategory;
