/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconWallet = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 20.5C19 18.636 20.511 17.125 22.375 17.125H26.5C26.9142 17.125 27.25 17.4608 27.25 17.875V23.125C27.25 23.5392 26.9142 23.875 26.5 23.875H22.375C20.511 23.875 19 22.364 19 20.5ZM22.375 18.625C21.3395 18.625 20.5 19.4645 20.5 20.5C20.5 21.5355 21.3395 22.375 22.375 22.375H25.75V18.625H22.375ZM22 20.5C22 20.0858 22.3358 19.75 22.75 19.75H23.5C23.9142 19.75 24.25 20.0858 24.25 20.5C24.25 20.9142 23.9142 21.25 23.5 21.25H22.75C22.3358 21.25 22 20.9142 22 20.5Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.75 13C10.75 11.7574 11.7574 10.75 13 10.75H22C23.2426 10.75 24.25 11.7574 24.25 13C24.25 13.263 24.2049 13.5154 24.122 13.75H24.25C25.9069 13.75 27.25 15.0931 27.25 16.75V24.25C27.25 25.9069 25.9069 27.25 24.25 27.25H13.75C12.0931 27.25 10.75 25.9069 10.75 24.25V13ZM12.25 15.122V24.25C12.25 25.0784 12.9216 25.75 13.75 25.75H24.25C25.0784 25.75 25.75 25.0784 25.75 24.25V16.75C25.75 15.9216 25.0784 15.25 24.25 15.25H13C12.737 15.25 12.4846 15.2049 12.25 15.122ZM22 13.75C22.4142 13.75 22.75 13.4142 22.75 13C22.75 12.5858 22.4142 12.25 22 12.25H13C12.5858 12.25 12.25 12.5858 12.25 13C12.25 13.4142 12.5858 13.75 13 13.75H22Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconWallet;
