import { useTranslation } from 'react-i18next';

import Select from '@/components/Select';

import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { FilterStatusProps } from '@/types/filters';
import useStatusFilter from '@/filters/useStatusFilter';

// TODO: доработать для multiple

const FilterStatus = <T extends string>({
  options,
  defaultValue,
  ...restProps
}: FilterStatusProps<T>) => {
  const { t } = useTranslation(['common']);

  const filterProps = useFilterProps<false>(restProps);
  const { status, setStatus } = useStatusFilter(defaultValue);

  const handleValueSelect = (value: SelectValue<false>) => setStatus(value, 'replaceIn', true);

  return (
    <Select
      {...filterProps}
      isControlFromProp
      label={t('common:tableLabels.status')}
      options={options}
      onSelect={handleValueSelect}
      value={status}
    />
  );
};

export default FilterStatus;
