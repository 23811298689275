import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Select from '@/components/Select';

import useUserRoles from '@/hooks/useUserRoles';
import useUserInfo from '@/hooks/useUserInfo';

import { ApiUser } from '@/services/users/users.types';
import { useChangeUserRoleMutation } from '@/services/users/users';
import { ApiUserRole } from '@/services/auth/auth.types';

interface UserRoleProps {
  user: ApiUser;
  isAllRoles?: boolean;
}

const possibleChangeUserRole = [
  ApiUserRole.LEAD_USER,
  ApiUserRole.COMMAND_FINANCIER,
  ApiUserRole.USER,
];

const UserRole = ({ user, isAllRoles = false }: UserRoleProps) => {
  const { t } = useTranslation(['common']);

  const { isOwner } = useUserInfo();
  const roleOptions = useUserRoles(isAllRoles);

  const [changeUserRole] = useChangeUserRoleMutation();
  const isPossibleChangeUserRole = possibleChangeUserRole.includes(user.role);

  const isEditable = isOwner && isPossibleChangeUserRole;

  const handleChangeUserRole = useCallback(
    (value: string) => {
      if (!isEditable) return;
      changeUserRole({ id: user.id, role: value as ApiUserRole });
    },
    [changeUserRole, isEditable, user.id],
  );

  const options = !isEditable
    ? roleOptions
    : roleOptions.filter((a) => possibleChangeUserRole.includes(a.value));

  return (
    <Select
      fullWidth
      disabled={!isEditable}
      size='small'
      value={user.role}
      options={options}
      label={t('common:role')}
      onSelect={handleChangeUserRole}
    />
  );
};

export default UserRole;
