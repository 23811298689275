import { useLocation } from 'react-router';
import { useMemo } from 'react';

const useActiveRoute = (items: { path?: string }[]) => {
  const location = useLocation();

  return useMemo(() => {
    let result = false;
    const urlParts = location.pathname.split('/');

    for (let k = 0; k < items.length; k++) {
      const route = items[k];
      if (!route.path) continue;

      const routeParts = route.path.split('/');

      if (location.pathname === route.path) {
        result = true;
        break;
      }

      let routeResult = true;

      if (routeParts.length <= urlParts.length) {
        for (let i = 0; i < routeParts.length; i++) {
          if (routeParts[i] !== urlParts[i]) {
            routeResult = false;
            break;
          }
        }
      }

      if (routeResult) {
        result = true;
        break;
      }
    }

    return result;
  }, [items, location.pathname]);
};

export default useActiveRoute;
