import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiCardStatus } from '@/services/cards/cards.types';

const useLocalizeCardStatus = () => {
  const { t } = useTranslation('cards');

  const localizeCardStatus = useCallback(
    (status: ApiCardStatus) => {
      switch (status) {
        case ApiCardStatus.OPEN:
          return t('status.open');
        case ApiCardStatus.CLOSED:
          return t('status.closed');
        default:
          return status;
      }
    },
    [t],
  );

  return { localizeCardStatus };
};

export default useLocalizeCardStatus;
