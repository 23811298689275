import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserCommand from '@/hooks/useUserCommand';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import {
  ApiConsumableCategory,
  ApiConsumableCategoryPostBody,
} from '@/services/consumableCategories/consumableCategories.types';
import {
  useCreateConsumableCategoryMutation,
  useUpdateConsumableCategoryByIdMutation,
} from '@/services/consumableCategories/consumableCategories';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalModalConsumableCategoryData,
  FormModalModalConsumableCategoryValues,
} from '@/modals/ModalConsumableCategory/ModalConsumableCategoryTypes';

const ModalConsumableCategory: StaticModalWrappedComponent<ApiConsumableCategory> = (props) => {
  const { t } = useTranslation(['common', 'consumables']);

  const { commandId } = useUserCommand();

  const [createConsumableCategory, { isLoading: isCreating }] =
    useCreateConsumableCategoryMutation();

  const [updateConsumableCategory, { isLoading: isUpdating }] =
    useUpdateConsumableCategoryByIdMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalModalConsumableCategoryData) => {
      const body: ApiConsumableCategoryPostBody = {
        commandId,
        name: data[FormModalModalConsumableCategoryValues.NAME],
      };

      const response = props.data
        ? await updateConsumableCategory({ ...body, id: props.data.id })
        : await createConsumableCategory(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [commandId, createConsumableCategory, props, updateConsumableCategory],
  );

  const isEdit = Boolean(props.data);
  const title = isEdit ? t('consumables:editCategory') : t('consumables:addCategory');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} defaultValues={props.data}>
        <FormElement
          component='input'
          label={t('consumables:categoryName')}
          name={FormModalModalConsumableCategoryValues.NAME}
          rules={FormRuler.requiredInput}
        />
        <DialogActions>
          <Button isLoading={isCreating || isUpdating} type='submit'>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiConsumableCategory>(ModalConsumableCategory);
