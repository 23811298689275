import { PossibleStatisticCategories } from '@/resources/constantsStatistic';

import checkStatisticCategory from '@/utils/dashboard/checkStatisticCategory';

import { ApiDashboardCommonStatistics } from '@/services/dashboard/dashboard.types';

export const getParametersCount = (dates: ApiDashboardCommonStatistics[]) => {
  const result: Record<PossibleStatisticCategories | string, number> = {};

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];

    for (const key of Object.keys(date)) {
      if (checkStatisticCategory(key)) {
        if (!result[key]) result[key] = 0;
        result[key] = Number((result[key] + date[key]).toFixed(3));
      }
    }
  }

  return result;
};
