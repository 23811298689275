/* eslint-disable max-len */
import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconFilter = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 20 20'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.50015 2.33663C2.50082 2.33746 2.5017 2.33848 2.50283 2.33969L8.72163 8.99996H11.6442L17.497 2.33983C17.4983 2.33843 17.4992 2.33728 17.4998 2.33639C17.4998 2.33622 17.4998 2.33605 17.4998 2.33586C17.4997 2.33506 17.4995 2.3342 17.4993 2.33329H2.50077C2.50049 2.33445 2.50029 2.33554 2.50016 2.33653C2.50016 2.33657 2.50016 2.3366 2.50015 2.33663ZM11.1879 10.6666H9.19294V14.969L11.1879 15.978V10.6666ZM7.52628 10.1619L1.28464 3.47713C0.316612 2.44039 1.00208 0.666626 2.48419 0.666626H17.5158C18.9736 0.666626 19.6704 2.39157 18.749 3.44002L12.8546 10.1474V17.3333C12.8546 17.6228 12.7043 17.8915 12.4577 18.0432C12.2111 18.1948 11.9035 18.2076 11.6451 18.0769L7.98352 16.2251C7.70307 16.0832 7.52628 15.7957 7.52628 15.4814V10.1619Z'
        fill='#989FA6'
      />
    </Icon>
  );
};

export default IconFilter;
