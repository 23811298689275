import { useTranslation } from 'react-i18next';
import React from 'react';
import { Divider, DrawerProps, IconButton } from '@mui/material';

import Typography from '@/components/Typography';
import {
  StyledSidebar,
  HeaderSidebar,
  Overlay,
  FiltersWrapper,
} from '@/components/FiltersWithSidebar/FiltersSidebar/FiltersSidebarStyled';

import IconClose from '@/icons/IconClose';

interface FiltersSidebarProps extends DrawerProps {
  children: React.ReactNode;
  open: boolean;
  close: () => void;
  title?: string;
}

export const FiltersSidebar = ({ children, title, open, close, ...rest }: FiltersSidebarProps) => {
  const { t } = useTranslation();

  return (
    <Overlay $isOpen={open} onClick={(e) => e.currentTarget === e.target && close()}>
      <StyledSidebar variant='persistent' anchor='right' open={open} {...rest}>
        <HeaderSidebar>
          <Typography variant='h6'>{title || t('sidebar.allFilters')}</Typography>
          <IconButton onClick={close}>
            <IconClose />
          </IconButton>
        </HeaderSidebar>

        <Divider />

        <FiltersWrapper>{children}</FiltersWrapper>
      </StyledSidebar>
    </Overlay>
  );
};
