import { styled, Grid } from '@mui/material';

import Typography from '@/components/Typography';

export const StyledErrorPageWrapperTypography = styled(Typography)`
  max-width: 396px;
  text-align: center;
`;

export const StyledErrorPageGrid = styled(Grid)`
  margin-top: 30px;
  width: 100%;
  text-align: center;
`;
