import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';

import i18next from '@/resources/i18next';

import clearAllCache from '@/utils/clearAllCache';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import store from '@/redux';

type ApiErrorHandler = Middleware;

const apiErrorHandler: ApiErrorHandler = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const responseMessage = action.payload.data.message;

    switch (action.payload.status) {
      case 401:
      case 402:
        if (store.getState().API?.queries?.['getMe(undefined)']?.status === 'fulfilled') {
          clearAllCache(store.dispatch);
        }
        break;

      default:
        Alerter.show(responseMessage || i18next.t('common:serverRequestError'), AlerterTypes.error);
        break;
    }
  }

  return next(action);
};

export default apiErrorHandler;
