import { Typography, styled, css } from '@mui/material';

export const StyledTypography = styled(Typography)`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      display: inline;
    `};
`;
