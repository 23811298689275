import { TableWrapper, EmptyTableWrapper } from '@/pages/DashboardsPage/DashboardsPageStyled';
import { DashboardSettings } from '@/pages/DashboardsPage/DashboardsPage.types';

import Table from '@/components/Table';

import useDashboardConfig from '@/hooks/useDashboardConfig';

export const DashboardTable = ({ data }: { data: DashboardSettings }) => {
  const { config: tableConfig, isLoading } = useDashboardConfig(data);

  const tableLayout = <Table config={tableConfig} isLoading={isLoading} isCustomChildrenConfig />;

  return tableConfig.length ? (
    <TableWrapper>{tableLayout}</TableWrapper>
  ) : (
    <EmptyTableWrapper>{tableLayout}</EmptyTableWrapper>
  );
};
