import { FormControlLabel, InputBase } from '@mui/material';
import styled from '@emotion/styled';

export const StyledPageSettings = styled('div')`
  background: #fff;

  width: fit-content;

  padding: 24px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};

  display: flex;
  flex-direction: column;
`;

export const SearchIconWrapper = styled('div')`
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Search = styled('div')`
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.other.outlineBorder};
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledInputBase = styled(InputBase)`
  padding-left: 28px;
  width: 480px;
`;

export const StyledFiltersWrapper = styled('div')`
  label {
    margin-top: 8px;

    :first-child {
      margin-top: 0px;
    }
  }
`;

export const StyledSettingsTitleWrapper = styled('div')`
  display: flex;
  align-items: center;

  span {
    margin-left: 24px;
  }
`;

export const PageSettingsTableCheckbox = styled(FormControlLabel)`
  display: flex;
`;
