import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { CssBaseline } from '@mui/material';

import Router from '@/components/Router';
import ReduxProvider from '@/components/ReduxProvider';
import Notification from '@/components/Notification';
import MaterialThemeProvider from '@/components/MaterialThemeProvider';
import Loader from '@/components/Loader';
import ErrorBoundary from '@/components/ErrorBoundary';
import AppModalsList from '@/components/AppModalsList';

import EffectAppInit from '@/hooks/EffectAppInit';

import 'moment/locale/ru';

import '@/resources/i18next';

ReactDOM.render(
  <MaterialThemeProvider>
    <CssBaseline />
    <ReduxProvider>
      <BrowserRouter>
        {/* @ts-ignore */}
        <ErrorBoundary>
          <EffectAppInit />

          <Suspense fallback={<Loader />}>
            <Router />
          </Suspense>

          <Notification />
          <AppModalsList />
        </ErrorBoundary>
      </BrowserRouter>
    </ReduxProvider>
  </MaterialThemeProvider>,
  document.getElementById('root'),
);
