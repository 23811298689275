import { useMemo } from 'react';

import filterBoolean from '@/utils/filterBoolean';

import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';

import { ApiUserRole } from '@/services/auth/auth.types';

const useUserRoles = (isAllRoles = false) => {
  const { localizeUserRole } = useLocalizeUserRole();

  return useMemo(
    () =>
      [
        isAllRoles && {
          value: ApiUserRole.COMPANY_FINANCIER,
          label: localizeUserRole(ApiUserRole.COMPANY_FINANCIER),
        },
        {
          value: ApiUserRole.COMMAND_FINANCIER,
          label: localizeUserRole(ApiUserRole.COMMAND_FINANCIER),
        },
        isAllRoles && { value: ApiUserRole.OWNER, label: localizeUserRole(ApiUserRole.OWNER) },
        {
          value: ApiUserRole.LEAD_USER,
          label: localizeUserRole(ApiUserRole.LEAD_USER),
        },
        { value: ApiUserRole.USER, label: localizeUserRole(ApiUserRole.USER) },
      ].filter(filterBoolean),
    [isAllRoles, localizeUserRole],
  );
};

export default useUserRoles;
