import { Dispatch } from '@reduxjs/toolkit';

import { DEFAULT_LANGUAGE, STORE_INTERFACE_LANGUAGE } from '@/resources/constants';

import LocalStorage from '@/utils/LocalStorage';

import commonApi from '@/services/common/common';
import userSlice from '@/redux/user';

const { resetAll } = userSlice.actions;

// NOTE
// RTK Query могут застрять в статусе "pending"
// https://github.com/reduxjs/redux-toolkit/issues/1376

// NOTE
// Переосмыслить!!!
// AuthService не очищается, потому что:
// - при login происходит инвалидация
// - при logout происходит инвалидация

// NOTE
// Проблемы сброса кэша описаны тут
// https://github.com/reduxjs/redux-toolkit/pull/1735

// NOTE
// Кажется, починили в версии 1.7.0
// Сейчас локально проблем не наблюдаю

const clearAllCache = (dispatch: Dispatch<any>) => {
  const language = LocalStorage.get(STORE_INTERFACE_LANGUAGE) || DEFAULT_LANGUAGE;

  LocalStorage.clear();
  dispatch(resetAll());
  dispatch(commonApi.util.resetApiState());

  LocalStorage.set(STORE_INTERFACE_LANGUAGE, String(language));
};

export default clearAllCache;
