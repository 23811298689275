import themeVolgaVolga from 'vvdev-theme';
import { FunctionComponent } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material';

import typography from '@/components/MaterialThemeProvider/config/typography';
import ungroupedComponents from '@/components/MaterialThemeProvider/config/components/ungroupedComponents';
import tableComponents from '@/components/MaterialThemeProvider/config/components/tableComponents';
import dialogComponents from '@/components/MaterialThemeProvider/config/components/dialogComponents';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // TODO: актулазировать новый дизайн
    // NOTE: в новом дизайне это subtitle1, добавлен для обратной совместимости
    subtitle3: true;
  }
}

export let theme = createTheme(themeVolgaVolga);

const buttons: Theme['components'] = {
  MuiButton: {
    styleOverrides: {
      outlinedSecondary: {
        color: '#989FA6',
        borderColor: '#EEEEEE',
        ':hover': {
          borderColor: '#EEEEEE',
          backgroundColor: '#EEEEEE',
        },
      },
    },
  },
};

theme = createTheme(theme, {
  typography,
  components: {
    ...tableComponents,
    ...dialogComponents,
    ...ungroupedComponents,
    ...buttons,
  },
});

const MaterialThemeProvider: FunctionComponent = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
