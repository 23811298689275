import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';

const useClosedFacebookPageState = () => {
  const { isMultipleAccessToCommandUser } = useUserInfo();
  const { isComandWithDolphin } = useUserCommand();
  const { commandsWithDolphinId } = useHighLevelUserCommands();

  return isMultipleAccessToCommandUser ? !commandsWithDolphinId.length : !isComandWithDolphin;
};

export default useClosedFacebookPageState;
