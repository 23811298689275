import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'FaceBook.title';

export const onboardingFacebookPage = [
  {
    title,
    target: 'page-facebook',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'FaceBook.step1.title',
      message: 'FaceBook.step1.message',
    },
  },
  {
    title,
    target: 'step-facebook',
    disableScrolling: true,
    disableScrollParentFix: true,
    content: {
      title: 'FaceBook.step2.title',
      message: 'FaceBook.step2.message',
    },
  },
  {
    title,
    target: 'step-facebook-3',
    disableScrolling: true,
    disableScrollParentFix: true,
    placement: 'left',
    content: {
      title: 'FaceBook.step3.title',
      message: 'FaceBook.step3.message',
    },
  },
] as const satisfies OnboardingSteps;
