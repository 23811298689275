import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import filterBoolean from '@/utils/filterBoolean';
import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';

import {
  ApiOffer,
  ApiOfferPaymentModel,
  ApiOfferPlatform,
  ApiOfferPostBody,
} from '@/services/offers/offers.types';
import { useCreateOfferMutation, useUpdateOfferByIdMutation } from '@/services/offers/offers';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  offerGeoOptions,
  offerPlatformOptions,
  offerPaymentModelOptions,
} from '@/modals/ModalOffer/resources';
import { FormModalOfferData, FormModalOfferValues } from '@/modals/ModalOffer/ModalOfferTypes';

const ModalOffer: StaticModalWrappedComponent<ApiOffer> = (props) => {
  const { t } = useTranslation(['common', 'offer']);

  const [createOffer, { isLoading: isCreating }] = useCreateOfferMutation();
  const [updateService, { isLoading: isUpdating }] = useUpdateOfferByIdMutation();

  const { trackerInfo } = useUserInfo();
  const { commandId } = useUserCommand();

  const handleSubmit = async (data: FormModalOfferData) => {
    const body: ApiOfferPostBody = {
      commandId,
      geo: data[FormModalOfferValues.GEO]!.value,
      name: data[FormModalOfferValues.NAME],
      partner: data[FormModalOfferValues.PARTNER],
      comment: data[FormModalOfferValues.COMMENT],
      platform: data[FormModalOfferValues.PLATFORM],
      employeeIdColumn: data[FormModalOfferValues.EMPLOYEE_ID_COLUMN],

      paymentModel: data[FormModalOfferValues.PAYMENT_MODEL],
      paymentAmount: Number(data[FormModalOfferValues.PAYMENT_AMOUNT]),

      userIds: (data[FormModalOfferValues.USER_IDS] || [])
        .map((a) => a.value)
        .filter(filterBoolean),
    };

    const response = props.data
      ? await updateService({ ...body, id: props.data.id })
      : await createOffer(body);

    if ('data' in response) {
      props.onClose();
    }
  };

  const isEdit = Boolean(props.data);
  const title = isEdit ? t('offer:editOffer') : t('offer:addOffer');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  const defaultValues: FormModalOfferData = useMemo(
    () => ({
      [FormModalOfferValues.GEO]:
        offerGeoOptions.find((geo) => geo.value === props.data?.geo) || null,
      [FormModalOfferValues.NAME]: props.data?.name || '',
      [FormModalOfferValues.PARTNER]: props.data?.partner || '',
      [FormModalOfferValues.COMMENT]: props.data?.comment || '',
      [FormModalOfferValues.EMPLOYEE_ID_COLUMN]: props.data?.employeeIdColumn || 'sub_id_1',
      [FormModalOfferValues.PAYMENT_AMOUNT]: String(props.data?.paymentAmount || 0),
      [FormModalOfferValues.PLATFORM]: props.data?.platform || ApiOfferPlatform.FACEBOOK,
      [FormModalOfferValues.PAYMENT_MODEL]: props.data?.paymentModel || ApiOfferPaymentModel.CPA,
      [FormModalOfferValues.USER_IDS]: (props.data?.users || []).map(({ id }) => ({
        id,
        value: id,
      })),
    }),
    [props.data],
  );

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
        <FormElement
          label={t('common:tableLabels.name')}
          component='input'
          rules={FormRuler.required}
          name={FormModalOfferValues.NAME}
        />

        <FormElement
          label={t('common:tableLabels.partner')}
          component='input'
          rules={FormRuler.required}
          name={FormModalOfferValues.PARTNER}
        />

        <FormElement
          label={t('common:tableLabels.geo')}
          disableClearable
          component='autocomplete'
          options={offerGeoOptions}
          rules={FormRuler.required}
          name={FormModalOfferValues.GEO}
        />

        <FormElement
          component='select'
          label={t('offer:paymentModel')}
          rules={FormRuler.required}
          values={offerPaymentModelOptions}
          name={FormModalOfferValues.PAYMENT_MODEL}
        />

        <FormElement
          isDecimalNumber
          component='input'
          label={t('offer:paymentAmount')}
          rules={{ ...FormRuler.requiredNumberInput, min: 1 }}
          name={FormModalOfferValues.PAYMENT_AMOUNT}
        />

        {trackerInfo && (
          <FormElement
            component='input'
            label={t('offer:employeeLabel')}
            rules={FormRuler.required}
            name={FormModalOfferValues.EMPLOYEE_ID_COLUMN}
          />
        )}

        <FormElement
          component='input'
          label={t('common:tableLabels.comment')}
          name={FormModalOfferValues.COMMENT}
        />

        <FormElement
          disabled
          label={t('common:tableLabels.platform')}
          component='select'
          rules={FormRuler.required}
          values={offerPlatformOptions}
          name={FormModalOfferValues.PLATFORM}
        />

        <FormElement
          type='user'
          userId={undefined}
          component='picker'
          title={t('common:formLabels.addUser')}
          disableAutofill={isEdit}
          disableSelectMasters={Boolean(props.data?.trackerId)}
          name={FormModalOfferValues.USER_IDS}
        />

        <DialogActions>
          <Button type='submit' isLoading={isCreating || isUpdating}>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiOffer>(ModalOffer);
