import useUserData from '@/hooks/useUserData';
import useSelectOptions from '@/hooks/useSelectOptions';

import { ApiOffersGetResponse } from '@/services/offers/offers.types';
import { useLazyGetOffersQuery } from '@/services/offers/offers';

const useUserOffers = (id?: string) => {
  const userData = useUserData<ApiOffersGetResponse>(useLazyGetOffersQuery, id);
  const offers = useSelectOptions(userData?.list, 'name', 'id');

  return { offers };
};

export default useUserOffers;
