import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

import Typography from '@/components/Typography';
import { StyledEmptyMessage } from '@/components/EmptyMessage/EmptyMessageStyled';

interface EmptyMessageProps {
  message?: string;
  className?: string;
  style?: CSSProperties;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { t } = useTranslation();

  const { style, className, message = t('emptyMessage') } = props;

  return (
    <StyledEmptyMessage style={style} className={className}>
      <Typography color='text.secondary' variant='subtitle1'>
        {message}
      </Typography>
    </StyledEmptyMessage>
  );
};

export default EmptyMessage;
