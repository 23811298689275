import { Stack } from '@mui/material';

import Typography from '@/components/Typography';
import { StatisticBlockItemProps } from '@/components/StatisticBlockItem/StatisticBlockItemTypes';

const StatisticBlockItem = ({ label, value }: StatisticBlockItemProps) => {
  return (
    <Stack spacing={1} direction='row'>
      <Typography variant='body2' color='text.secondary' sx={{ whiteSpace: 'nowrap' }}>
        {label}:
      </Typography>
      <Typography variant='body2'>{value}</Typography>
    </Stack>
  );
};

export default StatisticBlockItem;
