import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import { TOUR_STEP_ANCHOR } from '@/resources/constants';

const title: OnboardingSteps[0]['title'] = 'commands.title';

export const onboardingCommandsPage = [
  {
    title,
    target: 'page-commands',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'commands.step1.title',
      message: 'commands.step1.message',
    },
  },
  {
    title,
    target: 'step-commands-2',
    content: {
      title: 'commands.step2.title',
      message: 'commands.step2.message',
    },
  },
  {
    title,
    target: 'step-commands-2',
    content: {
      title: 'commands.step3.title',
      message: 'commands.step3.message',
    },
  },
  {
    title,
    target: 'step-commands',
    content: {
      title: 'commands.step4.title',
      message: 'commands.step4.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: 'step-commands-4',
    placement: 'left',
    content: {
      title: 'commands.step5.title',
      message: 'commands.step5.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: `${TOUR_STEP_ANCHOR}commands`,
    additionalTarget: ':nth-last-child(2)',
    content: {
      title: 'commands.step6.title',
      message: 'commands.step6.message',
    },
  },
] as const satisfies OnboardingSteps;
