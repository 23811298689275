import { ApiArchiveParams, ApiResponse } from '@/types/api';
import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import commonApi from '@/services/common/common';
import {
  ApiCardsGetResponse,
  ApiCardsGetQuery,
  ApiCardByIdGetResponse,
  ApiCardByIdGetQuery,
  ApiCardPostResponse,
  ApiCardPostBody,
  ApiCardByIdPatchResponse,
  ApiCardByIdPatchBody,
  ApiCard,
  ApiCloseCardByIdPatchBody,
  ApiCardByIdReplenishmentPatchBody,
  ApiCardByIdReplenishmentPatchResponse,
  ApiCardByIdTrafficPatchResponse,
  ApiCardByIdTrafficPatchBody,
  ApiCloseCardByIdPatchResponse,
} from '@/services/cards/cards.types';
import { servicesTags } from '@/services';

type EntityId = string | number;

const baseUrl = '/cards';
const serviceTag = servicesTags.cards;

const cardsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCards: build.query<ApiCardsGetResponse, ApiCardsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getCardById: build.query<ApiCardByIdGetResponse, ApiCardByIdGetQuery>({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),

    createCard: build.mutation<ApiCardPostResponse, ApiCardPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    updateCardById: build.mutation<ApiCardByIdPatchResponse, ApiCardByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    replenishmentCardById: build.mutation<
      ApiCardByIdReplenishmentPatchResponse,
      ApiCardByIdReplenishmentPatchBody
    >({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/replenishment`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesListItem(serviceTag, [
        servicesTags.operations,
        servicesTags.services,
      ]),
    }),
    writeTrafficCostsCardById: build.mutation<
      ApiCardByIdTrafficPatchResponse,
      ApiCardByIdTrafficPatchBody
    >({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/traffic`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesListItem(serviceTag, [
        servicesTags.operations,
        servicesTags.pipes,
      ]),
    }),

    closeCardById: build.mutation<ApiCloseCardByIdPatchResponse, ApiCloseCardByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/close`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([
        serviceTag,
        servicesTags.services,
        servicesTags.operations,
      ]),
    }),
    openCardById: build.mutation<ApiResponse<ApiCard>, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}/open`, method: 'PATCH' }),
      invalidatesTags: invalidatesTags([
        serviceTag,
        servicesTags.services,
        servicesTags.operations,
      ]),
    }),
    changeCardArchiveStatus: build.mutation<ApiResponse<null>, ApiArchiveParams>({
      query: ({ id, ...params }) => ({
        url: `${baseUrl}/${id}/change-archive-status`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.cards]),
    }),
  }),
});

export const {
  useWriteTrafficCostsCardByIdMutation,
  useReplenishmentCardByIdMutation,
  useGetCardsQuery,
  useGetCardByIdQuery,
  useChangeCardArchiveStatusMutation,
  useLazyGetCardsQuery,
  useCreateCardMutation,
  useUpdateCardByIdMutation,
  useOpenCardByIdMutation,
  useCloseCardByIdMutation,
} = cardsApi;

export default cardsApi;
