import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { FormControlLabel } from '@mui/material';

import { StyledSwitch } from '@/components/FormElement/FormElementStyled';

import { FormElementSwitchProps } from '@/types/form';

const FormElementSwitch = ({
  name,
  rules,
  label,
  disabled,
  labelPlacement = 'start',
}: FormElementSwitchProps) => {
  const { control } = useFormContext();

  const render: ControllerProps['render'] = useCallback(
    ({ field }) => {
      const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        field.onChange(value);
      };

      return (
        <FormControlLabel
          label={label}
          disabled={disabled}
          checked={Boolean(field.value)}
          labelPlacement={labelPlacement}
          control={
            <StyledSwitch
              {...field}
              onChange={handleChange}
              $isAlignRight={labelPlacement === 'end'}
            />
          }
        />
      );
    },
    [disabled, label, labelPlacement],
  );

  return <Controller name={name} rules={rules} control={control} render={render} />;
};

export default FormElementSwitch;
