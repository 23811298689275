import useAuthorization from '@/hooks/useAuthorization';

const useUserCompanyInfo = () => {
  const { userInfo } = useAuthorization();

  return {
    company: userInfo?.company,

    name: userInfo?.company.name || '',
    apiKey: userInfo?.company.apiKey || '',

    tracker: userInfo?.company.tracker,
    selfhost: userInfo?.company.selfhost,
  };
};

export default useUserCompanyInfo;
