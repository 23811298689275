import isEmail from 'validator/lib/isEmail';
import { unitOfTime } from 'moment';

import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

import { maxLength, minLength } from '@/resources/constants';

import Formatter from '@/utils/Formatter';

class FormRuler {
  static required = {
    required: true,
  };

  static email = {
    validate: {
      email: isEmail,
    },
  };

  static url = {
    pattern: /^http[s]?:\/\/.*/g,
  };

  static requiredNumberInput = {
    ...FormRuler.required,
  };

  static requiredInput = {
    ...FormRuler.required,
    minLength: minLength.common,
    maxLength: maxLength.common,
  };

  static requiredPassword = {
    ...FormRuler.required,
    minLength: minLength.password,
    maxLength: maxLength.password,
  };

  static requiredUrl = {
    ...FormRuler.url,
    ...FormRuler.required,
  };

  static validateDate = (dateValue = '', isBefore: boolean, granularity: unitOfTime.StartOf) => ({
    validate: {
      date: (value: Date | string) => {
        if (!dateValue) return true;
        return Formatter.compareDates(value, dateValue, isBefore, granularity);
      },
    },
  });

  static requiredConditional = (required?: boolean) => ({ required });

  static comparePasswords = (passwordValue: string) => ({
    validate: {
      differentPasswords: (value: string) => value === passwordValue,
    },
  });

  static checkNumber = (maxValue: number | string) => ({
    validate: {
      maxNumber: (value: number) => value <= Number(maxValue),
    },
  });

  static validateSumWithCurrency = {
    validate: {
      required: (value?: SumWithCurrency) => Boolean(value?.currency && value?.currencySum),
    },
  };
}

export default FormRuler;
