import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiOperation, ApiOperationCategory } from '@/services/operations/operations.types';

const useLocalizeOperationName = () => {
  const { t } = useTranslation('operations');

  const localizeOperationName = useCallback(
    (operation: ApiOperation) => {
      switch (operation.category) {
        case ApiOperationCategory.ALL:
        case ApiOperationCategory.OTHER:
        case ApiOperationCategory.REFUND:
        case ApiOperationCategory.INVESTMENT:
          return operation.name;

        case ApiOperationCategory.PIPE_CONSUMABLE:
          return t('name.PIPE_CONSUMABLE');

        default:
          return t(`name.${operation.category}`, { value: operation.relatedEntityName });
      }
    },
    [t],
  );

  return { localizeOperationName };
};

export default useLocalizeOperationName;
