import { useSelector } from 'react-redux';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormControl, styled, TextField, TextFieldProps } from '@mui/material';

import IconCalendar from '@/icons/IconCalendar';

import { dateFormatStartsWithYear, dateInputFormat } from '@/resources/constants';

import useUpdateEffect from '@/hooks/useUpdateEffect';
import useFormValidation from '@/hooks/useFormValidation';

import { FormElementDatePickerProps } from '@/types/form';
import { selectUserLanguage } from '@/redux/user/userGetters';

const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-adornedEnd {
    padding-right: 0px;
  }
` as typeof DatePicker;

const FormElementDatePicker = ({
  name,
  rules,
  label,
  minDate,
  maxDate,
  value: initialValue = '',
}: FormElementDatePickerProps) => {
  const { control, setValue } = useFormContext();
  const { fieldValidate } = useFormValidation();

  const language = useSelector(selectUserLanguage);

  useEffect(() => {
    setValue(name, moment(initialValue || minDate || undefined).format(dateFormatStartsWithYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    setValue(name, moment(minDate || undefined).format(dateFormatStartsWithYear));
  }, [minDate]);

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const render: ControllerProps['render'] = useCallback(
    ({ field, fieldState }) => {
      const value = field.value;

      const { isError, description } = fieldValidate(fieldState.error);

      return (
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
            <StyledDatePicker
              components={{
                OpenPickerIcon: IconCalendar,
              }}
              label={label}
              value={value}
              minDate={minDate}
              maxDate={maxDate}
              inputFormat={dateInputFormat}
              onChange={(result: moment.MomentInput) => {
                if (result) result = moment(result).format(dateFormatStartsWithYear);
                field.onChange(result);
              }}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} error={isError} helperText={description} fullWidth />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      );
    },
    [fieldValidate, label, language, maxDate, minDate],
  );

  return <Controller name={name} render={render} control={control} rules={{ ...rules }} />;
};

export default FormElementDatePicker;
