import { useTranslation } from 'react-i18next';
import { MouseEvent, useMemo } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import IconUnlink from '@mui/icons-material/LinkOff';
import { useTheme } from '@emotion/react';

import {
  TableRowActionsProps,
  TableRowActionHandler,
  SmartIconButtonProps,
} from '@/components/TableRowActions/TableRowActionsTypes';
import {
  StyledTableRowActions,
  StyledTableCell,
  StyledIconButton,
} from '@/components/TableRowActions/TableRowActionsStyled';
import Popper from '@/components/Popper/Popper';
import { ButtonProps } from '@/components/Button/ButtonTypes';
import Button from '@/components/Button';

import IconUnarchive from '@/icons/IconUnarchive';
import IconTrash2 from '@/icons/IconTrash2';
import IconRefresh from '@/icons/IconRefresh';
import IconInfo from '@/icons/IconInfo';
import IconEdit from '@/icons/IconEdit';
import IconArchive from '@/icons/IconArchive';

const handleTableAction = (func?: TableRowActionHandler) => (e: MouseEvent<HTMLButtonElement>) => {
  e.stopPropagation();
  if (func) func();
};

const CalloutIcon = ({
  isComment,
  callout,
  calloutContent,
  calloutType = 'info',
  calloutWidth,
}: TableRowActionsProps) => {
  const { t } = useTranslation();

  const { palette } = useTheme();

  const prefix = isComment ? `${t('tableLabels.comment')}: ` : '';
  const text = prefix + callout;

  const isIconInfo = calloutType === 'info';
  const iconColor = calloutType === 'error' ? palette.error.dark : palette.warning.main;

  if (!callout) {
    return isIconInfo ? <SmartIconButton Icon={IconInfo} /> : null;
  }

  return (
    <Popper text={text} width={calloutWidth}>
      {calloutContent ? (
        <StyledTableCell>{calloutContent}</StyledTableCell>
      ) : (
        <IconButton disableRipple>
          <IconInfo color={isIconInfo ? undefined : iconColor} />
        </IconButton>
      )}
    </Popper>
  );
};

const Edit = ({ onEdit, isIconEdit }: TableRowActionsProps) => {
  const { t } = useTranslation();

  if (!isIconEdit) {
    return <SmartButton onClick={onEdit}>{t('buttonActions.edit')}</SmartButton>;
  }

  return <SmartIconButton onClick={onEdit} Icon={IconEdit} />;
};

const SmartIconButton = ({
  onClick,
  Icon,
  isIconLoading,
  isLoadingWithOriginalIcon,
  color = '#2374EE',
}: SmartIconButtonProps) => {
  if (!onClick) {
    return (
      <StyledIconButton disabled disableRipple color='secondary' $isLoading={isIconLoading}>
        <Icon color='rgba(204, 205, 211, .5)' />
      </StyledIconButton>
    );
  }

  return (
    <StyledIconButton
      disabled={isIconLoading}
      onClick={handleTableAction(onClick)}
      $isLoading={isIconLoading}
    >
      {isIconLoading && !isLoadingWithOriginalIcon ? (
        <CircularProgress size={24} color='info' />
      ) : (
        <Icon color={color} />
      )}
    </StyledIconButton>
  );
};

const SmartButton = ({
  onClick,
  ...props
}: Omit<ButtonProps, 'onClick'> & { onClick?: TableRowActionsProps['onRefresh'] }) => {
  return (
    <Button {...props} size='small' disabled={!onClick} onClick={handleTableAction(onClick)} />
  );
};

const TableRowActions = (props: TableRowActionsProps) => {
  const {
    informationInModal,
    availableButtons = true,
    buttonsConfig,
    onRefresh,
    onDelete,
    onArchive,
    onUnarchive,
    isIconLoading,
    onUnlink,
    customWrapperStyle,
  } = props;

  const { t } = useTranslation();

  const config = useMemo(() => {
    return buttonsConfig?.map(({ textForButton = t('buttonActions.add'), ...restProps }) => (
      <SmartButton key={textForButton} size='small' {...restProps}>
        {textForButton}
      </SmartButton>
    ));
  }, [buttonsConfig, t]);

  return (
    <StyledTableRowActions style={customWrapperStyle}>
      {'callout' in props && <CalloutIcon {...props} />}

      {availableButtons && (
        <>
          {'onEdit' in props && <Edit {...props} />}
          {'informationInModal' in props && (
            <SmartIconButton onClick={informationInModal} Icon={IconInfo} />
          )}
          {'onRefresh' in props && (
            <SmartIconButton
              onClick={onRefresh}
              isLoadingWithOriginalIcon
              Icon={IconRefresh}
              isIconLoading={isIconLoading}
            />
          )}
          {'onDelete' in props && (
            <SmartIconButton onClick={onDelete} Icon={IconTrash2} isIconLoading={isIconLoading} />
          )}
          {'onArchive' in props && (
            <SmartIconButton color='error' onClick={onArchive} Icon={IconArchive} />
          )}
          {'onUnarchive' in props && (
            <SmartIconButton color='primary' onClick={onUnarchive} Icon={IconUnarchive} />
          )}
          {'onUnlink' in props && !!onUnlink && (
            <Tooltip title={t('buttonActions.unlink')} arrow>
              <IconButton onClick={onUnlink}>
                <IconUnlink color='error' />
              </IconButton>
            </Tooltip>
          )}
          {config}
        </>
      )}
    </StyledTableRowActions>
  );
};

export default TableRowActions;
