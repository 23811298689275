import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';

import useUserCommand from '@/hooks/useUserCommand';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { downloadFile } from '@/redux/files/filesActions';
import { ApiFile } from '@/redux/files/files.types';

const useDownloadFile = () => {
  const dispatch = useDispatch();

  const { commandId } = useUserCommand();

  const [handleDownloadFile] = useAwaitCallback(
    async (file?: ApiFile) => {
      if (!file) return;

      const response = await dispatch(downloadFile({ id: file.id, commandId }));

      if (response) {
        saveAs(response, file.originalName);
      }
    },
    [commandId, dispatch],
  );

  return { handleDownloadFile };
};

export default useDownloadFile;
