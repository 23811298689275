import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { Alert } from '@mui/material';

import Typography from '@/components/Typography';
import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserCommand from '@/hooks/useUserCommand';

import { ApiCommand, ApiCommandDolphinTokenPatchBody } from '@/services/commands/commands.types';
import {
  useAddCommandDolphinTokenMutation,
  useDeleteCommandDolphinTokenMutation,
  useUpdateCommandDolphinTokenMutation,
} from '@/services/commands/commands';
import { setCommandAction } from '@/redux/user/userActions';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalDolphinData,
  FormModalDolphinValues,
} from '@/modals/ModalDolphin/ModalDolphinTypes';

const ModalDolphin: StaticModalWrappedComponent<ApiCommand> = (props) => {
  const { t } = useTranslation(['common', 'facebook']);

  const dispatch = useDispatch();
  const { commandId } = useUserCommand();

  const defaultValues = {
    [FormModalDolphinValues.TOKEN]: props.data?.dolphinToken?.token,
  };

  const isEdit = Boolean(props.data?.dolphinToken?.id);
  const isCommandMatchWithSelected = commandId === props.data?.id;

  const [addCommandDolphinToken, { isLoading: isAdding }] = useAddCommandDolphinTokenMutation();
  const [updateCommandDolphinToken, { isLoading: isUpdating }] =
    useUpdateCommandDolphinTokenMutation();
  const [deleteCommandDolphinToken, { isLoading: isDeleting }] =
    useDeleteCommandDolphinTokenMutation();

  const handleSubmit = useCallback(
    async (data: FormModalDolphinData) => {
      const body: ApiCommandDolphinTokenPatchBody = {
        commandId: props.data?.id,
        token: data[FormModalDolphinValues.TOKEN] || '',
        id: String(props.data?.dolphinToken?.id),
      };

      const response = isEdit
        ? await updateCommandDolphinToken(body)
        : await addCommandDolphinToken(body);

      if ('data' in response) {
        Alerter.show(
          t(
            `facebook:${
              isEdit ? 'dolphinTokenSuccessfullyChanged' : 'dolphinTokenSuccessfullyAdded'
            }`,
          ),
          AlerterTypes.success,
        );

        if (isCommandMatchWithSelected) {
          dispatch(setCommandAction({ commandId, isComandWithDolphin: true }));
        }

        props.onClose();
      }
    },
    [
      addCommandDolphinToken,
      commandId,
      dispatch,
      isCommandMatchWithSelected,
      isEdit,
      props,
      t,
      updateCommandDolphinToken,
    ],
  );

  const handleDeleteToken = useCallback(async () => {
    const response = await deleteCommandDolphinToken({
      id: String(props.data?.dolphinToken?.id),
      commandId: props.data?.id,
    });

    if ('data' in response) {
      Alerter.show(t('facebook:dolphinTokenSuccessfullyDeleted'), AlerterTypes.success);

      if (isCommandMatchWithSelected) {
        dispatch(setCommandAction({ commandId, isComandWithDolphin: false }));
      }

      props.onClose();
    }
  }, [commandId, deleteCommandDolphinToken, dispatch, isCommandMatchWithSelected, props, t]);

  const title = isEdit ? t('facebook:editDolphinToken') : t('facebook:addDolphinToken');
  const buttonText = isEdit ? t('common:buttonActions.save') : t('common:buttonActions.add');

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
        <FormElement
          component='input'
          label={t('facebook:tokenDolphin')}
          name={FormModalDolphinValues.TOKEN}
          rules={isEdit ? undefined : FormRuler.required}
        />

        <Alert severity='info' sx={{ mt: 4 }}>
          <Typography variant='body2' color='inherit'>
            {t('facebook:dolphinInfo.p1')}
          </Typography>
          <Typography sx={{ mt: 1 }} variant='body2' color='inherit'>
            {t('facebook:dolphinInfo.p2')}
          </Typography>
          <Typography sx={{ mt: 1 }} variant='body2' color='inherit'>
            {t('facebook:dolphinInfo.p3')}
          </Typography>
        </Alert>

        <DialogActions>
          {isEdit && (
            <Button color='error' isLoading={isDeleting} onClick={handleDeleteToken}>
              {t('common:buttonActions.delete')}
            </Button>
          )}
          <Button isLoading={isAdding || isUpdating} type='submit'>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCommand>(ModalDolphin);
