import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'Pipes.title';

export const onboardingPipesPage = [
  {
    title,
    target: 'page-pipes',
    placement: 'right',
    content: {
      title: 'Pipes.step1.title',
      message: 'Pipes.step1.message',
    },
  },
  {
    title,
    target: 'step-pipes',
    content: {
      title: 'Pipes.step2.title',
      message: 'Pipes.step2.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: 'step-pipes-2',
    additionalTarget: ':first-child',
    content: {
      title: 'Pipes.step3.title',
      message: 'Pipes.step3.message',
    },
  },
] as const satisfies OnboardingSteps;
