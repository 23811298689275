/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconBlankText = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.875 11.5375C14.875 10.7408 15.4701 10 16.375 10H21.625C22.5299 10 23.125 10.7408 23.125 11.5375V11.575H23.7812C25.0673 11.575 26.125 12.6076 26.125 13.9V25.675C26.125 26.9674 25.0673 28 23.7812 28H14.2188C12.9327 28 11.875 26.9674 11.875 25.675V13.9C11.875 12.6076 12.9327 11.575 14.2188 11.575H14.875V11.5375ZM14.875 13.075H14.2188C13.7444 13.075 13.375 13.4527 13.375 13.9V25.675C13.375 26.1223 13.7444 26.5 14.2188 26.5H23.7812C24.2556 26.5 24.625 26.1223 24.625 25.675V13.9C24.625 13.4527 24.2556 13.075 23.7812 13.075H23.125V13.1125C23.125 13.9092 22.5299 14.65 21.625 14.65H16.375C15.4701 14.65 14.875 13.9092 14.875 13.1125V13.075ZM16.3804 11.5C16.3778 11.5074 16.375 11.5195 16.375 11.5375V13.1125C16.375 13.1305 16.3778 13.1426 16.3804 13.15H21.6196C21.6222 13.1426 21.625 13.1305 21.625 13.1125V11.5375C21.625 11.5195 21.6222 11.5074 21.6196 11.5H16.3804Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.875 17.5C14.875 17.0858 15.2108 16.75 15.625 16.75H22.375C22.7892 16.75 23.125 17.0858 23.125 17.5C23.125 17.9142 22.7892 18.25 22.375 18.25H15.625C15.2108 18.25 14.875 17.9142 14.875 17.5ZM14.875 20.5C14.875 20.0858 15.2108 19.75 15.625 19.75H19C19.4142 19.75 19.75 20.0858 19.75 20.5C19.75 20.9142 19.4142 21.25 19 21.25H15.625C15.2108 21.25 14.875 20.9142 14.875 20.5Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconBlankText;
