export enum FormRecoveryValues {
  'newPassword' = 'newPassword',
  'passwordConfirm' = 'passwordConfirm',
}

export interface FormRecoveryPasswordProps {
  recoveryHash: string;
  setRecoveryHash: (value: string) => void;
  setForgotPasswordState: (value: boolean) => void;
}
