import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import LeadsInfo from '@/components/LeadsInfo';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';
import Button from '@/components/Button';

import IconRefresh from '@/icons/IconRefresh';

import { timeFormat } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import filterBoolean from '@/utils/filterBoolean';
import Formatter from '@/utils/Formatter';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useUserCommand from '@/hooks/useUserCommand';
import usePagination from '@/hooks/usePagination';
import useColumnVisibility from '@/hooks/useColumnVisibility';

import { TableWithSettings } from '@/types/table';
import { ApiLead } from '@/services/leads/leads.types';
import {
  useDeleteLeadByIdMutation,
  useGetLeadsQuery,
  useSynchronizationLeadsMutation,
} from '@/services/leads/leads';
import ModalSettingTableColumns from '@/modals/ModalSettingTableColumns/ModalSettingTableColumns';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useStatusesFilter from '@/filters/useStatusesFilter';
import useMasterFilter from '@/filters/useMasterFilter';
import useFromToFilter from '@/filters/useFromToFilter';
import FilterMaster from '@/filters/FilterMaster';
import FilterLeadStatus from '@/filters/FilterLeadStatus';
import FilterFromTo from '@/filters/FilterFromTo';

const LeadsPage = () => {
  const { t } = useTranslation(['common', 'leads']);

  const { masterId } = useMasterFilter(undefined, true);
  const { to, from } = useFromToFilter();
  const { statuses } = useStatusesFilter();

  const { commandId } = useUserCommand();
  const { selfhost } = useUserCompanyInfo();
  const { isOwner, isMaster } = useUserInfo();
  const { checkColumnVisibility } = useColumnVisibility(TableWithSettings.LEADS);

  const {
    leads: data,
    isLoading,
    setPage,
    setLimit,
    getPagination,
  } = usePagination({
    cacheKey: 'leads',
    useQuery: useGetLeadsQuery,
    queryArg: {
      to,
      from,
      commandId,
      statuses,
      'userIds[]': masterId || undefined,
    },
  });

  const [synchronization, { isLoading: isSynchronization }] = useSynchronizationLeadsMutation();
  const [deleteLeadById, { isLoading: isDeleting }] = useDeleteLeadByIdMutation();

  const onLeadsSynchronization = async () => {
    const response = await synchronization();

    if ('data' in response) {
      Alerter.show(t('common:notifications.synchronized'), AlerterTypes.success);
    }
  };

  const onLeadsDelete = useCallback(
    (id: string) => async () => {
      ModalConfirm.show({
        onlyContinue: true,
        onContinue: async () => {
          const response = await deleteLeadById({ id });

          if ('data' in response) {
            Alerter.show(t('common:notifications.deleted'), AlerterTypes.success);
          }
        },
      });
    },
    [deleteLeadById, t],
  );

  const openSettingTableColumnsModal = () => {
    ModalSettingTableColumns.show({
      tableName: TableWithSettings.LEADS,
    });
  };

  const config: TableConfig = useMemo(() => {
    const list: ApiLead[] = data?.list || [];

    const { page, limit } = getPagination(data?.meta);
    const skip = page * limit - limit;

    return list.map((a, i) => {
      const actionsProps: TableRowActionsProps = isOwner
        ? { onDelete: onLeadsDelete(a.id), isIconDelete: true, isIconLoading: isDeleting }
        : {};

      const result = {
        id: a.id,
        data: [
          {
            label: '',
            value: skip + i + 1,
            isHiddenLabel: true,
          },
          {
            columnId: 'date',
            label: t('leads:table.date'),
            value: Formatter.formatDate(a.date),
          },
          {
            columnId: 'time',
            label: t('common:tableLabels.time'),
            value: Formatter.formatDate(a.date, timeFormat),
          },
          {
            columnId: 'lastName',
            label: t('leads:table.lastName'),
            value: a.clientLastName,
          },
          {
            columnId: 'firstName',
            label: t('leads:table.firstName'),
            value: a.clientFirstName,
          },
          {
            columnId: 'phone',
            label: t('leads:table.phone'),
            value: a.phone,
          },
          {
            columnId: 'master',
            label: t('leads:table.master'),
            value: getUserNickname(a.user),
          },
          {
            columnId: 'offer',
            label: t('leads:table.offer'),
            value: a.offer,
          },
          {
            columnId: 'status',
            label: t('leads:table.status'),
            value: a.status,
          },
          {
            columnId: 'advertisement',
            label: t('common:tableLabels.advertisement'),
            value: a.ad || '-',
          },
          {
            columnId: 'geo',
            label: t('common:tableLabels.geo'),
            value: a.geo || '-',
          },
          {
            columnId: 'sum',
            label: t('common:tableLabels.sum'),
            value: a.sum || '-',
          },
          {
            columnId: 'domain',
            label: t('common:tableLabels.domain'),
            value: a.domain || '-',
          },
          {
            columnId: 'email',
            label: t('common:tableLabels.email'),
            value: a.email || '-',
          },
          {
            columnId: 'notes',
            label: t('common:tableLabels.notes'),
            value: a.notes || '-',
          },
          {
            columnId: 'campaign',
            label: t('common:tableLabels.campaign'),
            value: a.campaing || '-',
          },
          {
            columnId: 'subid',
            label: t('common:tableLabels.subid'),
            value: a.subid || '-',
          },
          {
            columnId: 'advertising',
            label: t('common:tableLabels.advertising'),
            value: a.advertising || '-',
          },
          {
            columnId: 'description',
            label: t('common:tableLabels.description'),
            value: a.description || '-',
          },
          {
            label: '',
            value: <TableRowActions {...actionsProps} />,
          },
        ].filter(filterBoolean),
      };

      result.data = result.data.filter(
        (a) => checkColumnVisibility(String(a.columnId)) || a.label === '',
      );

      return result;
    });
  }, [
    data?.list,
    data?.meta,
    getPagination,
    isOwner,
    onLeadsDelete,
    isDeleting,
    t,
    checkColumnVisibility,
  ]);

  return (
    <>
      <PageTitle>{t('common:sidebar.leads')}</PageTitle>

      {!isMaster && selfhost && (
        <PageHeaderContent>
          <Button
            size='large'
            variant='outlined'
            isLoading={isSynchronization}
            onClick={onLeadsSynchronization}
            startIcon={<IconRefresh color='#2374EE' />}
          >
            {t('common:buttonActions.synchronize')}
          </Button>
        </PageHeaderContent>
      )}

      <FiltersWithSidebar
        settingsButtonProps={{
          onClick: openSettingTableColumnsModal,
          style: { maxWidth: '180px' },
        }}
        hasFilterFromTo
      >
        <FilterFromTo isClearable />
        <FilterLeadStatus disableAutofill />
        {!isMaster && <FilterMaster disableAutofill />}
      </FiltersWithSidebar>

      {Boolean(config.length) && <LeadsInfo isStatusInfo />}
      {Boolean(config.length) && !isMaster && <LeadsInfo />}

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(data?.meta)}
      />
    </>
  );
};

export default LeadsPage;
