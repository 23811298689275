import { useTranslation } from 'react-i18next';
import { MouseEvent, useMemo } from 'react';

import Typography from '@/components/Typography';
import { TooltipProps } from '@/components/OnboardingTour/OnboardingTourTypes';
import {
  OnboardingToolTip,
  OnboardingToolTipHeader,
  OnboardingToolTipHBody,
  OnboardingTourFooter,
} from '@/components/OnboardingTooltip/OnboardingTooltipStyles';
import Button from '@/components/Button/Button';

import useOnboardingStatus from '@/hooks/useOnboardingStatus';

const OnboardingTooltip = ({
  index,
  step,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  size,
}: TooltipProps) => {
  const { t } = useTranslation('onboarding');
  const { setOnboardingStatus } = useOnboardingStatus();

  const CurrentPrimaryProps = useMemo(
    () => ({
      ...primaryProps,
      onClick: isLastStep
        ? (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
            setOnboardingStatus();
            primaryProps.onClick(e);
          }
        : primaryProps.onClick,
      title: '',
    }),
    [isLastStep, primaryProps, setOnboardingStatus],
  );

  const CurrentSkipProps = useMemo(
    () => ({
      ...skipProps,
      onClick: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
        skipProps.onClick(e);
        setOnboardingStatus();
      },
      title: '',
    }),
    [setOnboardingStatus, skipProps],
  );

  return (
    <OnboardingToolTip {...tooltipProps}>
      <OnboardingToolTipHeader>
        <Typography variant='body2' color='text.secondary'>
          {index + 1}/{size}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {t(step.title)}
        </Typography>
      </OnboardingToolTipHeader>
      <OnboardingToolTipHBody>
        <Typography variant='h6'>{t(step.content.title)}</Typography>
        <Typography variant='body1'>{t(step.content.message)}</Typography>
      </OnboardingToolTipHBody>

      <OnboardingTourFooter>
        {!isLastStep && (
          <Button {...CurrentSkipProps} variant='outlined' color='secondary' size='small'>
            {t('buttons.skip')}
          </Button>
        )}
        <Button {...CurrentPrimaryProps} variant='outlined' size='small'>
          {isLastStep ? t('buttons.finish') : t('buttons.next')}
        </Button>
      </OnboardingTourFooter>
    </OnboardingToolTip>
  );
};

export default OnboardingTooltip;
