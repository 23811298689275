import { useEffect, useRef } from 'react';

import { DashboardSettings } from '@/pages/DashboardsPage/DashboardsPage.types';

import { useDashboardsPageCurrentTab } from '@/hooks/useDashboardsPageCurrentTab';

import { ApiDashboardGetQuery } from '@/services/dashboard/dashboard.types';
import { useLazyGetDashboardStatisticsQuery } from '@/services/dashboard/dashboard';
import useFromToFilter from '@/filters/useFromToFilter';

const checkEqualObjects = (a: Record<string, any>, b: Record<string, any>) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const useStatisticData = (settings: DashboardSettings) => {
  const { currentTab } = useDashboardsPageCurrentTab();
  const [getStatistics, { data, isFetching }] = useLazyGetDashboardStatisticsQuery();

  const { to, from } = useFromToFilter();

  const prevRequestData = useRef<ApiDashboardGetQuery>();

  useEffect(() => {
    if (settings.tab !== currentTab) return;
    if (settings.tab && !settings?.commandsIds?.length) return;

    const data: ApiDashboardGetQuery = {
      to,
      from,
      usersIds: settings?.usersIds || undefined,
      commandsIds: settings?.commandsIds,
    };

    if (prevRequestData.current && checkEqualObjects(data, prevRequestData.current)) return;

    prevRequestData.current = data;
    getStatistics(data);
  }, [currentTab, from, getStatistics, settings, settings?.commandsIds, settings?.usersIds, to]);

  return {
    mastersIds: settings?.usersIds,
    parameters: settings?.parameters,
    commandsIds: settings?.commandsIds,

    tab: settings.tab,
    isLoading: isFetching,

    data: data,
    filterQueryKey: '',
  };
};

export type UseStatisticDataResult = ReturnType<typeof useStatisticData>;

export default useStatisticData;
