/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconDownload = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 10.75C19.4142 10.75 19.75 11.0858 19.75 11.5L19.75 19.4628L21.4943 17.7505C21.7899 17.4603 22.2647 17.4647 22.5549 17.7603C22.8451 18.0559 22.8407 18.5307 22.5451 18.8209L19.5254 21.7852C19.2337 22.0716 18.7663 22.0716 18.4746 21.7852L15.4549 18.8209C15.1593 18.5307 15.1549 18.0559 15.4451 17.7603C15.7353 17.4647 16.2101 17.4603 16.5057 17.7505L18.25 19.4628L18.25 11.5C18.25 11.0858 18.5858 10.75 19 10.75ZM12.25 20.7322C12.6642 20.7322 13 21.068 13 21.4822V24.6964C13 25.0748 13.3042 25.375 13.6712 25.375H24.3288C24.6958 25.375 25 25.0748 25 24.6964V21.4822C25 21.068 25.3358 20.7322 25.75 20.7322C26.1642 20.7322 26.5 21.068 26.5 21.4822V24.6964C26.5 25.8959 25.5316 26.875 24.3288 26.875H13.6712C12.4684 26.875 11.5 25.8959 11.5 24.6964V21.4822C11.5 21.068 11.8358 20.7322 12.25 20.7322Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconDownload;
