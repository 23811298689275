import { SelectItem } from '@/types/form';

export enum FormModalLinkAdvertisingCampaignToPipeValues {
  ADVERTISING_CAMPAIGN = 'advertisingCampaign',
  PIPE = 'pipe',
}

export interface FormModalLinkAdvertisingCampaignToPipeData {
  [FormModalLinkAdvertisingCampaignToPipeValues.ADVERTISING_CAMPAIGN]: SelectItem;
  [FormModalLinkAdvertisingCampaignToPipeValues.PIPE]: SelectItem;
}
