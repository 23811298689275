import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import Select from '@/components/Select';

import useLeadStatuses from '@/hooks/useLeadStatuses';
import useFilterProps from '@/hooks/useFilterProps';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { FilterSelectProps } from '@/types/filters';
import useStatusesFilter from '@/filters/useStatusesFilter';

const FilterLeadStatus = (props: FilterSelectProps<true>) => {
  const { t } = useTranslation(['common']);

  const { leadStatuses } = useLeadStatuses();

  const filterProps = useFilterProps(props);

  const { statuses, setStatuses } = useStatusesFilter();

  const handleValueSelect = useCallback(
    (value: SelectValue<true>) => {
      const newValue = value.join(',');
      setStatuses(newValue, 'replaceIn', true);
    },
    [setStatuses],
  );

  return (
    <Select<true>
      {...filterProps}
      isMultiple
      isClearable
      isControlFromProp
      label={t('common:tableLabels.status')}
      options={leadStatuses}
      onSelect={handleValueSelect}
      value={statuses}
    />
  );
};

export default FilterLeadStatus;
