import { providesList } from '@/services/utils';
import {
  ApiOffersFromTrackerGetQuery,
  ApiOffersFromTrackerGetResponse,
} from '@/services/tracker/tracker.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/tracker';
const serviceTag = servicesTags.tracker;

const trackerApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getOffersFromTracker: build.query<
      ApiOffersFromTrackerGetResponse,
      ApiOffersFromTrackerGetQuery
    >({
      query: (params) => ({ url: `${baseUrl}/offers`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
  }),
});

export const { useGetOffersFromTrackerQuery } = trackerApi;

export default trackerApi;
