import { RootState } from '@/types';
import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import commonApi from '@/services/common/common';
import {
  ApiCommandByIdGetQuery,
  ApiCommandByIdGetResponse,
  ApiCommandByIdPatchBody,
  ApiCommandByIdPatchResponse,
  ApiCommandDolphinTokenDeleteBody,
  ApiCommandDolphinTokenPatchBody,
  ApiCommandDolphinTokenPostBody,
  ApiCommandPostBody,
  ApiCommandPostResponse,
  ApiCommandsGetQuery,
  ApiCommandsGetResponse,
} from '@/services/commands/commands.types';
import { servicesTags } from '@/services';
import { setCommandAction } from '@/redux/user/userActions';

type EntityId = string | number;

const baseUrl = '/commands';
const dolphinTokenUrl = `${baseUrl}/dolphin-token`;
const serviceTag = servicesTags.commands;

const commandsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCommands: build.query<ApiCommandsGetResponse, ApiCommandsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
      onCacheEntryAdded: async (_, api) => {
        const state = api.getState() as RootState;
        const cacheDataLoaded = await api.cacheDataLoaded;

        const selectedCommand = state.user.command;
        const fondedCommand = cacheDataLoaded.data.list.find(
          (a) => a.id === selectedCommand.commandId,
        );

        if (!fondedCommand) return;

        const isDataDifferent =
          selectedCommand.isComandWithDolphin !== Boolean(fondedCommand.dolphinToken?.id);

        if (isDataDifferent) {
          api.dispatch(
            setCommandAction({
              commandId: selectedCommand.commandId,
              isComandWithDolphin: !selectedCommand.isComandWithDolphin,
            }),
          );
        }
      },
    }),
    getCommandById: build.query<ApiCommandByIdGetResponse, ApiCommandByIdGetQuery>({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),

    createCommand: build.mutation<ApiCommandPostResponse, ApiCommandPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateCommandById: build.mutation<ApiCommandByIdPatchResponse, ApiCommandByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    deleteCommandById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),

    addCommandDolphinToken: build.mutation<void, ApiCommandDolphinTokenPostBody>({
      query: (body) => ({ url: `${dolphinTokenUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateCommandDolphinToken: build.mutation<void, ApiCommandDolphinTokenPatchBody>({
      query: ({ id, ...body }) => ({ url: `${dolphinTokenUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    deleteCommandDolphinToken: build.mutation<void, ApiCommandDolphinTokenDeleteBody>({
      query: ({ id, ...body }) => ({ url: `${dolphinTokenUrl}/${id}`, method: 'DELETE', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
  }),
});

export const {
  useGetCommandsQuery,
  useLazyGetCommandsQuery,
  useGetCommandByIdQuery,
  useCreateCommandMutation,
  useUpdateCommandByIdMutation,
  useDeleteCommandByIdMutation,
  useAddCommandDolphinTokenMutation,
  useDeleteCommandDolphinTokenMutation,
  useUpdateCommandDolphinTokenMutation,
} = commandsApi;

export default commandsApi;
