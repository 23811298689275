import { useMemo } from 'react';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { ChildrenTableRowProps } from '@/components/ChildrenTableRow/ChildrenTableRowTypes';
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from '@/components/ChildrenTableRow/ChildrenTableRowStyled';

import { createUniqueKey } from '@/utils/createUniqueKey';

const ChildrenTableRow = ({
  childrenRows,
  colspanLength,
  childrenConfig,
}: ChildrenTableRowProps) => {
  const childrenHeadCells = useMemo(() => {
    const length = childrenConfig[0].data.length;

    return childrenConfig[0].data.map((cell, index) => {
      const key = createUniqueKey();
      const isLastCell = length === index + 1;

      return (
        <StyledTableCell $isLastCell={isLastCell} key={key}>
          {cell.label}
        </StyledTableCell>
      );
    });
  }, [childrenConfig]);

  return (
    <TableRow>
      <TableCell padding='none' colSpan={colspanLength}>
        <StyledTable>
          <TableHead>
            <StyledTableRow>{childrenHeadCells}</StyledTableRow>
          </TableHead>

          <TableBody>{childrenRows}</TableBody>
        </StyledTable>
      </TableCell>
    </TableRow>
  );
};

export default ChildrenTableRow;
