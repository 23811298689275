import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styled from '@emotion/styled';

import { ZINDEX } from '@/resources/constants';

export const StyledSidebarItemHardExpandMore = styled(ExpandMore)`
  margin-left: 8px;
`;

export const StyledSidebarItemHardExpandLess = styled(ExpandLess)`
  margin-left: 8px;
`;

export const StyledPopperContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  z-index: ${ZINDEX.SIDEBAR_ITEM};
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const StyledPopperWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 8px;
`;
