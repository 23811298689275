import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useMemo, useCallback, ChangeEvent, useState } from 'react';
import { TextField, Typography, IconButton } from '@mui/material';

import {
  StyledSelect,
  StyledWrapper,
  StyledFormElementPicker,
  StyledFormElementPickerHeader,
  StyledFormElementPickerBody,
} from '@/components/FormElementPickerWithSelect/FormElementPickerWithSelectStyled';
import { FormElementData } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import IconTrash from '@/icons/IconTrash';
import IconPlus from '@/icons/IconPlus';

import { SelectValue } from '@/hooks/useControlSelectValue';
import useCommandMasters from '@/hooks/useCommandMasters';

import { FormElementPickerWithSelectProps } from '@/types/form';
import { OperationMemberType } from '@/services/operations/operations.types';
import { MembersModelCustom } from '@/modals/ModalExpenseOperation/ModalExpenseOperationTypes';

// TODO: переделать компонент полностью на кастомизированный

const FormElementPickerWithSelect = ({
  name,
  maxLength,
  ...restProps
}: FormElementPickerWithSelectProps) => {
  const { t } = useTranslation();

  const elementType = restProps.type;
  const isCustomType = restProps.type === 'custom';

  const [selectedMaster, setSelectedMaster] = useState<SelectValue<true>>([]);
  const { masters } = useCommandMasters();

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormElementData<MembersModelCustom>>();

  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const disableAddButton = isCustomType
    ? restProps.options.length === fields.length
    : masters.length === fields.length;

  const handleChangePercent = useCallback(
    (_id: string, index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors();

      const fieldName = `${name}[${index}].value.percent`;
      const value = e.target.value.replace(/[^\d]/gm, '');

      // @ts-ignore
      setValue(fieldName, value);
    },
    [clearErrors, name, setValue],
  );

  const handleSelectUser = useCallback(
    <Multiple extends boolean>(_id: string, index: number, prevValue: string) =>
      (memberId: SelectValue<Multiple>) => {
        if (fields.some((a) => a.value?.memberId === memberId)) return;
        setSelectedMaster((prevState: SelectValue<true>) => {
          const filteredOptions = prevState.filter((a) => a !== prevValue);
          return [...filteredOptions, memberId] as SelectValue<true>;
        });

        const fieldName = `${name}[${index}].value.memberId`;
        // @ts-ignore
        setValue(fieldName, memberId);
      },
    [fields, name, setValue],
  );

  const handleDelete = useCallback(
    (_id: string, index: number, memberId?: string) => () => {
      clearErrors();
      remove(index);
      setSelectedMaster((prevState) => prevState.filter((item) => item !== memberId));
    },
    [clearErrors, remove],
  );

  const handleAddItem = () => {
    if (disableAddButton) return;

    clearErrors();
    append({
      id: 'new',
      value: {
        percent: '0',
        memberId: '',
        type: OperationMemberType.USER,
      },
    });
  };

  const getAvailableOptions = useCallback(
    (value?: string) => {
      const options = isCustomType ? restProps.options : masters;
      return options.filter((item) =>
        item.value === value ? true : !selectedMaster.includes(item.value),
      );
    },
    [isCustomType, restProps, masters, selectedMaster],
  );

  const config = useMemo(() => {
    const countLabel = elementType === 'operation' ? '%' : t('formLabels.quantity');
    const countMaxLength = (elementType === 'operation' ? 3 : maxLength) || undefined;

    return fields.map(({ id, value }, i) => {
      return (
        <StyledFormElementPickerBody key={id}>
          <StyledWrapper>
            <StyledSelect
              fullWidth
              size='small'
              label={isCustomType ? restProps.selectLabel : t('roles.user')}
              options={getAvailableOptions(value?.memberId)}
              value={value?.memberId}
              onSelect={handleSelectUser(id, i, value?.memberId || '')}
            />

            <TextField
              style={{ maxWidth: '120px' }}
              error={Boolean(errors[name]?.message)}
              type='string'
              // May be useful later
              // value={value?.percent === 0 ? undefined : value?.percent}
              value={value?.percent}
              fullWidth
              inputProps={{ maxLength: countMaxLength }}
              label={isCustomType ? restProps.inputLabel : countLabel}
              onChange={handleChangePercent(id, i)}
              size='small'
            />
          </StyledWrapper>
          <IconTrash onClick={handleDelete(id, i, value?.memberId)} />
        </StyledFormElementPickerBody>
      );
    });
  }, [
    elementType,
    t,
    maxLength,
    fields,
    isCustomType,
    restProps,
    getAvailableOptions,
    handleSelectUser,
    errors,
    name,
    handleChangePercent,
    handleDelete,
  ]);

  return (
    <StyledFormElementPicker>
      <StyledFormElementPickerHeader>
        <Typography variant='h6'>
          {isCustomType ? restProps.label : t('buttonActions.distribute')}
        </Typography>
        <IconButton onClick={handleAddItem} color='primary' disabled={disableAddButton}>
          <IconPlus color={disableAddButton ? 'gray' : '#2374EE'} />
        </IconButton>
      </StyledFormElementPickerHeader>

      {errors[name] && (
        <Typography variant='body1' color='error' sx={{ marginTop: '16px' }}>
          {errors[name]?.message}
        </Typography>
      )}

      {config}
    </StyledFormElementPicker>
  );
};

export default FormElementPickerWithSelect;
