import { UseFormReturn } from 'react-hook-form';
import { TFunction } from 'i18next';

import { ElementObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

import { MembersModelCustom } from '@/modals/ModalExpenseOperation/ModalExpenseOperationTypes';
import { FormModalConsumablesData } from '@/modals/ModalConsumables/ModalConsumablesTypes';
// eslint-disable-next-line max-len
import { FormModalConsumableDistributionData } from '@/modals/ModalConsumableDistribution/ModalConsumableDistributionTypes';

export type PossibleFieldValues = UseFormReturn<
  FormModalConsumablesData | FormModalConsumableDistributionData
>;

const convertDistributions = (
  data: ElementObject<MembersModelCustom>[],
  amount: number,
  methods: PossibleFieldValues,
  t: TFunction<'common', undefined, 'common'>,
) => {
  const filteredDistributions = data.filter((a) => Number(a.value!.percent) > 0);
  if (!filteredDistributions.length) return [];

  const isAvailableDistribution =
    filteredDistributions.reduce((prev, current) => prev + Number(current.value!.percent), 0) >
    amount;

  if (isAvailableDistribution) {
    // @ts-ignore
    methods.setError('distributions', { message: t('cannotBeDistributed', { amount }) });
    return 'error';
  }

  return filteredDistributions.map((a) => ({
    type: a.value!.type,
    id: a.value!.memberId,
    amount: Number(a.value!.percent),
  }));
};

export default convertDistributions;
