/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconMagnet = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        d='M24.8849 16.9531C24.8089 16.8771 24.7187 16.8167 24.6194 16.7756C24.5201 16.7344 24.4136 16.7132 24.3061 16.7132C24.1986 16.7132 24.0922 16.7344 23.9929 16.7756C23.8936 16.8167 23.8034 16.8771 23.7274 16.9531L18.9348 21.7457C18.5799 22.1007 18.0985 22.3002 17.5965 22.3003C17.0945 22.3004 16.613 22.101 16.258 21.7461C15.9029 21.3912 15.7034 20.9098 15.7034 20.4078C15.7033 19.9058 15.9026 19.4243 16.2576 19.0693L21.0469 14.2726C21.1229 14.1966 21.1833 14.1064 21.2244 14.0071C21.2656 13.9078 21.2868 13.8014 21.2868 13.6939C21.2868 13.5864 21.2656 13.4799 21.2244 13.3806C21.1833 13.2813 21.1229 13.1911 21.0469 13.1151L18.1749 10.2399C18.0989 10.1639 18.0087 10.1035 17.9094 10.0624C17.8101 10.0212 17.7037 10 17.5962 10C17.4887 10 17.3823 10.0212 17.2829 10.0624C17.1836 10.1035 17.0934 10.1639 17.0175 10.2399L12.2249 15.0325C10.8003 16.4571 10 18.3892 10 20.4038C10 22.4184 10.8003 24.3506 12.2249 25.7751C13.6494 27.1997 15.5816 28 17.5962 28C19.6108 28 21.5429 27.1997 22.9675 25.7751L27.7601 20.9825C27.8361 20.9066 27.8965 20.8164 27.9376 20.7171C27.9788 20.6178 28 20.5113 28 20.4038C28 20.2963 27.9788 20.1899 27.9376 20.0906C27.8965 19.9913 27.8361 19.9011 27.7601 19.8251L24.8849 16.9531ZM17.5966 11.9789L19.3144 13.6967L18.0759 14.9335L16.3582 13.2157L17.5966 11.9789ZM21.8109 24.6177C20.6834 25.6843 19.1843 26.269 17.6324 26.2475C16.0806 26.226 14.5983 25.5999 13.5008 24.5025C12.4033 23.405 11.7773 21.9227 11.7558 20.3708C11.7343 18.8189 12.319 17.3199 13.3856 16.1924L15.1999 14.3716L16.9177 16.0893L15.1009 17.9085C14.4581 18.5741 14.1024 19.4655 14.1104 20.3907C14.1184 21.316 14.4895 22.2011 15.1438 22.8554C15.7981 23.5097 16.6832 23.8808 17.6085 23.8888C18.5337 23.8968 19.4251 23.5411 20.0906 22.8983L21.9082 21.0807L23.626 22.7985L21.8109 24.6177ZM24.7851 21.6435L23.0673 19.9257L24.3066 18.6864L26.0243 20.4042L24.7851 21.6435Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconMagnet;
