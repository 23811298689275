import { invalidatesTags } from '@/services/utils';
import {
  ApiDashboardAllSettingsPatchBody,
  ApiDashboardAllSettingsPatchResponse,
  ApiDashboardGetQuery,
  ApiDashboardGetResponse,
  ApiDashboardSettingsDeleteParams,
  ApiDashboardSettingsGetParams,
  ApiDashboardSettingsGetResponse,
  ApiDashboardSettingsPatchBody,
  ApiDashboardSettingsPatchResponse,
  ApiDashboardSettingsPostBody,
  ApiDashboardSettingsPostResponse,
} from '@/services/dashboard/dashboard.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/dashboard';
const baseSettingsUrl = `${baseUrl}/settings`;
const serviceTag = servicesTags.dashboard;

const dashboardApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getDashboardStatistics: build.query<ApiDashboardGetResponse, ApiDashboardGetQuery>({
      query: (params) => ({ url: `${baseUrl}/statistics`, params: params || undefined }),
    }),
    getDashboardSettings: build.query<
      ApiDashboardSettingsGetResponse,
      ApiDashboardSettingsGetParams
    >({
      query: () => ({ url: baseSettingsUrl }),
      providesTags: invalidatesTags(serviceTag),
    }),
    postDashboardSettings: build.mutation<
      ApiDashboardSettingsPostResponse,
      ApiDashboardSettingsPostBody
    >({
      query: (body) => ({ url: baseSettingsUrl, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    patchAllDashboardSettings: build.mutation<
      ApiDashboardAllSettingsPatchResponse,
      ApiDashboardAllSettingsPatchBody
    >({
      query: (body) => ({
        url: baseSettingsUrl,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    patchDashboardSettings: build.mutation<
      ApiDashboardSettingsPatchResponse,
      ApiDashboardSettingsPatchBody
    >({
      query: ({ id, ...body }) => ({
        url: `${baseSettingsUrl}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    deleteDashboardSettings: build.mutation<undefined, ApiDashboardSettingsDeleteParams>({
      query: ({ id }) => ({ url: `${baseSettingsUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
  }),
});

export const {
  useGetDashboardStatisticsQuery,
  useLazyGetDashboardStatisticsQuery,
  useGetDashboardSettingsQuery,
  usePatchDashboardSettingsMutation,
  usePatchAllDashboardSettingsMutation,
  usePostDashboardSettingsMutation,
  useDeleteDashboardSettingsMutation,
} = dashboardApi;

export default dashboardApi;
