import { UseFormReturn } from 'react-hook-form';
import { TFunction } from 'i18next';

import { SelectItem } from '@/types/form';
import {
  FormModalPipeConsumableData,
  FormModalPipeConsumableValues,
} from '@/modals/ModalPipeConsumable/ModalPipeConsumableTypes';

export const isNotAvailableCount = (
  data: FormModalPipeConsumableData,
  consumables: (SelectItem & {
    availableCount: number;
  })[],
  { setError, clearErrors }: UseFormReturn<FormModalPipeConsumableData>,
  t: TFunction<'common', undefined, 'common'>,
) => {
  const foundConsumable = consumables.find((a) => a.value === data.consumableId);

  if (!foundConsumable) {
    setError(FormModalPipeConsumableValues.AMOUNT, {
      message: t('impossibleToDetermineMaximum'),
    });
    return true;
  }

  if (foundConsumable.availableCount < Number(data.amount)) {
    setError(FormModalPipeConsumableValues.AMOUNT, {
      message: t('cannotChooseMore', { count: foundConsumable.availableCount }),
    });
    return true;
  }

  clearErrors(FormModalPipeConsumableValues.AMOUNT);
};
