import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

import Autocomplete from '@/components/Autocomplete';

import useFormValidation from '@/hooks/useFormValidation';

import { FormElementAutocompleteProps } from '@/types/form';

const FormElementAutocomplete = ({ name, rules, ...restProps }: FormElementAutocompleteProps) => {
  const { control } = useFormContext();

  const { fieldValidate } = useFormValidation();

  const render: ControllerProps['render'] = useCallback(
    ({ field: { ref, onChange, ...restFieldProps }, fieldState }) => {
      const { isError, description } = fieldValidate(fieldState.error);

      return (
        <Autocomplete
          {...restProps}
          {...restFieldProps}
          onChange={(_, value) => onChange(value)}
          InputProps={{ inputRef: ref, helperText: description, error: isError }}
        />
      );
    },
    [fieldValidate, restProps],
  );

  return <Controller control={control} name={name} render={render} rules={rules} />;
};

export default FormElementAutocomplete;
