import { Provider } from 'react-redux';
import { FC } from 'react';

import store from '@/redux';

const ReduxProvider: FC = ({ children }) => {
  // NOTE: No overload matches this call
  // @ts-ignore
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
