import actionRedux from '@/utils/ApiReduxAction';

import { AppThunkResponseType } from '@/types';
import {
  ApiFileGetResponse,
  ApiFileGetParams,
  ApiFilePostBody,
  ApiFilePostResponse,
} from '@/redux/files/files.types';
import FilesApi from '@/redux/files/files';

export const downloadFile = actionRedux(
  (params: ApiFileGetParams): AppThunkResponseType<ApiFileGetResponse> =>
    async () => {
      const response = await FilesApi.downloadFile(params);
      return response.data;
    },
);

export const uploadFileAction = actionRedux(
  (body: ApiFilePostBody): AppThunkResponseType<ApiFilePostResponse> =>
    async () => {
      const response = await FilesApi.uploadFile(body);
      return response.data;
    },
);
