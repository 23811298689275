import { SelectItem } from '@/types/form';
import { ApiOfferPaymentModel, ApiOfferPlatform } from '@/services/offers/offers.types';

export enum FormModalOfferValues {
  GEO = 'geo',
  NAME = 'name',
  PARTNER = 'partner',
  COMMENT = 'comment',
  USER_IDS = 'userIds',
  PLATFORM = 'platform',
  PAYMENT_MODEL = 'paymentModel',
  PAYMENT_AMOUNT = 'paymentAmount',
  EMPLOYEE_ID_COLUMN = 'employeeIdColumn',
}

export interface FormModalOfferData {
  [FormModalOfferValues.GEO]: SelectItem | null;
  [FormModalOfferValues.NAME]: string;
  [FormModalOfferValues.PARTNER]: string;
  [FormModalOfferValues.COMMENT]: string;
  [FormModalOfferValues.PAYMENT_AMOUNT]: string;
  [FormModalOfferValues.PLATFORM]: ApiOfferPlatform;
  [FormModalOfferValues.PAYMENT_MODEL]: ApiOfferPaymentModel;
  [FormModalOfferValues.USER_IDS]: { value: string }[];
  [FormModalOfferValues.EMPLOYEE_ID_COLUMN]: string;
}
