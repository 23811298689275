import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import Typography from '@/components/Typography';
import StatisticBlockItem from '@/components/StatisticBlockItem';
import StatisticBlock from '@/components/StatisticBlock';

import { getUserNickname } from '@/utils/getUserNickname';

import useUserCommand from '@/hooks/useUserCommand';

import { useGetLeadsStatisticsQuery } from '@/services/leads/leads';
import useStatusesFilter from '@/filters/useStatusesFilter';
import useMasterFilter from '@/filters/useMasterFilter';
import useFromToFilter from '@/filters/useFromToFilter';

type LeadsInfoProps = { isStatusInfo?: boolean };

const LeadsInfo = ({ isStatusInfo = false }: LeadsInfoProps) => {
  const { t } = useTranslation(['common']);

  const { commandId } = useUserCommand();
  const { to, from } = useFromToFilter();
  const { statuses } = useStatusesFilter();
  const { masterId } = useMasterFilter(undefined);

  const { data, isLoading } = useGetLeadsStatisticsQuery({
    to,
    from,
    statuses,
    commandId,
    userId: masterId,
  });

  const isEmptyStatistic = isStatusInfo
    ? !data?.statisticsByStatus.length
    : !data?.statistics.length;

  const config = useMemo(() => {
    if (!data || isEmptyStatistic) {
      return (
        <Typography variant='body2' color='text.secondary'>
          {t('common:noDataAvailable')}
        </Typography>
      );
    }

    let totalCount = 0;

    const statisticsData = isStatusInfo ? data.statisticsByStatus : data.statistics;
    const result = statisticsData.map((a) => {
      totalCount += Number(a.count);
      const isUserStatistic = 'user' in a;
      return (
        <StatisticBlockItem
          key={isUserStatistic ? a.user.id : a.status}
          label={isUserStatistic ? getUserNickname(a.user) : a.status}
          value={a.count}
        />
      );
    });

    return [
      <StatisticBlockItem
        key={'totalCount'}
        label={t('common:tableLabels.total')}
        value={totalCount}
      />,
      ...result,
    ];
  }, [data, isEmptyStatistic, isStatusInfo, t]);

  return (
    <StatisticBlock
      isLoading={isLoading}
      hasData={!isEmptyStatistic}
      title={isStatusInfo ? t('statisticsOnStatuses') : t('statisticsOnMasters')}
    >
      {config}
    </StatisticBlock>
  );
};

export default LeadsInfo;
