import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import UserRole from '@/pages/CommandPage/UserRole';

import { CustomTooltip } from '@/components/Tooltip/Tooltip';
import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';

import IconSmallCircle from '@/icons/IconSmallCircle';

import { getUserNickname } from '@/utils/getUserNickname';
import getBooleanArchivingStatus from '@/utils/getBooleanArchivingStatus';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useUserCommand from '@/hooks/useUserCommand';
import { usePageParams } from '@/hooks/usePageParams';
import useCheckingAccessToArchiving from '@/hooks/useCheckingAccessToArchiving';

import { UsersTableHookResponse } from '@/types/hooks';
import { ApiUser } from '@/services/users/users.types';
import {
  useBanUserByIdMutation,
  useGetCompanyUsersQuery,
  useUnBanUserByIdMutation,
} from '@/services/users/users';
import { useGetCommandByIdQuery } from '@/services/commands/commands';
import ModalUser from '@/modals/ModalUser/ModalUser';
import ModalConfirm from '@/modals/ModalConfirm/ModalConfirm';
import useUserRoleFilter from '@/filters/useUserRoleFilter';
import useArchivedFilter from '@/filters/useArchivedFilter';

const useUsersTable = <T extends boolean>(isCompanyPage: T): UsersTableHookResponse<T> => {
  const { t } = useTranslation(['common', 'command']);

  const { id } = usePageParams();
  const { commandId: userCommandId } = useUserCommand();
  const { archived } = useArchivedFilter();
  const { userRole } = useUserRoleFilter();
  const { isHighLevelUser } = useUserInfo();
  const { tracker, selfhost } = useUserCompanyInfo();
  const checkingAccessToArchiving = useCheckingAccessToArchiving(isCompanyPage);

  const commandId = isHighLevelUser ? id : userCommandId;

  const {
    data: commandById,
    isLoading: isCommandByIdLoading,
    isFetching: isCommandByIdFetching,
  } = useGetCommandByIdQuery({ id: commandId || '' }, { skip: !commandId && isCompanyPage });
  const {
    data: companyUsers,
    isLoading: isCompanyUsersLoading,
    isFetching: isCompanyUsersFetching,
  } = useGetCompanyUsersQuery(
    { role: userRole, isEnabled: getBooleanArchivingStatus(archived) },
    { skip: !isCompanyPage },
  );

  const [unBanUserById] = useUnBanUserByIdMutation();
  const [banUserById] = useBanUserByIdMutation();

  const data = isCompanyPage ? companyUsers : commandById;

  const handleUserArchive = useCallback(
    (user: ApiUser & { nickname: string }) => () => {
      ModalConfirm.show({
        continueLabel: t('common:buttonActions.archive'),
        title: t('common:notifications.confirmActionOnPage'),
        subTitle: t('command:confirmArchive', { nickname: user.nickname }),
        onlyContinue: true,
        onContinue: () =>
          banUserById({
            id: user.id,
            commandId: user.command?.id,
          }),
      });
    },
    [banUserById, t],
  );

  const handleUserUnArchive = useCallback(
    (user: ApiUser) => () => {
      unBanUserById({
        id: user.id,
        commandId: user.command?.id,
      });
    },
    [unBanUserById],
  );

  const openEditUserModal = useCallback(
    (userInfo: ApiUser) => () => {
      if (!userInfo.command?.id) return;
      ModalUser.show({ ...userInfo, commandId: userInfo.command.id });
    },
    [],
  );

  const config: TableConfig = useMemo(() => {
    let list: ApiUser[] = [];

    if (data) {
      if ('users' in data) list = [...data.users, ...data.allowedUsers];
      else list = data.list;
    }

    return list.map((a, i) => {
      const userNickname = getUserNickname(a);

      const onArchiveClick = a.isEnabled
        ? handleUserArchive({ ...a, nickname: userNickname })
        : handleUserUnArchive({ ...a });

      const actionProps: TableRowActionsProps = isCompanyPage
        ? {}
        : {
            isIconEdit: true,
            onEdit: openEditUserModal(a),
          };

      const hasAccessToArchiving = checkingAccessToArchiving(a);

      if (a.isEnabled) {
        actionProps.onArchive = hasAccessToArchiving ? onArchiveClick : undefined;
      } else {
        actionProps.onUnarchive = hasAccessToArchiving ? onArchiveClick : undefined;
      }

      return {
        id: a.id,
        data: [
          {
            label: 'ID',
            value: i + 1,
            isHiddenLabel: true,
          },
          isCompanyPage && {
            label: '',
            value: (
              <>
                <CustomTooltip
                  arrow
                  title={t(`common:employeeState.${a.isEnabled ? 'active' : 'inactive'}`)}
                >
                  <IconSmallCircle status={a.isEnabled ? 'active' : 'inactive'} />
                </CustomTooltip>
              </>
            ),
          },
          {
            label: t('common:roles.employee'),
            value: userNickname,
          },
          {
            label: t('common:sidebar.email'),
            value: a.email,
          },
          {
            label: t('common:role'),
            value: <UserRole user={a} isAllRoles={isCompanyPage} />,
          },
          isCompanyPage && {
            label: t('common:roles.team'),
            value:
              [a.command, ...(a.allowedCommands || [])]
                .map((a) => a?.name)
                .filter(filterBoolean)
                .join(', ') || '-',
          },
          Boolean(tracker) && {
            label: 'TrackerId',
            value: a.trackerId || '-',
          },
          Boolean(selfhost) && {
            label: 'SelfhostId',
            value: a.leadId || '-',
          },
          {
            label: 'BrocardId',
            value:
              a.servicesIntegrations.map((a) => a.paymentServiceIntegrationId).join(', ') || '-',
          },
          {
            label: '',
            value: <TableRowActions {...actionProps} />,
          },
        ].filter(filterBoolean),
      };
    });
  }, [
    checkingAccessToArchiving,
    data,
    handleUserArchive,
    handleUserUnArchive,
    isCompanyPage,
    openEditUserModal,
    selfhost,
    t,
    tracker,
  ]);

  return {
    config,
    data,
    isLoading: isCompanyPage
      ? isCompanyUsersLoading || isCompanyUsersFetching
      : isCommandByIdLoading || isCommandByIdFetching,
  } as UsersTableHookResponse<T>;
};

export default useUsersTable;
