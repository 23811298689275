import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const OwnerContentPage = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant='h1' color='text.secondary'>
        {t('pleaseCreateCommand')}
      </Typography>
    </div>
  );
};

export default OwnerContentPage;
