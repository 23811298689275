import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';

import {
  FormLoginProps,
  FormLoginData,
  FormLoginValues,
} from '@/pages/LoginPage/FormLogin/FormLoginTypes';
import { StyledGrid, StyledLocalizationWrapper } from '@/pages/LoginPage/FormLogin/FormLoginStyled';

import Typography from '@/components/Typography';
import Tabs from '@/components/Tabs';
import Localization from '@/components/Localization/Localization';
import FormElement from '@/components/FormElement';
import Button from '@/components/Button/Button';
import { StyledLoginForm } from '@/components/AuthPageForm';

import clearAllCache from '@/utils/clearAllCache';
import FormRuler from '@/utils/FormRuler';

import useQueryParam from '@/hooks/useQueryParam';
import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';

import { MutationResponse } from '@/types/api';
import { useLazyGetInviteByHashQuery } from '@/services/invites/invites';
import {
  useLoginUserMutation,
  useRegisterByInvitationMutation,
  useRegisterOwnerMutation,
} from '@/services/auth/auth';

const isDevelopmentMode = process.env.NODE_ENV === 'development';

const defaultValues: FormLoginData = {
  [FormLoginValues.EMAIL]: isDevelopmentMode ? 'owner1@yandex.ru' : '',
  [FormLoginValues.COMPANY]: '',
  [FormLoginValues.PASSWORD]: isDevelopmentMode ? '12345678' : '',
  [FormLoginValues.FIRST_NAME]: '',
  [FormLoginValues.LAST_NAME]: '',
};

enum PossibleLoginTabs {
  signIn = 'signIn',
  signUp = 'signUp',
}

const FormLogin = ({ setForgotPasswordState }: FormLoginProps) => {
  const { t } = useTranslation(['login', 'common']);
  const methods = useForm<FormLoginData>({ defaultValues });

  const { localizeUserRole } = useLocalizeUserRole();

  const isWasResetCache = useRef(false);

  const password = methods.watch(FormLoginValues.PASSWORD);

  const [loginUser, { isLoading: isLoginLoading }] = useLoginUserMutation();
  const [registerOwner, { isLoading: isRegistrationOwnerLoading }] = useRegisterOwnerMutation();
  const [registerByInvitation, { isLoading: isRegistrationByInvitationLoading }] =
    useRegisterByInvitationMutation();

  const dispatch = useDispatch();
  const [inviteHash, setInviteHash] = useQueryParam('invite', '');
  const [activeTab, setActiveTab] = useState(
    inviteHash ? PossibleLoginTabs.signUp : PossibleLoginTabs.signIn,
  );

  const [getInviteByHash, { data: inviteInfo }] = useLazyGetInviteByHashQuery();

  useEffect(() => {
    if (!inviteHash) return;
    getInviteByHash({ hash: inviteHash });
  }, [getInviteByHash, inviteHash]);

  const isRegistration = activeTab === PossibleLoginTabs.signUp;

  const handleClickForgotPassword = useCallback(() => {
    setForgotPasswordState(true);
  }, [setForgotPasswordState]);

  const handleSubmit = async (data: FormLoginData) => {
    const callback = (response: MutationResponse) => {
      if ('data' in response) {
        setInviteHash('');
      }
    };

    if (!isWasResetCache.current) {
      // NOTE
      // CACHE должен быть сброшен до того как мы вошли в систему

      clearAllCache(dispatch);
      isWasResetCache.current = true;
    }

    if (isRegistration) {
      const response = inviteHash
        ? await registerByInvitation({ ...data, inviteHash })
        : await registerOwner(data);

      callback(response);
      return;
    }

    const response = await loginUser(data);
    callback(response);
  };

  const baseFormFields = useMemo(() => {
    return (
      <>
        <Grid item>
          <FormElement
            component='input'
            label={t('common:formLabels.email')}
            name={FormLoginValues.EMAIL}
            rules={{ ...FormRuler.email, ...FormRuler.required }}
          />
        </Grid>

        <Grid item>
          <FormElement
            type='password'
            component='input'
            label={t('common:formLabels.password')}
            name={FormLoginValues.PASSWORD}
            rules={{ ...FormRuler.requiredPassword }}
          />
        </Grid>
      </>
    );
  }, [t]);

  const loginForm = useMemo(() => {
    return (
      <>
        {baseFormFields}

        <Grid item>
          <Typography color='text.secondary' variant='body1' onClick={handleClickForgotPassword}>
            {t('login:signIn.forgotPassword')}
          </Typography>
        </Grid>
      </>
    );
  }, [baseFormFields, handleClickForgotPassword, t]);

  const inviteMessage = useMemo(() => {
    if (!inviteInfo) return;

    if (inviteInfo?.command) {
      return `${t('common:roles.company')} «${inviteInfo.command.company.name}» — ${t(
        'common:roles.team',
      )} «${inviteInfo.command.name}» — ${t('common:role')}
          «${localizeUserRole(inviteInfo.role)}»`;
    }

    if (inviteInfo?.company) {
      return `${t('common:roles.company')} «${inviteInfo.company.name}» — ${t('common:role')}
          «${localizeUserRole(inviteInfo.role)}»`;
    }

    return `${t('common:role')} «${localizeUserRole(inviteInfo!.role)}»`;
  }, [inviteInfo, localizeUserRole, t]);

  const registerForm = useMemo(() => {
    if (inviteHash) {
      return (
        <>
          {inviteInfo && (
            <Grid item>
              <Typography sx={{ fontWeight: 500 }}>{inviteMessage}</Typography>
            </Grid>
          )}
          <Grid item>
            <FormElement
              component='input'
              label={t('common:formLabels.firstName')}
              name={FormLoginValues.FIRST_NAME}
              rules={{ ...FormRuler.requiredInput }}
            />
          </Grid>
          <Grid item>
            <FormElement
              component='input'
              label={t('common:formLabels.lastName')}
              name={FormLoginValues.LAST_NAME}
              rules={{ ...FormRuler.requiredInput }}
            />
          </Grid>
          <Grid item>
            <FormElement
              type='password'
              component='input'
              label={t('common:formLabels.password')}
              name={FormLoginValues.PASSWORD}
              rules={{ ...FormRuler.requiredPassword }}
            />
          </Grid>
          <Grid item>
            <FormElement
              type='password'
              component='input'
              label={t('common:formLabels.passwordConfirm')}
              name={FormLoginValues.CONFIRM_PASSWORD}
              rules={{ ...FormRuler.requiredPassword, ...FormRuler.comparePasswords(password) }}
            />
          </Grid>
        </>
      );
    }

    return (
      <>
        {baseFormFields}

        <Grid item>
          <FormElement
            type='password'
            component='input'
            label={t('common:formLabels.passwordConfirm')}
            name={FormLoginValues.CONFIRM_PASSWORD}
            rules={{ ...FormRuler.requiredPassword, ...FormRuler.comparePasswords(password) }}
          />
        </Grid>

        <Grid item>
          <FormElement
            component='input'
            label={t('common:formLabels.firstName')}
            name={FormLoginValues.FIRST_NAME}
            rules={{ ...FormRuler.requiredInput }}
          />
        </Grid>

        <Grid item>
          <FormElement
            component='input'
            label={t('common:formLabels.lastName')}
            name={FormLoginValues.LAST_NAME}
            rules={{ ...FormRuler.requiredInput }}
          />
        </Grid>

        <Grid item>
          <FormElement
            component='input'
            label={t('common:formLabels.companyName')}
            name={FormLoginValues.COMPANY}
            rules={{ ...FormRuler.requiredInput }}
          />
        </Grid>
      </>
    );
  }, [baseFormFields, inviteHash, inviteInfo, inviteMessage, password, t]);

  useEffect(() => {
    methods.reset();
  }, [isRegistration, methods]);

  const tabs = useMemo(
    () => [
      {
        value: PossibleLoginTabs.signIn,
        label: t('login:signIn.tabTitle'),
      },
      {
        value: PossibleLoginTabs.signUp,
        label: t('login:signUp.tabTitle'),
      },
    ],
    [t],
  );
  const buttonLabel = isRegistration ? t('login:signUp.submit') : t('login:signIn.submit');

  const currentForm = isRegistration ? registerForm : loginForm;
  const isLoading =
    isLoginLoading || isRegistrationOwnerLoading || isRegistrationByInvitationLoading;

  return (
    <>
      <Tabs options={tabs} value={activeTab} setValue={setActiveTab} />

      <StyledLoginForm onSubmit={handleSubmit} contextMethods={methods}>
        <Grid container direction='column' spacing={6}>
          {currentForm}

          <StyledGrid item $withoutPadding $isCustomPadding>
            <Button isLoading={isLoading} size='large' type='submit' fullWidth>
              {buttonLabel}
            </Button>
          </StyledGrid>
        </Grid>
      </StyledLoginForm>

      <StyledLocalizationWrapper>
        <Typography variant='body1'>{t('common:language')}</Typography>
        <Localization />
      </StyledLocalizationWrapper>
    </>
  );
};

export default FormLogin;
