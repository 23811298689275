import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useCallback, ChangeEvent } from 'react';
import { DialogActions, FormControlLabel, Switch, Stack, FormHelperText } from '@mui/material';

import Select from '@/components/Select';
import Modal from '@/components/Modal';
import Button from '@/components/Button';

import {
  DEFAULT_LEADS_VISIBLE_COLUMNS,
  DEFAULT_OFFERS_VISIBLE_COLUMNS,
  leadsColumns,
  offersColumns,
  STORAGE_LEADS_VISIBLE_COLUMNS,
  STORAGE_OFFERS_VISIBLE_COLUMNS,
} from '@/resources/constantsTableSettings';

import useLocalizeTableColumns from '@/hooks/useLocalizeTableColumns';
import { SelectValue } from '@/hooks/useControlSelectValue';

import { TableWithSettings } from '@/types/table';
import { selectTableSettings } from '@/redux/user/userGetters';
import { setTableSettingsAction } from '@/redux/user/userActions';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';

type ModalProps = { tableName: TableWithSettings };

const getNecessaryTools = (tableName: ModalProps['tableName']) => {
  const tableColumns = {
    [TableWithSettings.OFFERS]: [...offersColumns],
    [TableWithSettings.LEADS]: [...leadsColumns],
  };

  const tableDefaultColumns = {
    [TableWithSettings.OFFERS]: [...DEFAULT_OFFERS_VISIBLE_COLUMNS],
    [TableWithSettings.LEADS]: [...DEFAULT_LEADS_VISIBLE_COLUMNS],
  };

  const storageKeys = {
    [TableWithSettings.OFFERS]: STORAGE_OFFERS_VISIBLE_COLUMNS,
    [TableWithSettings.LEADS]: STORAGE_LEADS_VISIBLE_COLUMNS,
  };

  return {
    tableName: tableName,
    storageKey: storageKeys[tableName],

    columns: tableColumns[tableName],
    defaultColumns: tableDefaultColumns[tableName],
  };
};

const ModalSettingTableColumns: StaticModalWrappedComponent<ModalProps> = (props) => {
  const tableName = props.data!.tableName;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const defaultSelected = useSelector(selectTableSettings(tableName));

  const { localizeTableColumns } = useLocalizeTableColumns(tableName);
  const { columns, defaultColumns, storageKey } = getNecessaryTools(tableName);

  const [errorState, setErrorState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<SelectValue<true>>(defaultSelected);
  const [switchState, setSwitchState] = useState(columns.length === selectedColumns.length);

  const options = useMemo(() => {
    const sortedColumns = [...columns].sort();
    return sortedColumns.map((label) => ({ label: localizeTableColumns(label), value: label }));
  }, [columns, localizeTableColumns]);

  const handleSelectColumn = useCallback(
    (value: SelectValue<true>) => {
      setSelectedColumns(value);
      setErrorState(!value.length);
      setSwitchState(columns.length === value.length);
    },
    [columns.length],
  );

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? handleSelectColumn(columns) : handleSelectColumn(defaultColumns);
  };

  const handleSaveSelectedColumns = () => {
    if (errorState) return;
    dispatch(setTableSettingsAction(tableName, selectedColumns, storageKey));
    props.onClose();
  };

  return (
    <Modal {...props} title={t('configuringTableColumns')} hasEmptyPadding>
      <Stack direction='column' spacing={6}>
        <FormControlLabel
          control={<Switch checked={switchState} onChange={handleSwitch} />}
          label={t('selectAll')}
        />

        <div>
          <Select<true>
            fullWidth
            isMultiple
            options={options}
            label={t('availableColumns')}
            value={selectedColumns}
            onSelect={handleSelectColumn}
            isError={errorState}
          />

          {errorState && <FormHelperText error>{t('selectOneParameter')}</FormHelperText>}
        </div>
      </Stack>

      <DialogActions>
        <Button onClick={handleSaveSelectedColumns}>{t('buttonActions.save')}</Button>
      </DialogActions>
    </Modal>
  );
};

export default withStaticModal<ModalProps>(ModalSettingTableColumns);
