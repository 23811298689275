export interface CompanyLeadsFormProps {
  isChecked: boolean;
  onChangeState: () => void;

  isWriteHeaders: boolean;
  onChangeHeadersState: () => void;
}

export enum CompanyLeadsFormValues {
  LEADS_API_URL = 'leadsApiUrl', // FormModalCompanyValues.SELFHOST_LEADS_API_URL
  LEADS_API_HEADERS = 'leadsApiHeaders', // FormModalCompanyValues.SELFHOST_LEADS_API_HEADERS
}
