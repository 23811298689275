import useUserData from '@/hooks/useUserData';
import useSelectOptions from '@/hooks/useSelectOptions';

import { ApiCardsGetResponse } from '@/services/cards/cards.types';
import { useLazyGetCardsQuery } from '@/services/cards/cards';

const useUserCards = (id?: string) => {
  const userData = useUserData<ApiCardsGetResponse>(useLazyGetCardsQuery, id);
  const cards = useSelectOptions(userData?.list, 'name', 'id');

  return { cards };
};

export default useUserCards;
