import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import MuiAutocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

import { AutocompleteProps } from '@/components/Autocomplete/AutocompleteTypes';

import { SelectItem } from '@/types/form';

const defaultIsOptionEqualToValue = (option: Partial<SelectItem>, value: Partial<SelectItem>) =>
  option?.value === value?.value;

const Autocomplete = ({
  label,
  inputProps,
  InputProps,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  ...restProps
}: AutocompleteProps) => {
  const { t } = useTranslation();

  const value = restProps?.multiple ? restProps?.value || [] : restProps?.value || null;

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          {...InputProps}
          inputProps={{ ...params.inputProps, ...inputProps }}
          label={label}
        />
      );
    },
    [InputProps, inputProps, label],
  );

  return (
    <MuiAutocomplete
      {...restProps}
      openOnFocus
      loadingText={t('select.loading')}
      noOptionsText={t('select.empty')}
      isOptionEqualToValue={isOptionEqualToValue}
      ListboxProps={{ style: { maxHeight: 300 } }}
      componentsProps={{ paper: { sx: { p: 0 } } }}
      renderInput={renderInput}
      // @ts-ignore
      value={value}
    />
  );
};

export default Autocomplete;
