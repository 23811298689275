/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconArrowLeft = ({ size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} customWidth={40} customHeight={40} viewBox='0 0 40 40'>
      <rect width='40' height='40' rx='20' fill='black' fillOpacity='0.04' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.0303 14.9697C22.3232 15.2626 22.3232 15.7374 22.0303 16.0303L18.0607 20L22.0303 23.9697C22.3232 24.2626 22.3232 24.7374 22.0303 25.0303C21.7374 25.3232 21.2626 25.3232 20.9697 25.0303L16.4697 20.5303C16.1768 20.2374 16.1768 19.7626 16.4697 19.4697L20.9697 14.9697C21.2626 14.6768 21.7374 14.6768 22.0303 14.9697Z'
        fill='#BDBDBD'
      />
    </Icon>
  );
};

export default IconArrowLeft;
