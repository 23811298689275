import { Theme } from '@mui/material/styles/createTheme';
import { css } from '@mui/material';

export const BlockWrapper = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(3)};

  border: 1px solid ${theme.palette.other.outlineBorder};
  border-radius: ${theme.shape.borderRadiusSecond}px;
  background-color: ${theme.palette.common.white};
`;
