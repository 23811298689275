import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';

import Select from '@/components/Select';

import routes from '@/resources/routes';

import useUserInfo from '@/hooks/useUserInfo';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';

import { selectCommand } from '@/redux/user/userGetters';
import { setCommandAction } from '@/redux/user/userActions';

export const CommandSelect = () => {
  const { pathname } = useLocation();

  const { t } = useTranslation(['common']);

  const { commandId, isComandWithDolphin } = useSelector(selectCommand);
  const { commands, commandsWithDolphinId } = useHighLevelUserCommands();
  const { isMultipleAccessToCommandUser } = useUserInfo();

  const dispatch = useDispatch();

  const handleSelectCommand = useCallback(
    (value: string | string[]) => {
      const currentCommand = commands.find((a) => a.value === value);
      dispatch(
        setCommandAction({
          commandId: currentCommand?.value || '',
          isComandWithDolphin: Boolean(currentCommand?.itemEntity?.isComandWithDolphin),
        }),
      );
    },
    [commands, dispatch],
  );

  const isFacebookPage = pathname === routes.facebook.path;

  useEffect(() => {
    if (isFacebookPage && !isComandWithDolphin) {
      handleSelectCommand(commandsWithDolphinId[0].value || '');
    }
  }, [commandsWithDolphinId, handleSelectCommand, isComandWithDolphin, isFacebookPage]);

  if (!isMultipleAccessToCommandUser) return null;

  return (
    <Select
      size='small'
      withCanAwait
      value={commandId}
      options={isFacebookPage ? commandsWithDolphinId : commands}
      label={t(commandId ? 'roles.team' : 'selectCommand')}
      onSelect={handleSelectCommand}
      maxWidth={252}
      width={'100%'}
    />
  );
};
