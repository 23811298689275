import { ValueObject } from '@/components/FormElementConsumablePicker/FormElementConsumablePickerTypes';

export enum FormModalPipeValues {
  NAME = 'name',
  DATE = 'date',
  COMMENT = 'comment',
  CONSUMABLES = 'consumables',

  USER = 'user',
  CARD_IDS = 'cardIds',
  OFFER_ID = 'offerId',
}

export interface FormModalPipeData {
  [FormModalPipeValues.NAME]: string;
  [FormModalPipeValues.DATE]: string;
  [FormModalPipeValues.COMMENT]: string;
  [FormModalPipeValues.OFFER_ID]: string;
  [FormModalPipeValues.CARD_IDS]: { value: string }[];
  [FormModalPipeValues.CONSUMABLES]: { value: ValueObject }[];
  [FormModalPipeValues.USER]: string;
}
