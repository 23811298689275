import React, { useMemo } from 'react';
import LibraryTabs from '@mui/material/Tabs';

import { TabItem, TabsProps } from '@/components/Tabs/TabsTypes';
import { StyledTabs, StyledTabsTab } from '@/components/Tabs/TabsStyled';

const Tabs = <T extends TabItem[]>({
  value,
  setValue,
  options,
  className,
  isWithoutBackground = false,
}: TabsProps<T>) => {
  const handleChange = (_event: React.SyntheticEvent, index: number) => {
    setValue(options[index].value);
  };

  const renderOptions = useMemo(() => {
    return options.map((item, index) => <StyledTabsTab key={index} label={item.label} />);
  }, [options]);

  const tabValue = useMemo(() => {
    return options.findIndex((option) => option.value === value);
  }, [options, value]);

  return (
    <StyledTabs className={className} $isWithoutBackground={Boolean(isWithoutBackground)}>
      <LibraryTabs onChange={handleChange} value={tabValue} selectionFollowsFocus>
        {renderOptions}
      </LibraryTabs>
    </StyledTabs>
  );
};

export default Tabs;
