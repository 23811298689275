import { ApiHighLevelUserParams, ApiResponse } from '@/types/api';
import { ApiUser } from '@/services/users/users.types';
import { ApiOffer } from '@/services/offers/offers.types';

export enum ApiReportType {
  EXPECTED = 'expected',
  CONFIRMED = 'confirmed',
}

export interface ApiReport {
  id: string;
  date: string;
  type: ApiReportType;

  deposits: number;
  installations: number;
  registrations: number;

  hold: number;
  rejected: number;

  user: ApiUser;
  offer: ApiOffer;

  trackerConversions: {
    id: string;
  };
}
// === CREATE ↴

export type ApiOfferReportPostBody = ApiHighLevelUserParams &
  Pick<ApiReport, 'id' | 'deposits' | 'installations' | 'registrations' | 'hold' | 'rejected'> & {
    userId: string;
  };
export type ApiOfferReportPostResponse = ApiResponse<ApiReport>;

// === UPDATE ↴

export type ApiOfferReportHoldPatchBody = ApiHighLevelUserParams &
  Pick<ApiReport, 'id' | 'date' | 'hold'> & {
    reason: string;
    userId: string;
  };
export type ApiOfferReportHoldPatchResponse = ApiOfferReportPostResponse;
