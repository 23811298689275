import { ThunkAction, Action } from '@reduxjs/toolkit';

import { TableWithSettings } from '@/types/table';
import { reducer } from '@/redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppThunkResponseType<ReturnType = void> = AppThunk<Promise<ReturnType | undefined>>;

export interface UserState {
  command: {
    commandId: string;
    isComandWithDolphin: boolean;
  };
  isOpenSidebar: boolean;

  language: LanguagesCode;
  tableSettings: Record<TableWithSettings, string[]>;
}

export type ValueOf<T> = T[keyof T];
export type LanguagesCode = 'en' | 'ru' | 'ua';

export enum Languages {
  ru = 'ru',
  en = 'en',
  ua = 'ua',
}

export type RootState = ReturnType<typeof reducer>;

export type DateType = Date | string;
export type Optional<T> = T | undefined;

// Note
// https://dev.to/bwca/deep-readonly-generic-in-typescript-4b04
export type DeepReadonly<T> = Readonly<{
  [K in keyof T]: T[K] extends number | string | symbol
    ? Readonly<T[K]>
    : T[K] extends Array<infer A>
    ? Readonly<Array<DeepReadonly<A>>>
    : DeepReadonly<T[K]>;
}>;
