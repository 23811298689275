import { SumWithCurrency } from '@/components/FormElementSumWithCurrency';

export enum FormModalCardReplenishmentValues {
  SUM_WITH_CURRENCY = 'sumWithCurrency',
  DATE = 'date',
}

export interface FormModalCardReplenishmentData {
  [FormModalCardReplenishmentValues.SUM_WITH_CURRENCY]: SumWithCurrency;
  [FormModalCardReplenishmentValues.DATE]: string;
}
