import { useMemo } from 'react';

import { PossibleTabs, dashboardTabs } from '@/pages/DashboardsPage/DashboardsPage.types';

import { useQueryParam } from '@/hooks/useQueryParam';

const findOrDefault = (value: PossibleTabs) => {
  return dashboardTabs.find((tab) => tab.value === value) || dashboardTabs[0];
};

export const useDashboardsPageCurrentTab = () => {
  const [tabsQuery, setTabQuery] = useQueryParam<PossibleTabs>('tab', PossibleTabs.parameters);

  const currentTab = useMemo(() => {
    return findOrDefault(tabsQuery);
  }, [tabsQuery]);

  const setCurrentTab = (value: PossibleTabs) => {
    setTabQuery(findOrDefault(value).label);
  };

  return {
    setCurrentTab,
    tab: currentTab,
    currentTab: currentTab.value,
  };
};
