import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ApiPipeStatus } from '@/services/pipes/pipes.types';

const useLocalizePipeStatus = () => {
  const { t } = useTranslation('pipes');

  const localizePipeStatus = useCallback(
    (status: ApiPipeStatus) => {
      switch (status) {
        case ApiPipeStatus.CREATED:
          return t('status.created');
        case ApiPipeStatus.REVIEW:
          return t('status.review');
        case ApiPipeStatus.ACTIVE:
          return t('status.active');
        case ApiPipeStatus.OFF:
          return t('status.off');
        default:
          return status;
      }
    },
    [t],
  );

  return { localizePipeStatus };
};

export default useLocalizePipeStatus;
