import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import { getUserNickname } from '@/utils/getUserNickname';
import FormRuler from '@/utils/FormRuler';

import useUserInfo from '@/hooks/useUserInfo';
import useSelectOptions from '@/hooks/useSelectOptions';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useCommandMasters, { filterPossibleMasters } from '@/hooks/useCommandMasters';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { SelectItem } from '@/types/form';
import { useLazyGetOffersQuery } from '@/services/offers/offers';
import { ApiCap, ApiCapsPostBody } from '@/services/caps/caps.types';
import { useCreateCapsByOfferMutation, useUpdateCapsByOfferMutation } from '@/services/caps/caps';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { FormModalCapData, FormModalCapValues } from '@/modals/ModalCap/ModalCapTypes';

const DEFAULT_CAP_LIMIT = '90';
const capLimits = Array.from({ length: 5 }, (_, index) => String(60 + index * 10));

// TODO: Стоит вынести как минимум
// TODO: Стоит подумать/вынести/отрефакторить логику
const useDifficultWayGetMasters = () => {
  const { isHighLevelUser, userInfo } = useUserInfo();

  const mastersLowLevelUsers = useCommandMasters();
  const mastersHighLevelUsers = useMemo<SelectItem<string>[]>(() => {
    // TODO: Отдельный метод бы, по аналогии с офферами
    const masters = (userInfo?.company.users || []).filter(filterPossibleMasters);

    return masters.map((a) => ({
      label: getUserNickname(a),
      value: a.id,
    }));
  }, [userInfo?.company.users]);

  return isHighLevelUser ? mastersHighLevelUsers : mastersLowLevelUsers.masters;
};

const ModalCap: StaticModalWrappedComponent<ApiCap> = (props) => {
  const { t } = useTranslation(['common', 'caps']);

  const {
    isOwner,
    isLeader,
    userInfo,
    isHighLevelUser,
    isCommandFinancier,
    isCompanyWithTracker,
    isMultipleAccessToCommandUser,
  } = useUserInfo();

  const isShowCompanyLimit = isHighLevelUser && isCompanyWithTracker;
  const isShowCommandLimit = (isLeader || isCommandFinancier) && !isMultipleAccessToCommandUser;
  const isShowLimit = isShowCompanyLimit || isShowCommandLimit;

  const methods = useForm<FormModalCapData>();

  const [defaultValues] = useState(() => {
    const usersLimits = props.data?.caps.filter((a) => a.user.id) || [];
    const commandsLimit = props.data?.caps.filter((a) => a.command.id) || [];
    const companyLimit = props.data?.caps.find((a) => a.company.id)?.limit;

    const firstCap = props.data?.caps[0];

    const result: Partial<FormModalCapData> = {
      [FormModalCapValues.OFFER]: props.data?.offerId
        ? { value: props.data.offerId, label: props.data.offerName }
        : undefined,
      [FormModalCapValues.COMMENT]: firstCap?.comment || '',
      [FormModalCapValues.COMPANY_LIMIT]: companyLimit ? String(companyLimit) : '',

      [FormModalCapValues.DISTRIBUTION_TO_MASTERS]: usersLimits.map((a) => ({
        id: a.id,
        value: {
          type: 'user',
          memberId: a.user.id || '',
          percent: String(a.limit),
        },
      })),

      [FormModalCapValues.PERCENTAGE_FOR_ALERTS]: String(
        firstCap?.percentageForAlerts || DEFAULT_CAP_LIMIT,
      ),
    };

    if (isShowCommandLimit) {
      result[FormModalCapValues.COMMAND_LIMIT] = commandsLimit[0]?.limit
        ? String(commandsLimit[0].limit)
        : '';
    } else {
      result[FormModalCapValues.DISTRIBUTION_TO_COMMAND] = commandsLimit.map((a) => ({
        id: a.id,
        value: {
          type: 'command',
          memberId: a.command.id || '',
          percent: String(a.limit),
        },
      }));
    }

    return result;
  });

  const { commands: commandsOptions } = useHighLevelUserCommands();
  const [updateCaps, { isLoading: isUpdatingCaps }] = useUpdateCapsByOfferMutation();
  const [createCaps, { isLoading: isCreatingCaps }] = useCreateCapsByOfferMutation();
  const [getOffers, { data, isLoading: isGettingOffers }] = useLazyGetOffersQuery(); // TODO: Limit 1000?

  useEffect(() => {
    getOffers({ limit: '3000' });
  }, [getOffers]);

  useEffect(() => {
    // NOTE: если просто передать defaultValues, то элементы в pickerWithSelect не изменяются
    // TODO: поискать другое решение
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const mastersOptions = useDifficultWayGetMasters();
  // TODO: убрать использованные оффера
  const offersOptions = useSelectOptions(data?.list || [], 'name', 'id');
  const capsLimitsOptions: SelectItem[] = capLimits.map((a) => ({
    label: t('caps:percentageOfSetCap', { value: a }),
    value: a,
  }));

  const [handleSubmit] = useAwaitCallback(async (data: FormModalCapData) => {
    const caps: ApiCapsPostBody['caps'] = [];

    if (data[FormModalCapValues.COMMAND_LIMIT]) {
      caps.push({
        entityType: 'command',
        entity: userInfo?.command?.id,
        limit: Number(data[FormModalCapValues.COMMAND_LIMIT]),
      });
    }

    if (data[FormModalCapValues.COMPANY_LIMIT]) {
      caps.push({
        entityType: 'company',
        entity: userInfo?.company?.id,
        limit: Number(data[FormModalCapValues.COMPANY_LIMIT]),
      });
    }

    if (data[FormModalCapValues.DISTRIBUTION_TO_COMMAND]?.length) {
      data[FormModalCapValues.DISTRIBUTION_TO_COMMAND]?.forEach((a) => {
        caps.push({
          entityType: 'command',
          entity: a.value?.memberId,
          limit: Number(a.value?.percent),
        });
      });
    }

    if (data[FormModalCapValues.DISTRIBUTION_TO_MASTERS]?.length) {
      data[FormModalCapValues.DISTRIBUTION_TO_MASTERS].forEach((a) => {
        caps.push({
          entityType: 'master',
          entity: a.value?.memberId,
          limit: Number(a.value?.percent),
        });
      });
    }

    // =======

    const body: ApiCapsPostBody = {
      caps,
      comment: data[FormModalCapValues.COMMENT],
      offerId: data[FormModalCapValues.OFFER].value,
      percentageForAlerts: Number(data[FormModalCapValues.PERCENTAGE_FOR_ALERTS]),
    };

    const response = isEdit ? await updateCaps(body) : await createCaps(body);

    if ('data' in response) {
      props.onClose();
    }
  }, []);

  const isEdit = Boolean(props.data?.id);
  const isShowDistributionToCommands = isHighLevelUser || isMultipleAccessToCommandUser;

  return (
    <Modal {...props} title={t(`caps:modal.${isEdit ? 'editTitle' : 'title'}`)}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        <FormElement
          disabled={isEdit}
          options={offersOptions}
          component='autocomplete'
          loading={isGettingOffers}
          rules={FormRuler.required}
          name={FormModalCapValues.OFFER}
          label={t('common:tableLabels.offer')}
        />

        {isShowLimit && (
          <FormElement
            component='input'
            label={t(`caps:${isOwner ? 'companyLimit' : 'commandLimit'}`)}
            name={isOwner ? FormModalCapValues.COMPANY_LIMIT : FormModalCapValues.COMMAND_LIMIT}
          />
        )}

        <FormElement
          component='select'
          label={t('caps:capLimit')}
          values={capsLimitsOptions}
          rules={FormRuler.required}
          name={FormModalCapValues.PERCENTAGE_FOR_ALERTS}
        />

        <FormElement
          component='input'
          name={FormModalCapValues.COMMENT}
          label={t('common:tableLabels.comment')}
        />

        {isShowDistributionToCommands && (
          <FormElement
            type='custom'
            options={commandsOptions}
            inputLabel={t('caps:cap')}
            component='pickerWithSelect'
            selectLabel={t('common:roles.team')}
            label={t('caps:distributeToCommands')}
            name={FormModalCapValues.DISTRIBUTION_TO_COMMAND}
          />
        )}

        <FormElement
          type='custom'
          options={mastersOptions}
          inputLabel={t('caps:cap')}
          component='pickerWithSelect'
          selectLabel={t('common:roles.user')}
          label={t('caps:distributeToMasters')}
          name={FormModalCapValues.DISTRIBUTION_TO_MASTERS}
        />

        <DialogActions>
          <Button isLoading={isUpdatingCaps || isCreatingCaps} type='submit'>
            {t(`common:buttonActions.${isEdit ? 'edit' : 'add'}`)}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCap>(ModalCap);
