import { useTranslation } from 'react-i18next';

import useLocalizeOfferInformation from '@/hooks/useLocalizeOfferInformation';

import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

export enum OfferInformation {
  MASTERS_EXPENSES = 'mastersExpenses',
  REPORTS = 'reports',
}

const useOfferInformationFilter = (isMaster: boolean) => {
  const { t } = useTranslation('offer');

  const { Component, information } = usePageFilter('information', '');

  const { localizeOfferInformation } = useLocalizeOfferInformation();

  const offerInformationOptions = [
    {
      label: localizeOfferInformation(OfferInformation.MASTERS_EXPENSES),
      value: OfferInformation.MASTERS_EXPENSES,
    },
    {
      label: localizeOfferInformation(OfferInformation.REPORTS),
      value: OfferInformation.REPORTS,
    },
  ];

  return {
    information,
    OperationCategoryFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component
        {...props}
        label={t('grouping')}
        options={isMaster ? [offerInformationOptions[1]] : offerInformationOptions}
      />
    ),
  };
};

export default useOfferInformationFilter;
