import { invalidatesTags } from '@/services/utils';
import commonApi from '@/services/common/common';
import {
  ApiUsersMeGetResponse,
  ApiAuthRegisterOwnerPostBody,
  ApiAuthRegisterOwnerPostResponse,
  ApiAuthLoginPostBody,
  ApiAuthLoginPostResponse,
  ApiAuthRegisterByInvitationPostResponse,
  ApiAuthRegisterByInvitationPostBody,
  ApiAuthForgotPasswordPostBody,
  ApiAuthRecoveryPasswordPostBody,
  ApiAuthForgotPasswordPostResponse,
} from '@/services/auth/auth.types';
import { servicesTags } from '@/services';

const serviceTag = servicesTags.auth;

const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<ApiUsersMeGetResponse, void>({
      query: () => '/users/me',
      providesTags: [serviceTag],
    }),
    logoutUser: builder.mutation<ApiAuthLoginPostResponse, void>({
      query: () => '/auth/logout',
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    registerOwner: builder.mutation<ApiAuthRegisterOwnerPostResponse, ApiAuthRegisterOwnerPostBody>(
      {
        query: (body) => ({
          body,
          method: 'POST',
          url: '/auth/register_owner',
        }),
        invalidatesTags: invalidatesTags(serviceTag),
      },
    ),
    registerByInvitation: builder.mutation<
      ApiAuthRegisterByInvitationPostResponse,
      ApiAuthRegisterByInvitationPostBody
    >({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/register_by_invitation',
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    loginUser: builder.mutation<ApiAuthLoginPostResponse, ApiAuthLoginPostBody>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/login',
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    forgotPassword: builder.mutation<
      ApiAuthForgotPasswordPostResponse,
      ApiAuthForgotPasswordPostBody
    >({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/forgot',
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    recoveryPassword: builder.mutation<ApiUsersMeGetResponse, ApiAuthRecoveryPasswordPostBody>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/recovery',
      }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
  }),
});

export const {
  useGetMeQuery,
  useLoginUserMutation,
  useLogoutUserMutation,
  useRegisterOwnerMutation,
  useForgotPasswordMutation,
  useRecoveryPasswordMutation,
  useRegisterByInvitationMutation,
} = authApi;

export default authApi;
