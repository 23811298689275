export enum FormModalPipeConsumableValues {
  DATE = 'date',
  AMOUNT = 'amount',
  CATEGORY = 'category',
  CONSUMABLE_ID = 'consumableId',
  PIPE_CONSUMABLE_ID = 'pipeConsumableId',
}

export interface FormModalPipeConsumableData {
  [FormModalPipeConsumableValues.DATE]: string;
  [FormModalPipeConsumableValues.AMOUNT]: string;
  [FormModalPipeConsumableValues.CATEGORY]: string;
  [FormModalPipeConsumableValues.CONSUMABLE_ID]: string;
  [FormModalPipeConsumableValues.PIPE_CONSUMABLE_ID]: string;
}
