import { styled } from '@mui/material';

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

import transientProps from '@/utils/transientProps';

type DirectionIcon = 'right' | 'left' | 'up' | 'down';
interface IconArrowProps extends IconProps {
  direction?: DirectionIcon;
}

const getDegrees = (direction: DirectionIcon) => {
  switch (direction) {
    case 'left':
      return 0;
    case 'right':
      return 180;
    case 'down':
      return 270;
    case 'up':
      return 90;
  }
};

export const StyledIcon = styled(Icon, transientProps)<{
  $direction: DirectionIcon;
}>`
  transform: rotate(${({ $direction }) => getDegrees($direction)}deg);
`;

const IconArrow = ({
  direction = 'left',
  size = IconSize.l,
  color = '#CCCDD3',
  ...restProps
}: IconArrowProps) => {
  return (
    <StyledIcon $direction={direction} {...restProps} size={size} viewBox='0 0 24 24'>
      <path
        d='M3 12L8.58537 6M3 12L8.58537 18M3 12H21'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </StyledIcon>
  );
};

export default IconArrow;
