import styled from '@emotion/styled';

export const OnboardingToolTip = styled('div')`
  background-color: #fff;
  padding: 16px;
  box-shadow: 4px 4px 20px 0px #0000001f;
  border-radius: 8px;
  width: 300px;
`;

export const OnboardingToolTipHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const OnboardingToolTipHBody = styled('div')`
  h6 {
    margin-bottom: 8px;
  }
`;

export const OnboardingTourFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;
