/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconTrendUp = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.75 15.25C22.3358 15.25 22 14.9142 22 14.5C22 14.0858 22.3358 13.75 22.75 13.75H27.25C27.6642 13.75 28 14.0858 28 14.5V19C28 19.4142 27.6642 19.75 27.25 19.75C26.8358 19.75 26.5 19.4142 26.5 19V16.3107L20.6553 22.1553C20.3624 22.4482 19.8876 22.4482 19.5947 22.1553L16.375 18.9357L11.2803 24.0303C10.9874 24.3232 10.5126 24.3232 10.2197 24.0303C9.92678 23.7374 9.92678 23.2626 10.2197 22.9697L15.8447 17.3447C16.1376 17.0518 16.6124 17.0518 16.9053 17.3447L20.125 20.5643L25.4393 15.25H22.75Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconTrendUp;
