import { css } from '@mui/material';
import styled from '@emotion/styled';

import transientProps from '@/utils/transientProps';

export const StyledRouterContentWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;
  overflow-x: hidden;
`;

export const StyledRouterContent = styled('div', transientProps)<{ $hasPadding?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding: 24px;
      overflow-y: auto;
    `}
`;
