import { useCallback, useMemo } from 'react';

import { formatNumber } from '@/utils/formatNumber';

import useLocalizeOfferCategory from '@/hooks/useLocalizeOfferCategory';

import { ApiOfferDashboardCategories } from '@/services/dashboard/dashboard.types';

const excludedCategories = [
  ApiOfferDashboardCategories.EXPECTED_ROI,
  ApiOfferDashboardCategories.EXPECTED_ROMI,
  ApiOfferDashboardCategories.CONFIRMED_ROI,
  ApiOfferDashboardCategories.CONFIRMED_ROMI,
];

const useGetFormattedStatisticValue = () => {
  const { localizeOfferCategory } = useLocalizeOfferCategory();

  const finalCategories = useMemo(() => {
    const localizedCategories = excludedCategories.map(localizeOfferCategory);
    return [...excludedCategories, ...localizedCategories];
  }, [localizeOfferCategory]);

  const getFormattedStatisticValue = useCallback(
    (category: string, value: number | string) => {
      return finalCategories.includes(category as (typeof finalCategories)[number])
        ? `${Number(value || 0)} %`
        : formatNumber(value);
    },
    [finalCategories],
  );

  return { getFormattedStatisticValue };
};

export default useGetFormattedStatisticValue;
