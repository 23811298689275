import { useTranslation } from 'react-i18next';
import { FieldError, FieldValues, RegisterOptions, ValidationRule } from 'react-hook-form';
import { useCallback } from 'react';

import Alerter from '@/utils/Alerter/Alerter';

export type RulesVariants = RegisterOptions<FieldValues, string> & {
  maxNumber?: ValidationRule<number | string>;
};

const useFormValidation = (rules?: RulesVariants) => {
  const { t } = useTranslation(['common']);

  const getDescriptionValue = useCallback(
    (error: FieldError) => {
      if (error.message) return error.message;

      switch (error.type) {
        case 'email':
          return t('common:formErrors.invalidEmail');

        case 'validate':
          return t('common:formErrors.validate');

        case 'differentPasswords':
          return t('common:formErrors.differentPasswords');

        case 'required':
          return t('common:formErrors.required');

        case 'minLength':
          // NOTE: https://github.com/i18next/react-i18next/issues/1601
          // @ts-ignore
          return t('common:formErrors.minLength', { minLength: rules?.minLength });

        case 'maxLength':
          return t('common:formErrors.maxLength', { maxLength: rules?.maxLength });

        case 'maxNumber':
          return t('common:formErrors.maxNumber');

        case 'max':
          return t('common:formErrors.max');

        case 'date':
          return t('common:formErrors.date');

        default:
          return t('common:formErrors.default');
      }
    },
    [rules?.maxLength, rules?.minLength, t],
  );

  const fieldValidate = useCallback(
    (error?: FieldError) => {
      const result = {
        isError: false,
        description: '',
      };

      if (error) {
        try {
          result.isError = Boolean(error);
          result.description = getDescriptionValue(error);
        } catch (error) {
          Alerter.logError('useValidation hook');
        }
      }

      return result;
    },
    [getDescriptionValue],
  );

  return { fieldValidate };
};

export default useFormValidation;
