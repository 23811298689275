export function usePermissionGranted(_permission: any) {
  // const userPermissions = useSelector(selectUserPermissions);

  // return useMemo(() => {
  //   try {
  //     return Boolean(userPermissions[permission]);
  //   } catch {
  //     return false;
  //   }
  // }, [permission, userPermissions]);

  return true;
}

export function usePermissionsGranted(_permissions: any[] = []) {
  // const userPermissions = useSelector(selectUserPermissions);

  // return useMemo(() => {
  //   try {
  //     if (permissions.length === 0) return true;
  //     return permissions.every((permission) => userPermissions[permission]);
  //   } catch {
  //     return false;
  //   }
  // }, [permissions, userPermissions]);

  return true;
}

export function useAnyPermissionGranted(_permissions: any[] = []) {
  // const userPermissions = useSelector(selectUserPermissions);

  // return useMemo(() => {
  //   try {
  //     if (permissions.length === 0) return true;
  //     return permissions.some((permission) => userPermissions[permission]);
  //   } catch {
  //     return false;
  //   }
  // }, [permissions, userPermissions]);

  return true;
}
