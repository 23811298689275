import { useMemo } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Typography from '@/components/Typography';
import { AccordionProps } from '@/components/Accordion/AccordionTypes';

const Accordion = ({ details, summary, className }: AccordionProps) => {
  const config = useMemo(
    () =>
      details.map((a) => (
        <Stack key={a.label} direction='row' justifyContent='space-between'>
          <Typography variant='body1'>{a.label}</Typography>
          <Typography variant='body1' color='text.secondary'>
            {a.value}
          </Typography>
        </Stack>
      )),
    [details],
  );

  if (details.length < 1) return null;

  return (
    <MuiAccordion className={className}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#CCCDD3' }} />}>
        <Typography variant='body1'>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction='column' spacing={2}>
          {config}
        </Stack>
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
