import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo from '@/hooks/useUserInfo';
import useLocalizeUserRole from '@/hooks/useLocalizeUserRole';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiInvitePostBody } from '@/services/invites/invites.types';
import { useCreateInviteMutation } from '@/services/invites/invites';
import { ApiCommand } from '@/services/commands/commands.types';
import { ApiUserRole } from '@/services/auth/auth.types';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { FormModalInviteData, FormModalInviteValues } from '@/modals/ModalInvite/ModalInviteTypes';

const ModalInvite: StaticModalWrappedComponent<ApiCommand> = (props) => {
  const { isOwner } = useUserInfo();
  const { localizeUserRole } = useLocalizeUserRole();

  const { t } = useTranslation(['common', 'command']);

  const [createInvite, { isLoading }] = useCreateInviteMutation();

  const rolesOptions = [{ value: ApiUserRole.USER, label: localizeUserRole(ApiUserRole.USER) }];

  const roleOptionsForOwner = [
    ...rolesOptions,
    { value: ApiUserRole.LEAD_USER, label: localizeUserRole(ApiUserRole.LEAD_USER) },
    {
      value: ApiUserRole.COMMAND_FINANCIER,
      label: localizeUserRole(ApiUserRole.COMMAND_FINANCIER),
    },
  ];

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalInviteData) => {
      const commandId = props.data?.id;

      if (!commandId) {
        return Alerter.show(t('command:commandIsNotSpecified'), AlerterTypes.error);
      }

      const url = window.location.origin;

      const body: ApiInvitePostBody = {
        url,
        commandId,
        role: data[FormModalInviteValues.ROLE],
        email: data[FormModalInviteValues.EMAIL],
      };

      const response = await createInvite(body);

      if ('data' in response) {
        Alerter.show(t('command:invitationHasBeenSent'), AlerterTypes.success);
        props.onClose();
      }
    },
    [createInvite, props.data?.id],
  );

  return (
    <Modal {...props} title={t('command:addEmployee')}>
      <Form onSubmit={handleSubmit}>
        <FormElement
          component='select'
          label={t('command:chooseRole')}
          values={isOwner ? roleOptionsForOwner : rolesOptions}
          rules={FormRuler.required}
          name={FormModalInviteValues.ROLE}
        />

        <FormElement
          component='input'
          label='E-mail'
          name={FormModalInviteValues.EMAIL}
          rules={FormRuler.requiredInput}
        />

        <DialogActions>
          <Button isLoading={isLoading} type='submit'>
            {t('common:buttonActions.add')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCommand>(ModalInvite);
