/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconDelete = ({ color = '#2374EE', size = IconSize.l, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 24 24'>
      <path
        d='M6.84201 18.189C6.84201 19.1668 7.64201 19.9668 8.61979 19.9668H15.7309C16.7087 19.9668 17.5087 19.1668 17.5087 18.189V7.52235H6.84201V18.189ZM8.61979 9.30013H15.7309V18.189H8.61979V9.30013ZM15.2865 4.85569L14.3976 3.9668H9.95312L9.06424 4.85569H5.95312V6.63346H18.3976V4.85569H15.2865Z'
        fill={color}
      />
    </Icon>
  );
};

export default IconDelete;
