import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { OfferInformation } from '@/filters/useOfferInformationFilter';

const useLocalizeOfferInformation = () => {
  const { t } = useTranslation('offer');

  const localizeOfferInformation = useCallback(
    (info: OfferInformation) => {
      switch (info) {
        case OfferInformation.MASTERS_EXPENSES:
          return t('information.mastersExpenses');
        case OfferInformation.REPORTS:
          return t('information.reports');
        default:
          return info;
      }
    },
    [t],
  );

  return { localizeOfferInformation };
};

export default useLocalizeOfferInformation;
