class CorrectMath {
  static prepare(value: number, d = 3) {
    const multiplier = Math.pow(10, d);
    return Math.round(value * multiplier) / multiplier;
  }

  static addition(value1: number, value2: number) {
    return CorrectMath.prepare(CorrectMath.prepare(value1) + CorrectMath.prepare(value2));
  }

  static subtraction(value1: number, value2: number) {
    return CorrectMath.prepare(CorrectMath.prepare(value1) - CorrectMath.prepare(value2));
  }

  static division(value1: number, value2: number) {
    return CorrectMath.prepare(CorrectMath.prepare(value1) / CorrectMath.prepare(value2));
  }

  static multiplication(value1: number, value2: number) {
    return CorrectMath.prepare(CorrectMath.prepare(value1) * CorrectMath.prepare(value2));
  }
}

export default CorrectMath;
