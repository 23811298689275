import { providesList, invalidatesTags, invalidatesListItem } from '@/services/utils';
import commonApi from '@/services/common/common';
import {
  ApiCapsGetResponse,
  ApiCapsGetQuery,
  ApiCapsPostResponse,
  ApiCapsPostBody,
  ApiCapsByIdPatchBody,
  ApiCapsByIdPatchResponse,
  ApiCapsByIdDeleteBody,
  ApiCapsByIdDeleteResponse,
} from '@/services/caps/caps.types';
import { servicesTags } from '@/services';

const baseUrl = '/caps';
const serviceTag = servicesTags.caps;

const capsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCaps: build.query<ApiCapsGetResponse, ApiCapsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    createCapsByOffer: build.mutation<ApiCapsPostResponse, ApiCapsPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateCapsByOffer: build.mutation<ApiCapsByIdPatchResponse, ApiCapsByIdPatchBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    deleteCapsByOffer: build.mutation<ApiCapsByIdDeleteResponse, ApiCapsByIdDeleteBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'DELETE', body }),
      // @ts-ignore
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const {
  useGetCapsQuery,
  useCreateCapsByOfferMutation,
  useUpdateCapsByOfferMutation,
  useDeleteCapsByOfferMutation,
} = capsApi;
