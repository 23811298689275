import { FloaterProps, Styles } from 'react-joyride';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';

import { ZINDEX } from '@/resources/constants';

export const StartBtn = styled(IconButton)`
  :hover {
    cursor: pointer;
    background: none;
    * {
      stroke: #1d60d1;
    }
  }
  :disabled {
    display: none;
  }
`;

export const joyrideTourStyles: Styles = {
  beacon: {
    display: 'none',
  },
  options: {
    // NOTE
    // Библиотека сама проставляет +100 к zIndex
    zIndex: ZINDEX.ONBOARDING - 100,
  },
};

export const floaterProps: FloaterProps = {
  styles: {
    floaterWithComponent: {
      filter: 'none',
    },
  },
};
