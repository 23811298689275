import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

import { ApiUserRole } from '@/services/auth/auth.types';

const title: OnboardingSteps[0]['title'] = 'PaymentServices.title';

export const onboardingServicePage = [
  {
    title,
    target: 'page-services',
    placement: 'right',
    content: {
      title: 'PaymentServices.step1.title',
      message: 'PaymentServices.step1.message',
    },
  },
  {
    title,
    target: 'step-service',
    roleList: [
      ApiUserRole.SUPER_ADMIN,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.LEAD_USER,
      ApiUserRole.OWNER,
    ],
    content: {
      title: 'PaymentServices.step2.title',
      message: 'PaymentServices.step2.message',
    },
  },
  {
    title,
    target: 'step-service',
    roleList: [
      ApiUserRole.SUPER_ADMIN,
      ApiUserRole.COMMAND_FINANCIER,
      ApiUserRole.COMPANY_FINANCIER,
      ApiUserRole.LEAD_USER,
      ApiUserRole.OWNER,
    ],
    content: {
      title: 'PaymentServices.step3.title',
      message: 'PaymentServices.step3.message',
    },
  },
] as const satisfies OnboardingSteps;
