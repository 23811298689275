/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconEdit = ({ color = '#2374EE', size = IconSize.m, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} customHeight={21} customWidth={20} viewBox='0 0 21 20'>
      <g clipPath='url(#clip0_674_12254)'>
        <path
          d='M17.4167 12.2166V16.6666C17.4167 17.5871 16.6706 18.3333 15.7501 18.3333H4.08341C3.16294 18.3333 2.41675 17.5871 2.41675 16.6666V4.99996C2.41675 4.07948 3.16294 3.33329 4.08341 3.33329H8.53342M15.7501 1.66663L19.0834 4.99996L10.7501 13.3333H7.41675V9.99996L15.7501 1.66663Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_674_12254'>
          <rect width='20' height='20' fill='white' transform='translate(0.75)' />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconEdit;
