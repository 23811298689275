import Joyride from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Tooltip } from '@mui/material';

import { OnboardingTourProps } from '@/components/OnboardingTour/OnboardingTourTypes';
import {
  floaterProps,
  joyrideTourStyles,
  StartBtn,
} from '@/components/OnboardingTour/OnboardingTourStyles';
import OnboardingTooltip from '@/components/OnboardingTooltip/OnboardingTooltip';

import IconInfo from '@/icons/IconInfo';

import useTourCards from '@/hooks/useTourCards';
import useOnboardingTour from '@/hooks/useOnboardingTour';
import useOnboardingStatus from '@/hooks/useOnboardingStatus';

const JoyrideTour = Joyride as unknown as FC<OnboardingTourProps>; //Это костыль

export const OnboardingTour = ({ ...props }: OnboardingTourProps & { onClick: () => void }) => {
  const { t } = useTranslation('onboarding');

  const { onClick, steps, deleteCardsInTables } = props;

  const { tourKey, joyrideCallback } = useOnboardingTour();

  const { status } = useOnboardingStatus();

  const tourCards = useTourCards(steps, deleteCardsInTables);

  if (!tourCards.length) return null;

  return (
    <>
      {status && (
        <Tooltip title={t('buttons.advice')} arrow placement='bottom'>
          <StartBtn onClick={onClick}>
            <IconInfo color='#989fa6' />
          </StartBtn>
        </Tooltip>
      )}

      <JoyrideTour
        {...props}
        key={tourKey}
        disableOverlay
        steps={tourCards}
        styles={joyrideTourStyles}
        callback={joyrideCallback}
        floaterProps={floaterProps}
        tooltipComponent={OnboardingTooltip}
      />
    </>
  );
};

export default OnboardingTour;
