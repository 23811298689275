export const STORAGE_OFFERS_VISIBLE_COLUMNS = 'STORAGE_OFFERS_VISIBLE_COLUMNS';
export const STORAGE_LEADS_VISIBLE_COLUMNS = 'STORAGE_LEADS_VISIBLE_COLUMNS';

export const excludedLabels = ['Actions', 'ActionsInfo'];

export const DEFAULT_OFFERS_VISIBLE_COLUMNS = [
  'name',
  'partner',
  'geo',
  'payment',
  'status',
  'costs',
  'expectedIncome',
  'expectedRoi',
  'expectedRomi',
  'expectedDeposits',
] as const;

export const offersColumns = [
  ...DEFAULT_OFFERS_VISIBLE_COLUMNS,
  'platform',
  'confirmedIncome',
  'confirmedRoi',
  'confirmedRomi',
  'rejected',
  'hold',
  'confirmedProfit',
  'expectedProfit',
  'installations',
  'confirmedDeposits',
  'registrations',
] as const;

export const DEFAULT_LEADS_VISIBLE_COLUMNS = [
  'date',
  'time',
  'lastName',
  'firstName',
  'phone',
  'master',
  'offer',
  'status',
] as const;

export const leadsColumns = [
  ...DEFAULT_LEADS_VISIBLE_COLUMNS,
  'advertisement',
  'geo',
  'sum',
  'email',
  'subid',
  'notes',
  'domain',
  'campaign',
  'advertising',
  'description',
] as const;
