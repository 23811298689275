import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { DialogActions } from '@mui/material';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';

import useUserServices from '@/hooks/useUserServices';
import useUserInfo from '@/hooks/useUserInfo';
import useSelectOptions from '@/hooks/useSelectOptions';
import useCommandMasters from '@/hooks/useCommandMasters';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiCard, ApiCardPostBody } from '@/services/cards/cards.types';
import { useCreateCardMutation, useUpdateCardByIdMutation } from '@/services/cards/cards';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import { FormModalCardData, FormModalCardValues } from '@/modals/ModalCard/ModalCardTypes';

const ModalCard: StaticModalWrappedComponent<ApiCard> = (props) => {
  const methods = useForm<FormModalCardData>({
    defaultValues: {
      ...props.data,
      user: '',
      serviceId: '',
    },
  });

  const userId = methods.watch(FormModalCardValues.USER);

  const { t } = useTranslation(['common', 'cards']);
  const { isMaster } = useUserInfo();

  const services = useUserServices(userId);

  // TODO: добавить фильтр withAnyServices?: boolean;
  const { masters } = useCommandMasters();

  const [createCard, { isLoading: isCreating }] = useCreateCardMutation();
  const [updateCard, { isLoading: isUpdating }] = useUpdateCardByIdMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalCardData) => {
      const body: ApiCardPostBody = {
        date: data[FormModalCardValues.DATE],
        name: data[FormModalCardValues.NAME],
        card: data[FormModalCardValues.CARD].replace(/-/g, ''),
        userId: data[FormModalCardValues.USER],
        serviceId: data[FormModalCardValues.SERVICE_ID],
      };

      const response = props.data
        ? await updateCard({ ...body, id: props.data.id })
        : await createCard(body);

      if ('data' in response) {
        props.onClose();
      }
    },
    [createCard, props, updateCard],
  );

  const isEdit = Boolean(props.data);
  const title = isEdit ? t('cards:editCard', { value: props.data?.name }) : t('cards:addCard');
  const buttonText = isEdit ? t('common:buttonActions.edit') : t('common:buttonActions.add');

  const paymentService = useSelectOptions(
    props.data?.service ? [props.data?.service] : [],
    'name',
    'id',
  );
  useEffect(() => {
    if (!methods.formState.dirtyFields.user) return;
    methods.setValue(FormModalCardValues.SERVICE_ID, '');
  }, [methods, userId]);

  useEffect(() => {
    if (!isEdit) return;
    if (!masters.length) return;

    const user = masters.find((a) => a.value === props.data?.user.id);
    if (user) methods.setValue(FormModalCardValues.USER, user.value);
  }, [isEdit, masters, methods, props.data?.user.id]);

  useEffect(() => {
    if (!isEdit) return;
    if (!services.length) return;

    methods.setValue(FormModalCardValues.SERVICE_ID, props.data!.service.id);
  }, [isEdit, methods, props.data, props.data?.service.id, services.length]);

  return (
    <Modal {...props} title={title}>
      <Form onSubmit={handleSubmit} contextMethods={methods}>
        {!isEdit && (
          <FormElement
            component='datepicker'
            label={t('common:formLabels.dateAdded')}
            rules={FormRuler.required}
            name={FormModalCardValues.DATE}
          />
        )}

        <FormElement
          component='input'
          mask='9999-9999-9999-9999'
          label={t('common:formLabels.cardNumber')}
          rules={FormRuler.requiredInput}
          name={FormModalCardValues.CARD}
        />

        <FormElement
          component='input'
          label={t('common:formLabels.cardName')}
          rules={FormRuler.requiredInput}
          name={FormModalCardValues.NAME}
        />

        {!isMaster && (
          <FormElement
            label={t('common:roles.user')}
            values={masters}
            disabled={isEdit}
            component='select'
            rules={FormRuler.required}
            name={FormModalCardValues.USER}
          />
        )}

        <FormElement
          values={services}
          label={t('common:tableLabels.serviceReplenishment')}
          component='select'
          extraItems={paymentService}
          rules={FormRuler.required}
          name={FormModalCardValues.SERVICE_ID}
          disabled={isEdit}
          disableAutofill={isEdit}
        />

        <DialogActions>
          <Button isLoading={isCreating || isUpdating} type='submit'>
            {buttonText}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ApiCard>(ModalCard);
