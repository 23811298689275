import { ApiArchiveParams } from '@/types/api';
import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import {
  ApiConsumableCategoryByIdGetQuery,
  ApiConsumableCategoryByIdGetResponse,
  ApiConsumableCategoryByIdPatchBody,
  ApiConsumableCategoryByIdPatchResponse,
  ApiConsumableCategoryPostBody,
  ApiConsumableCategoryPostResponse,
  ApiConsumableCategoriesGetQuery,
  ApiConsumableCategoriesGetResponse,
} from '@/services/consumableCategories/consumableCategories.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/consumablesCategories';
const serviceTag = servicesTags.consumableCategories;

const consumableCategoriesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getConsumableCategories: build.query<
      ApiConsumableCategoriesGetResponse,
      ApiConsumableCategoriesGetQuery
    >({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getConsumableCategoryById: build.query<
      ApiConsumableCategoryByIdGetResponse,
      ApiConsumableCategoryByIdGetQuery
    >({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),

    createConsumableCategory: build.mutation<
      ApiConsumableCategoryPostResponse,
      ApiConsumableCategoryPostBody
    >({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateConsumableCategoryById: build.mutation<
      ApiConsumableCategoryByIdPatchResponse,
      ApiConsumableCategoryByIdPatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    archiveConsumableCategoryById: build.mutation<void, ApiArchiveParams>({
      query: ({ id, commandId }) => ({
        url: `${baseUrl}/${id}/archive`,
        method: 'PATCH',
        params: { commandId },
      }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    unArchiveConsumableCategoryById: build.mutation<void, ApiArchiveParams>({
      query: ({ id, commandId }) => ({
        url: `${baseUrl}/${id}/unarchive`,
        method: 'PATCH',
        params: { commandId },
      }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const {
  useGetConsumableCategoriesQuery,
  useGetConsumableCategoryByIdQuery,
  useCreateConsumableCategoryMutation,
  useUpdateConsumableCategoryByIdMutation,
  useArchiveConsumableCategoryByIdMutation,
  useUnArchiveConsumableCategoryByIdMutation,
} = consumableCategoriesApi;

export default consumableCategoriesApi;
