import useQueryParam from '@/hooks/useQueryParam';

const useUserRoleFilter = () => {
  const queryName = 'userRole';
  const [userRole, setUserRole] = useQueryParam(queryName, '');

  return {
    setUserRole,
    userRole: userRole || undefined,
  };
};

export default useUserRoleFilter;
