import Notification from '@/components/Notification';

import { isDebugMode } from '@/resources/constants';

import { AlerterTypes } from '@/utils/Alerter/AlerterTypes';

class Alerter {
  show(message: string, type = AlerterTypes.error, delay?: number) {
    Notification.show({ type, message, delay });
  }

  logError(place: string, ...args: unknown[]) {
    if (isDebugMode) {
      console.error(`Developer error: ${place}`);
    }

    if (args.length) console.error(...args);
  }
}

export * from '@/utils/Alerter/AlerterTypes';
export default new Alerter();
