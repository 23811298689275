import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import SidebarItem from '@/components/SidebarItem';
import SidebarUserInfo from '@/components/Sidebar/components/SidebarUserInfo/SidebarUserInfo';
import SidebarHeader from '@/components/Sidebar/components/SidebarHeader/SidebarHeader';
import {
  StyledList,
  StyledSection,
  StyledSectionLabel,
  StyledSidebar,
  StyledSidebarContentWrapper,
} from '@/components/Sidebar/SidebarStyled';

import useSidebarRouting from '@/hooks/useSidebarRouting';

import { selectSidebarState } from '@/redux/user/userGetters';

const Sidebar = () => {
  const sidebarConfig = useSidebarRouting();

  const isSidebarOpen = useSelector(selectSidebarState);

  const routesList = useMemo(() => {
    return sidebarConfig.map(({ label, items }) => (
      <StyledSection key={label}>
        <StyledSectionLabel variant='subtitle3' $isSidebarOpen={isSidebarOpen}>
          {label}
        </StyledSectionLabel>
        {items.map((a) => (
          <SidebarItem key={a.name} {...a} />
        ))}
      </StyledSection>
    ));
  }, [isSidebarOpen, sidebarConfig]);

  return (
    <StyledSidebar variant='permanent' open={isSidebarOpen}>
      <SidebarHeader />

      <StyledSidebarContentWrapper>
        <StyledList>{routesList}</StyledList>

        <SidebarUserInfo />
      </StyledSidebarContentWrapper>
    </StyledSidebar>
  );
};

export default Sidebar;
