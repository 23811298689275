import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useCallback } from 'react';

import { TableRowActionsProps } from '@/components/TableRowActions/TableRowActionsTypes';
import TableRowActions from '@/components/TableRowActions';
import { TableConfig } from '@/components/Table/TableTypes';
import Table from '@/components/Table';
import PageTitle from '@/components/PageTitle';
import PageHeaderContent from '@/components/PageHeaderContent';
import { FiltersWithSidebar } from '@/components/FiltersWithSidebar/FiltersWithSidebar';
import EntityStatus from '@/components/EntityStatus';

import { onboardingServicePage } from '@/resources/onboarding/servicePage';
import { SYSTEM_CURRENCY } from '@/resources/constants';

import { getUserNickname } from '@/utils/getUserNickname';
import { formatNumber } from '@/utils/formatNumber';
import filterBoolean from '@/utils/filterBoolean';

import useUserInfo from '@/hooks/useUserInfo';
import useUserCommand from '@/hooks/useUserCommand';
import usePagination from '@/hooks/usePagination';
import useArchiveHelpers from '@/hooks/useArchiveHelper';

import { ApiService } from '@/services/services/services.types';
import {
  useChangeServiceArchiveStatusMutation,
  useGetServicesQuery,
  useSyncServiceByIdMutation,
} from '@/services/services/services';
import { ApiCardStatus } from '@/services/cards/cards.types';
import ModalServiceReplenishment from '@/modals/ModalServiceReplenishment/ModalServiceReplenishment';
import ModalService from '@/modals/ModalService/ModalService';
import FilterArchive from '@/filters/FilterArchive';

const ServicesPage = () => {
  const { t } = useTranslation(['common', 'service']);

  const { isMaster } = useUserInfo();

  const { commandId } = useUserCommand();

  const [changeArchiveStatus] = useChangeServiceArchiveStatusMutation();
  const [syncService] = useSyncServiceByIdMutation();
  const { isArchived, prepareArchiveColumns } = useArchiveHelpers(changeArchiveStatus, (name) =>
    t('service:confirmArchive', { name }),
  );
  const { services, isLoading, setPage, setLimit, getPagination } = usePagination({
    cacheKey: 'services',
    useQuery: useGetServicesQuery,
    queryArg: { commandId, isArchived },
  });

  const openCreateServiceModal = () => {
    ModalService.show();
  };

  const openEditServiceModal = (service: ApiService) => () => {
    ModalService.show(service);
  };

  const openReplenishmentServiceModal = (service: ApiService) => () => {
    ModalServiceReplenishment.show(service);
  };

  const generateServiceInformation = useCallback(
    (service: ApiService) => {
      const isSystemSum = service.currency?.title === SYSTEM_CURRENCY || !service.currency?.title;

      const systemSum = formatNumber(service.cardPrice);

      return service.apiIntegrationKey
        ? `${t('service:apiIntegrationEnabled')} ${t('service:apiIntegrationInfo')}`
        : (t('service:information', {
            value1: service.commissionReplenishmentCard,
            value2: service.commissionReplenishmentService,
            value3: service.commissionReturnFunds,
            value4: isSystemSum
              ? systemSum
              : `${formatNumber(service.currencySum, service.currency?.title)} (~${systemSum})`,
          }) as string);
    },
    [t],
  );

  const config: TableConfig = useMemo(() => {
    const list: ApiService[] = services?.list || [];

    return list.map((item) => {
      const users = !isMaster ? item.users.map((a) => getUserNickname(a)) : [];
      const isManyUsers = users.length > 1;
      const isExternal = Boolean(item.apiIntegrationKey);
      const actionProps: TableRowActionsProps = {
        isIconEdit: true,
        callout: generateServiceInformation(item),
        buttonsConfig: [
          {
            textForButton: String(t('common:buttonActions.replenish')),
            onClick: !isExternal && openReplenishmentServiceModal(item),
          },
        ],
        onRefresh:
          isExternal &&
          (() => {
            syncService({ id: item.id });
          }),
      };

      if (!isMaster) {
        actionProps.onEdit = openEditServiceModal(item);
      }
      prepareArchiveColumns(item, actionProps);
      return {
        id: item.id,
        data: [
          {
            label: t('common:tableLabels.name'),
            value: item.name,
          },
          {
            label: t('common:tableLabels.service'),
            value: item.service,
          },
          {
            label: t('common:tableLabels.status'),
            value: (
              <EntityStatus statusCode={item.isArchived ? 'archived' : 'active'}>
                {t(`common:${item.isArchived ? 'archived' : 'active'}`)}
              </EntityStatus>
            ),
          },
          !isMaster && {
            label: t('common:tableLabels.users'),
            value: isManyUsers ? (
              <TableRowActions calloutContent={users.join(', ')} callout={users.join(', ')} />
            ) : (
              users[0] || '-'
            ),
            maxWidth: '200px',
          },
          {
            label: t('common:tableLabels.balanceService'),
            value: formatNumber(item.balance),
          },
          {
            label: t('common:tableLabels.openCard'),
            value: item.cards.filter(({ status }) => status === ApiCardStatus.OPEN).length,
          },
          {
            label: t('common:tableLabels.closeCard'),
            value: item.cards.filter(({ status }) => status === ApiCardStatus.CLOSED).length,
          },
          {
            label: t('common:tableLabels.totalCards'),
            value: item.cards.length,
          },
          {
            label: 'Actions',
            isHiddenLabel: true,
            value: <TableRowActions {...actionProps} />,
            width: '200px',
          },
        ].filter(filterBoolean),
      };
    });
  }, [services?.list, isMaster, generateServiceInformation, t, prepareArchiveColumns, syncService]);

  return (
    <>
      <PageTitle onboardingSteps={onboardingServicePage}>{t('common:sidebar.services')}</PageTitle>

      {!isMaster && (
        <PageHeaderContent
          onClick={openCreateServiceModal}
          adviseTarget={onboardingServicePage[1].target}
          label={t('common:tableLabels.service')}
        />
      )}
      <FiltersWithSidebar>
        <FilterArchive />
      </FiltersWithSidebar>

      <Table
        config={config}
        isLoading={isLoading}
        onChangePage={setPage}
        onChangeLimit={setLimit}
        pagination={getPagination(services?.meta)}
      />
    </>
  );
};

export default ServicesPage;
