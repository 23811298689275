import { ApiResponse } from '@/types/api';
import { invalidatesTags, providesList } from '@/services/utils';
import {
  ApiOperationsGetResponse,
  ApiOperationsGetQuery,
  ApiOperationPostResponse,
  ApiOperationPostBody,
  ApiOperationsGetStatisticQuery,
  ApiOperationsGetStatisticResponse,
  ApiOperationCorrectPatchResponse,
  ApiOperationCorrectPatchBody,
  ApiDeleteOperationParams,
} from '@/services/operations/operations.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/operations';
const serviceTag = servicesTags.operations;

const operationsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getStatistic: build.query<ApiOperationsGetStatisticResponse, ApiOperationsGetStatisticQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}/statistic`, params: params || undefined }),
      providesTags: () => [servicesTags.operationsStatistic],
    }),
    getOperations: build.query<ApiOperationsGetResponse, ApiOperationsGetQuery>({
      // TODO: params || undefined
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    createOperation: build.mutation<ApiOperationPostResponse, ApiOperationPostBody>({
      query: (body) => ({ url: `${baseUrl}/common-consumption`, method: 'POST', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operationsStatistic]),
    }),
    deleteOperation: build.mutation<ApiResponse<null>, ApiDeleteOperationParams>({
      query: ({ id, commandId }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
        params: { commandId },
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operationsStatistic]),
    }),
    correctOperation: build.mutation<
      ApiOperationCorrectPatchResponse,
      ApiOperationCorrectPatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/correct`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, ...Object.values(servicesTags)]),
    }),
  }),
});

export const {
  useGetStatisticQuery,
  useGetOperationsQuery,
  useLazyGetOperationsQuery,
  useDeleteOperationMutation,
  useCreateOperationMutation,
  useCorrectOperationMutation,
} = operationsApi;

export default operationsApi;
