/* eslint-disable max-len */

import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

const IconProfilePlus = ({ size = IconSize.s_38, ...restProps }: IconProps) => {
  return (
    <Icon {...restProps} size={size} viewBox='0 0 38 38'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 13.75C25.4142 13.75 25.75 14.0858 25.75 14.5V16H27.25C27.6642 16 28 16.3358 28 16.75C28 17.1642 27.6642 17.5 27.25 17.5H25.75V19C25.75 19.4142 25.4142 19.75 25 19.75C24.5858 19.75 24.25 19.4142 24.25 19V17.5H22.75C22.3358 17.5 22 17.1642 22 16.75C22 16.3358 22.3358 16 22.75 16H24.25V14.5C24.25 14.0858 24.5858 13.75 25 13.75Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 23.5341C10 21.291 11.7001 19.375 13.9131 19.375H18.8369C21.0499 19.375 22.75 21.291 22.75 23.5341C22.75 24.9033 21.7167 26.125 20.3101 26.125H12.4399C11.0333 26.125 10 24.9033 10 23.5341ZM13.9131 20.875C12.6323 20.875 11.5 22.0116 11.5 23.5341C11.5 24.1983 11.9799 24.625 12.4399 24.625H20.3101C20.7701 24.625 21.25 24.1983 21.25 23.5341C21.25 22.0116 20.1177 20.875 18.8369 20.875H13.9131Z'
        fill='#2374EE'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.375 13.375C15.3395 13.375 14.5 14.2145 14.5 15.25C14.5 16.2855 15.3395 17.125 16.375 17.125C17.4105 17.125 18.25 16.2855 18.25 15.25C18.25 14.2145 17.4105 13.375 16.375 13.375ZM13 15.25C13 13.386 14.511 11.875 16.375 11.875C18.239 11.875 19.75 13.386 19.75 15.25C19.75 17.114 18.239 18.625 16.375 18.625C14.511 18.625 13 17.114 13 15.25Z'
        fill='#2374EE'
      />
    </Icon>
  );
};

export default IconProfilePlus;
