import { useTranslation } from 'react-i18next';

import {
  StyledContentWrapper,
  StyledButtonWrapper,
  StyledContent,
} from '@/pages/PaymentErrorPage/PaymentErrorPageStyled';

import Typography from '@/components/Typography';
import Button from '@/components/Button';

import { SUPPORT_CONTACT, SUPPORT_CONTACT_LINK } from '@/resources/constants';

import useLogoutUser from '@/hooks/useLogoutUser';

import { Languages } from '@/types';

const PaymentErrorPage = () => {
  const { t, i18n } = useTranslation(['common']);

  const logoutUser = useLogoutUser();

  return (
    <StyledContentWrapper>
      <StyledContent>
        <Typography variant='h4'>{t('common:accessToTheServiceIsRestricted.p1')}</Typography>

        <Typography
          variant='body1'
          color='text.secondary'
          sx={{ maxWidth: i18n.language === Languages.ru ? 600 : 460 }}
        >
          {t('common:accessToTheServiceIsRestricted.p2')}
          {'. '}
          <Typography href={SUPPORT_CONTACT_LINK} isTargetBlank color='text.access'>
            Telegram: @{SUPPORT_CONTACT}
          </Typography>
        </Typography>

        <StyledButtonWrapper>
          <Button fullWidth onClick={logoutUser}>
            {t('common:buttonActions.logOut')}
          </Button>
        </StyledButtonWrapper>
      </StyledContent>
    </StyledContentWrapper>
  );
};

export default PaymentErrorPage;
