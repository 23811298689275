import { EmptyMessageWithIconProps } from '@/components/EmptyMessageWithIcon/EmptyMessageWithIconTypes';
import {
  StyledDescription,
  StyledEmptyMessageWithIconWrapper,
  StyledWidthWrapper,
  StyledTitle,
} from '@/components/EmptyMessageWithIcon/EmptyMessageWithIconStyled';
import Button from '@/components/Button';

import IconEmptyState from '@/icons/IconEmptyState';

const EmptyMessageWithIcon = ({
  title,
  description,
  additionalDescription,
  linkText,
  linkUrl,
}: EmptyMessageWithIconProps) => {
  return (
    <StyledEmptyMessageWithIconWrapper>
      <StyledWidthWrapper>
        <IconEmptyState />
        <StyledTitle variant='subtitle1'>{title}</StyledTitle>
        <StyledDescription
          variant='subtitle2'
          color='text.secondary'
          $hasMarginBottom={!additionalDescription}
        >
          {description}
        </StyledDescription>
        {additionalDescription && (
          <StyledDescription variant='subtitle2' color='text.secondary' $hasMarginBottom>
            {additionalDescription}
          </StyledDescription>
        )}
        {!!linkUrl && (
          <Button variant='contained' size='large' color='primary' href={linkUrl}>
            {linkText}
          </Button>
        )}
      </StyledWidthWrapper>
    </StyledEmptyMessageWithIconWrapper>
  );
};

export default EmptyMessageWithIcon;
