import { EntityId } from '@reduxjs/toolkit';

import { ApiArchiveParams, ApiResponse } from '@/types/api';
import { invalidatesTags, providesList, invalidatesListItem } from '@/services/utils';
import {
  ApiConsumablePostResponse,
  ApiConsumablePostBody,
  ApiConsumableGetResponse,
  ApiConsumableGetQuery,
  ApiConsumableDistributePatchResponse,
  ApiConsumableDistributePatchBody,
  ApiConsumableRefundPatchResponse,
  ApiConsumableRefundPatchBody,
  ApiConsumablePatchBody,
  ApiConsumablePatchResponse,
} from '@/services/consumables/consumables.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/consumables';
const serviceTag = servicesTags.consumables;

const consumablesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getConsumable: build.query<ApiConsumableGetResponse, ApiConsumableGetQuery>({
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    createConsumable: build.mutation<ApiConsumablePostResponse, ApiConsumablePostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    updateConsumable: build.mutation<ApiConsumablePatchResponse, ApiConsumablePatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    consumableDistribute: build.mutation<
      ApiConsumableDistributePatchResponse,
      ApiConsumableDistributePatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/distribute`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    consumableRefund: build.mutation<
      ApiConsumableRefundPatchResponse,
      ApiConsumableRefundPatchBody
    >({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/refund`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.operations]),
    }),
    deleteConsumableById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),

    changeConsumableArchiveStatus: build.mutation<ApiResponse<null>, ApiArchiveParams>({
      query: ({ id, ...params }) => ({
        url: `${baseUrl}/${id}/change-archive-status`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.cards]),
    }),
  }),
});

export const {
  useGetConsumableQuery,
  useLazyGetConsumableQuery,
  useUpdateConsumableMutation,
  useCreateConsumableMutation,
  useConsumableRefundMutation,
  useConsumableDistributeMutation,
  useDeleteConsumableByIdMutation,
  useChangeConsumableArchiveStatusMutation,
} = consumablesApi;

export default consumablesApi;
