import { useTranslation } from 'react-i18next';

import useLocalizePipeStatus from '@/hooks/useLocalizePipeStatus';

import { ApiPipeStatus } from '@/services/pipes/pipes.types';
import usePageFilter, { HookComponentProps } from '@/filters/usePageFilter';

export const getPipeStatusOptions = (localizePipeStatus: { (status: ApiPipeStatus): string }) => [
  {
    label: localizePipeStatus(ApiPipeStatus.CREATED),
    value: ApiPipeStatus.CREATED,
  },
  {
    label: localizePipeStatus(ApiPipeStatus.REVIEW),
    value: ApiPipeStatus.REVIEW,
  },
  {
    label: localizePipeStatus(ApiPipeStatus.ACTIVE),
    value: ApiPipeStatus.ACTIVE,
  },
  {
    label: localizePipeStatus(ApiPipeStatus.OFF),
    value: ApiPipeStatus.OFF,
  },
];

const usePipeStatusFilter = () => {
  const { t } = useTranslation();

  const { localizePipeStatus } = useLocalizePipeStatus();

  const pipeStatusOptions = getPipeStatusOptions(localizePipeStatus);

  const { Component, status } = usePageFilter('status', '');

  return {
    status: status || undefined,
    PipeStatusFilter: <Multiple extends boolean>(props: HookComponentProps<Multiple>) => (
      <Component {...props} label={t('tableLabels.status')} options={pipeStatusOptions} />
    ),
  };
};

export default usePipeStatusFilter;
