import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import DialogActions from '@mui/material/DialogActions';
import IconLink from '@mui/icons-material/InsertLinkRounded';

import Modal from '@/components/Modal';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserCompanyInfo from '@/hooks/useUserCompanyInfo';
import useUserCommand from '@/hooks/useUserCommand';
import useSelectOptions from '@/hooks/useSelectOptions';

import { useGetPipesQuery } from '@/services/pipes/pipes';
import {
  useGetDolphinAdvertisingCampaignsQuery,
  useLinkAdvertisingCampaignToPipeMutation,
} from '@/services/facebook/facebook';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
// eslint-disable-next-line max-len
import {
  FormModalLinkAdvertisingCampaignToPipeData,
  FormModalLinkAdvertisingCampaignToPipeValues,
} from '@/modals/ModalLinkAdvertisingCampaignToPipe/ModalLinkAdvertisingCampaignToPipeTypes';
import { ArchiveEnum } from '@/filters/useArchivedFilter';

const ModalLinkAdvertisingCampaignToPipe: StaticModalWrappedComponent = (props) => {
  const { t } = useTranslation(['common', 'facebook']);

  const { commandId } = useUserCommand();
  const { company } = useUserCompanyInfo();

  const [linkAdvertisingCampaignToPipe, { isLoading }] = useLinkAdvertisingCampaignToPipeMutation();

  const { data: advertisingCampaigns, isLoading: isGetAdvertisingCampaigns } =
    useGetDolphinAdvertisingCampaignsQuery({ commandId });
  const { data: pipes, isLoading: isGetPipesLoading } = useGetPipesQuery({
    commandId,
    companyId: company?.id,
    isArchived: ArchiveEnum.UNARCHIVED,
    // TODO: поиск по названию
    limit: '1000',
  });

  const pipesOptions = useSelectOptions(pipes?.list || [], 'name', 'id');

  const filteredAdvertisingCampaigns = (advertisingCampaigns?.list || []).filter(
    (a) => !a.isAlreadyAdded,
  );
  const advertisingCampaignsOptions = useSelectOptions(filteredAdvertisingCampaigns, 'name', 'id');

  const handleSubmit = useCallback(
    async (data: FormModalLinkAdvertisingCampaignToPipeData) => {
      const response = await linkAdvertisingCampaignToPipe({
        commandId,
        campaignId: data[FormModalLinkAdvertisingCampaignToPipeValues.ADVERTISING_CAMPAIGN].value,
        pipeId: data[FormModalLinkAdvertisingCampaignToPipeValues.PIPE].value,
      });

      if ('data' in response) {
        Alerter.show(
          t('facebook:advertisingCampaignSuccessfullyLinkedToPipe'),
          AlerterTypes.success,
        );
        props.onClose();
      }
    },
    [commandId, linkAdvertisingCampaignToPipe, props, t],
  );

  return (
    <Modal {...props} title={t('facebook:linkAdvertisingCampaignToPipe')}>
      <Form onSubmit={handleSubmit}>
        <FormElement
          component='autocomplete'
          options={advertisingCampaignsOptions}
          loading={isGetAdvertisingCampaigns}
          name={FormModalLinkAdvertisingCampaignToPipeValues.ADVERTISING_CAMPAIGN}
          label={t('common:tableLabels.campaign')}
          rules={FormRuler.required}
        />

        <FormElement
          component='autocomplete'
          options={pipesOptions}
          loading={isGetPipesLoading}
          name={FormModalLinkAdvertisingCampaignToPipeValues.PIPE}
          label={t('common:tableLabels.pipe')}
          rules={FormRuler.required}
        />

        <DialogActions>
          <Button isLoading={isLoading} type='submit' endIcon={<IconLink />}>
            {t('common:buttonActions.link')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal(ModalLinkAdvertisingCampaignToPipe);
