import { css, Drawer, styled } from '@mui/material';

import { ZINDEX } from '@/resources/constants';

import transientProps from '@/utils/transientProps';

export const StyledSidebar = styled(Drawer)`
  & > .MuiPaper-root {
    padding: 0;

    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06), 0px 8px 32px 0px rgba(0, 0, 0, 0.04);

    & .MuiTypography-root {
      margin: 0 !important;
    }

    & > div {
      padding: 0 24px;
      padding-top: 20px;
    }

    & > .MuiDivider-root {
      margin-top: 12px;
    }
  }
`;

export const HeaderSidebar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Overlay = styled('div', transientProps)<{ $isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${ZINDEX.OVERLAY};

  transition: 250ms linear;

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transition: 250ms linear;
      width: 0;
      height: 0;
    `}
`;

export const FiltersWrapper = styled('div')`
  display: flex;
  flex-direction: column;

  width: 426px;

  border: 0;
  padding: 0;

  .MuiFormControl-root,
  .MuiAutocomplete-root,
  .MuiInputBase-root {
    height: 56px !important;
  }

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-sizeSmall {
    padding-top: 8px;
  }

  & .MuiFormControl-root,
  .MuiAutocomplete-root {
    max-width: 100% !important;
  }

  & .MuiInputBase-root {
    max-width: 100%;
  }

  & div + div {
    margin-top: 20px;
  }
`;
