import { styled, css } from '@mui/material';

import Select from '@/components/Select';

import transientProps from '@/utils/transientProps';

export const StyledFormElementPicker = styled('div', transientProps)<{ $isDisabled?: boolean }>`
  padding: 14px 24px;

  ${({ $isDisabled, theme }) =>
    $isDisabled &&
    css`
      background-color: ${theme.palette.background.default};
    `};

  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.palette.other.outlineBorder};
`;

export const StyledFormElementPickerHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFormElementPickerBody = styled('div')`
  margin-top: 16px;

  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 8px;
  }
`;

export const StyledSelect = styled(Select)`
  margin: 0 8px;
`;
