import { AxiosPromise } from 'axios';

import Api from '@/utils/Api';

import {
  ApiFileGetParams,
  ApiFilePostBody,
  ApiFilePostResponse,
  ApiFileGetResponse,
} from '@/redux/files/files.types';

class FilesApi {
  static downloadFile({ id, ...params }: ApiFileGetParams): AxiosPromise<ApiFileGetResponse> {
    return Api.call(`/files/${id}`, { params, responseType: 'blob' });
  }

  static uploadFile(data: ApiFilePostBody): AxiosPromise<ApiFilePostResponse> {
    return Api.call('/files', { data, method: 'POST' });
  }
}

export default FilesApi;
