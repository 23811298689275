import { ApiArchiveParams, ApiResponse } from '@/types/api';
import { invalidatesTags, invalidatesListItem, providesList } from '@/services/utils';
import {
  ApiOfferByIdPatchBody,
  ApiOfferByIdPatchResponse,
  ApiOfferPostBody,
  ApiOfferPostResponse,
  ApiOffersGetQuery,
  ApiOffersGetResponse,
  ApiOfferStatusByIdPatchBody,
  ApiOfferByTrackerIdPostBody,
  ApiAddAllOffersFromTrackerPostBody,
  ApiOffersPartnersGetResponse,
  ApiOfferSynchronizationByTrackerPostResponse,
  ApiOfferSynchronizationByTrackerPostBody,
  ApiOffersPartnersGetParams,
  ApiAddAllOffersFromTrackerPostResponse,
} from '@/services/offers/offers.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

const baseUrl = '/offers';
const serviceTag = servicesTags.offers;

const offersApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getOffers: build.query<ApiOffersGetResponse, ApiOffersGetQuery>({
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getPartners: build.query<ApiOffersPartnersGetResponse, ApiOffersPartnersGetParams>({
      query: (params) => ({ url: `/dictionaries`, params }),
    }),
    createOffer: build.mutation<ApiOfferPostResponse, ApiOfferPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    addOfferByTrackerId: build.mutation<ApiOfferPostResponse, ApiOfferByTrackerIdPostBody>({
      query: ({ trackerOfferId: trackerId, ...body }) => ({
        url: `${baseUrl}/tracker/${trackerId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.tracker]),
    }),
    addAllOffersFromTracker: build.mutation<
      ApiAddAllOffersFromTrackerPostResponse,
      ApiAddAllOffersFromTrackerPostBody
    >({
      // TODO
      // NOTE
      // Тупо передаем в квери commandId
      query: (body) => ({
        url: `${baseUrl}/trackerAll`,
        method: 'POST',
        params: body,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.tracker]),
    }),
    updateOfferById: build.mutation<ApiOfferByIdPatchResponse, ApiOfferByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    synchronizationAllOffersFromTracker: build.mutation<
      ApiOfferSynchronizationByTrackerPostResponse,
      ApiOfferSynchronizationByTrackerPostBody
    >({
      query: (body) => ({
        url: `${baseUrl}/synchronization-all`,
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.tracker]),
    }),
    updateOfferStatusById: build.mutation<ApiOfferByIdPatchResponse, ApiOfferStatusByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}/status`, method: 'PATCH', body }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
    changeOfferArchiveStatus: build.mutation<ApiResponse<null>, ApiArchiveParams>({
      query: ({ id, ...params }) => ({
        url: `${baseUrl}/${id}/change-archive-status`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.offers]),
    }),
  }),
});

export const {
  useGetOffersQuery,
  useGetPartnersQuery,
  useLazyGetOffersQuery,
  useCreateOfferMutation,
  useUpdateOfferByIdMutation,
  useAddOfferByTrackerIdMutation,
  useUpdateOfferStatusByIdMutation,
  useAddAllOffersFromTrackerMutation,
  useChangeOfferArchiveStatusMutation,
  useSynchronizationAllOffersFromTrackerMutation,
} = offersApi;

export default offersApi;
