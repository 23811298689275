import { styled, css } from '@mui/material';

import IconArrowLeft from '@/icons/IconArrowLeft';

import transientProps from '@/utils/transientProps';

export const StyledSidebarHeader = styled('div', transientProps)<{ $isSidebarOpen: boolean }>`
  margin-bottom: 24px;

  display: flex;
  align-items: center;

  ${({ $isSidebarOpen }) =>
    $isSidebarOpen
      ? css`
          padding: 0 24px;
          justify-content: space-between;
        `
      : css`
          justify-content: center;
        `};
`;

export const StyledIconArrow = styled(IconArrowLeft, transientProps)<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: scale(-1, 1);
    `}
`;

export const LogoWrapper = styled('div')`
  height: 40px;
`;
