import { useTranslation } from 'react-i18next';
import { useState, useCallback, ChangeEvent } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

import { PageFiltersProps } from '@/components/PageFilters/PageFiltersTypes';
import { StyledPageFilters } from '@/components/PageFilters/PageFilterStyled';
import Button from '@/components/Button';

// import SearchIcon from '@/icons/SearchIcon';

const PageFilters = ({
  children,
  onSearch,
  searchLabel,
  className,
  isSpaceBetweenContent,
}: PageFiltersProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    if (!onSearch) return;
    onSearch(search);
  }, [onSearch, search]);

  const buttonLabel = t('search');
  const inputLabel = searchLabel || buttonLabel;

  return (
    <StyledPageFilters
      className={className}
      style={{ justifyContent: isSpaceBetweenContent ? 'space-between' : '' }}
    >
      {children}

      {onSearch && (
        <>
          <FormControl variant='outlined'>
            <InputLabel htmlFor='outlined-search'>{searchLabel}</InputLabel>
            <OutlinedInput
              value={search}
              label={inputLabel}
              onChange={handleChangeSearch}
              id='outlined-search'
              // endAdornment={
              //   <InputAdornment position='end'>
              //     {/* <SearchIcon size={IconSize.s} /> */}
              //   </InputAdornment>
              // }
            />
          </FormControl>

          <Button onClick={handleSearch} variant='contained'>
            {buttonLabel}
          </Button>
        </>
      )}
    </StyledPageFilters>
  );
};

export default PageFilters;
