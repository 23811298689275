import { css, styled, Checkbox, Switch, FormControlLabel } from '@mui/material';

import transientProps from '@/utils/transientProps';

export const StyledFormElement = styled('div', transientProps)<{
  $isCheckbox?: boolean;
}>`
  width: 100%;

  & + & {
    margin-top: 24px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: ${({ theme }) => theme.spacing(-2)};
  margin-bottom: ${({ theme }) => theme.spacing(-2)};
`;

export const StyledCheckboxContainer = styled(FormControlLabel, transientProps)<{
  $isAlignItemsStart?: boolean;
}>`
  ${({ $isAlignItemsStart }) =>
    $isAlignItemsStart &&
    css`
      align-items: flex-start;

      .MuiCheckbox-root {
        transform: translateY(-5px);
      }
    `};
`;

export const StyledRadioContainer = styled(FormControlLabel, transientProps)<{
  $isAlignItemsStart?: boolean;
}>`
  ${({ $isAlignItemsStart }) =>
    $isAlignItemsStart &&
    css`
      align-items: flex-start;

      .MuiRadio-root {
        transform: translateY(-3px);
      }
    `};
`;

export const StyledRadioContainerWrapper = styled('label')`
  cursor: pointer;
`;

export const StyledSwitch = styled(Switch, transientProps)<{ $isAlignRight?: boolean }>`
  ${({ $isAlignRight }) =>
    $isAlignRight &&
    css`
      margin-left: -8px;
    `}
`;
