import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import { IconProps } from '@/components/Icon/IconTypes';

const IconUnarchive = ({ onClick, color = 'primary' }: IconProps & { isDisabled?: boolean }) => {
  // @ts-ignore
  return <UnarchiveOutlinedIcon onClick={onClick} color={color} />;
};

export default IconUnarchive;
