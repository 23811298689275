import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'ConsumableCategories.title';

export const onboardingConsumableCategoriesPage = [
  {
    title,
    target: 'page-consumableCategories',
    placement: 'right',
    disableScrolling: true,
    content: {
      title: 'ConsumableCategories.step1.title',
      message: 'ConsumableCategories.step1.message',
    },
  },
] as const satisfies OnboardingSteps;
