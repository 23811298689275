import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { DialogActions } from '@mui/material';

import Select from '@/components/Select';
import Modal from '@/components/Modal';
import { StyledFormElement } from '@/components/FormElement/FormElementStyled';
import FormElement from '@/components/FormElement';
import Form from '@/components/Form';
import Button from '@/components/Button';

import FormRuler from '@/utils/FormRuler';
import Alerter, { AlerterTypes } from '@/utils/Alerter/Alerter';

import useUserInfo, { useTargetInfo } from '@/hooks/useUserInfo';
import useHighLevelUserCommands from '@/hooks/useHighLevelUserCommands';
import useAwaitCallback from '@/hooks/useAwaitCallback';

import { ApiUserByIdPatchBody } from '@/services/users/users.types';
import { useSetAllowedCommandsMutation, useUpdateUserByIdMutation } from '@/services/users/users';
import { ApiUserNonCommandByIdPatchBody } from '@/services/nonCommandsUsers/nonCommandsUsers.types';
import { useUpdateNonCommandUserByIdMutation } from '@/services/nonCommandsUsers/nonCommandsUsers';
import withStaticModal, { StaticModalWrappedComponent } from '@/modals/withStaticModal';
import {
  FormModalUserData,
  FormModalUserValues,
  ModalUserProps,
} from '@/modals/ModalUser/ModalUserTypes';

const ModalUser: StaticModalWrappedComponent<ModalUserProps> = (props) => {
  const { t } = useTranslation(['common', 'command']);

  const [defaultValues] = useState((): FormModalUserData<true> => {
    return {
      [FormModalUserValues.FIRST_NAME]: props.data?.firstName,
      [FormModalUserValues.LAST_NAME]: props.data?.lastName,
      [FormModalUserValues.TRACKER_ID]: props.data?.trackerId,
      [FormModalUserValues.SELFHOST_ID]: props.data?.leadId,
      [FormModalUserValues.SERVICE_INTEGRATIONS]:
        props.data?.servicesIntegrations.map((a) => a.paymentServiceIntegrationId) || [],
      [FormModalUserValues.ALLOWED_COMMANDS_IDS]:
        props.data?.allowedCommands?.map((a) => a.id) || [],
    };
  });

  const user = props.data;
  const { commands } = useHighLevelUserCommands();
  const { isTargetCommandFinancier, isTargetCompanyFinancier } = useTargetInfo(props.data);
  const { isHighLevelUser, isCompanyWithSelfhostApi, isCompanyWithTracker } = useUserInfo();

  const [updateUser, { isLoading }] = useUpdateUserByIdMutation();
  const [updateNonCommandUser, { isLoading: isUpdating }] = useUpdateNonCommandUserByIdMutation();
  const [setAllowedCommands, { isLoading: isUpdatingAllowedCommands }] =
    useSetAllowedCommandsMutation();

  const [handleSubmit] = useAwaitCallback(
    async (data: FormModalUserData) => {
      if (!props.data) {
        return Alerter.show(t('command:userIdNotPassed'), AlerterTypes.error);
      }

      const promises = [];

      const commonBody: Pick<
        ApiUserByIdPatchBody,
        'id' | 'lastName' | 'firstName' | 'servicesIntegrations'
      > = {
        id: props.data.id,
        lastName: data[FormModalUserValues.LAST_NAME],
        firstName: data[FormModalUserValues.FIRST_NAME],
        servicesIntegrations: data[FormModalUserValues.SERVICE_INTEGRATIONS],
      };

      if (!props.data.command) {
        const body: ApiUserNonCommandByIdPatchBody = commonBody;
        promises.push(updateNonCommandUser(body));
      } else {
        const body: ApiUserByIdPatchBody = {
          ...commonBody,
          commandId: props.data.commandId || undefined,
          trackerId: data[FormModalUserValues.TRACKER_ID],
          leadId: data[FormModalUserValues.SELFHOST_ID],
        };

        promises.push(await updateUser(body));
      }

      if (isTargetCommandFinancier) {
        promises.push(
          setAllowedCommands({
            id: props.data.id,
            commandIds: data[FormModalUserValues.ALLOWED_COMMANDS_IDS],
          }),
        );
      }

      const [response] = await Promise.all(promises);

      if ('data' in response) {
        props.onClose();
      }
    },
    [defaultValues[FormModalUserValues.SERVICE_INTEGRATIONS]],
  );

  const filteredCommands = useMemo(
    () => commands.filter((a) => a.value !== props.data?.command?.id),
    [commands, props.data?.command?.id],
  );

  return (
    <Modal {...props} title={t('command:editEmployee')}>
      <Form onSubmit={handleSubmit} defaultValues={defaultValues}>
        <FormElement
          label={t('common:formLabels.firstName')}
          component='input'
          rules={FormRuler.requiredInput}
          name={FormModalUserValues.FIRST_NAME}
        />

        <FormElement
          label={t('common:formLabels.lastName')}
          component='input'
          rules={FormRuler.requiredInput}
          name={FormModalUserValues.LAST_NAME}
        />

        {!isTargetCompanyFinancier &&
          !isTargetCommandFinancier &&
          (isCompanyWithTracker || isCompanyWithSelfhostApi) && (
            <>
              {isCompanyWithTracker && (
                <FormElement
                  label='TrackerId'
                  component='input'
                  name={FormModalUserValues.TRACKER_ID}
                />
              )}

              {isCompanyWithSelfhostApi && (
                <FormElement
                  label='SelfhostId'
                  component='input'
                  name={FormModalUserValues.SELFHOST_ID}
                />
              )}
            </>
          )}

        {isHighLevelUser && isTargetCommandFinancier && (
          <FormElement
            isMultiple
            renderSelected='placeholder'
            component='select'
            label={t('common:allowedCommands')}
            values={filteredCommands}
            name={FormModalUserValues.ALLOWED_COMMANDS_IDS}
          />
        )}

        {isHighLevelUser && user && isTargetCommandFinancier && (
          <StyledFormElement>
            <Select
              fullWidth
              disabled
              value={user.command.id}
              label={t('common:mainCommand')}
              options={[{ value: user?.command.id, label: user?.command.name }]}
            />
          </StyledFormElement>
        )}

        <FormElement
          label='BrocardId'
          component='inputChips'
          name={FormModalUserValues.SERVICE_INTEGRATIONS}
        />

        <DialogActions>
          <Button type='submit' isLoading={isLoading || isUpdating || isUpdatingAllowedCommands}>
            {t('common:buttonActions.save')}
          </Button>
        </DialogActions>
      </Form>
    </Modal>
  );
};

export default withStaticModal<ModalUserProps>(ModalUser);
