import { useCallback, useMemo } from 'react';

import { SelectProps } from '@/components/Select/SelectTypes';
import Select from '@/components/Select';

import useQueryParam from '@/hooks/useQueryParam';
import { SelectValue } from '@/hooks/useControlSelectValue';

export type HookComponentProps<Multiple extends boolean = false> = Omit<
  SelectProps<Multiple>,
  'label' | 'options'
>;

interface Hook {
  <T extends string, R extends string>(key: T, initialValue: R): Record<T, R> & {
    Component: <Multiple extends boolean = false>(props: SelectProps<Multiple>) => JSX.Element;
  };
}

const usePageFilter: Hook = (key, initialValue) => {
  const [query, setQuery] = useQueryParam(key, initialValue);

  const handleValueSelect = useCallback(
    <Multiple extends boolean>(value: SelectValue<Multiple>) =>
      setQuery(value as SelectValue<false>, 'replaceIn', true),
    [setQuery],
  );

  return {
    [key]: query,
    Component: <Multiple extends boolean = false>(props: SelectProps<Multiple>) => {
      const mutableProps: Partial<SelectProps<Multiple>> = useMemo(() => {
        const commonProps: Partial<SelectProps<Multiple>> = {
          size: 'small',
          maxWidth: 240,
        };

        if (!props) {
          return commonProps;
        }

        return { ...commonProps, ...props };
      }, [props]);

      return (
        <Select
          {...mutableProps}
          // NOTE
          // Если isClearable не требуется
          // Обязательно зафиксировать это комментарием
          // Сейчас точно используется на странице расходников
          isClearable
          value={query as unknown as SelectValue<Multiple>}
          label={props.label}
          options={props.options}
          onSelect={handleValueSelect}
        />
      );
    },
  } as any;
};

export default usePageFilter;
