import {
  invalidatesTags,
  invalidatesListItem,
  providesList,
  providesListItem,
} from '@/services/utils';
import {
  ApiCompanyByIdGetQuery,
  ApiCompanyByIdGetResponse,
  ApiCompanyByIdPatchBody,
  ApiCompanyByIdPatchResponse,
  ApiCompanyPostBody,
  ApiCompanyPostResponse,
  ApiCompaniesGetQuery,
  ApiCompaniesGetResponse,
} from '@/services/companies/companies.types';
import commonApi from '@/services/common/common';
import { servicesTags } from '@/services';

type EntityId = string | number;

const serviceTag = servicesTags.companies;
const baseUrl = '/companies';

const companiesApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ApiCompaniesGetResponse, ApiCompaniesGetQuery>({
      query: (params) => ({ url: `${baseUrl}`, params: params || undefined }),
      providesTags: providesList(serviceTag),
    }),
    getCompanyById: build.query<ApiCompanyByIdGetResponse, ApiCompanyByIdGetQuery>({
      query: ({ id }) => `${baseUrl}/${id}`,
      providesTags: providesListItem(serviceTag),
    }),
    createCompany: build.mutation<ApiCompanyPostResponse, ApiCompanyPostBody>({
      query: (body) => ({ url: `${baseUrl}`, method: 'POST', body }),
      invalidatesTags: invalidatesTags(serviceTag),
    }),
    updateCompanyById: build.mutation<ApiCompanyByIdPatchResponse, ApiCompanyByIdPatchBody>({
      query: ({ id, ...body }) => ({ url: `${baseUrl}/${id}`, method: 'PATCH', body }),
      invalidatesTags: invalidatesTags([serviceTag, servicesTags.auth]),
    }),
    deleteCompanyById: build.mutation<void, EntityId>({
      query: (id) => ({ url: `${baseUrl}/${id}`, method: 'DELETE' }),
      invalidatesTags: invalidatesListItem(serviceTag),
    }),
  }),
});

export const {
  useLazyGetCompaniesQuery,
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyByIdMutation,
  useDeleteCompanyByIdMutation,
} = companiesApi;

export default companiesApi;
