import { Components, Theme } from '@mui/material';

import typography from '@/components/MaterialThemeProvider/config/typography';

const tableComponents: Components<Omit<Theme, 'components'>> = {
  MuiTableCell: {
    styleOverrides: {
      root: {
        ...typography.subtitle2,
        whiteSpace: 'nowrap',
      },
      body: ({ theme }) => ({
        border: 'none',
        minHeight: 72,

        '& .MuiTable-root': {
          '.MuiTableBody-root .MuiTableRow-root': {
            borderBottom: `1px solid ${theme.palette.other.outlineBorder}`,
          },
        },
      }),
      head: ({ theme }) => ({
        padding: theme.spacing(2, 4),
        minHeight: 42,
        fontWeight: 500,
        borderColor: theme.palette.other.outlineBorder,
      }),
    },
  },
};

export default tableComponents;
