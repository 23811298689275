import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

import { IconProps } from '@/components/Icon/IconTypes';

const IconArchive = ({ onClick, color = 'error' }: IconProps & { isDisabled?: boolean }) => {
  // @ts-ignore
  return <ArchiveOutlinedIcon onClick={onClick} color={color} />;
};

export default IconArchive;
