import { OnboardingSteps } from '@/components/OnboardingTour/OnboardingTourTypes';

const title: OnboardingSteps[0]['title'] = 'FinancialOperations.title';

export const onboardingOperationPage = [
  {
    title,
    target: 'page-operations',
    placement: 'right',
    content: {
      title: 'FinancialOperations.step1.title',
      message: 'FinancialOperations.step1.message',
    },
  },
  {
    title,
    target: 'step-operations',
    content: {
      title: 'FinancialOperations.step2.title',
      message: 'FinancialOperations.step2.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: 'step-operation-3',
    placement: 'right',
    content: {
      title: 'FinancialOperations.step3.title',
      message: 'FinancialOperations.step3.message',
    },
  },
  {
    title,
    hideWithoutData: true,
    target: 'step-operation-4',
    placement: 'left',
    content: {
      title: 'FinancialOperations.step4.title',
      message: 'FinancialOperations.step4.message',
    },
  },
] as const satisfies OnboardingSteps;
