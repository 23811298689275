/* eslint-disable max-len */
import { IconProps, IconSize } from '@/components/Icon/IconTypes';
import Icon from '@/components/Icon';

interface IconSmallCircleProps extends IconProps {
  status?: 'active' | 'inactive';
}

const IconSmallCircle = ({
  size = IconSize.m,
  status = 'active',
  ...restProps
}: IconSmallCircleProps) => {
  const color = status === 'active' ? '#53BC64' : '#FF5A5A';

  return (
    <Icon {...restProps} customWidth={8} customHeight={8} viewBox='0 0 8 8'>
      <circle cx='4' cy='4' r='4' fill={color} />
    </Icon>
  );
};

export default IconSmallCircle;
