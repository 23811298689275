import { css, styled } from '@mui/material';

import Typography from '@/components/Typography';

import transientProps from '@/utils/transientProps';

export const StyledEmptyMessageWithIconWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledWidthWrapper = styled('div')`
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledTitle = styled(Typography)`
  margin-top: 32px;
  font-size: 16px;
  text-align: center;
`;

export const StyledDescription = styled(Typography, transientProps)<{ $hasMarginBottom: boolean }>`
  margin-top: 16px;
  font-weight: 500;
  text-align: center;

  ${({ $hasMarginBottom }) =>
    $hasMarginBottom &&
    css`
      margin-bottom: 32px;
    `}
`;
